import { DownloadOutlined, PictureFilled } from '@ant-design/icons'
import { Button, message } from 'antd'
import axios from 'axios'
import moment from 'moment'
import React, { useState } from 'react'
import styled from 'styled-components/macro'

import { NODE_URL } from '../../../../constants'
import { isVideo } from '../../../../utils'
import VideoItem from '../../../VideoItem'

export const UploadsListItem = ({ upload, brandId, nodeToken }) => {
  const [isRead, setIsRead] = useState(upload.read)
  const handleOnRead = async messageId => {
    try {
      await axios.put(`${NODE_URL}/brand/${brandId}/campaign/uploads/${messageId}/read`, {
        headers: { Authorization: `Bearer ${nodeToken}` },
      })
      setIsRead(true)
    } catch (err) {
      console.error(err)
      message.error('Error while marking content as read.')
    }
  }

  return (
    <Wrapper
      className='content-wrapper'
      onClick={() => window.open(upload.content, '_blank')}
      onKeyDown={() => window.open(upload.content, '_blank')}
      role='button'
      tabIndex={0}>
      {isVideo(upload.content) ? (
        <div className='thumbnail'>
          <VideoItem url={upload.content} />
        </div>
      ) : (
        <div
          className='thumbnail'
          style={{
            backgroundImage: `url(${upload.content})`,
          }}>
          <PictureFilled className='img-icon' style={{ color: '#007df0', fontSize: '15px' }} />
        </div>
      )}

      <div className='user-info'>
        <p className='name'>
          {upload.user.firstName} {upload.user.lastName}
        </p>
        <p className='date'>
          {upload.created ? moment(new Date(upload.created)).format('lll') : 'Unknown Date'}
        </p>
      </div>

      {!isRead && <div className='notification-badge' />}
      <Button type='link' className='action-button' onClick={() => handleOnRead(upload.id)}>
        <DownloadOutlined /> Download
      </Button>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  .img-icon {
    position: absolute;
    top: 15px;
    left: 15px;
    height: 25px;
    width: 25px;
    background: #fff;
    border-radius: 50%;
    padding: 5px 3px 3px 3px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  }
`
