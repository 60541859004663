import { PictureOutlined } from '@ant-design/icons'
import moment from 'moment'
import numeral from 'numeral'
import React from 'react'
import { CircleFlag } from 'react-circle-flags'
import styled from 'styled-components'

import { ImageLoader } from '../../components/general/ImageLoader'
import comment from '../../images/icons/comment.svg'
import like from '../../images/icons/like.svg'
import video from '../../images/icons/video.svg'
import impression from '../../images/icons/view.svg'

export const ContentCard = ({ view, content }) => {
  const user = content.metaData?.user

  return (
    <Wrapper className={view}>
      <a className='content-inner' href={content.url} target='_blank' rel='noopener noreferrer'>
        {content.type === 'video' && <img src={video} alt='video' className='video-icon' />}
        {content.metaData.thumbnailUrl ? (
          <div className='thumbnail'>
            <ImageLoader src={content.metaData.thumbnailUrl} alt='' />
          </div>
        ) : (
          <div className='thumbnail placeholder'>
            <PictureOutlined />
          </div>
        )}

        <div className='text'>
          <div className='user'>
            <div className='user-info-row'>
              <p className='name'>@{user?.username}</p>
              {user?.geo?.country?.code && (
                <CircleFlag
                  className='flag'
                  countryCode={`${user.geo.country.code.toLowerCase()}`}
                />
              )}
            </div>
            {user?.followers ? (
              <p className='followers'>
                {numeral(user?.followers).format('0a').toUpperCase()} followers
              </p>
            ) : (
              ''
            )}
          </div>
          <p className='caption'>{content.title}</p>
          {content.metaData.sponsor && (
            <p className='sponsor'>Sponsor: @{content.metaData.sponsor.username}</p>
          )}
          <p className='date'>{moment(content.posted).format('lll')}</p>
        </div>

        <div className='stats'>
          <div className={`stat ${!content.likes && 'none'}`}>
            <img src={like} alt='like' className='icon' />
            <span className='num'>{content.likes ? numeral(content.likes).format('0a') : '-'}</span>
          </div>
          <div className={`stat ${!content.comments && 'none'}`}>
            <img src={comment} alt='comment' className='icon' />
            <span className='num'>
              {content.comments ? numeral(content.comments).format('0a') : '-'}
            </span>
          </div>
          <div className={`stat ${!content.views && 'none'}`}>
            <img src={impression} alt='view' className='icon' />
            <span className='num'>
              {content.views
                ? numeral((content.views || 0) + (content.replays || 0)).format('0a')
                : '-'}
            </span>
          </div>
        </div>
      </a>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  .content-inner {
    height: 100%;
    display: flex;
    position: relative;
  }
  &.grid {
    .content-inner {
      flex-direction: column;
      margin: 0 auto;
      padding: 5px;
      gap: 5px;
    }
    .thumbnail {
      height: 270px;
      width: 100%;
    }
    .caption {
      margin: 5px;
    }
    .stats {
      margin: auto 0 0 0;
      padding-top: 5px;
      border-top: 1px solid #e6e6e6;
    }
  }
  &.list {
    .content-inner {
      align-items: center;
      flex-wrap: wrap;
      gap: 30px;
      padding: 10px;
    }
    .thumbnail {
      height: 150px;
      min-width: 150px;
      max-width: 150px;
      flex: 1;
    }
    .text {
      flex: 2;
      margin-right: 20px;
    }
    .caption {
      margin: 0;
    }
    .stats {
      flex: 1;
    }
  }
  .content-inner {
    cursor: pointer;
    background-color: white;
    border-radius: 5px;
    transition: 0.2s ease-in-out;
  }
  .video-icon {
    position: absolute;
    top: 15px;
    left: 15px;
    height: 25px;
    width: 25px;
    background: #fff;
    border-radius: 50%;
    padding: 3px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  }
  .thumbnail {
    background-color: #e6e6e6;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      border-radius: 5px;
    }
    &.placeholder {
      .anticon {
        font-size: 50px;
        opacity: 0.1;
      }
    }
  }
  .user {
    margin: 5px 0;
    .user-info-row {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .flag {
        height: 20px;
        width: 20px;
      }
    }
    p {
      margin: 0;
    }
    .name {
      color: #027df0;
    }
    .followers {
      color: #333;
      font-size: 0.8rem;
    }
  }
  .caption {
    color: #080830;
    font-family: 'Campton-Light', sans-serif;
    font-size: 0.8rem;
    margin: 10px;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-word;
  }
  .sponsor {
    font-size: 0.7rem;
    margin: 0;
  }
  .date {
    color: #999;
    font-size: 0.7rem;
    margin: 10px 0 0 0;
  }
  .stats {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 30px;
    margin-top: 5px;
    font-size: 12px;
    margin: 0 20px;
    .stat {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 5px;
      flex: 1;
      &.none {
        opacity: 0.3;
      }
    }
    .icon {
      height: 20px;
      width: 20px;
    }
    .num {
      color: #444;
      font-size: 0.7rem;
    }
  }

  &:hover {
    .content-inner {
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    }
  }
`
