import { DownOutlined, MinusCircleOutlined, UpOutlined } from '@ant-design/icons'
import { Button, message, Drawer, Switch, Tooltip } from 'antd'
import axios from 'axios'
import React, { useContext, useState } from 'react'
import { queryCache } from 'react-query'
import styled from 'styled-components'

// import { CreateEditStep } from './CreateEditStep'
import { CreateEditStep } from './CreateEditStep'
import { NODE_URL } from '../../constants'
import { BrandContext } from '../../contexts/BrandContext'

const CampaignStep = ({ index, step, setSteps, saveSteps, steps, swapSteps, campaignId }) => {
  const { brandId } = useContext(BrandContext)
  const programmedStep = step.step
  const [editOpen, setEditOpen] = useState(false)
  const [deleting, setDeleting] = useState(false)
  const [notificationEnabled, setNotificationEnabled] = useState(
    step.metaData?.notificationEnabled || false
  )
  const title =
    step.customStepTitle || programmedStep?.title?.replace('Old: ', '') || 'Untitled Step'

  const handleToggle = async () => {
    // Update toggle state
    setNotificationEnabled(!notificationEnabled)

    // Create a new steps array with the updated step
    const newStepsArr = steps.map(item => {
      if (step.id === item.id) {
        item.metaData = {
          ...item.metaData,
          notificationEnabled: !notificationEnabled,
        }
      }
      return item
    })

    saveSteps(newStepsArr)
  }

  const handleRemove = async () => {
    setDeleting(true)
    axios
      .delete(`${NODE_URL}/brand/${brandId}/campaign/${campaignId}/step/${step.id}`)
      .then(res => {
        message.success('Step Deleted')
        setSteps(res.data?.steps)
        queryCache.invalidateQueries('campaign')
      })
      .catch(err => {
        message.error(err?.response?.data?.err || 'Something went wrong deleting the step')
      })
      .finally(() => setDeleting(false))
  }

  const handleEditSubmit = async (values, stepId) => {
    const {
      brandStatus,
      influencerStatus,
      brandCompletedStatus,
      influencerCompletedStatus,
      customForm,
      ...customStep
    } = values

    const newStepsArr = steps.map(item => {
      if (stepId === item.id) {
        item = {
          ...item,
          ...customStep,
          customForm,
          stepSettings: {
            brandStatus,
            influencerStatus,
            brandCompletedStatus,
            influencerCompletedStatus,
          },
        }
      }
      return item
    })

    await saveSteps(newStepsArr)
    setEditOpen(false)
  }

  return (
    <Wrapper>
      <div className='container'>
        <div className='controls'>
          <Button type='link' disabled={index + 1 === steps.length} onClick={() => swapSteps(1)}>
            <DownOutlined />
          </Button>
          <Button type='link' disabled={index === 0 && true} onClick={() => swapSteps(-1)}>
            <UpOutlined />
          </Button>
        </div>
        <span className='number'>{step.placement}</span>
        <div
          onClick={() => !step.stepId && setEditOpen(true)}
          onKeyDown={() => !step.stepId && setEditOpen(true)}
          role='button'
          tabIndex={0}
          className={`step-container ${!step.stepId && 'custom'}`}>
          <span className='title'>{title}</span>
        </div>
        <div className='right'>
          <div>
            <Tooltip
              arrow
              title={`Turn ${
                step.metaData?.notificationEnabled ? 'off' : 'on'
              } notifications for this step.`}>
              <Switch
                size='medium'
                checked={step.metaData?.notificationEnabled}
                onChange={() => handleToggle(step.id)}
              />
            </Tooltip>
          </div>
          <div className='remove'>
            <Button
              disabled={step.optInSteps?.length}
              onClick={() => handleRemove(step.id)}
              danger
              type='link'
              icon={<MinusCircleOutlined />}
              loading={deleting}
            />
          </div>
        </div>
      </div>
      <Drawer
        open={editOpen}
        footer={null}
        title='Edit Custom Step'
        destroyOnClose
        width={550}
        onClose={() => setEditOpen(false)}>
        <CreateEditStep handleEditSubmit={handleEditSubmit} step={step} />
      </Drawer>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  .container {
    background: #fff;
    border-radius: 5px;
    border: 1px solid #e6e6e6;
    padding: 5px;
    display: flex;
    align-items: center;
    margin-bottom: 8px;

    .controls {
      margin-right: 8px;
      .ant-btn {
        font-size: 14px;
        padding: 5px;
      }
    }

    .number {
      font-size: 1rem;
      margin-right: 8px;
      background-color: #ecf6ff;
      color: #027df0;
      border-radius: 5px;
      padding: 2px 8px;
      font-weight: bold;
    }

    .step-container {
      max-width: 350px;
      .title {
        font-size: 1rem;
      }
      &:not(.custom) {
        color: gray;
      }
      &.custom {
        color: #027df0;
        cursor: pointer;
      }
    }

    .right {
      flex: 1;
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
    .notification {
      width: 14px;
      filter: invert(100%);
    }
  }
`

export default CampaignStep
