import {
  LoadingOutlined,
  PlusOutlined,
  DeleteOutlined,
  InfoCircleOutlined,
  UndoOutlined,
  StopOutlined,
} from '@ant-design/icons'
import { Collapse, Button, Input, Empty, Tooltip, message } from 'antd'
import axios from 'axios'
import { Formik, Form } from 'formik'
import { debounce } from 'lodash'
import React, { useContext, useMemo, useState } from 'react'
import { queryCache, useQuery } from 'react-query'

import { ProductListItems } from './ProductListItems'
import FormItem from '../../../components/forms/FormItem'
import { NODE_URL } from '../../../constants'
import { BrandContext } from '../../../contexts/BrandContext'

export const ProductLists = () => {
  const { brandId } = useContext(BrandContext)

  const { data: lists } = useQuery(['product-lists', brandId], () =>
    axios.get(`${NODE_URL}/brand/${brandId}/product-list`).then(res => res.data.lists)
  )
  const [newListLoading, setNewListLoading] = useState(false)
  const [updateListLoading, setUpdateListLoading] = useState([])
  const [deleteListLoading, setDeleteListLoading] = useState([])

  const handleCreateList = async () => {
    setNewListLoading(true)
    await axios
      .post(`${NODE_URL}/brand/${brandId}/product-list`)
      .then(() => queryCache.invalidateQueries(['product-lists']))
      .catch(() => message.error('Something went wrong'))
      .finally(() => setNewListLoading(false))
  }

  const debouncedUpdateList = useMemo(
    () =>
      debounce(async list => {
        setUpdateListLoading(prev => [...prev, list.id])
        await axios
          .put(`${NODE_URL}/brand/${brandId}/product-list/${list.id}`, {
            title: list.title,
            description: list.description,
          })
          .catch(() => message.error('Something went wrong'))
          .finally(() => setUpdateListLoading(prev => prev.filter(id => id !== list.id)))
      }, 400),
    []
  )

  const handleUpdateList = async list => {
    queryCache.setQueryData(['product-lists', brandId], prev =>
      prev?.map(v => (v.id === list.id ? list : v))
    )
    await debouncedUpdateList(list)
  }

  const handleDeleteList = async listId => {
    setDeleteListLoading(prev => [...prev, listId])
    await axios
      .delete(`${NODE_URL}/brand/${brandId}/product-list/${listId}`)
      .then(() => queryCache.invalidateQueries(['product-lists']))
      .catch(() => message.error('Something went wrong'))
      .finally(() => setDeleteListLoading(prev => prev.filter(id => id !== listId)))
  }

  const handleReactivateList = async listId => {
    setDeleteListLoading(prev => [...prev, listId])
    await axios
      .put(`${NODE_URL}/brand/${brandId}/product-list/${listId}`, {
        activate: true,
      })
      .catch(() => message.error('Something went wrong'))
      .finally(() => setDeleteListLoading(prev => prev.filter(id => id !== listId)))
  }

  return (
    <Formik initialValues={{}} onSubmit={() => {}}>
      {() => {
        return (
          <Form className='account-section' id='product-lists'>
            <div className='section-header'>
              <h2 className='section-title'>Product Lists</h2>
              <Button
                type='primary'
                loading={newListLoading}
                onClick={handleCreateList}
                icon={<PlusOutlined />}>
                New
              </Button>
            </div>
            <div className='section-body'>
              {lists?.length ? (
                <Collapse>
                  {lists.map(list => (
                    <Collapse.Panel
                      header={
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                          }}>
                          <div>
                            {list.title}{' '}
                            {list.deactivated && (
                              <Tooltip title='This list is deactivated and cannot be used for new opt-ins.'>
                                <span className='deactivated'>(Deactivated) </span>
                              </Tooltip>
                            )}
                            {updateListLoading.includes(list.id) && <LoadingOutlined spin />}
                          </div>
                          <Tooltip
                            title={
                              list.deactivated
                                ? 'Reactivate'
                                : list.inUse
                                  ? 'Deactivate (cannot delete list in use)'
                                  : 'Delete'
                            }>
                            <Button
                              icon={
                                list.deactivated ? (
                                  <UndoOutlined />
                                ) : list.inUse ? (
                                  <StopOutlined />
                                ) : (
                                  <DeleteOutlined />
                                )
                              }
                              danger={!list.deactivated}
                              type='link'
                              loading={deleteListLoading.includes(list.id)}
                              onClick={e => {
                                e.stopPropagation()
                                if (list.deactivated) {
                                  handleReactivateList(list.id)
                                  return
                                }
                                handleDeleteList(list.id)
                              }}
                            />
                          </Tooltip>
                        </div>
                      }
                      key={list.id}>
                      <FormItem size='small' label='Title'>
                        <Input
                          value={list.title}
                          onChange={e =>
                            handleUpdateList({
                              ...list,
                              title: e.target.value,
                            })
                          }
                        />
                      </FormItem>
                      <FormItem size='small' label='Description'>
                        <Input.TextArea
                          value={list.description}
                          onChange={e =>
                            handleUpdateList({
                              ...list,
                              description: e.target.value,
                            })
                          }
                        />
                      </FormItem>
                      <FormItem size='small' label='Items'>
                        <ProductListItems list={list} />
                      </FormItem>
                    </Collapse.Panel>
                  ))}
                </Collapse>
              ) : (
                <Empty
                  description={
                    <>
                      No products yet.{' '}
                      <Tooltip title='Create product lists for your campaigns to allow creators to select specific items.'>
                        <InfoCircleOutlined />
                      </Tooltip>
                    </>
                  }
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                />
              )}
            </div>
          </Form>
        )
      }}
    </Formik>
  )
}
