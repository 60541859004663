import { UndoOutlined, CheckCircleOutlined } from '@ant-design/icons'
import { Button, message } from 'antd'
import axios from 'axios'
import { Form, Formik } from 'formik'
import React, { useContext } from 'react'
import { queryCache } from 'react-query'
import styled from 'styled-components'

import SkipStep from './SkipStep'
import { NODE_URL } from '../../constants'
import { UserContext } from '../../contexts/UserContext'

const INFLUENCER_SUBMIT_CONTENT_PREVIEW = ({
  optIn,
  campaignStep,
  optInStep,
  setSelectedStepPlacement,
}) => {
  const { nodeToken } = useContext(UserContext)

  const handleSubmit = async ({ previousStep, stepStatus }) => {
    try {
      await axios.post(
        `${NODE_URL}/brand/opt-in/${optIn.id}/step/${campaignStep.id}`,
        { previousStep, stepStatus },
        {
          headers: {
            Authorization: `Bearer ${nodeToken}`,
          },
        }
      )
      message.success('Step has been updated')
      queryCache.invalidateQueries('opt-ins')
      setSelectedStepPlacement(optIn.currentStep?.placement)
    } catch (err) {
      message.error('Sorry, something went wrong.')
    }
  }

  return (
    <Wrapper>
      <Formik initialValues={{}} onSubmit={handleSubmit}>
        {({ isSubmitting, setValues, submitForm }) => (
          <Form>
            {optInStep?.completed ? (
              <div className='action'>
                <span>The creator has submitted their content preview.</span>
                <SkipStep
                  optInStep={optInStep}
                  isSubmitting={isSubmitting}
                  setValues={setValues}
                  submitForm={submitForm}>
                  <Button
                    type='primary'
                    htmlType='button'
                    className='skip-btn'
                    icon={optInStep?.completed ? <UndoOutlined /> : <CheckCircleOutlined />}
                    loading={isSubmitting}>
                    Mark as {optInStep?.completed ? 'Incomplete' : 'Complete'}
                  </Button>
                </SkipStep>
              </div>
            ) : (
              <div className='action'>
                <span>Waiting for creator to submit their content preview.</span>
                <SkipStep
                  optInStep={optInStep}
                  isSubmitting={isSubmitting}
                  setValues={setValues}
                  submitForm={submitForm}>
                  <Button
                    type='primary'
                    htmlType='button'
                    className='skip-btn'
                    icon={optInStep?.completed ? <UndoOutlined /> : <CheckCircleOutlined />}
                    loading={isSubmitting}>
                    Mark as {optInStep?.completed ? 'Incomplete' : 'Complete'}
                  </Button>
                </SkipStep>
              </div>
            )}
          </Form>
        )}
      </Formik>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: grid;
  place-items: center;
  height: 80px;

  .action {
    display: flex;
    flex-direction: column;
    align-items: center;

    .skip-btn {
      margin-top: 20px;
    }
  }
`

export default INFLUENCER_SUBMIT_CONTENT_PREVIEW
