import { Button } from 'antd'
import React from 'react'
import styled from 'styled-components'

import { Footer } from './Footer'
import { BOOK_DEMO_URL } from '../constants'
import { useDocumentTitle } from '../custom-hooks/useDocumentTitle'
import hero from '../images/book-demo-illustration.svg'

export const BookDemo = () => {
  const urlParams = window.location.href?.split('?')[1] || ''
  useDocumentTitle('Book a Demo | Brands')

  return (
    <Wrapper>
      <section className='banner'>
        <div className='text'>
          <h1>Learn How to Drive Sales with Creators</h1>
          <ul>
            <li>All campaigns are exposed to our community of 175,000+ creators</li>
            <li>Upload unlimited campaigns</li>
            <li>Invite and collaborate with as many creators as you&apos;d like</li>
            <li>Sponsor or download to repurpose content for further marketing initiatives</li>
          </ul>
          <Button type='primary'>
            <a href={`${BOOK_DEMO_URL}?${urlParams}`} target='_blank' rel='noopener noreferrer'>
              Book a Demo Today
            </a>
          </Button>
        </div>
        <div className='hero-img'>
          <img src={hero} alt='' />
        </div>
      </section>

      <Footer />
    </Wrapper>
  )
}

export const Wrapper = styled.main`
  // MOBILE
  background: #fff;
  color: #23262f;
  font-family: 'Poppins-Regular', sans-serif;
  max-width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  min-height: calc(100vh - 50px);
  .banner {
    flex: 1;
    width: 90%;
    max-width: 1800px;
    background: #ece5ff;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 20px;
    .text {
      max-width: 550px;
      margin: 40px 20px;
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 20px;
    }
    h1 {
      font-family: 'Gilroy-ExtraBold', Verdana, sans-serif;
      font-size: 2rem;
      margin: 0;
    }
    p {
      font-family: 'Poppins-Medium', sans-serif;
      color: #777e90;
      font-size: 1rem;
      margin: 0;
    }
    ul {
      font-family: 'Poppins-Medium', sans-serif;
      color: #777e90;
      padding: 0 0 0 20px;
      margin: 0;
      text-align: left;
      max-width: 450px;
      li {
        margin: 10px 0;
      }
    }
    button {
      background: #23262f;
      color: #fff;
      border: none;
      padding: 8px 30px;
      font-size: 1rem;
      transition: 0.2s ease-in-out;
      height: 42px;
      &:hover {
        background: ${props => props.theme.crcoTechBlue};
      }
    }
    .hero-img {
      margin: 20px;
      max-width: 400px;
    }
  }

  // TABLET
  @media only screen and (min-width: ${props => props.theme.breakpointTablet}) {
    .banner {
      border-radius: 30px;
      margin: 40px;
      .text {
        margin: 60px 30px;
        gap: 30px;
      }
      h1 {
        font-size: 3rem;
      }
      ul {
        font-size: 1rem;
      }
      .hero-img {
        max-width: 500px;
        margin: 30px;
      }
    }
  }

  // DESKTOP
  @media only screen and (min-width: ${props => props.theme.breakpointDesktop}) {
    .banner {
      width: 95%;
      flex-direction: row;
      justify-content: space-evenly;
      border-radius: 40px;
      .text {
        margin: 60px 20px;
        text-align: left;
        align-items: flex-start;
        z-index: 1;
        h1 {
          font-size: 60px;
        }
        ul {
          font-size: 18px;
          li {
            margin: 15px 0;
          }
        }
        button {
          margin: 0;
        }
      }
      .hero-img {
        max-width: 600px;
        margin: 60px 20px;
      }
    }
  }
`
