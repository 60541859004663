import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import facebookIcon from '../images/icons/social/facebook-circle.svg'
import instagramIcon from '../images/icons/social/instagram-circle.svg'
import tiktokIcon from '../images/icons/social/tiktok-circle.svg'
import xIcon from '../images/icons/social/x-circle.svg'
import youtubeIcon from '../images/icons/social/youtube-circle.svg'
import creatorLogo from '../images/logos/creator-logo-white.svg'

export const Footer = () => {
  const currentYear = new Date().getFullYear()

  return (
    <Wrapper>
      <div className='inner'>
        <div className='logo-wrapper'>
          <img className='logo' src={creatorLogo} alt='creator.co' />
          <div className='copyright'>&copy; {currentYear} Creator.co. All rights reserved.</div>
        </div>

        <div className='links'>
          <div className='group'>
            <h4>Brands</h4>
            <a href='https://creator.co/brands'>Home</a>
            <Link to='/pricing'>Pricing</Link>
            <a href='https://creator.co/brand-terms'>Terms & Conditions</a>
            <a href='https://creator.co/brand-contact'>Contact Us</a>
            <a href='https://creator.co/brand-faq'>FAQ</a>
          </div>
          <div className='group'>
            <h4>Creators</h4>
            <a href='https://creator.co/creators'>Home</a>
            <a href='https://creator.co/creator-privacy'>Privacy Policy</a>
            <a href='https://creator.co/creator-terms'>Terms & Conditions</a>
            <a href='https://creator.co/creator-contact'>Contact Us</a>
            <a href='https://creator.co/creator-faq'>FAQ</a>
          </div>
        </div>

        <div>
          <div className='social-links'>
            <a
              href='https://www.facebook.com/CreatorCommunity'
              target='_blank'
              rel='noopener noreferrer'>
              <img className='icon' src={facebookIcon} alt='Facebook' />
            </a>
            <a
              href='https://www.instagram.com/creator.co.community/'
              target='_blank'
              rel='noopener noreferrer'>
              <img className='icon' src={instagramIcon} alt='Instagram' />
            </a>
            <a
              href='https://www.youtube.com/channel/UCi-e0d3ov7eshzQb5N5Wbxw'
              target='_blank'
              rel='noopener noreferrer'>
              <img className='icon' src={youtubeIcon} alt='YouTube' />
            </a>
            <a href='https://x.com/creator_company' target='_blank' rel='noopener noreferrer'>
              <img className='icon' src={xIcon} alt='X' />
            </a>
            <a
              href='https://www.tiktok.com/@creator.co.community'
              target='_blank'
              rel='noopener noreferrer'>
              <img className='icon' src={tiktokIcon} alt='TikTok' />
            </a>
          </div>
        </div>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.footer`
  // MOBILE
  font-family: 'Poppins-Regular', Verdana, sans-serif;
  background: #23262f;
  color: #ede9fe;
  padding: 80px 20px;
  width: 100%;
  z-index: 1;
  .inner {
    max-width: 1720px;
    margin: auto;
    display: flex;
    flex-direction: column;
    gap: 60px;
  }

  h4 {
    font-family: 'Poppins-Bold', Verdana, sans-serif;
    font-size: 18px;
    color: #ede9fe;
  }

  .logo {
    height: 20px;
    margin-bottom: 10px;
  }
  .copyright {
    font-size: 14px;
  }

  .links {
    display: flex;
    justify-content: space-between;
    gap: 40px;
    width: 100%;
    max-width: 400px;
    .group {
      display: flex;
      flex-direction: column;
      gap: 10px;
      h4 {
        margin-bottom: 20px;
      }
      a {
        width: fit-content;
        font-size: 12px;
        text-decoration: none;
        color: #fff;
        white-space: nowrap;
        cursor: pointer;
        transition: 0.2s ease-in-out;
        &:hover {
          color: ${props => props.theme.crcoTechBlue};
        }
      }
    }
  }

  .social-links {
    max-width: 400px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    .icon {
      height: 36px;
      width: 36px;
      opacity: 0.7;
      transition: 0.2s ease-in-out;
      &:hover {
        opacity: 1;
      }
    }
  }

  // TABLET/DESKTOP
  @media only screen and (min-width: 768px) {
    padding: 80px 40px;
    .inner {
      flex-direction: row;
      justify-content: space-between;
      flex-wrap: wrap;
      gap: 80px;
    }
    h4 {
      font-size: 24px;
    }
    .logo {
      margin-bottom: 30px;
    }
    .links {
      gap: 60px;
      .group a {
        font-size: 16px;
      }
    }
    .social-links {
      max-width: fit-content;
    }
  }
`
