import React, { useState } from 'react'
import styled from 'styled-components/macro'

export const ImageLoader = ({ src, alt, placeholder }) => {
  const [imageLoaded, setImageLoaded] = useState(false)
  const [validUrl, setValidUrl] = useState(true)

  return (
    <Wrapper>
      {validUrl ? (
        <>
          <img
            src={src}
            alt={alt}
            onError={() => setValidUrl(false)}
            className={`smooth-image ${imageLoaded ? 'visible' : 'hidden'}`}
            onLoad={() => setImageLoaded(true)}
          />
          {!imageLoaded && (
            <div className='smooth-preloader'>
              <span className='loader' />
            </div>
          )}
        </>
      ) : (
        <>
          <img
            src={placeholder}
            alt={alt}
            className={`placeholder smooth-image ${imageLoaded ? 'visible' : 'hidden'}`}
            onLoad={() => setImageLoaded(true)}
          />
          {!imageLoaded && (
            <div className='smooth-preloader'>
              <span className='loader' />
            </div>
          )}
        </>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  .smooth-image {
    transition: opacity 0.4s;
  }
  .visible {
    opacity: 1;
  }
  .hidden {
    opacity: 0;
  }
`
