import { Button } from 'antd'
import React from 'react'
import { Link } from 'react-router-dom'

import { Wrapper } from './BookDemo'
import { Footer } from './Footer'
import { useDocumentTitle } from '../custom-hooks/useDocumentTitle'
import hero from '../images/book-demo-illustration.svg'

const DemoConfirmation = () => {
  useDocumentTitle('Demo Booked | Brands')

  return (
    <Wrapper>
      <section className='banner'>
        <div className='text'>
          <h1>Your demo has been booked!</h1>
          <p>
            Thank you for booking a demo. We&apos;re excited to show you the power of the Creator
            Economy.
          </p>
          <Button type='primary'>
            <Link to='/pricing'>View Pricing</Link>
          </Button>
        </div>
        <div className='hero-img'>
          <img src={hero} alt='' />
        </div>
      </section>

      <Footer />
    </Wrapper>
  )
}

export default DemoConfirmation
