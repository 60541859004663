import { UndoOutlined, CheckCircleOutlined, UnorderedListOutlined } from '@ant-design/icons'
import { message } from 'antd'
import moment from 'moment'
import numeral from 'numeral'
import React, { useContext } from 'react'
import { queryCache } from 'react-query'
import styled from 'styled-components/macro'

import { BrandContext } from '../../../contexts/BrandContext'
import { socialProfileToUrl } from '../../../utils'

export const ArchivedCreator = ({ creator }) => {
  const { brandId, recoverCreatorListItem } = useContext(BrandContext)

  const handleRecover = async creatorListId => {
    const result = await recoverCreatorListItem(creator.id)
    queryCache.invalidateQueries('archived-creators', brandId)
    queryCache.invalidateQueries('creator-list-items', creatorListId)
    if (result.status === 200) message.success('Creator has been recovered.')
  }

  return (
    <Wrapper>
      <div className='creator-info'>
        <div className='profile'>
          <a
            className='profile-link'
            href={socialProfileToUrl(creator.socialProfile)}
            target='_blank'
            rel='noopener noreferrer'>
            <div
              className='avatar'
              style={{
                backgroundImage: `url(${creator.socialProfile.profilePicUrl})`,
              }}
            />
            <div className='info'>
              <div className='username'>
                {creator.socialProfile.username}
                {creator.socialProfile.metaData.is_verified && (
                  <span className='verified'>
                    <CheckCircleOutlined />
                  </span>
                )}
              </div>
              {creator.socialProfile.platform !== 'youtube' && (
                <div className='fullname'>{creator.socialProfile.metaData.fullname}</div>
              )}
            </div>
          </a>
          <div className='stats'>
            <span className='stat'>
              {numeral(creator.socialProfile.followerCount).format('0.0a')}
              <p className='label'>
                {creator.socialProfile.platform === 'youtube' ? 'subscribers' : 'followers'}
              </p>
            </span>
            {creator.socialProfile.platform === 'youtube' ? (
              <span className='stat'>
                {numeral(creator.socialProfile.avgViews).format('0a')}
                <p className='label'>avg views</p>
              </span>
            ) : (
              <span className='stat'>
                {Math.round(creator.socialProfile.engagement * 1000) / 1000}%
                <p className='label'>engagement</p>
              </span>
            )}
          </div>
        </div>
      </div>
      <div className='list-info'>
        <div>
          <span className={`list-title ${creator.creatorList.archived && 'deleted'}`}>
            <UnorderedListOutlined /> {creator.creatorList.title || 'Untitled'}{' '}
            {creator.creatorList.archived && '(deleted)'}
          </span>
          <p className='date'>Archived {moment(creator.archived).format('ll')}</p>
        </div>
        {creator.creatorList.archived ? (
          <div style={{ opacity: '0.5', textAlign: 'center' }}>Cannot recover.</div>
        ) : (
          <button className='recover-btn' onClick={() => handleRecover(creator.creatorList.id)}>
            <UndoOutlined /> Recover
          </button>
        )}
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  background: #fff;
  border-radius: 5px;
  padding: 10px;
  display: flex;
  gap: 10px;
  .creator-info {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
  .profile {
    flex: 1;
    display: flex;
    border: 1px solid #e6e6e6;
    border-radius: 5px;
  }
  .profile-link {
    flex: 1;
    display: flex;
    align-items: center;
    min-width: 250px;
    padding: 5px 10px;
    transition: all 0.2s ease-in-out;
    &:hover {
      background: #e9f4fc;
      .info .username {
        color: ${props => props.theme.crcoTechBlue};
      }
    }
    .avatar {
      background-color: #eee;
      height: 50px;
      min-width: 50px;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      border-radius: 50%;
      margin-right: 16px;
    }
    .info {
      word-break: break-word;
      .username {
        color: ${props => props.theme.crcoTechBlue};
        font-size: 0.9rem;
        display: flex;
        align-items: center;
        gap: 8px;
        .verified {
          background: ${props => props.theme.crcoTechBlue};
          color: #fff;
          border-radius: 50%;
          padding: 1px;
          display: grid;
          place-content: center;
          font-size: 0.8rem;
        }
      }
      .fullname {
        font-family: 'Campton-Light';
        color: #000;
      }
    }
  }
  .stats {
    border-left: 1px dotted #e6e6e6;
    padding: 5px 10px;
    display: flex;
    align-items: center;
    gap: 30px;
  }
  .list-info {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    p {
      font-family: 'Campton-Light', Verdana, sans-serif;
      margin: 0;
      display: grid;
    }
    .date {
      margin-top: 5px;
      font-size: 0.8rem;
    }
    .list-title {
      padding: 5px 10px;
      border-radius: 5px;
      background: #e9f4fc;
      color: ${props => props.theme.crcoTechBlue};
      font-size: 0.8rem;
      margin: 0;
      &.deleted {
        background: #f5f5f5;
        color: #999;
      }
    }
  }
  .recover-btn {
    background: transparent;
    border: none;
    color: ${props => props.theme.crcoTechBlue};
  }
`
