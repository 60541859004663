import { EyeTwoTone, EyeInvisibleOutlined } from '@ant-design/icons'
import { Button, Alert } from 'antd'
import { CountryPhoneInput, ConfigProvider } from 'antd-country-phone-input'
import axios from 'axios'
import { Formik, Form } from 'formik'
import { Input } from 'formik-antd'
import React from 'react'
import { Link } from 'react-router-dom'
import en from 'world_countries_lists/data/countries/en/world.json'
import * as yup from 'yup'

import { NODE_URL } from '../../constants'
import arrow from '../../images/login/arrow-shape.svg'
import FormItem from '../forms/FormItem'

export const InviteForm = ({ inviteString }) => {
  const initialValues = {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    short: 'US',
    code: '',
    password: '',
    confirmPassword: '',
  }

  const validationSchema = yup.object().shape({
    firstName: yup.string().required('Required'),
    lastName: yup.string().required('Required'),
    phone: yup.string().required('Required'),
    password: yup.string().required('Required').min(8, 'Minimum 8 characters'),
    confirmPassword: yup
      .string()
      .required('Confirm your password')
      .oneOf([yup.ref('password'), null], 'Passwords must match'),
  })

  const handleSubmit = async (values, { setStatus }) => {
    setStatus('')

    const data = {
      inviteCode: inviteString,
      firstName: values.firstName,
      lastName: values.lastName,
      phone: values.phone,
      phoneCode: values.code,
      phoneShort: values.short,
      password: values.password,
    }

    try {
      const res = await axios.post(`${NODE_URL}/invite-signup/`, data)
      localStorage.setItem('nodeToken', res.data.token)
      window.location.replace('/')
    } catch (err) {
      setStatus(err?.response?.data)
    }
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}>
      {({ values, setValues, status, isSubmitting }) => (
        <Form>
          <img src={arrow} alt='' className='arrow' />
          <h1>Welcome!</h1>
          <p className='subtext'>Please submit your contact info to join your brand as a user.</p>

          <div className='form-row-wrapper'>
            <FormItem name='firstName' required={true} size='small'>
              <Input name='firstName' placeholder='First name' />
            </FormItem>
            <FormItem name='lastName' required={true} size='small'>
              <Input name='lastName' placeholder='Last name' />
            </FormItem>
          </div>

          <ConfigProvider locale={en}>
            <FormItem name='phone' size='small'>
              <CountryPhoneInput
                inline
                autoComplete='phone'
                name='phone'
                placeholder='Phone'
                value={values}
                onChange={e => {
                  setValues(prev => ({ ...prev, ...e }))
                }}
              />
            </FormItem>
          </ConfigProvider>

          <FormItem name='password' size='small'>
            <Input.Password
              name='password'
              placeholder='Password'
              iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
              autoComplete='new-password'
            />
          </FormItem>
          <FormItem name='confirmPassword' size='small'>
            <Input.Password
              name='confirmPassword'
              placeholder='Confirm Password'
              iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
              autoComplete='new-password'
            />
          </FormItem>

          {!status || <Alert message={status} type='error' showIcon />}

          <div className='buttons'>
            <Button type='primary' htmlType='submit' loading={isSubmitting}>
              Submit
            </Button>
          </div>
          <div className='sub-links'>
            Already have an account? <Link to='/login'>Log In</Link>
          </div>
        </Form>
      )}
    </Formik>
  )
}
