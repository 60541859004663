import React from 'react'
import styled from 'styled-components'

import 'react-slidedown/lib/slidedown.css'
import SavedReports from './SavedReports'

const Reports = () => {
  return (
    <Wrapper>
      <SavedReports />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  color: #080830;
  height: 100%;
  display: flex;
  flex-direction: column;
`

export default Reports
