import {
  LoadingOutlined,
  MinusCircleOutlined,
  PlusOutlined,
  UploadOutlined,
  UndoOutlined,
  StopOutlined,
} from '@ant-design/icons'
import { Button, Collapse, Input, Upload, message, Modal, Select, Tooltip } from 'antd'
import axios from 'axios'
import { parse } from 'csv-parse/dist/esm'
import { debounce } from 'lodash'
import React, { useContext, useMemo, useState } from 'react'
import { queryCache, useQuery } from 'react-query'
import styled from 'styled-components'

import FormItem from '../../../components/forms/FormItem'
import { NODE_URL } from '../../../constants'
import { BrandContext } from '../../../contexts/BrandContext'

export const ProductListItems = ({ list }) => {
  const { brandId } = useContext(BrandContext)

  const [newItemLoading, setNewItemLoading] = useState([])
  const [updateItemLoading, setUpdateItemLoading] = useState([])
  const [deleteItemLoading, setDeleteItemLoading] = useState([])
  const [csvModalOpen, setCsvModalOpen] = useState(false)
  const [fileList, setFileList] = useState([])
  const [csvColumns, setCsvColumns] = useState(null)
  const [titleColumn, setTitleColumn] = useState('')
  const [descriptionColumn, setDescriptionColumn] = useState('')
  const [uploadingCsv, setUploadingCsv] = useState(false)

  const { data: items } = useQuery(['product-list-items', list.id], () =>
    axios.get(`${NODE_URL}/brand/${brandId}/product-list/${list.id}/item`).then(r => r.data.items)
  )
  const handleCreateItem = async listId => {
    setNewItemLoading(prev => [...prev, listId])
    await axios
      .post(`${NODE_URL}/brand/${brandId}/product-list/${listId}/item`)
      .then(() => queryCache.invalidateQueries(['product-list-items', listId]))
      .catch(() => message.error('Something went wrong'))
      .finally(() => setNewItemLoading(prev => prev.filter(id => id !== listId)))
  }

  const debouncedUpdateListItem = useMemo(
    () =>
      debounce(async item => {
        setUpdateItemLoading(prev => [...prev, item.id])
        await axios
          .put(`${NODE_URL}/brand/${brandId}/product-list/${list.id}/item/${item.id}`, {
            title: item.title,
            description: item.description,
          })
          .catch(() => message.error('Something went wrong'))
          .finally(() => setUpdateItemLoading(prev => prev.filter(id => id !== item.id)))
      }, 400),
    []
  )

  const handleUpdateItem = async item => {
    queryCache.setQueryData(['product-list-items', list.id], prev =>
      prev?.map(v => (v.id === item.id ? item : v))
    )
    debouncedUpdateListItem(item)
  }

  const handleDeleteItem = async (listId, itemId) => {
    setDeleteItemLoading(prev => [...prev, itemId])
    await axios
      .delete(`${NODE_URL}/brand/${brandId}/product-list/${listId}/item/${itemId}`)
      .then(() => queryCache.invalidateQueries(['product-list-items', listId]))
      .catch(() => message.error('Something went wrong'))
      .finally(() => setDeleteItemLoading(prev => prev.filter(id => id !== itemId)))
  }

  const handleReactivateItem = async (listId, itemId) => {
    setDeleteItemLoading(prev => [...prev, itemId])
    await axios
      .put(`${NODE_URL}/brand/${brandId}/product-list/${list.id}/item/${itemId}`, {
        activate: true,
      })
      .then(() => queryCache.invalidateQueries(['product-list-items', listId]))
      .catch(() => message.error('Something went wrong'))
      .finally(() => setDeleteItemLoading(prev => prev.filter(id => id !== itemId)))
  }

  const handleCsvColumns = ({ file }) => {
    // parse csv so user can select title and description columns
    setFileList([file])
    const reader = new FileReader()

    reader.onload = e => {
      const csv = e.target.result
      const lines = csv.split('\n')
      const csvColumns = lines[0].split(',').map(column => column.trim())
      setCsvColumns(csvColumns)
    }

    reader.onerror = () => {
      message.error('Error reading file')
    }

    reader.readAsText(file)
  }

  const handleClearCsv = () => {
    setFileList([])
    setCsvColumns(null)
  }

  const handleCsvUpload = async () => {
    if (!titleColumn || !descriptionColumn) {
      message.error('Please select both title and description columns.')
      return
    }

    setUploadingCsv(true)

    // Initialize FileReader to read the CSV file
    const reader = new FileReader()

    reader.onload = async e => {
      let csv = e.target.result
      // convert to string
      if (csv instanceof ArrayBuffer) {
        const enc = new TextDecoder('utf-8')
        csv = enc.decode(csv)
      }
      parse(csv, {}, async (err, data) => {
        if (err) {
          message.error('Error parsing CSV')
          setUploadingCsv(false)
          return
        }

        const headers = data[0]?.map(header => header.trim())

        // Get the indices of the selected columns
        const titleIndex = headers.indexOf(titleColumn)
        const descriptionIndex = headers.indexOf(descriptionColumn)

        const items = data
          .slice(1) // Ignore the header row
          .map(row => ({
            title: row[titleIndex]?.trim(),
            description: row[descriptionIndex]?.trim(),
          }))
          .filter(item => item.title && item.description) // Filter out empty rows

        try {
          await axios.post(`${NODE_URL}/brand/${brandId}/product-list/${list.id}/item/bulk`, {
            items,
          })
          await queryCache.invalidateQueries(['product-list-items', list.id])
          handleClearCsv()
          setCsvModalOpen(false)
          message.success('Items successfully added!')
        } catch (error) {
          message.error('Error adding items')
        } finally {
          setUploadingCsv(false)
        }
      })
    }

    reader.onerror = () => {
      message.error('Error reading file')
      setUploadingCsv(false)
    }

    reader.readAsText(fileList[0])
  }

  return (
    <div>
      {items?.length ? (
        <Collapse style={{ marginBottom: '20px' }}>
          {items.map(item => (
            <Collapse.Panel
              header={
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}>
                  <div>
                    {item.title}{' '}
                    {item.deactivated && (
                      <Tooltip title='This item is deactivated and cannot be selected by new opt-ins.'>
                        <span className='deactivated'>(Deactivated) </span>
                      </Tooltip>
                    )}
                    {updateItemLoading.includes(item.id) && <LoadingOutlined spin />}
                  </div>
                  <Tooltip
                    title={
                      item.deactivated
                        ? 'Reactivate'
                        : item.inUse
                          ? 'Deactivate (cannot delete item in use)'
                          : 'Delete'
                    }>
                    <Button
                      icon={
                        item.deactivated ? (
                          <UndoOutlined />
                        ) : item.inUse ? (
                          <StopOutlined />
                        ) : (
                          <MinusCircleOutlined />
                        )
                      }
                      danger={!item.deactivated}
                      type='link'
                      loading={deleteItemLoading.includes(item.id)}
                      onClick={e => {
                        e.stopPropagation()
                        if (item.deactivated) {
                          handleReactivateItem(list.id, item.id)
                          return
                        }
                        handleDeleteItem(list.id, item.id)
                      }}
                    />
                  </Tooltip>
                </div>
              }
              key={item.id}>
              <FormItem size='small' label='Title'>
                <Input
                  value={item.title}
                  onChange={e => handleUpdateItem({ ...item, title: e.target.value })}
                />
              </FormItem>
              <FormItem size='small' label='Description'>
                <Input.TextArea
                  value={item.description}
                  onChange={e => handleUpdateItem({ ...item, description: e.target.value })}
                />
              </FormItem>
            </Collapse.Panel>
          ))}
        </Collapse>
      ) : (
        <p style={{ color: 'red' }}>No items yet.</p>
      )}

      <div className='buttons'>
        <Button
          icon={<PlusOutlined />}
          type='secondary'
          loading={newItemLoading.includes(list.id)}
          onClick={() => handleCreateItem(list.id)}>
          Add Item
        </Button>
        <Button
          type='link'
          icon={<UploadOutlined />}
          loading={uploadingCsv}
          onClick={() => {
            setCsvModalOpen(true)
          }}>
          Upload CSV
        </Button>
      </div>

      <Modal
        title='Upload CSV'
        open={csvModalOpen}
        okText='Submit'
        okButtonProps={{ disabled: !titleColumn || !descriptionColumn, loading: uploadingCsv }}
        onOk={handleCsvUpload}
        onCancel={() => setCsvModalOpen(false)}
        destroyOnClose>
        <p>Add multiple items at once by uploading your own CSV file.</p>
        <Upload.Dragger
          name='products-csv'
          accept='text/csv'
          customRequest={handleCsvColumns}
          onRemove={handleClearCsv}
          multiple={false}
          fileList={fileList}>
          <UploadWrapper>
            <UploadOutlined />
            <p>{fileList.length ? 'Replace' : 'Upload'} CSV</p>
          </UploadWrapper>
        </Upload.Dragger>

        {!!fileList.length && (
          <div style={{ marginTop: '20px' }}>
            <FormItem
              label='Title Column'
              info='The column in your CSV file that contains the item title'>
              <Select
                placeholder='Select'
                onChange={value => {
                  setTitleColumn(value)
                }}
                options={csvColumns?.map(col => ({
                  label: col,
                  value: col,
                }))}
                notFoundContent='No columns found'
                style={{ width: '100%' }}
              />
            </FormItem>
            <FormItem
              label='Description Column'
              info='The column in your CSV file that contains the item description'>
              <Select
                placeholder='Select'
                onChange={value => {
                  setDescriptionColumn(value)
                }}
                options={csvColumns?.map(col => ({
                  label: col,
                  value: col,
                }))}
                notFoundContent='No columns found'
                style={{ width: '100%' }}
              />
            </FormItem>
          </div>
        )}
      </Modal>
    </div>
  )
}

const UploadWrapper = styled.div`
  .anticon {
    font-size: 1.3rem;
  }
`
