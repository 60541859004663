import { Select } from 'formik-antd'
import React from 'react'

import FormItem from '../../../components/forms/FormItem'

export const BrandIdFilter = ({ brandData }) => {
  return (
    <FormItem size='small' label='Brands' name='brandIds'>
      <Select
        getPopupContainer={trigger => trigger.parentNode}
        virtual={false}
        name='brandIds'
        placeholder='Select'
        mode='multiple'
        style={{ width: '100%' }}
        showArrow
        options={[brandData.childBrands.map(brand => ({ label: brand.name, value: brand.id }))]}
      />
    </FormItem>
  )
}
