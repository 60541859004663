import { ArrowDownOutlined, ArrowUpOutlined, DownOutlined, SearchOutlined } from '@ant-design/icons'
import { Button, Empty } from 'antd'
import { Formik, Form } from 'formik'
import { Input, Select } from 'formik-antd'
import React, { useContext, useState, useRef } from 'react'
import { useInfiniteQuery } from 'react-query'
import styled from 'styled-components'

import { ArchivedCreators } from './ArchivedCreators'
import { CreatorList } from './CreatorList'
import { BrandContext } from '../../../contexts/BrandContext'
import instagram from '../../../images/icons/social/instagram-color-square.svg'
import tiktok from '../../../images/icons/social/tiktok-color-square.svg'
import youtube from '../../../images/icons/social/youtube-color-square.svg'
import SavedExports from '../SavedExports'
const { Option } = Select

export const CreatorLists = () => {
  const { brandId, fetchCreatorListsDetail } = useContext(BrandContext)
  const submitRef = useRef(0)
  const [formData, setFormData] = useState({})

  const {
    data: creatorLists,
    status: creatorListsStatus,
    fetchMore,
    canFetchMore,
    isFetchingMore,
  } = useInfiniteQuery(
    ['paginated-creator-lists', { brandId, formData }],
    fetchCreatorListsDetail,
    {
      getFetchMore: lastPage => {
        return lastPage.nextPage
      },
    }
  )

  return (
    <Wrapper>
      <div className='inner'>
        <Formik
          initialValues={formData}
          onSubmit={data => {
            submitRef.current++
            const thisSubmit = submitRef.current
            setTimeout(() => {
              if (thisSubmit === submitRef.current) {
                setFormData(data)
              }
            }, 300)
          }}>
          {({ submitForm }) => (
            <Form className='filters'>
              <Input
                onChange={submitForm}
                name='search'
                placeholder='Search lists'
                suffix={<SearchOutlined />}
                allowClear
              />
              <Select name='tags' placeholder='Status' onChange={submitForm} allowClear>
                <Option value='Awaiting response'>Awaiting response</Option>
                <Option value='Interested'>Interested</Option>
                <Option value='Not interested'>Not interested</Option>
                <Option value='In discussion'>In discussion</Option>
                <Option value='In negotiation'>In negotiation</Option>
                <Option value='To be approved'>To be approved</Option>
                <Option value='Invited'>Invited</Option>
                <Option value='Approved'>Approved</Option>
                <Option value='Rejected'>Rejected</Option>
              </Select>
              <Select name='platform' placeholder='Platform' onChange={submitForm} allowClear>
                <Option value='instagram'>
                  <img style={{ height: '20px', marginRight: '8px' }} src={instagram} alt='' />
                  Instagram
                </Option>
                <Option value='youtube'>
                  <img style={{ height: '20px', marginRight: '8px' }} src={youtube} alt='' />
                  YouTube
                </Option>
                <Option value='tiktok'>
                  <img style={{ height: '20px', marginRight: '8px' }} src={tiktok} alt='' />
                  TikTok
                </Option>
              </Select>
              <Select name='sort' placeholder='Sort by' onChange={submitForm} allowClear>
                <Option value='saved-desc'>
                  <ArrowUpOutlined /> Newest
                </Option>
                <Option value='saved-asc'>
                  <ArrowDownOutlined /> Oldest
                </Option>
                <Option value='followers'>
                  <ArrowUpOutlined /> Followers
                </Option>
                <Option value='engagement'>
                  <ArrowUpOutlined /> Engagement
                </Option>
                <Option value='views'>
                  <ArrowUpOutlined /> Average views
                </Option>
              </Select>
            </Form>
          )}
        </Formik>
        <div className='results-inner'>
          {creatorListsStatus === 'success' && (
            <>
              {creatorLists?.[0]?.lists?.length ? (
                <>
                  {creatorLists.map(group =>
                    group.lists.map((list, i) => {
                      return <CreatorList key={i} list={list} formData={formData} />
                    })
                  )}
                  {canFetchMore && (
                    <div className='load-more'>
                      <Button
                        type='link'
                        loading={isFetchingMore}
                        icon={<DownOutlined />}
                        onClick={() => fetchMore()}>
                        {isFetchingMore ? 'Loading...' : 'Load More'}
                      </Button>
                    </div>
                  )}
                </>
              ) : (
                <div className='empty'>
                  <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description={
                      Object.keys(formData).length ? 'No results.' : 'You have no lists.'
                    }
                  />
                </div>
              )}
            </>
          )}
          {creatorListsStatus === 'loading' &&
            Array.from(Array(10).keys()).map(i => (
              <div className='skeleton' key={i}>
                <div className='skelement' />
              </div>
            ))}
          <div className='border' />
          <ArchivedCreators />
          <SavedExports />
        </div>
      </div>
    </Wrapper>
  )
}

export const Wrapper = styled.div`
  height: 100%;
  max-width: 1400px;
  margin: 0 auto;
  padding: 20px;
  position: relative;
  .inner {
    background: #fff;
    border-radius: 10px;
    padding: 20px;
    display: flex;
    flex-direction: column;
  }
  .filters {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 12px;
    padding: 0 15px 15px 15px;
    box-shadow: 0px 10px 10px -10px rgba(0, 0, 0, 0.15);
    min-height: 50px;
    z-index: 10;
    .ant-input-affix-wrapper,
    .ant-select {
      flex: 1;
    }
  }
  .results-inner {
    padding: 0 10px;
    .load-more {
      display: flex;
      justify-content: center;
      margin-top: 20px;
    }
  }
  .border {
    margin: 20px 0;
  }
  .empty,
  .loading {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    button {
      margin-top: 20px;
    }
  }
  .skeleton {
    background: #fff;
    padding: 10px;
    display: flex;
    height: 52px;
    border-bottom: 1px solid #e6e6e6;
    .skelement {
      flex: 1;
      border-radius: 3px;
      background-image: linear-gradient(90deg, #fafafa 0px, #ffffff 100px, #fafafa 300px);
      background-size: 100vw 100%;
      animation: shine 1.5s infinite ease-in-out;
    }
  }
  @keyframes shine {
    0% {
      background-position-x: -20vw;
    }
    95%,
    100% {
      background-position-x: 85vw;
    }
  }
`
