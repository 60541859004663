import { PlusOutlined, EditOutlined } from '@ant-design/icons'
import { Button, message } from 'antd'
import axios from 'axios'
import React, { useState, useEffect, useContext } from 'react'
import { queryCache, useQuery } from 'react-query'
import styled from 'styled-components/macro'

import { OptInList } from './OptInList'
import { NODE_URL } from '../../../../constants'
import { BrandContext } from '../../../../contexts/BrandContext'

export const OptInsTab = ({ campaign }) => {
  const { brandId } = useContext(BrandContext)
  const [activeView, setActiveView] = useState(undefined)
  const [formData, setFormData] = useState(undefined)
  const [filtersOpen, setFiltersOpen] = useState(false)
  const [newTitle, setNewTitle] = useState('')
  const [unsavedChanges, setUnsavedChanges] = useState(false)

  const { data: optInViews } = useQuery(['opt-in-views', campaign.id], async () => {
    const views = await axios
      .get(`${NODE_URL}/brand/${brandId}/campaign/${campaign.id}/opt-in-views`)
      .then(res => res.data.views)
    return views
  })

  useEffect(() => {
    if (activeView) setFormData(activeView.filters)
  }, [activeView])

  useEffect(() => {
    // search for specific opt-in (linked from inbox)
    const urlParams = new URLSearchParams(window.location.search)
    const search = urlParams.get('search')

    if (search) {
      setActiveView(undefined)
      setFormData(prev => ({ ...prev, search }))
    }
    // prevent resetting to first view when user creates new view by only setting active view if it's not already set
    else if (!activeView && optInViews) {
      setActiveView(optInViews[0])
    }
  }, [activeView, optInViews])

  useEffect(() => {
    // stringify objects to be able to compare
    const formDataString = JSON.stringify(formData)
    const activeViewFiltersString = JSON.stringify(activeView?.filters)

    if (formDataString !== activeViewFiltersString || newTitle) {
      setUnsavedChanges(true)
    } else {
      setUnsavedChanges(false)
    }
  }, [formData, activeView, newTitle])

  const handleNewView = async () => {
    try {
      const res = await axios.post(
        `${NODE_URL}/brand/${brandId}/campaign/${campaign.id}/opt-in-view`
      )
      queryCache.invalidateQueries(['opt-in-views', campaign.id])
      setActiveView(res.data)
    } catch (err) {
      message.error('Error creating new view')
    }
  }

  return (
    <Wrapper>
      <div className='views'>
        {optInViews?.length > 0 &&
          optInViews.map(view => (
            <span key={view.id} className='view-btn-wrapper'>
              <div
                className={`view-btn ${activeView?.id === view.id && 'active'}`}
                onClick={() => {
                  if (view.id === activeView?.id) return // prevent re-fetching data if already active
                  setActiveView(view)
                  setFormData(view.filters)
                  // clear url params when selecting view
                  window.history.replaceState({}, '', window.location.pathname)
                }}
                onKeyDown={() => {
                  if (view.id === activeView?.id) return
                  setActiveView(view)
                  setFormData(view.filters)
                  // clear url params when selecting view
                  window.history.replaceState({}, '', window.location.pathname)
                }}
                role='button'
                tabIndex={0}>
                <span>{view.title}</span>

                {activeView?.id === view.id && (
                  <button
                    className='edit-btn'
                    onClick={() => {
                      setFiltersOpen(true)
                    }}>
                    {unsavedChanges && <span className='unsaved-indicator' />}
                    <EditOutlined />
                  </button>
                )}
              </div>
              <div className='btn-border' />
            </span>
          ))}

        <Button type='link' onClick={handleNewView}>
          <PlusOutlined /> New View
        </Button>
      </div>

      <OptInList
        campaign={campaign}
        formData={formData}
        setFormData={setFormData}
        filtersOpen={filtersOpen}
        setFiltersOpen={setFiltersOpen}
        activeView={activeView}
        setActiveView={setActiveView}
        setNewTitle={setNewTitle}
        unsavedChanges={unsavedChanges}
        optInViews={optInViews}
      />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  .views {
    background: #fff;
    padding: 10px 25px 0 25px;
    border-bottom: 1px solid #e6e6e6;
    display: flex;
    align-items: center;
    overflow: auto;
    ::-webkit-scrollbar {
      height: 6px;
      width: 6px;
    }
    ::-webkit-scrollbar-track {
      background-color: #fff;
      border-radius: 5px;
    }
    ::-webkit-scrollbar-thumb {
      background-color: #eee;
      border-radius: 5px;
      cursor: pointer;
    }
  }
  .view-btn-wrapper {
    display: flex;
    align-items: center;
  }
  .view-btn {
    background: transparent;
    border: none;
    border-bottom: 2px solid transparent;
    font-size: 14px;
    padding: 5px 10px;
    transition: 0.2s ease-in-out;
    color: #23262f;
    opacity: 0.7;
    text-transform: capitalize;
    position: relative;
    white-space: nowrap;
    display: flex;
    align-items: center;
    gap: 6px;
    position: relative;
    cursor: pointer;
    &:hover {
      opacity: 1;
    }
    &.active {
      color: ${props => props.theme.crcoTechBlue};
      border-color: ${props => props.theme.crcoTechBlue};
      opacity: 1;
    }
    .anticon {
      margin-right: 5px;
    }
    .edit-btn {
      background: #f6f6f6;
      border: 1px solid #ddd;
      border-radius: 6px;
      padding: 3px;
      display: grid;
      place-content: center;
      color: #999;
      transition: 0.2s ease-in-out;
      font-size: 0.8rem;
      position: relative;
      .anticon {
        margin: 0;
      }
      .unsaved-indicator {
        position: absolute;
        top: -5px;
        right: -5px;
        width: 11px;
        height: 11px;
        border-radius: 50%;
        background: orange;
        border: 2px solid #fff;
        z-index: 1;
      }
      &:hover {
        background: #eee;
        color: #666;
        border-color: #ccc;
      }
    }
  }
  .btn-border {
    height: 20px;
    width: 1.5px;
    background: #e6e6e6;
    flex: none; // prevent flex from shrinking it
    margin: 0 8px;
    &.last {
      margin-left: auto;
    }
  }
`
