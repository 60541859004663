import { Button, message } from 'antd'
import axios from 'axios'
import { Form, Formik } from 'formik'
import { Input } from 'formik-antd'
import React, { useContext } from 'react'
import { queryCache } from 'react-query'
import styled from 'styled-components'

import { NODE_URL } from '../../constants'
import { UserContext } from '../../contexts/UserContext'

const BRAND_SUBMIT_GIVEAWAY_SHIPMENT_TRACKING_LINK = ({ optIn, campaignStep, optInStep }) => {
  const { nodeToken } = useContext(UserContext)

  return (
    <Wrapper>
      <p>Enter link for giveaway winner to track their shipment.</p>
      <Formik
        initialValues={{
          url: optInStep?.data?.url,
        }}
        enableReinitialize
        onSubmit={(data, { setSubmitting }) => {
          axios
            .post(`${NODE_URL}/brand/opt-in/${optIn.id}/step/${campaignStep.id}`, data, {
              headers: {
                Authorization: `Bearer ${nodeToken}`,
              },
            })
            .then(async () => {
              message.success('Shipment tracking link saved')
              queryCache.invalidateQueries('opt-ins')
              setSubmitting(false)
            })
            .catch(() => {
              message.error('Sorry, something went wrong.')
              setSubmitting(false)
            })
        }}>
        {({ isSubmitting }) => (
          <Form>
            <Input name='url' placeholder='Tracking link' style={{ width: '300px' }} />
            <Button type='primary' htmlType='submit' loading={isSubmitting}>
              Submit
            </Button>
          </Form>
        )}
      </Formik>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: grid;
  place-items: center;
  min-height: 80px;
  form {
    display: flex;
    gap: 10px;
  }
`

export default BRAND_SUBMIT_GIVEAWAY_SHIPMENT_TRACKING_LINK
