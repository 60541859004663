import { CaretRightOutlined, InfoCircleOutlined } from '@ant-design/icons'
import { Button, message, Modal } from 'antd'
import axios from 'axios'
import { Formik, Form } from 'formik'
import { InputNumber } from 'formik-antd'
import React, { useContext, useState } from 'react'
import { queryCache } from 'react-query'
import styled from 'styled-components'

import { NODE_URL } from '../../../../constants'
import { BrandContext } from '../../../../contexts/BrandContext'
import { UserContext } from '../../../../contexts/UserContext'
import { getProfilePicUrl } from '../../../../utils'
import CreatorAvatar from '../../../general/CreatorAvatar'

const NegotiatePayments = ({ optIn }) => {
  const { nodeToken } = useContext(UserContext)
  const { brandId } = useContext(BrandContext)
  const { campaign } = optIn
  const [paymentOpen, setPaymentOpen] = useState(false)
  const profilePicUrl = getProfilePicUrl(optIn)

  const paymentType = campaign.minPaidAmount ? 'ranged' : 'fixed'
  const negotiationEnabled = paymentType === 'ranged' || campaign.metaData?.enableNegotiation
  const offerStatus = negotiationEnabled ? optIn.extraData.requestedPaymentStatus : 'fixed'
  const requestedAmount = optIn.extraData.requestedPaymentAmount
  const paymentStatus = optIn.paymentStatus
  const paymentAmount = optIn.paymentAmount ?? campaign.maxPaidAmount

  let statusText = null
  let amountDisplay = null

  switch (offerStatus) {
    case 'pending':
      amountDisplay = (
        <div className='amount'>
          {optIn.paymentAmount && (
            <>
              <span>${paymentAmount}</span>
              <span className='arrow'>
                <CaretRightOutlined />
              </span>
            </>
          )}
          <span>${requestedAmount}</span>
        </div>
      )
      statusText = 'Offer Pending'
      break
    case 'declined':
      amountDisplay = requestedAmount && <div className='amount'>${requestedAmount}</div>
      statusText = 'Offer Declined'
      break
    case 'accepted':
      amountDisplay = <div className='amount'>${paymentAmount}</div>
      statusText = 'Offer Accepted'
      break
    case 'fixed':
      amountDisplay = <div className='amount'>${paymentAmount}</div>
      statusText = 'Fixed Amount'
      break
    default:
      statusText = 'Negotiate Payment'
  }

  const handleOffer = async data => {
    try {
      await axios.put(
        `${NODE_URL}/brand/${brandId}/opt-in/${optIn.id}/payout/amount`,
        { amount: data.payment },
        {
          headers: { Authorization: `Bearer ${nodeToken}` },
        }
      )
      message.success('Offer sent.')
      queryCache.invalidateQueries('completed-optins')
      queryCache.invalidateQueries('opt-ins')
      setPaymentOpen(false)
    } catch (err) {
      message.error('Sorry, something went wrong.')
    }
  }

  return paymentStatus !== 'completed' ? (
    <Wrapper
      onClick={e => {
        e.stopPropagation()
        negotiationEnabled && setPaymentOpen(true)
      }}>
      <div className={`content ${offerStatus}`}>
        {amountDisplay}
        <span className={`status ${offerStatus}`}>{statusText}</span>
      </div>

      <Modal
        width={470}
        footer={null}
        open={paymentOpen}
        onCancel={e => {
          e.stopPropagation()
          setPaymentOpen(false)
        }}
        title={
          <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
            <CreatorAvatar
              className='avatar'
              size={30}
              id={optIn.user.id}
              url={profilePicUrl}
              initials={`${optIn.user.firstName?.charAt(0).toUpperCase()} ${optIn.user.lastName
                ?.charAt(0)
                .toUpperCase()}`}
            />
            <span className='name'>
              {optIn.user.firstName} {optIn.user.lastName}
            </span>
          </div>
        }
        destroyOnClose>
        <ModalWrapper>
          <Formik
            initialValues={{
              payment: paymentAmount,
            }}
            onSubmit={handleOffer}>
            {({ values, isSubmitting }) => (
              <Form>
                <div>Negotiate Payment</div>
                <p className='instructions'>
                  Enter the amount you would like to offer as payment for this creator.
                </p>
                <div className='payment-amount'>
                  <InputNumber
                    style={{ width: '150px' }}
                    controls
                    size='large'
                    name='payment'
                    prefix='$'
                    defaultValue={requestedAmount || optIn.campaign.maxPaidAmount}
                  />
                  <span className='suffix'>USD</span>
                </div>
                {optIn.status === 'pending' ? (
                  <Button htmlType='submit' loading={isSubmitting} type='primary'>
                    Send Offer
                  </Button>
                ) : (
                  values.payment !== requestedAmount &&
                  (values.payment > requestedAmount ? (
                    <>
                      <Button htmlType='submit' loading={isSubmitting} type='primary'>
                        Update
                      </Button>
                      <p className='info-text'>
                        <InfoCircleOutlined /> You are increasing your offer. The payment amount
                        will be updated automatically.
                      </p>
                    </>
                  ) : (
                    <>
                      <Button htmlType='submit' loading={isSubmitting} type='primary'>
                        Send Offer
                      </Button>
                      <p className='info-text'>
                        <InfoCircleOutlined /> You are reducing your offer. The creator will need to
                        accept or decline the new amount.
                      </p>
                    </>
                  ))
                )}
              </Form>
            )}
          </Formik>
        </ModalWrapper>
      </Modal>
    </Wrapper>
  ) : (
    <Wrapper>
      <div className='content completed'>
        <span className='amount'>
          $
          {
            // Extra check here in case payment amount is actually 0 we don't want to show the maxPaidAmount
            optIn.paymentAmount || optIn.paymentAmount === 0
              ? optIn.paymentAmount
              : optIn.campaign.maxPaidAmount
          }
        </span>
        <span className='status'>Paid</span>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  .content {
    min-height: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: 0.2s;
    justify-content: center;
    padding: 5px 10px;
    border-radius: 5px;
    color: ${props => props.theme.crcoTechBlue};
    &:not(.completed):not(.fixed):hover {
      background-color: #f7f7f7;
    }
    .status {
      text-align: center;
      font-size: 0.8rem;
    }
    &.pending {
      color: #999;
    }
    &.accepted,
    &.fixed {
      color: #080830;
    }
    &.declined {
      color: ${props => props.theme.crcoCoral};
    }
    .amount {
      display: flex;
      align-items: center;
      gap: 4px;
      font-size: 18px;
      font-weight: bold;
      .arrow {
        font-size: 12px;
      }
    }
  }
`

const ModalWrapper = styled.div`
  display: flex;
  gap: 16px;
  .creator {
    display: flex;
    flex-direction: column;
    gap: 5px;
    text-align: center;
    border-right: 1px solid #e6e6e6;
    padding-right: 8px;
    .avatar {
      margin: 0 auto;
    }
  }

  form {
    flex: 1;
    font-size: 18px;
    font-weight: bold;
    .instructions {
      font-size: 14px;
      font-weight: normal;
    }
    .payment-amount {
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      .suffix {
        margin-left: 5px;
      }
    }
    .fee {
      font-size: 0.8rem;
      color: #999;
      font-weight: normal;
      margin-bottom: 30px;
    }
    .info-text {
      font-size: 0.8rem;
      font-weight: normal;
      color: #999;
      margin: 10px 0 0 0;
    }
  }
`

export default NegotiatePayments
