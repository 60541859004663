import { Spin, Row } from 'antd'
import React, { useState } from 'react'
import styled from 'styled-components/macro'

import video from '../images/icons/video.svg'

const VideoItem = ({ url }) => {
  const [loaded, setLoaded] = useState(false)

  return (
    <Wrapper>
      {!loaded && (
        <SpinContainer type='flex' justify={'center'} align={'middle'}>
          <Spin spinning />
        </SpinContainer>
      )}
      <Video
        className={loaded && 'loaded'}
        preload='auto'
        onLoadedData={() => setLoaded(true)}
        controls={false}
        muted={true}
        autoPlay={true}
        loop={true}>
        {/* #t=1 means preview at first elapsed second! */}
        <source src={`${url}#t=1`} type='video/mp4' />
      </Video>
      <img src={video} alt='video' className='video-icon' />
    </Wrapper>
  )
}

const SpinContainer = styled(Row)`
  width: 100%;
  height: 100%;
`

const Video = styled.video`
  width: 100%;
  height: 100%;
  object-fit: contain;
  display: none;

  &.loaded {
    display: block;
  }
`
const Wrapper = styled.span`
  .video-icon {
    position: absolute;
    top: 15px;
    left: 15px;
    height: 25px;
    width: 25px;
    background: #fff;
    border-radius: 50%;
    padding: 3px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  }
`

export default VideoItem
