import {
  InfoCircleOutlined,
  DisconnectOutlined,
  AppstoreAddOutlined,
  CheckOutlined,
  SyncOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons'
import { Button, Empty, message, Modal, Tooltip } from 'antd'
import axios from 'axios'
import moment from 'moment'
import React, { useState, useContext } from 'react'
import { queryCache, useQuery } from 'react-query'
import styled from 'styled-components/macro'

import { NODE_URL } from '../../constants'
import { BrandContext } from '../../contexts/BrandContext'
import shopifyIcon from '../../images/icons/shopify-icon.svg'

const BrandIntegrations = () => {
  const { brandId, fetchBrandData } = useContext(BrandContext)
  const { data: brandData } = useQuery(['brand', brandId], fetchBrandData)
  const { shopifyStore } = brandData

  const [disconnectModalOpen, setDisconnectModalOpen] = useState(false)
  const [resyncLoading, setResyncLoading] = useState(false)
  const [disconnectLoading, setDisconnectLoading] = useState(false)

  const handleResync = async () => {
    setResyncLoading(true)
    await axios
      .post(`${NODE_URL}/brand/${brandId}/shopify/store/${shopifyStore.id}/sync`)
      .then(() => {
        queryCache.invalidateQueries(['brand', brandId])
        message.success('Store re-synced successfully')
      })
      .catch(() => {
        message.error('Something went wrong. Please try again later.')
      })
    setResyncLoading(false)
  }

  const handleDisconnect = async () => {
    setDisconnectLoading(true)
    await axios
      .delete(`${NODE_URL}/brand/${brandId}/shopify/store/${shopifyStore.id}`)
      .then(() => {
        setDisconnectModalOpen(false)
        queryCache.invalidateQueries(['brand', brandId])
        message.success('Store disconnected successfully')
      })
      .catch(() => {
        message.error('Something went wrong. Please try again later.')
      })
    setDisconnectLoading(false)
  }

  return (
    <Wrapper className='account-section' id='shopify'>
      <div className='section-header'>
        <span className='section-title'>
          <img className='icon' src={shopifyIcon} alt='' /> Shopify{' '}
        </span>
        <div className='connect-shopify'>
          <a href={'https://apps.shopify.com/creator'}>
            <Button type='primary' icon={<AppstoreAddOutlined />}>
              Connect Store
            </Button>
          </a>
        </div>
      </div>
      <div className='section-body'>
        {shopifyStore ? (
          <div className='connected-store'>
            <div className='details'>
              <span className='name'>{shopifyStore.name}</span>
              <Button
                type='text'
                danger
                icon={<DisconnectOutlined />}
                onClick={() => setDisconnectModalOpen(true)}>
                Disconnect
              </Button>
            </div>
            <div className='sync'>
              {shopifyStore.syncStatus === 'synced' && (
                <>
                  <span className='status'>
                    {brandData.totalShopifyProducts} Products synced on{' '}
                    {moment(shopifyStore.lastSynced).format('ll')} <CheckOutlined />
                  </span>
                  <Button
                    onClick={handleResync}
                    loading={resyncLoading}
                    type='link'
                    icon={<SyncOutlined />}>
                    Resync
                  </Button>
                </>
              )}
              {shopifyStore.syncStatus === 'syncing' && (
                <span className='status'>
                  Syncing products... <SyncOutlined />
                </span>
              )}
              {shopifyStore.syncStatus === 'error' && (
                <>
                  <span className='status error'>
                    Products failed to sync{' '}
                    <Tooltip title={shopifyStore.metaData.syncError}>
                      <QuestionCircleOutlined />
                    </Tooltip>
                  </span>

                  <Button
                    onClick={handleResync}
                    loading={resyncLoading}
                    type='link'
                    icon={<SyncOutlined />}>
                    Resync
                  </Button>
                </>
              )}
            </div>
          </div>
        ) : (
          <Empty
            description={
              <>
                No stores connected.{' '}
                <Tooltip title='Connect your Shopify store to easily create product orders for your creators via our platform.'>
                  <InfoCircleOutlined />
                </Tooltip>
              </>
            }
            image={Empty.PRESENTED_IMAGE_SIMPLE}
          />
        )}

        <Modal
          title='Disconnect Shopify Store'
          destroyOnClose
          open={disconnectModalOpen}
          footer={null}
          onCancel={() => {
            setDisconnectModalOpen(false)
          }}>
          <p>
            Are you sure you want to disconnect this store? You will not be able to access products
            from this store after disconnecting.
          </p>
          <Button onClick={handleDisconnect} danger type='primary' loading={disconnectLoading}>
            Disconnect
          </Button>
        </Modal>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  .section-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;

    .section-title {
      .icon {
        width: 20px;
        height: 20px;
      }
      font-size: 18px;
    }
  }
  .section-body {
    padding: 10px !important;
    .connected-stores {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 10px 0;

      .stores-list {
        display: flex;
        align-items: center;
        justify-content: flex-start;
      }
      .open-store {
        font-size: 16px;
      }
    }
    .connect-shopify {
      margin: 15px 0 0 0;
    }
  }
  .connected-store {
    padding: 10px 20px;
    .details {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 20px;

      .name {
        font-size: 20px;
      }
    }
    .sync {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .status {
        color: gray;
        &.error {
          color: red;
        }
      }
    }
  }
`

export default BrandIntegrations
