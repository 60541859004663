import { Empty } from 'antd'
import React from 'react'
import {
  Bar,
  ComposedChart,
  Legend,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'
import * as yup from 'yup'

import { theme } from '../../../GlobalStyles'
import { BrandIdFilter } from '../filters/BrandIdFilter'
import { CampaignIdFilter } from '../filters/CampaignIdFilter'
import { DateRangeFilter } from '../filters/DateRangeFilter'
import { ManualDateRangeFilter } from '../filters/ManualDateRangeFilter'
import { ManageWidget } from '../ManageWidget'
import { useCampaignOptions } from '../utils'
import { MetricsWrapper, WidgetCard } from '../WidgetCard'

export const AffiliateSalesVsPosts = ({ widget, activeView, setActiveView, brandData, data }) => {
  const campaignOptions = useCampaignOptions(brandData)

  const defaultValues = {
    title: widget.defaultTitle,
    ...widget.defaultFilters,
  }

  const initialValues = {
    title: widget.title || defaultValues.title,
    brandIds: widget.filters?.brandIds || defaultValues.brandIds,
    campaignIds: widget.filters?.campaignIds || defaultValues.campaignIds,
    fromDate: widget.filters?.fromDate || defaultValues.fromDate,
    toDate: widget.filters?.toDate || defaultValues.toDate,
    dateRange:
      widget.filters?.fromDate ||
      defaultValues.fromDate ||
      widget.filters?.toDate ||
      defaultValues.toDate
        ? 'custom'
        : widget.filters?.dateRange || defaultValues.dateRange,
  }

  const validationSchema = yup.object().shape({
    title: yup.string().required('Required').max(50, 'Must be 50 characters or less'),
    brandIds: yup.array(),
    campaignIds: yup.array(),
    dateRange: yup.string(),
  })

  const constructData = values => {
    return {
      title: values.title,
      filters: {
        brandIds: values.brandIds,
        campaignIds: values.campaignIds,
        fromDate: values.fromDate,
        toDate: values.toDate,
        dateRange: values.dateRange,
      },
    }
  }

  const Filters = ({ setFieldValue }) => {
    return (
      <>
        {!!brandData.childBrands?.length && <BrandIdFilter brandData={brandData} />}
        <CampaignIdFilter campaignOptions={campaignOptions} />

        <div className='date-range-wrapper'>
          <ManualDateRangeFilter setFieldValue={setFieldValue} />
          <DateRangeFilter setFieldValue={setFieldValue} />
        </div>
      </>
    )
  }

  const Metrics = () => {
    const formattedData = data?.map(dataPoint => {
      return {
        name: dataPoint.month,
        Posts: Number(dataPoint.posts),
        Sales: Number(dataPoint.sales),
      }
    })

    const TrendLineChart = () => {
      return (
        <ResponsiveContainer width='100%' minWidth={600} height={400}>
          <ComposedChart data={formattedData}>
            <XAxis dataKey='name' stroke={theme.crcoGrey} />
            <YAxis yAxisId='left' orientation='left' stroke={theme.crcoLavender} />
            <YAxis yAxisId='right' orientation='right' stroke={'#4D9CE6'} />
            <Tooltip
              cursor={false} // removes the cursor (vertical line that appears when you hover over the chart)
            />
            <Legend />
            <Bar
              yAxisId='left'
              type='monotone' // makes the line smooth
              dataKey='Posts'
              fill={theme.crcoLavender} // bar color
              stroke={theme.crcoLavender} // line color
              isAnimationActive={false} // FIXME: issue with animations due to multiple renders
              barSize={10}
              radius={[10, 10, 0, 0]}
            />
            <Line
              yAxisId='right'
              type='monotone'
              dataKey='Sales'
              stroke={'#4D9CE6'}
              isAnimationActive={false}
            />
          </ComposedChart>
        </ResponsiveContainer>
      )
    }

    return (
      <MetricsWrapper className='overflow-auto'>
        {data?.length ? (
          <TrendLineChart />
        ) : (
          <div className='no-data'>
            <Empty description='No data.' />
          </div>
        )}
      </MetricsWrapper>
    )
  }

  const settingsProps = {
    widget,
    Filters,
    defaultValues,
    initialValues,
    validationSchema,
    constructData,
    activeView,
    setActiveView,
    brandId: brandData.id,
  }

  const cardProps = {
    widget,
    settings: <ManageWidget {...settingsProps} />,
    metrics: <Metrics />,
    size: 'large',
    brandData,
  }

  return <WidgetCard {...cardProps} />
}
