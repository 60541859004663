import JSZip from 'jszip'

export async function downloadFile(fileName, url) {
  const response = await fetch(url, { headers: { 'Cache-Control': 'no-cache' } })

  const blob = await response.blob()

  downloadBlob(blob, fileName)
}

export async function downloadFilesZip(zipName, files) {
  const jsZip = new JSZip()

  for (const file of files) {
    try {
      const response = await fetch(file.url, { headers: { 'Cache-Control': 'no-cache' } })

      const blob = await response.blob()
      jsZip.file(file.fileName, blob)
    } catch (err) {
      // TODO: Handle error?
    }
  }

  const zipBlob = await jsZip.generateAsync({ type: 'blob' })

  downloadBlob(zipBlob, zipName)
}

export async function downloadBlob(zipBlob, fileName) {
  const zipUrl = URL.createObjectURL(zipBlob)

  const a = document.createElement('a')
  a.href = zipUrl
  a.download = fileName
  document.body.appendChild(a)
  a.click()
  document.body.removeChild(a)

  // Clean up the object URL to avoid memory leaks
  URL.revokeObjectURL(zipUrl)
}

export function findExtension(url) {
  const path = url.split('?')[0]
  return path.split('.').pop()
}
