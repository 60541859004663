import { Tooltip } from 'antd'
import React from 'react'
import styled from 'styled-components/macro'

export const HorizontalBarChart = ({ data, color = '#027df0' }) => {
  // recommended use: items where the data labels are long or with icons, sort data from highest to lowest value to show ranking, limit to 5-7 items
  return (
    <Wrapper>
      {data.map((item, i) => (
        <div key={i} className='list-item'>
          <div className='info'>
            {item.icon && <div className='icon-wrapper'>{item.icon}</div>}
            <span className='label'>{item.label}</span>
          </div>
          <div className='metric'>
            <Tooltip
              key={i}
              title={`${item.label}: ${item.percentage}% (${item.count.toLocaleString()} total)`}
              placement='left'>
              {!!item.percentage && <Bar color={color} width={item.percentage} />}
            </Tooltip>
            <span className='value'>{item.percentage?.toFixed(0) || 0}%</span>
          </div>
        </div>
      ))}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;
  font-size: 14px;
  .list-item {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
  .info {
    display: flex;
    align-items: center;
    gap: 5px;
  }
  .icon-wrapper {
    height: 20px;
    width: 20px;
    display: grid;
    place-content: center;
    img {
      height: 100%;
      width: 100%;
    }
  }
  .label {
    color: ${props => props.theme.crcoGrey};
    margin-top: 2px;
  }
  .metric {
    display: flex;
    align-items: center;
  }
  .value {
    color: ${props => props.theme.crcoLavender};
    font-size: 12px;
    margin-top: 2px;
  }
`

const Bar = styled.div`
  background: ${props => props.color};
  width: ${props => props.width}%;
  height: 15px;
  border-radius: 15px;
  margin-right: 5px;
`
