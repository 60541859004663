import { Alert, Button } from 'antd'
import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components/macro'

import { BOOK_DEMO_URL } from '../../constants'

export const LimitedAccessAlert = () => {
  return (
    <Wrapper>
      <Alert
        message='Looking for Full Access?'
        description='This special feature is just one of many available to our subscribers. Interested in exploring more? Subscribe now or connect with our team by booking a demo.'
        type='info'
        showIcon
      />
      <div className='buttons'>
        <a href={BOOK_DEMO_URL} target='_blank' rel='noopener noreferrer'>
          <Button type='secondary'>Book Demo</Button>
        </a>
        <Link to='/pricing'>
          <Button type='primary'>View Plans</Button>
        </Link>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  height: 100%;
  max-width: 600px;
  margin: auto;
  display: grid;
  place-content: center;
  padding: 20px;
  .buttons {
    align-items: center;
    justify-content: center;
    margin: 20px;
    display: flex;
    gap: 12px;
  }
`
