import { LoadingOutlined, CheckCircleOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import React from 'react'
import styled from 'styled-components/macro'

// use this wherever we need a simple indicator showing saving status for forms
// make sure to pass savingStatus (possible values: undefined, submitting, success, error) and you can use setTimeout (2 seconds) to make this only appear temporarily
export const SavingIndicator = ({ savingStatus, label }) => {
  return (
    <Wrapper>
      <div className='icon-wrapper'>
        <div className={`icon submitting ${savingStatus === 'submitting' && 'visible'}`}>
          <LoadingOutlined spin />
        </div>
        <div className={`icon success ${savingStatus === 'success' && 'visible'}`}>
          <CheckCircleOutlined />
        </div>
        <div className={`icon error ${savingStatus === 'error' && 'visible'}`}>
          <ExclamationCircleOutlined />
        </div>
      </div>
      {label && (
        <span className={`label ${savingStatus && 'visible'}`}>
          {savingStatus === 'submitting'
            ? 'Saving'
            : savingStatus === 'success'
              ? 'Saved'
              : savingStatus === 'error'
                ? 'Error'
                : ''}
        </span>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  color: ${props => props.theme.crcoGrey};
  .icon-wrapper {
    display: grid;
    place-content: center;
    position: relative;
  }
  .icon {
    visibility: hidden;
    opacity: 0;
    color: #fff;
    height: 20px;
    width: 20px;
    display: grid;
    place-content: center;
    border-radius: 50%;
    transition: 0.2s ease-in-out;
    &.visible {
      visibility: visible;
      opacity: 1;
    }
  }
  .submitting {
    color: #ccc;
  }
  .success {
    background: ${props => props.theme.crcoTechBlue};
    position: absolute;
  }
  .error {
    background: #ee0000;
    position: absolute;
  }
  .label {
    visibility: hidden;
    opacity: 0;
    transition: 0.2s ease-in-out;
    &.visible {
      visibility: visible;
      opacity: 1;
    }
  }
`
