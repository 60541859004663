import { Row, Col, Button, Alert, message } from 'antd'
import axios from 'axios'
import { Formik, Form } from 'formik'
import { Input } from 'formik-antd'
import React, { useContext } from 'react'
import { queryCache } from 'react-query'
import * as yup from 'yup'

import FormItem from '../../components/forms/FormItem'
import { NODE_URL } from '../../constants'
import { BrandContext } from '../../contexts/BrandContext'

export const InviteTeammate = ({ closeModal }) => {
  const { brandId } = useContext(BrandContext)

  const initialValues = {
    firstName: '',
    lastName: '',
    email: '',
  }

  const validationSchema = yup.object().shape({
    firstName: yup.string().required('Required'),
    lastName: yup.string().required('Required'),
    email: yup.string().email('Invalid email').required('Required'),
  })

  const handleSubmit = async (data, { setStatus }) => {
    setStatus('')
    try {
      await axios.post(`${NODE_URL}/brand/${brandId}/user/invite`, data)
      closeModal()
      message.success('User successfully invited.')
      queryCache.invalidateQueries(['brand', brandId])
    } catch (err) {
      setStatus(err.response.data.err || 'Sorry, something went wrong.')
    }
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}>
      {({ status, isSubmitting }) => (
        <Form>
          <p>Invite a member of your team to be added as a user to your brand account.</p>

          <Row gutter='12'>
            <Col span='12'>
              <FormItem name='firstName' label='First Name' size='small'>
                <Input name='firstName' />
              </FormItem>
            </Col>
            <Col span='12'>
              <FormItem name='lastName' label='Last Name' size='small'>
                <Input name='lastName' />
              </FormItem>
            </Col>
          </Row>
          <Row>
            <Col span='24'>
              <FormItem name='email' label='Email' size='small'>
                <Input name='email' />
              </FormItem>
            </Col>
          </Row>

          {status && <Alert message={status} type='error' showIcon />}

          <Button
            type='primary'
            htmlType='submit'
            loading={isSubmitting}
            style={{ marginTop: '20px' }}>
            Submit
          </Button>
        </Form>
      )}
    </Formik>
  )
}
