import { EditOutlined, PlusOutlined, QuestionCircleOutlined } from '@ant-design/icons'
import { Button, Drawer, Empty, message, Modal } from 'antd'
import { Formik, Form } from 'formik'
import { Input } from 'formik-antd'
import React, { useContext, useState, useEffect } from 'react'
import { useQuery, queryCache } from 'react-query'
import styled from 'styled-components/macro'

import { AnalyticsGuide } from './AnalyticsGuide'
import { AnalyticsView } from './AnalyticsView'
import { ManageView } from './ManageView'
import { widgetComponents } from './widgetComponents'
import FormItem from '../../components/forms/FormItem'
import { ErrorFallBack } from '../../components/general/ErrorFallback'
import LoadingLogo from '../../components/general/LoadingLogo'
import { BrandContext } from '../../contexts/BrandContext'
import { UserContext } from '../../contexts/UserContext'
import { useAnalyticsApi } from '../../custom-hooks/analyticsApi'
import { useDocumentTitle } from '../../custom-hooks/useDocumentTitle'

export const AnalyticsDashboard = () => {
  // #region State
  const [widgets, setWidgets] = useState([])
  const [guideOpen, setGuideOpen] = useState(false)
  const [settingsOpen, setSettingsOpen] = useState(false)
  const [newViewOpen, setNewViewOpen] = useState(false)
  const [duplicatingView, setDuplicatingView] = useState(false)
  const [deletingView, setDeletingView] = useState(false)
  const [activeView, setActiveView] = useState(undefined)
  const analyticsApi = useAnalyticsApi()
  // #endregion State

  // #region Context
  const { role } = useContext(UserContext)
  const { brandId, fetchBrandData } = useContext(BrandContext)
  // #endregion Context

  // #region Queries
  const { data: brandData } = useQuery(['brand', brandId], fetchBrandData)
  const {
    data: views,
    status,
    error,
  } = useQuery(['analytics-views', brandId], () =>
    analyticsApi.views.viewsDetail(brandId).then(d => d.views)
  )
  // #endregion Queries

  // #region Hooks & Effects
  useDocumentTitle(
    role === 'administrator' && brandData?.name
      ? `${brandData.name} - Analytics | Brand Dashboard`
      : 'Analytics | Brand Dashboard'
  )

  useEffect(() => {
    // set active view to first view in list if none is selected yet
    if (!activeView && views) setActiveView(views[0])
  }, [activeView, setActiveView, views])

  useEffect(() => {
    // for each widget in active view, find the corresponding widget component and add to state
    if (activeView) {
      const activeViewWidgets = activeView.widgets.map(widget => {
        const component = widgetComponents.find(
          ({ componentId }) => componentId === widget.componentId
        )
        return {
          ...component,
          ...widget,
        }
      })

      setWidgets(activeViewWidgets)
    }
  }, [activeView])
  // #endregion Hooks & Effects

  // #region Functions
  const handleNewView = async values => {
    analyticsApi.view
      .viewNewCreate(brandId, {
        title: values.title,
      })
      .then(res => {
        setNewViewOpen(false)
        queryCache.invalidateQueries(['analytics-views', brandId])
        setActiveView(res)
      })
      .catch(() => {
        message.error('Error creating view')
      })
  }

  const handleDuplicateView = async () => {
    setDuplicatingView(true)

    analyticsApi.view
      .viewDuplicateCreate(brandData.id, activeView.id)
      .then(res => {
        queryCache.invalidateQueries(['analytics-views', brandData.id])
        message.success('View duplicated')
        setSettingsOpen(false)
        setActiveView(res)
      })
      .catch(() => {
        message.error('Error duplicating view')
      })
      .finally(() => {
        setDuplicatingView(false)
      })
  }

  const handleDeleteView = async () => {
    // prevent deleting if this is only view
    if (views.length === 1) return

    setDeletingView(true)

    analyticsApi.view
      .viewDelete(activeView.id, brandData.id)
      .then(() => {
        queryCache.invalidateQueries(['analytics-views', brandId])
        message.success('View deleted')
        setSettingsOpen(false)
        setActiveView(undefined)
      })
      .catch(() => {
        message.error('Error deleting view')
      })
      .finally(() => {
        setDeletingView(false)
      })
  }
  // #endregion Functions

  return (
    <Wrapper>
      {brandData && status === 'success' && (
        <>
          <section className='header-wrapper'>
            <div className='tab-header'>
              <div className='header-text'>
                <h1>Analytics</h1>
              </div>
              <Button
                type='link'
                icon={<QuestionCircleOutlined />}
                onClick={() => setGuideOpen(true)}>
                Help
              </Button>
              <Drawer
                title='Analytics Dashboard Guide'
                open={guideOpen}
                onClose={() => setGuideOpen(false)}>
                <AnalyticsGuide />
              </Drawer>
            </div>
          </section>
          <section className='views-wrapper'>
            <div className='view-selection'>
              {!!views?.length &&
                views.map(view => (
                  <div
                    key={view.id}
                    className={`view-btn ${activeView?.id === view.id && 'active'}`}
                    onClick={() => {
                      if (view.id === activeView.id) return // prevent re-rendering if already active
                      setActiveView(view)
                      setWidgets([]) // reset widgets to prevent old widgets showing in selected view
                    }}
                    onKeyDown={() => {
                      if (view.id === activeView.id) return
                      setActiveView(view)
                      setWidgets([])
                    }}
                    role='button'
                    tabIndex={0}>
                    <span>{view.title}</span>
                    {activeView?.id === view.id && (
                      <button
                        className='edit-btn'
                        onClick={e => {
                          e.stopPropagation()
                          setSettingsOpen(true)
                        }}>
                        <EditOutlined />
                      </button>
                    )}
                  </div>
                ))}

              <Button type='link' onClick={() => setNewViewOpen(true)}>
                <PlusOutlined /> New View
              </Button>
            </div>

            <div className='widgets'>
              {activeView ? (
                <AnalyticsView
                  widgets={widgets}
                  activeView={activeView}
                  setActiveView={setActiveView}
                  brandData={brandData}
                  setSettingsOpen={setSettingsOpen}
                />
              ) : (
                <div className='empty'>
                  <Empty description='No views.' />
                  <Button type='primary' onClick={() => setNewViewOpen(true)}>
                    Create View
                  </Button>
                </div>
              )}
            </div>
          </section>

          {/* MODALS / DRAWERS */}
          <Modal
            title='New View'
            open={newViewOpen}
            onCancel={() => setNewViewOpen(false)}
            destroyOnClose
            footer={null}>
            <Formik initialValues={{ title: '' }} onSubmit={handleNewView}>
              {({ values, submitForm, isSubmitting }) => (
                <Form>
                  <FormItem name='title' label='Title'>
                    <Input name='title' placeholder='e.g. My Campaign Performance' />
                  </FormItem>
                  <Button
                    type='primary'
                    onClick={submitForm}
                    loading={isSubmitting}
                    disabled={!values.title}>
                    Create View
                  </Button>
                </Form>
              )}
            </Formik>
          </Modal>

          {activeView && (
            <Drawer
              title='Edit View'
              open={settingsOpen}
              onClose={() => setSettingsOpen(false)}
              destroyOnClose
              width={window.innerWidth < 768 ? '100%' : '450px'}>
              <ManageView
                views={views}
                activeView={activeView}
                setActiveView={setActiveView}
                widgets={widgets}
                setWidgets={setWidgets}
                brandData={brandData}
                handleDuplicateView={handleDuplicateView}
                duplicatingView={duplicatingView}
                deletingView={deletingView}
                handleDeleteView={handleDeleteView}
              />
            </Drawer>
          )}
        </>
      )}

      {(!brandData || status === 'loading') && (
        <div className='loading-wrapper'>
          <LoadingLogo />
        </div>
      )}

      {(error || status === 'error') && <ErrorFallBack />}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  background: #fff;
  color: #23262f;
  height: 100%;
  max-width: 100%;
  overflow: hidden;

  .loading-wrapper,
  .error-wrapper {
    height: 100%;
    display: grid;
    place-items: center;
  }

  .header-wrapper {
    max-width: 1400px;
    padding: 20px;
    margin: 0 auto;
  }
  .tab-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 20px;
    width: 100%;
    .header-text {
      display: flex;
      align-items: center;
      gap: 12px;
    }
    .platform-icon {
      margin-left: 10px;
      height: 20px;
    }
  }
  h1 {
    font-family: 'Gilroy-ExtraBold', sans-serif;
    font-size: 1.5rem;
    margin: 0;
  }

  .views-wrapper {
    height: calc(100vh - 157px);
  }
  .view-selection {
    max-width: 1400px;
    margin: 0 auto -1px auto; // hide the bottom border
    display: flex;
    gap: 20px;
    padding: 0 20px;
    margin: 0 auto -1px auto;
    overflow-x: auto;
  }
  .view-btn {
    background: #dbdbee;
    color: ${props => props.theme.crcoMidnight};
    font-size: 16px;
    padding: 5px 15px;
    border-radius: 5px 5px 0 0;
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
    transition: 0.2s ease-in-out !important;
    border: 1px solid transparent;
    border-bottom: none;
    &.active {
      background: #f4f4f8;
      border-color: #dbdbee;
      color: ${props => props.theme.crcoTechBlue};
    }
  }
  .edit-btn {
    background: #f6f6f6;
    border: 1px solid #ddd;
    border-radius: 6px;
    height: 20px;
    width: 20px;
    display: grid;
    place-content: center;
    color: #999;
    transition: 0.2s ease-in-out;
    font-size: 0.8rem;
    position: relative;
    .anticon {
      margin: 0;
    }
    &:hover {
      background: #eee;
      color: #666;
      border-color: #ccc;
    }
  }

  .widgets {
    background: #f4f4f8;
    border-top: 1px solid #dbdbee;
    height: 100%;
    padding: 20px 0;
    .widgets-inner {
      max-height: 100%;
      max-width: 1400px;
      margin: 0 auto;
      display: flex;
      flex-wrap: wrap;
      gap: 20px;
      padding: 20px;
      overflow: auto;
      ${props => props.theme.scrollbar};
    }
  }

  .empty {
    height: 100%;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
  }
`
