import { Result, Button, Alert } from 'antd'
import React, { useContext } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { useQuery } from 'react-query'
import { Route, Switch, Redirect } from 'react-router-dom'
import styled from 'styled-components/macro'

import { BrandSettings } from './account/BrandSettings'
import Affiliate from './affiliate/Affiliate'
import { AnalyticsDashboard } from './analytics/AnalyticsDashboard'
import CampaignDashboard from './CampaignDashboard'
import { Inbox } from './chat/Inbox'
import CreatorSearch from './creator-search/CreatorSearch'
import EditCampaign from './edit-campaign/EditCampaign'
import Pricing from './pricing/Pricing'
import Sidebar from './Sidebar'
import { SocialListening } from './social-listening/SocialListening'
import { UserSettings } from './user-settings/UserSettings'
import { Welcome } from './Welcome'
import SingleCampaign from '../components/campaign-dashboard/SingleCampaign'
import { ErrorFallBack } from '../components/general/ErrorFallback'
import { BrandContext } from '../contexts/BrandContext'
import { CampaignContextProvider } from '../contexts/CampaignContext'
import { UserContext } from '../contexts/UserContext'

const Dashboard = () => {
  const { nodeToken, role } = useContext(UserContext)
  const { brandId, fetchBrandData, openBilling } = useContext(BrandContext)
  const mobileResponsiveRoutes = [
    '/pricing',
    '/welcome',
    '/account',
    '/profile',
    '/social-listening',
    '/analytics',
  ]
  const route = window.location.pathname

  const { data: brandData } = useQuery(['brand', brandId], fetchBrandData)

  return (
    <ErrorBoundary fallback={<ErrorFallBack />} showDialog={true}>
      <Wrapper>
        {/* {new Error('Test')} */}
        <Sidebar />
        <div className='content'>
          {!mobileResponsiveRoutes.includes(route) && (
            <div className='mobile-alert'>
              <Alert
                type='warning'
                message={`It looks like you're using a mobile device. This dashboard is not optimized for smaller screens. Please use a tablet or desktop computer.`}
                showIcon
                closable
              />
            </div>
          )}
          {brandData?.chargebeePastDue && role === 'administrator' && (
            <Alert
              type='warning'
              message='This account is past due on their payments. The brand has been blocked from accessing their campaigns and search tool.'
              showIcon
            />
          )}
          {brandData?.chargebeePastDue && role !== 'administrator' ? (
            <div className='dashboard-result'>
              <Result
                status='warning'
                title='Payment Past Due'
                subTitle='It looks like you have a payment that is overdue. You will not be able to access your dashboard until it is paid in full. Please update your billing details.'
                extra={
                  <Button type='primary' onClick={openBilling}>
                    Open Billing
                  </Button>
                }
              />
            </div>
          ) : (
            <Switch>
              <Route path='/welcome' component={Welcome} />
              <Route path={['/creator-search/:tab', '/creator-search/']}>
                <CreatorSearch />
              </Route>

              <Route path={['/creator-search-beta/:tab', '/creator-search-beta/']}>
                <CreatorSearch beta />
              </Route>

              <Route path='/social-listening'>
                <SocialListening />
              </Route>

              <Route path='/analytics'>
                <AnalyticsDashboard />
              </Route>

              <Route path='/affiliate/:tab'>
                <Affiliate />
              </Route>

              <Route path={['/inbox/:campaignId/chat/:optInId', '/inbox/:campaignId/', '/inbox']}>
                <Inbox brand={brandData?.name} />
              </Route>

              <Route path='/account'>
                <BrandSettings />
              </Route>

              <Route path='/profile'>
                <UserSettings />
              </Route>

              <Route path={['/pricing/:planId', '/pricing']}>
                {nodeToken && <Pricing brandId={brandId} />}
              </Route>

              <CampaignContextProvider>
                <Route path='/campaign-builder/:campaignId'>
                  <EditCampaign />
                </Route>

                <Route path={['/campaigns/:campaignID/:tab', '/campaigns/:campaignID/']}>
                  <SingleCampaign brand={brandData?.name} />
                </Route>

                <Route exact path='/campaigns'>
                  <CampaignDashboard brandName={brandData?.name} />
                </Route>

                <Route exact path='/'>
                  <Redirect to='/campaigns' />
                </Route>
              </CampaignContextProvider>
            </Switch>
          )}
        </div>
      </Wrapper>
    </ErrorBoundary>
  )
}

const Wrapper = styled.div`
  height: calc(100vh - 50px);
  overflow: hidden;
  display: flex;

  & > .content {
    display: flex;
    flex-direction: column;
    overflow: auto;
    flex: 1;
    scroll-behavior: smooth;

    .notice-board {
      padding: 12px;
      margin: 0 auto;
    }
  }

  // hide mobile alert on tablet
  @media only screen and (min-width: ${props => props.theme.breakpointTablet}) {
    .mobile-alert {
      display: none;
    }
  }
`

export default Dashboard
