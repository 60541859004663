import { Button, message } from 'antd'
import axios from 'axios'
import { Form, Formik } from 'formik'
import { Input } from 'formik-antd'
import React, { useContext } from 'react'
import { queryCache } from 'react-query'
import styled from 'styled-components'

import { NODE_URL } from '../../constants'
import { UserContext } from '../../contexts/UserContext'

const BRAND_SUBMIT_WEB_REDEMPTION_CODE = ({ optIn, campaignStep }) => {
  const { nodeToken } = useContext(UserContext)

  return (
    <Wrapper>
      <p>Enter web redemption code for the creator.</p>
      <Formik
        initialValues={{ shoutout_web_redemption_unique_code: optIn.extraData?.redemptionCode }}
        onSubmit={(data, { setSubmitting }) => {
          axios
            .post(`${NODE_URL}/brand/opt-in/${optIn.id}/step/${campaignStep.id}`, data, {
              headers: {
                Authorization: `Bearer ${nodeToken}`,
              },
            })
            .then(async () => {
              message.success('Code submitted')
              queryCache.invalidateQueries('opt-ins')
              setSubmitting(false)
            })
            .catch(() => {
              message.error('Sorry, something went wrong.')
              setSubmitting(false)
            })
        }}>
        {({ isSubmitting }) => (
          <Form>
            <Input
              required
              name='shoutout_web_redemption_unique_code'
              placeholder='Web redemption code'
              onClick={e => e.stopPropagation()}
            />
            <Button loading={isSubmitting} type='primary' htmlType='submit'>
              {optIn.extraData?.redemptionCode ? 'Update' : 'Submit'}
            </Button>
          </Form>
        )}
      </Formik>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: grid;
  place-items: center;
  min-height: 80px;
  form {
    display: flex;
    gap: 10px;
  }
`

export default BRAND_SUBMIT_WEB_REDEMPTION_CODE
