import { EyeTwoTone, EyeInvisibleOutlined, CheckCircleOutlined } from '@ant-design/icons'
import { GoogleLogin } from '@react-oauth/google'
import { Button, message } from 'antd'
import { CountryPhoneInput, ConfigProvider } from 'antd-country-phone-input'
import axios from 'axios'
import { Formik, Form } from 'formik'
import { Input, Checkbox } from 'formik-antd'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import en from 'world_countries_lists/data/countries/en/world.json'
import * as yup from 'yup'

import { NODE_URL } from '../../constants'
import { validateEmail } from '../../custom-hooks/utils'
import arrow from '../../images/login/arrow-shape.svg'
import FormItem from '../forms/FormItem'

import 'antd/dist/antd.css'
import 'antd-country-phone-input/dist/index.css'

// SIGN UP STEP 1 (name and email/phone verification)
export const UserInfoForm = ({
  googleProfile,
  logOut,
  formData,
  setFormData,
  nextStep,
  handleGoogleSignup,
  emailVerified,
  setEmailVerified,
}) => {
  const [loading, setLoading] = useState(false)
  const [code, setCode] = useState('')

  const validationSchema = googleProfile
    ? yup.object().shape({
        phone: yup
          .string()
          .required('Required')
          .matches(
            /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\\./0-9]*$/,
            'Please enter a valid phone number'
          )
          .min(7, 'Please enter a valid phone number'),
      })
    : yup.object().shape({
        firstName: yup.string().required('Required').min(2, 'Too short'),
        lastName: yup.string().required('Required').min(2, 'Too short'),
        email: yup.string().email('Please enter a valid email address').required('Required'),
        phone: yup
          .string()
          .required('Required')
          .matches(
            /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\\./0-9]*$/,
            'Please enter a valid phone number'
          )
          .min(7, 'Please enter a valid phone number'),
        password: yup.string().required('Required').min(8, 'Minimum 8 characters'),
        confirmPassword: yup
          .string()
          .required('Confirm your password')
          .oneOf([yup.ref('password'), null], 'Passwords must match'),
      })

  const sendCode = async email => {
    setLoading(true)

    const validEmail = validateEmail(email)
    if (!validEmail) {
      message.error('Invalid email address')
      setLoading(false)
      return
    }

    try {
      const res = await axios.post(`${NODE_URL}/brand/register/verify/${email}`)
      message.success('Verification code sent')
      setCode(res.data.key)
      setLoading(false)
    } catch (err) {
      message.error(err.response.data?.err || 'Sorry, something went wrong.')
      setLoading(false)
    }
  }

  return googleProfile ? (
    // LOGGED IN VIA GOOGLE
    <Formik
      initialValues={formData}
      validationSchema={validationSchema}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={values => {
        setFormData(prev => ({
          ...prev,
          ...values,
          firstName: values.firstName || prev.firstName,
          lastName: values.lastName || prev.lastName,
          email: values.email || prev.email,
          phone: values.phone || prev.phone,
        }))
        nextStep()
      }}>
      {({ values, setValues, submitForm, isSubmitting }) => (
        <Form>
          <div>
            <img src={arrow} alt='' className='arrow' />
            <h1>Welcome {formData.firstName}!</h1>
            <p className='subtext'>You are logged in through Google.</p>

            <div style={{ margin: '40px 0' }}>
              <ConfigProvider locale={en}>
                <FormItem name='phone' size='small'>
                  <CountryPhoneInput
                    inline
                    autoComplete='phone'
                    name='phone'
                    placeholder='Phone'
                    value={values}
                    onChange={e => {
                      setValues(prev => ({ ...prev, ...e }))
                    }}
                  />
                </FormItem>
              </ConfigProvider>
              <Checkbox name='sendText'>Text me a demo video</Checkbox>
            </div>

            <div className='nav-buttons'>
              <Button type='secondary' onClick={logOut}>
                Cancel
              </Button>
              <Button
                type='primary'
                onClick={submitForm}
                loading={isSubmitting}
                disabled={
                  !formData.firstName ||
                  !formData.lastName ||
                  !formData.email ||
                  !values.phone ||
                  !emailVerified
                }>
                Continue
              </Button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  ) : (
    // SIGN UP WITH EMAIL
    <Formik
      initialValues={formData}
      validationSchema={validationSchema}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={values => {
        setFormData(prev => ({ ...prev, ...values }))
        nextStep()
      }}>
      {({ values, setValues, submitForm, isSubmitting }) => (
        <Form>
          <img src={arrow} alt='' className='arrow' />
          <h1>Welcome to Creator.co!</h1>
          <p className='subtext'>Let&apos;s get you started.</p>

          <div className='form-row-wrapper'>
            <FormItem name='firstName' size='small' width={'100%'}>
              <Input name='firstName' placeholder='First Name' data-1p-ignore />
            </FormItem>
            <FormItem name='lastName' size='small' width={'100%'}>
              <Input name='lastName' placeholder='Last Name' data-1p-ignore />
            </FormItem>
          </div>

          <ConfigProvider locale={en}>
            <FormItem name='phone' size='small'>
              <CountryPhoneInput
                inline
                autoComplete='phone'
                name='phone'
                placeholder='Phone'
                value={values}
                onChange={e => {
                  setValues(prev => ({ ...prev, ...e }))
                }}
                data-1p-ignore
              />
            </FormItem>
          </ConfigProvider>
          <Checkbox className='demo-vid' name='sendText'>
            Text me a demo video
          </Checkbox>

          <FormItem name='password' size='small'>
            <Input.Password
              name='password'
              placeholder='Password'
              iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
              autoComplete='new-password'
              data-1p-ignore
            />
          </FormItem>
          <FormItem name='confirmPassword' size='small'>
            <Input.Password
              name='confirmPassword'
              placeholder='Confirm Password'
              iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
              autoComplete='new-password'
              data-1p-ignore
            />
          </FormItem>

          <FormItem name='email' size='small'>
            <p className='form-label'>Please verify your email.</p>
            <div className='phone-input-wrapper'>
              <Input
                name='email'
                placeholder='Email Address'
                onChange={() => {
                  setEmailVerified(false)
                  setCode('')
                }}
                data-1p-ignore
              />
              <Button
                className='verify-btn'
                type='primary'
                onClick={() => {
                  sendCode(values.email)
                }}
                disabled={!values.email}
                loading={loading}>
                {code ? 'Resend' : 'Send Code'}
              </Button>
            </div>
          </FormItem>
          {code && (
            <FormItem name='verificationCode' size='small'>
              <Input
                name='verificationCode'
                placeholder='Email Verification Code'
                suffix={emailVerified ? <CheckCircleOutlined style={{ color: '#027df0' }} /> : null}
                onChange={e => {
                  if (e.target.value === code.toString()) {
                    setEmailVerified(true)
                  }
                }}
                data-1p-ignore
              />
            </FormItem>
          )}

          <div className='buttons'>
            <Button
              type='primary'
              onClick={submitForm}
              loading={isSubmitting}
              disabled={
                !values.firstName ||
                !values.lastName ||
                !values.email ||
                !values.phone ||
                !values.password ||
                !values.confirmPassword ||
                !emailVerified
              }>
              Continue
            </Button>
            <div className='divider'>
              <span /> OR <span />
            </div>
            <GoogleLogin
              type='standard'
              shape='pill'
              size='medium'
              text='continue_with'
              onSuccess={credentialResponse => {
                handleGoogleSignup(credentialResponse.credential)
              }}
              onError={() => {
                message.error('Sorry, something went wrong.')
              }}
            />
          </div>
          <div className='sub-links'>
            Already have an account? <Link to='/login'>Log In</Link>
          </div>
        </Form>
      )}
    </Formik>
  )
}
