import { io } from 'socket.io-client'

import { NODE_URL } from '../constants'

const init = () => {
  return io(NODE_URL, { transports: ['websocket'] })
}

const socket = init()

export { socket }
