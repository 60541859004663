import { DownOutlined } from '@ant-design/icons'
import { Col, Popover, Row, Spin, Tag } from 'antd'
import axios from 'axios'
import { Checkbox, Input, InputNumber, Select, Slider } from 'formik-antd'
import React, { useState, useRef } from 'react'
import styled from 'styled-components/macro'

import FormItem from '../../../components/forms/FormItem'
import { NODE_URL } from '../../../constants'
import instagram from '../../../images/icons/social/instagram-color-square.svg'
import tiktok from '../../../images/icons/social/tiktok-color-square.svg'
import youtube from '../../../images/icons/social/youtube-color-square.svg'
const { Option } = Select

const CreatorFilters = ({
  platform,
  values,
  formChange,
  socapiFilters,
  setPlatform,
  setValues,
  submitForm,
}) => {
  const [fetchingLocations, setFetchingLocations] = useState(false)
  const [locations, setLocations] = useState([])
  const lastLocationFetchID = useRef(0)
  const fetchLocations = val => {
    if (val.length > 2) {
      lastLocationFetchID.current += 1
      const fetchID = lastLocationFetchID.current
      setFetchingLocations(true)
      setLocations([])
      axios.get(`${NODE_URL}/socialdata-countries/?platform=${platform}&val=${val}`).then(res => {
        if (fetchID !== lastLocationFetchID.current) {
          return
        }
        setFetchingLocations(false)
        setLocations(
          res.data?.map(location => ({
            text: location.title,
            value: location.id,
          }))
        )
      })
    }
  }

  const [fetchingTags, setFetchingTags] = useState(false)
  const [tags, setTags] = useState([])
  const lastTagFetchID = useRef(0)
  const fetchTags = search => {
    if (search.length > 2) {
      lastTagFetchID.current += 1
      const fetchID = lastTagFetchID.current
      setFetchingTags(true)
      setTags([])
      axios
        .get(`${NODE_URL}/socialdata-topics`, {
          params: {
            search,
            platform,
          },
        })
        .then(res => {
          if (fetchID !== lastTagFetchID.current) {
            return
          }
          setFetchingTags(false)
          setTags(
            res.data &&
              res.data.map(tag => ({
                text: tag.value,
                value: tag.tag,
              }))
          )
        })
    }
  }

  const tagRender = ({ value, closable, onClose }, prefix) => {
    let newVal = value.replace(prefix, '')
    return (
      <Tag value={newVal} closable={closable} onClose={onClose}>
        {prefix + newVal}
      </Tag>
    )
  }

  // #region POPOVER FILTERS
  const creatorPlatform = () => {
    return (
      <FormItem width={'275px'} size='small' name='platform'>
        <p>Choose a platform to search for creators.</p>
        <Select
          name='platform'
          style={{ width: '100%' }}
          onChange={platform => {
            setPlatform(platform)
            let newValues = { ...values, platform }

            // Define the irrelevant filter keys to delete for each platform
            const keysToDelete = {
              instagram: [
                'creatorViewsGrowthValue',
                'creatorLikesGrowthValue',
                'creatorAvgViewsMax',
              ],
              youtube: [
                'hashtags',
                'creatorVisibility',
                'creatorLikesGrowthValue',
                'creatorInterests',
                'creatorReelViewsMin',
                'creatorReelViewsMax',
                'hasAds',
                'creatorAccountType',
              ],
              tiktok: [
                'keywords',
                'creatorViewsGrowthValue',
                'creatorInterests',
                'creatorReelViewsMin',
                'creatorReelViewsMax',
                'hasAds',
                'creatorAccountType',
              ],
            }

            // Delete keys based on the selected platform
            keysToDelete[platform].forEach(key => {
              delete newValues[key]
            })

            // reels_plays only compatible with instagram
            if (newValues.sort.includes('reels_plays')) {
              newValues.sort = 'followers-desc'
            }

            setValues(newValues)
            submitForm()
          }}>
          <Option value='instagram'>
            <img
              className='platform-icon'
              src={instagram}
              alt=''
              style={{ height: '20px', marginRight: '5px' }}
            />{' '}
            Instagram
          </Option>
          <Option value='youtube'>
            <img
              className='platform-icon'
              src={youtube}
              alt=''
              style={{ height: '20px', marginRight: '5px' }}
            />{' '}
            YouTube
          </Option>
          <Option value='tiktok'>
            <img
              className='platform-icon'
              src={tiktok}
              alt=''
              style={{ height: '20px', marginRight: '5px' }}
            />{' '}
            TikTok
          </Option>
        </Select>
      </FormItem>
    )
  }

  const creatorGenderInput = handleChange => {
    return (
      <FormItem width={'275px'} size='small' name='creatorGender'>
        <p>Identify creators by gender.</p>
        <Select
          name='creatorGender'
          style={{ width: '100%' }}
          defaultValue=''
          onChange={e => {
            handleChange(e, 'creatorGender')
          }}>
          <Option value=''>Any</Option>
          <Option value='FEMALE'>Female</Option>
          <Option value='MALE'>Male</Option>
          <Option value='KNOWN'>Known</Option>
          <Option value='UNKNOWN'>Unknown</Option>
        </Select>
        <HelpText>
          <p>
            We determine gender by analyzing profile photos, name and text in their profile bio, as
            well as selfies in recent posts.
          </p>
        </HelpText>
      </FormItem>
    )
  }

  const creatorAgeInput = handleChange => {
    return (
      <FormItem width={'275px'} size='small'>
        <p>Identify creators by age.</p>
        <Slider
          name='creatorAgeRange'
          range
          step={null}
          marks={{
            18: '18',
            25: '25',
            35: '35',
            45: '45',
            65: '65',
          }}
          min={18}
          max={65}
          defaultValue={[18, 65]}
          onChange={e => {
            handleChange(e, 'creatorAgeRange')
          }}
        />
        <HelpText>
          <p>We determine age by analyzing profile photos and selfies in recent posts.</p>
        </HelpText>
      </FormItem>
    )
  }

  const creatorLanguageInput = handleChange => {
    return (
      <FormItem width={'275px'} size='small'>
        <p>Identify creators by language.</p>
        <Select
          showSearch
          optionFilterProp='label'
          optionLabelProp='label'
          filterOption
          loading={!socapiFilters}
          name='creatorLanguage'
          style={{ width: '100%' }}
          placeholder='Select'
          onChange={e => {
            handleChange(e, 'creatorLanguage')
          }}>
          {socapiFilters ? (
            <>
              <Option value=''>Any</Option>
              {socapiFilters.languages &&
                socapiFilters.languages.map(item => (
                  <Option label={item.name} key={item.code} value={item.code}>
                    {item.name}
                  </Option>
                ))}
            </>
          ) : (
            <span>Loading...</span>
          )}
        </Select>
        <HelpText>
          <p>We determine language by analyzing captions of recent posts.</p>
        </HelpText>
      </FormItem>
    )
  }

  const creatorLastPostedInput = handleChange => {
    return (
      <FormItem width={'275px'} size='small'>
        <p>Identify creators by the recency of their latest post.</p>
        <Select
          style={{ width: '100%' }}
          name='lastPosted'
          placeholder='Select a time period'
          onChange={e => {
            handleChange(e, 'lastPosted')
          }}>
          <Option value=''>Any</Option>
          <Option value='30'>1 Month</Option>
          <Option value='90'>3 Months</Option>
          <Option value='180'>6 Months</Option>
        </Select>
      </FormItem>
    )
  }

  const creatorLocationInput = handleChange => {
    return (
      <FormItem width={'275px'} size='small'>
        <p>Identify creators by location.</p>
        <Select
          name='creatorLocations'
          mode='multiple'
          labelInValue
          autoComplete='none'
          placeholder='Enter at least 3 characters'
          notFoundContent={fetchingLocations ? <Spin size='small' /> : null}
          filterOption={false}
          onSearch={val => fetchLocations(val)}
          onChange={e => {
            setFetchingLocations(false)
            setLocations([])
            handleChange(e, 'creatorLocations')
          }}
          style={{ width: '100%' }}>
          {locations.map(item => (
            <Option key={item.value} value={item.value}>
              {item.text}
            </Option>
          ))}
        </Select>
        <HelpText>
          <p>
            We determine location by analyzing location tags, language and caption of recent posts
            and text in profile bio.
          </p>
          <p>
            You can add several locations and the results will be refined with creators available in
            any of the specified locations.
          </p>
        </HelpText>
      </FormItem>
    )
  }

  const creatorInterestsInput = handleChange => {
    return (
      <FormItem width={'275px'} size='small'>
        <p>Identify creators by niche.</p>
        <Select
          showSearch
          optionFilterProp='label'
          filterOption
          mode='multiple'
          placeholder='Select'
          showArrow
          loading={!socapiFilters}
          name='creatorInterests'
          style={{ width: '100%' }}
          onChange={e => {
            handleChange(e, 'creatorInterests')
          }}>
          {socapiFilters && socapiFilters.interests ? (
            <>
              {socapiFilters.interests.interests.map(
                item =>
                  !item.deprecated && (
                    <Option label={item.name} key={item.id} value={item.id}>
                      {item.name}
                    </Option>
                  )
              )}
            </>
          ) : (
            <span>Loading...</span>
          )}
        </Select>
        <HelpText>
          <p>
            We determine niche by analyzing posts for caption text, mentions, hashtags and location
            tags.
          </p>
          <p>
            You can add several categories and the results will be refined with creators who match
            any of the specified categories.
          </p>
        </HelpText>
      </FormItem>
    )
  }

  const creatorFollowersInput = handleChange => {
    return (
      <FormItem width={'275px'} size='small'>
        <p>Find creators based on a specified range of followers.</p>
        <Row span={24}>
          <Col span={11}>
            <InputNumber
              name='creatorFollowersMin'
              min={1000} // can't search for less than 1k followers
              placeholder='Min'
              onChange={e => {
                handleChange(e, 'creatorFollowersMin')
              }}
            />
          </Col>
          <Col span={2} style={{ textAlign: 'center' }}>
            <b> - </b>
          </Col>
          <Col span={11}>
            <InputNumber
              name='creatorFollowersMax'
              min={1000} // can't search for less than 1k followers
              placeholder='Max'
              onChange={e => {
                handleChange(e, 'creatorFollowersMax')
              }}
            />
          </Col>
        </Row>
        <HelpText>
          <p>
            Values below 5k are rounded to the nearest thousand, while values above 5k are rounded
            to the nearest 5,000.
          </p>
          <p>
            For example: 4125 becomes 4000, 4800 becomes 5000, 27k becomes 25k, and 28k becomes 30k.
          </p>
        </HelpText>
      </FormItem>
    )
  }

  const creatorEngagementInput = handleChange => {
    return (
      <FormItem width={'275px'} size='small'>
        <p>Identify creators by minimum engagement rate.</p>
        <InputNumber
          name='creatorEngagementRate'
          min={0}
          max={100}
          placeholder='0%'
          style={{ width: '100%', maxWidth: '100%' }}
          onChange={e => handleChange(e, 'creatorEngagementRate')}
          formatter={value => value && `${value}%`}
          parser={value => value.replace('%', '')}
        />
      </FormItem>
    )
  }

  const creatorAccountTypeInput = handleChange => {
    return (
      <FormItem width={'275px'} size='small'>
        <p>Identify creators by their Instagram account type (professional or personal).</p>
        <Select
          style={{ width: '100%' }}
          name='creatorAccountType'
          placeholder='Select'
          onChange={e => {
            handleChange(e, 'creatorAccountType')
          }}>
          <Option value='1'>Personal</Option>
          <Option value='2'>Business</Option>
          <Option value='3'>Creator</Option>
        </Select>
      </FormItem>
    )
  }

  const creatorVisibilityInput = handleChange => {
    return (
      <FormItem width={'275px'} size='small'>
        <p>Identify creators by account visibility.</p>
        <Select
          style={{ width: '100%' }}
          name='creatorVisibility'
          placeholder='Select'
          onChange={e => {
            handleChange(e, 'creatorVisibility')
          }}>
          <Option value='1'>Private</Option>
          <Option value='0'>Public</Option>
        </Select>
      </FormItem>
    )
  }

  const creatorBioInput = handleChange => {
    return (
      <FormItem width={'275px'} size='small'>
        <p>Identify creators by a keyword within their bio description.</p>
        <Input
          name='creatorBio'
          placeholder='Keyword'
          onChange={e => {
            e.preventDefault()
            handleChange(e.target.value, 'creatorBio')
          }}
        />
      </FormItem>
    )
  }

  const creatorLookalikesInput = handleChange => {
    return (
      <FormItem width={'275px'} size='small'>
        <p>Find creators whose content is similar to a specific creator&apos;s.</p>
        <Input
          placeholder='Username'
          name='audienceRelevance'
          onChange={e => {
            e.preventDefault()
            handleChange(e.target.value, 'audienceRelevance')
          }}
        />
        <HelpText>
          <p>
            For example, entering &apos;selenagomez&apos; will identify creators with content akin
            to the @selenagomez account. This is based on the TopicTensor® technology.
          </p>
        </HelpText>
      </FormItem>
    )
  }

  const creatorHasAdsInput = handleChange => {
    return (
      <FormItem width={'275px'} size='small'>
        <p>Indentify creators by whether they have any sponsored content.</p>
        <Select
          style={{ width: '100%' }}
          name='hasAds'
          placeholder='Select'
          onChange={e => {
            handleChange(e, 'hasAds')
          }}>
          <Option value='1'>Sponsored</Option>
          <Option value='0'>Not sponsored</Option>
        </Select>
      </FormItem>
    )
  }

  const creatorContactInput = handleChange => {
    return (
      <FormItem width={'275px'} size='small'>
        <p>Identify creators by visibility of contact information (such as email).</p>
        <Select
          mode='multiple'
          style={{ width: '100%' }}
          name='withContact'
          placeholder='Select'
          showArrow
          onChange={e => {
            handleChange(e, 'withContact')
          }}>
          <Option value='bbm'>BBM</Option>
          <Option value='email'>Email</Option>
          <Option value='facebook'>Facebook</Option>
          <Option value='instagram'>Instagram</Option>
          <Option value='itunes'>iTunes</Option>
          <Option value='kakao'>Kakao</Option>
          <Option value='kik'>kik</Option>
          <Option value='lineid'>LineID</Option>
          <Option value='linktree'>LinkTree</Option>
          <Option value='phone'>Phone</Option>
          <Option value='pinterest'>Pinterest</Option>
          <Option value='sarahah'>Sarahah</Option>
          <Option value='sayat'>Sayat</Option>
          <Option value='skype'>Skype</Option>
          <Option value='snapchat'>Snapchat</Option>
          <Option value='telegram'>Telegram</Option>
          <Option value='tiktok'>TikTok</Option>
          <Option value='tumblr'>tumblr</Option>
          <Option value='twitch'>Twitch</Option>
          <Option value='twitter'>Twitter</Option>
          <Option value='viber'>Viber</Option>
          <Option value='vk'>VK</Option>
          <Option value='wechat'>wechat</Option>
          <Option value='weibo'>weibo</Option>
          <Option value='whatsapp'>whatsapp</Option>
          <Option value='youtube'>YouTube</Option>
        </Select>
      </FormItem>
    )
  }

  const creatorBrandsInput = handleChange => {
    return (
      <FormItem width={'275px'} size='small'>
        <p>Identify creators by brand affinity.</p>
        <Select
          showSearch
          optionFilterProp='label'
          filterOption
          mode='multiple'
          placeholder='Select'
          showArrow
          loading={!socapiFilters}
          name='creatorBrands'
          style={{ width: '100%' }}
          onChange={e => {
            handleChange(e, 'creatorBrands')
          }}>
          {socapiFilters ? (
            <>
              {socapiFilters.interests &&
                socapiFilters.interests.brands.map(
                  item =>
                    !item.deprecated && (
                      <Option label={item.name} key={item.id} value={item.id}>
                        {item.name}
                      </Option>
                    )
                )}
            </>
          ) : (
            <span>Loading...</span>
          )}
        </Select>
        <HelpText>
          <p>
            We determine brand affinity by analyzing posts for caption text, mentions, hashtags and
            location tags. You can add several brands and the results will be refined with creators
            who mention any of the specified brands.
          </p>
        </HelpText>
      </FormItem>
    )
  }

  const creatorTopicTagsInput = handleChange => {
    return (
      <FormItem width={'275px'} size='small'>
        <p>
          Identify creators by the similarity of their post topics to the topics of other creators.
        </p>
        <Select
          name='relevance'
          mode='multiple'
          labelInValue
          placeholder='Enter at least 3 characters'
          notFoundContent={fetchingTags ? <Spin size='small' /> : null}
          filterOption={false}
          onSearch={val => fetchTags(val)}
          onChange={e => {
            setFetchingTags(false)
            setTags([])
            handleChange(e, 'relevance')
          }}
          style={{ width: '100%' }}>
          {tags.map(item => (
            <Option key={item.value} value={item.value}>
              {item.text}
            </Option>
          ))}
        </Select>
        <HelpText>
          <p>
            For example, &apos;#cars #audi @topgear&apos; implies &apos;Writing about #cars and
            #audi, with topics similar to the @topgear account&apos;.
          </p>
        </HelpText>
      </FormItem>
    )
  }

  const creatorHashtagsInput = handleChange => {
    return (
      <FormItem width={'275px'} size='small'>
        <p>Filter by hashtags in creators&apos; posts.</p>
        <Select
          name='hashtags'
          mode='tags'
          allowClear
          placeholder='Add hashtags'
          style={{ width: '100%' }}
          tokenSeparators={[',', ' ']}
          tagRender={props => tagRender(props, '#')}
          onChange={e => {
            handleChange(e, 'hashtags')
          }}
        />
      </FormItem>
    )
  }

  const creatorUsernameInput = handleChange => {
    return (
      <>
        <FormItem width={'275px'} size='small'>
          <p>Identify creators by their handle or username.</p>
          <Input
            name='username'
            placeholder='Username'
            onChange={e => {
              e.preventDefault()
              handleChange(e.target.value, 'username')
            }}
          />
        </FormItem>
        <FormItem width={'275px'} size='small' name='usernameOperator'>
          <Checkbox
            name='usernameOperator'
            onChange={e => {
              handleChange(e.target.checked ? true : false, 'usernameOperator')
            }}>
            Exact match
          </Checkbox>
        </FormItem>
      </>
    )
  }

  const creatorKeywordsInput = handleChange => {
    return (
      <FormItem width={'275px'} size='small'>
        <p>Identify creators by keywords in their content.</p>
        <Input
          name='keywords'
          placeholder='Keywords'
          onChange={e => {
            e.preventDefault()
            handleChange(e.target.value, 'keywords')
          }}
        />
      </FormItem>
    )
  }

  const creatorFollowersGrowth = handleChange => {
    return (
      <>
        <p>Identify creators by {platform === 'youtube' ? 'subscriber' : 'follower'} growth.</p>
        <FormItem width={'275px'} size='small' name=''>
          <span>Growth Rate</span>
          <InputNumber
            name='creatorFollowersGrowthValue'
            min={0}
            placeholder='0%'
            style={{ width: '100%', maxWidth: '100%' }}
            formatter={value => value && `${value}%`}
            parser={value => value.replace('%', '')}
            required
            onChange={e => {
              handleChange(e, 'creatorFollowersGrowthValue')
            }}
          />
        </FormItem>
        {values.creatorFollowersGrowthValue && (
          <FormItem width={'275px'} size='small' name='creatorFollowersGrowthInterval'>
            <span>Period</span>
            <Select
              name='creatorFollowersGrowthInterval'
              style={{ width: '100%' }}
              placeholder='Select'
              onChange={e => {
                handleChange(e, 'creatorFollowersGrowthInterval')
              }}>
              <Option value='i1month'>Past month</Option>
              <Option value='i2months'>Past 2 months</Option>
              <Option value='i3months'>Past 3 months</Option>
              <Option value='i4months'>Past 4 months</Option>
              <Option value='i5months'>Past 5 months</Option>
              <Option value='i6months'>Past 6 months</Option>
            </Select>
          </FormItem>
        )}
      </>
    )
  }

  const creatorViewsGrowth = handleChange => {
    return (
      <>
        <p>Identify creators by views growth.</p>
        <FormItem width={'275px'} size='small' name=''>
          <span>Growth Rate</span>
          <InputNumber
            name='creatorViewsGrowthValue'
            min={0}
            placeholder='0%'
            style={{ width: '100%', maxWidth: '100%' }}
            formatter={value => value && `${value}%`}
            required
            onChange={e => {
              handleChange(e, 'creatorViewsGrowthValue')
            }}
          />
        </FormItem>
        {values.creatorViewsGrowthValue && (
          <FormItem width={'275px'} size='small' name='creatorViewsGrowthInterval'>
            <span>Period</span>
            <Select
              name='creatorViewsGrowthInterval'
              style={{ width: '100%' }}
              placeholder='Select'
              onChange={e => {
                handleChange(e, 'creatorViewsGrowthInterval')
              }}>
              <Option value='i1month'>Past month</Option>
              <Option value='i2months'>Past 2 months</Option>
              <Option value='i3months'>Past 3 months</Option>
              <Option value='i4months'>Past 4 months</Option>
              <Option value='i5months'>Past 5 months</Option>
              <Option value='i6months'>Past 6 months</Option>
            </Select>
          </FormItem>
        )}
      </>
    )
  }

  const creatorLikesGrowth = handleChange => {
    return (
      <>
        <p>Identify creators by likes growth.</p>
        <FormItem width={'275px'} size='small' name=''>
          <span>Growth Rate</span>
          <InputNumber
            name='creatorLikesGrowthValue'
            min={0}
            placeholder='0%'
            style={{ width: '100%', maxWidth: '100%' }}
            formatter={value => value && `${value}%`}
            required
            onChange={e => {
              handleChange(e, 'creatorLikesGrowthValue')
            }}
          />
        </FormItem>
        {values.creatorLikesGrowthValue && (
          <FormItem width={'275px'} size='small' name='creatorLikesGrowthInterval'>
            <span>Period</span>
            <Select
              name='creatorLikesGrowthInterval'
              style={{ width: '100%' }}
              placeholder='Select'
              onChange={e => {
                handleChange(e, 'creatorLikesGrowthInterval')
              }}>
              <Option value='i1month'>Past month</Option>
              <Option value='i2months'>Past 2 months</Option>
              <Option value='i3months'>Past 3 months</Option>
              <Option value='i4months'>Past 4 months</Option>
              <Option value='i5months'>Past 5 months</Option>
              <Option value='i6months'>Past 6 months</Option>
            </Select>
          </FormItem>
        )}
      </>
    )
  }

  // const onCreatorInput = handleChange => {
  //   return (
  //     <FormItem width={'275px'} size='small' name='onCreator'>
  //       <p>
  //         Identify creators based on whether they are already registered on our platform. Registered
  //         users are more likely to accept invites to campaigns.
  //       </p>
  //       <Select
  //         name='onCreator'
  //         placeholder='Select'
  //         style={{ width: '100%' }}
  //         onChange={e => {
  //           handleChange(e, 'onCreator')
  //         }}>
  //         <Option value='must'>Registered</Option>
  //         <Option value='not'>Not registered</Option>
  //       </Select>
  //     </FormItem>
  //   )
  // }

  const creatorAvgViews = handleChange => {
    return (
      <FormItem width={'275px'} size='small'>
        <p>Identify creators by average views on their content.</p>
        <Row span={24}>
          <Col span={11}>
            <InputNumber
              name='creatorAvgViewsMin'
              min={0}
              placeholder='Min'
              onChange={e => {
                handleChange(e, 'creatorAvgViewsMin')
              }}
            />
          </Col>
          <Col span={2} style={{ textAlign: 'center' }}>
            <b> - </b>
          </Col>
          <Col span={11}>
            <InputNumber
              name='creatorAvgViewsMax'
              placeholder='Max'
              onChange={e => {
                handleChange(e, 'creatorAvgViewsMax')
              }}
            />
          </Col>
        </Row>
      </FormItem>
    )
  }

  const creatorReelViews = handleChange => {
    return (
      <FormItem width={'275px'} size='small'>
        <p>Identify creators by their average reel views on Instagram.</p>
        <Row span={24}>
          <Col span={11}>
            <InputNumber
              name='creatorReelViewsMin'
              min={0}
              placeholder='Min'
              onChange={e => {
                handleChange(e, 'creatorReelViewsMin')
              }}
            />
          </Col>
          <Col span={2} style={{ textAlign: 'center' }}>
            <b> - </b>
          </Col>
          <Col span={11}>
            <InputNumber
              name='creatorReelViewsMax'
              placeholder='Max'
              onChange={e => {
                handleChange(e, 'creatorReelViewsMax')
              }}
            />
          </Col>
        </Row>
      </FormItem>
    )
  }

  const isCreatorVerified = handleChange => {
    return (
      <FormItem width={'275px'} size='small' name='isVerified'>
        <p>Identify creators by whether they are verified or not (blue tick).</p>
        <Checkbox
          name='isVerified'
          onChange={e => {
            handleChange(e.target.checked ? true : false, 'isVerified')
          }}
          defaultChecked={false}>
          Verified
        </Checkbox>
      </FormItem>
    )
  }
  // #endregion POPOVER FILTERS

  return (
    <div className='creator-filters'>
      <Popover placement='bottom' content={creatorPlatform(formChange)} trigger='hover'>
        <span className={`filter ${values.platform && 'filter-selected'}`}>
          Platform <DownOutlined />
        </span>
      </Popover>

      <Popover placement='bottom' content={creatorContactInput(formChange)} trigger='hover'>
        <span className={`filter ${values.withContact && 'filter-selected'}`}>
          Contact Info <DownOutlined />
        </span>
      </Popover>

      <Popover placement='bottom' content={creatorUsernameInput(formChange)} trigger='hover'>
        <span className={`filter ${values.username && 'filter-selected'}`}>
          Username <DownOutlined />
        </span>
      </Popover>

      <Popover placement='bottom' content={creatorFollowersInput(formChange)} trigger='hover'>
        <span
          className={`filter ${
            (values.creatorFollowersMin || values.creatorFollowersMax) && 'filter-selected'
          }`}>
          {platform === 'youtube' ? 'Subscribers' : 'Followers'} <DownOutlined />
        </span>
      </Popover>

      <Popover placement='bottom' content={creatorFollowersGrowth(formChange)} trigger='hover'>
        <span className={`filter ${values.creatorFollowersGrowthValue && 'filter-selected'}`}>
          {platform === 'youtube' ? 'Subscriber' : 'Follower'} Growth <DownOutlined />
        </span>
      </Popover>

      <Popover placement='bottom' content={creatorEngagementInput(formChange)} trigger='hover'>
        <span className={`filter ${values.creatorEngagementRate && 'filter-selected'}`}>
          Engagement <DownOutlined />
        </span>
      </Popover>

      <Popover placement='bottom' content={creatorLastPostedInput(formChange)} trigger='hover'>
        <span className={`filter ${values.lastPosted && 'filter-selected'}`}>
          Last Posted <DownOutlined />
        </span>
      </Popover>

      <Popover placement='bottom' content={creatorLocationInput(formChange)} trigger='hover'>
        <span className={`filter ${!!values.creatorLocations?.length && 'filter-selected'}`}>
          {`${platform === 'instagram' ? 'Location' : 'Country'}`} <DownOutlined />
        </span>
      </Popover>

      <Popover placement='bottom' content={creatorGenderInput(formChange)} trigger='hover'>
        <span className={`filter ${values.creatorGender && 'filter-selected'}`}>
          Gender <DownOutlined />
        </span>
      </Popover>

      <Popover placement='bottom' content={creatorAgeInput(formChange)} trigger='hover'>
        <span className={`filter ${values.creatorAgeRange && 'filter-selected'}`}>
          Age <DownOutlined />
        </span>
      </Popover>

      <Popover placement='bottom' content={creatorLanguageInput(formChange)} trigger='hover'>
        <span className={`filter ${values.creatorLanguage && 'filter-selected'}`}>
          Language <DownOutlined />
        </span>
      </Popover>

      <Popover placement='bottom' content={creatorBioInput(formChange)} trigger='hover'>
        <span className={`filter ${values.creatorBio && 'filter-selected'}`}>
          Bio <DownOutlined />
        </span>
      </Popover>

      <Popover placement='bottom' content={creatorLookalikesInput(formChange)} trigger='hover'>
        <span className={`filter ${values.audienceRelevance && 'filter-selected'}`}>
          Lookalikes <DownOutlined />
        </span>
      </Popover>

      <Popover placement='bottom' content={creatorBrandsInput(formChange)} trigger='hover'>
        <span className={`filter ${!!values.creatorBrands?.length && 'filter-selected'}`}>
          Brand Affinity <DownOutlined />
        </span>
      </Popover>

      <Popover placement='bottom' content={creatorTopicTagsInput(formChange)} trigger='hover'>
        <span className={`filter ${values.relevance && 'filter-selected'}`}>
          Topic Tags <DownOutlined />
        </span>
      </Popover>

      <Popover placement='bottom' content={isCreatorVerified(formChange)} trigger='hover'>
        <span className={`filter ${values.isVerified && 'filter-selected'}`}>
          Verified Creator
          <DownOutlined />
        </span>
      </Popover>

      {platform === 'youtube' ? (
        <Popover placement='bottom' content={creatorViewsGrowth(formChange)} trigger='hover'>
          <span className={`filter ${values.creatorViewsGrowthValue && 'filter-selected'}`}>
            Views Growth
            <DownOutlined />
          </span>
        </Popover>
      ) : (
        <>
          <Popover placement='bottom' content={creatorHashtagsInput(formChange)} trigger='hover'>
            <span className={`filter ${values.hashtags && 'filter-selected'}`}>
              Hashtags <DownOutlined />
            </span>
          </Popover>
          <Popover placement='bottom' content={creatorVisibilityInput(formChange)} trigger='hover'>
            <span className={`filter ${values.creatorVisibility && 'filter-selected'}`}>
              Account Visibility
              <DownOutlined />
            </span>
          </Popover>
        </>
      )}

      {platform === 'tiktok' ? (
        <Popover placement='bottom' content={creatorLikesGrowth(formChange)} trigger='hover'>
          <span className={`filter ${values.creatorLikesGrowthValue && 'filter-selected'}`}>
            Likes Growth
            <DownOutlined />
          </span>
        </Popover>
      ) : (
        <Popover placement='bottom' content={creatorKeywordsInput(formChange)} trigger='hover'>
          <span className={`filter ${values.keywords && 'filter-selected'}`}>
            Keywords
            <DownOutlined />
          </span>
        </Popover>
      )}

      {platform === 'instagram' ? (
        <>
          <Popover placement='bottom' content={creatorInterestsInput(formChange)} trigger='hover'>
            <span className={`filter ${!!values.creatorInterests?.length && 'filter-selected'}`}>
              Niche
              <DownOutlined />
            </span>
          </Popover>

          <Popover placement='bottom' content={creatorReelViews(formChange)} trigger='hover'>
            <span
              className={`filter ${
                (values.creatorReelViewsMin >= 0 || values.creatorReelViewsMax >= 0) &&
                'filter-selected'
              }`}>
              Reel Views
              <DownOutlined />
            </span>
          </Popover>

          <Popover placement='bottom' content={creatorHasAdsInput(formChange)} trigger='hover'>
            <span className={`filter ${values.hasAds && 'filter-selected'}`}>
              Sponsored <DownOutlined />
            </span>
          </Popover>

          <Popover placement='bottom' content={creatorAccountTypeInput(formChange)} trigger='hover'>
            <span className={`filter ${values.creatorAccountType && 'filter-selected'}`}>
              Account Type
              <DownOutlined />
            </span>
          </Popover>
        </>
      ) : (
        <Popover placement='bottom' content={creatorAvgViews(formChange)} trigger='hover'>
          <span className={`filter ${values.creatorAvgViewsMax && 'filter-selected'}`}>
            Average Views
            <DownOutlined />
          </span>
        </Popover>
      )}
    </div>
  )
}

export const HelpText = styled.div`
  margin-top: 20px;
  p {
    font-size: 13px;
    color: ${props => props.theme.crcoGrey};
  }
`

export default CreatorFilters
