import { Drawer, Empty } from 'antd'
import React, { useState } from 'react'
import styled from 'styled-components/macro'

import AffiliateProfileDrawer from './AffiliateProfileDrawer'
import CreatorAvatar from '../../components/general/CreatorAvatar'

const CreatorAffiliates = ({ creator }) => {
  const [openCreatorProfileDrawer, setOpenCreatorProfileDrawer] = useState(false)

  const profilePic = creator?.socialProfile[0]?.profilePicUrl

  return (
    <>
      <div
        className='affiliate'
        onClick={() => setOpenCreatorProfileDrawer(true)}
        onKeyDown={() => setOpenCreatorProfileDrawer(true)}
        role='button'
        tabIndex={0}>
        <div className='affiliate-name'>
          <CreatorAvatar
            className='avatar'
            size={40}
            id={creator.user.id}
            url={profilePic}
            initials={`${creator?.user?.firstName
              ?.charAt(0)
              .toUpperCase()} ${creator?.user?.lastName?.charAt(0).toUpperCase()}`}
          />
          <div className='url'>{creator.creatorName}</div>
        </div>
        <div
          className={`column-label ${
            creator.creatorLinks.length === 0 ? 'text-grey' : 'text-primaryBlue'
          }`}>
          {creator.creatorLinks.length}
        </div>
        <div className={`column-label ${creator.clicks === 0 ? 'text-grey' : 'text-primaryBlue'}`}>
          {creator.clicks}
        </div>
        <div
          className={`column-label ${creator.conversions === 0 ? 'text-grey' : 'text-primaryBlue'}
                    `}>
          {creator.conversions}
        </div>
        <div
          className={`column-label ${creator.commissions === 0 ? 'text-grey' : 'text-primaryBlue'}
                    `}>
          ${creator.commissions}
        </div>
      </div>
      <Drawer
        key={creator.creatorName}
        title={'Creator Profile Details'}
        open={openCreatorProfileDrawer}
        width={window.innerWidth > 700 ? 600 : window.innerWidth}
        onClose={() => {
          setOpenCreatorProfileDrawer(false)
        }}
        destroyOnClose>
        <AffiliateProfileDrawer creator={creator} />
      </Drawer>
    </>
  )
}

const AffiliateCreatorsView = ({ affiliateAnalytics, affiliateAnalyticsStatus }) => {
  const { totalCreatorClicks, totalCreatorCommissions, totalCreatorConversions } =
    affiliateAnalytics || {}

  return (
    <Wrapper>
      <div className='inner'>
        <div className='inner-content'>
          <div className='overview'>
            <div className='overview-item'>
              <span className='title'>Total Clicks</span>
              {affiliateAnalyticsStatus === 'loading' ? (
                <div className='skelement totals-skeleton' />
              ) : (
                <div
                  className={
                    totalCreatorClicks === 0 ? 'text-grey value' : 'text-primaryBlue value'
                  }>
                  {totalCreatorClicks ? totalCreatorClicks : 0}
                </div>
              )}
            </div>
            <div className='overview-item'>
              <span className='title'>Total Conversions</span>
              {affiliateAnalyticsStatus === 'loading' ? (
                <div className='skelement totals-skeleton' />
              ) : (
                <div
                  className={
                    totalCreatorConversions === 0 ? 'text-grey value' : 'text-primaryBlue value'
                  }>
                  {totalCreatorConversions ? totalCreatorConversions : 0}
                </div>
              )}
            </div>
            <div className='overview-item'>
              <span className='title'>Total Commissions</span>
              {affiliateAnalyticsStatus === 'loading' ? (
                <div className='skelement totals-skeleton' />
              ) : (
                <div
                  className={
                    totalCreatorCommissions === 0 ? 'text-grey value' : 'text-primaryBlue value'
                  }>
                  ${totalCreatorCommissions ? totalCreatorCommissions : 0}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className='inner'>
        <div className='inner-content'>
          <div className='affiliates'>
            <div className='affiliate-header'>
              <div className='affiliate-name'>Creator</div>
              <div className='column-label'>Links</div>
              <div className='column-label'>Clicks</div>
              <div className='column-label'>Conversions</div>
              <div className='column-label'>Commissions</div>
            </div>
            <div className='affiliate-list'>
              {affiliateAnalyticsStatus === 'success' && (
                <>
                  {affiliateAnalytics?.creatorAffiliates ? (
                    affiliateAnalytics?.creatorAffiliates?.map((creator, id) => {
                      return (
                        <div key={id}>
                          <CreatorAffiliates creator={creator} />
                        </div>
                      )
                    })
                  ) : (
                    <div
                      style={{
                        height: '100%',
                        display: 'grid',
                        placeItems: 'center',
                        paddingTop: '120px',
                        paddingBottom: '120px',
                      }}>
                      <Empty description='No affiliate links yet.' />
                    </div>
                  )}
                </>
              )}
            </div>

            {affiliateAnalyticsStatus === 'loading' && (
              <div className='skeleton-list'>
                {Array.from(Array(5).keys()).map(item => (
                  <div className='skeleton' key={item}>
                    <div className='skeleton-name'>
                      <div className='skelement' />
                    </div>
                    <div className='elements'>
                      <div className='skelement element' />
                    </div>
                    <div className='elements'>
                      <div className='skelement element' />
                    </div>
                    <div className='elements'>
                      <div className='skelement element' />
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  height: 100%;
  /* height: calc(100vh - 50px); */
  color: black;
  width: 90vw;
  max-width: 480px;
  margin: 0 auto;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 15px;

  @media only screen and (min-width: ${props => props.theme.breakpointMobile}) {
    width: 100%;
    max-width: 1400px;
  }

  .text-grey {
    opacity: 0.5;
  }
  .text-primaryBlue {
    color: #027df0;
  }

  .inner {
    height: 100%;

    .inner-content {
      background-color: #fff;
      padding: 20px;
      border-radius: 10px;

      .filter-bar {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        gap: 15px;
        flex-wrap: wrap;
        padding: 0 0 15px 0;

        section {
          display: flex;
          gap: 12px;
          flex-wrap: wrap;
        }

        .filter {
          display: flex;
          align-items: center;

          label {
            padding-right: 8px;
            color: black;
            font-weight: bold;
            margin: 0;
          }
        }

        .creator-select {
          min-width: 250px;
        }
      }
    }
  }

  .overview {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    max-height: 100%;
    padding-bottom: 20px;
  }

  .overview-item {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #fff;
    padding: 12px;
    border: 1px solid #e6e6e6;
    border-radius: 5px;

    .title {
      font-size: 0.9rem;
      text-transform: uppercase;
      opacity: 0.5;
    }

    .value {
      font-size: 1.7rem;
    }
  }

  .affiliates {
    flex: 1;
    max-height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;

    .affiliate-header {
      font-size: 1.1rem;
      margin-bottom: 12px;
      box-shadow: 0px 5px 10px -10px #000000ab;
      padding-bottom: 12px;
    }

    .affiliate-list {
      max-height: 100%;
      overflow: auto;
      ${props => props.theme.scrollbar}

      .no-results {
        display: flex;
        justify-items: center;
        align-items: center;
      }

      .affiliate {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 15px 0;
        background-color: #fff;
        cursor: pointer;

        &:hover {
          background: #f6f6f6;
          transition: all 0.3s ease-in-out;
        }

        .affiliate-name {
          display: flex;
          align-items: center;
          gap: 10px;

          .url {
            font-size: 1rem;
            // max 1 line with ellipsis text overflow
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          .description {
            font-size: 0.8rem;
            color: #666;
            // max 3 line with ellipsis text overflow
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }

        &:not(:last-child) {
          border-bottom: 1px solid #e6e6e6;
        }
      }

      .no-results {
        height: 100%;
        display: grid;
        place-items: center;
        padding-top: 120px;
      }
    }

    .affiliate-header,
    .affiliate-list .affiliate {
      display: flex;
      padding-left: 12px;
      padding-right: 12px;

      .affiliate-name {
        flex: 2;
      }

      .column-label {
        font-size: 1.1rem;
        text-align: center;
        flex: 1;
      }
    }
  }

  .skeleton-list {
    max-height: 100%;
    border-radius: 5px;
    background-color: #fff;
    overflow: auto;
    ${props => props.theme.scrollbar}

    .skeleton {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 15px 0 15px 12px;
      background-color: #fff;

      .skeleton-name {
        flex: 2;
        display: grid;
        height: 72px;
      }

      .elements {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;

        .element {
          height: 40px;
          width: 70px;
        }
      }

      &:not(:last-child) {
        border-bottom: 1px solid #e6e6e6;
      }
    }
  }

  .totals-skeleton {
    height: 35px;
    width: 70px;
  }

  .skelement {
    border-radius: 5px;
    background-image: linear-gradient(90deg, #fafafa 0px, #ffffff 100px, #fafafa 300px);
    background-size: 100vw 100%;
    animation: shine 1.5s infinite ease-in-out;
  }

  @keyframes shine {
    0% {
      background-position-x: -20vw;
    }
    95%,
    100% {
      background-position-x: 85vw;
    }
  }
`

export default AffiliateCreatorsView
