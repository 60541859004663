import { EditOutlined } from '@ant-design/icons'
import { Empty, Button } from 'antd'
import { Form, Formik } from 'formik'
import { DatePicker, Select } from 'formik-antd'
import React, { useState } from 'react'
import styled from 'styled-components/macro'

import CreateLinkModal from './CreateLinkModal'
import SideDrawer from './Sidedrawer'

const AffiliateLinksView = ({
  setFilters,
  affiliateFilters,
  affiliateFilterStatus,
  affiliateAnalytics,
  affiliateAnalyticsStatus,
  brandId,
}) => {
  const [openAffiliateDrawer, setOpenAffiliateDrawer] = useState(false)
  const [createLinkModalOpen, setCreateLinkModalOpen] = useState(false)

  const { totalClicks, totalSales, totalRevenue } = affiliateAnalytics || {}

  return (
    <Wrapper>
      <div className='inner'>
        <div className='inner-content'>
          <Formik
            initialValues={{ affiliate: '' }}
            onSubmit={values => {
              setFilters(prev => ({ ...prev, ...values }))
            }}>
            {({ values, submitForm }) => (
              <Form className='filter-bar'>
                <section>
                  <Select
                    value={values.affiliate}
                    onChange={submitForm}
                    className='creator-select'
                    loading={affiliateFilterStatus === 'loading'}
                    name='affiliate'>
                    <Select.Option value={''}>All</Select.Option>
                    {affiliateFilters?.map(affiliate => (
                      <Select.Option value={affiliate.id} key={affiliate.id}>
                        {`${affiliate.firstName} ${affiliate.lastName}`}
                      </Select.Option>
                    ))}
                  </Select>
                  <div className='date-filter'>
                    <DatePicker onChange={submitForm} placeholder='From Date' name='fromDate' />
                    {' - '}
                    <DatePicker onChange={submitForm} placeholder='To Date' name='toDate' />
                  </div>
                </section>
                <Button
                  onClick={() => setOpenAffiliateDrawer(true)}
                  icon={<EditOutlined />}
                  type='primary'>
                  Manage Links
                </Button>
              </Form>
            )}
          </Formik>

          <div className='overview'>
            <div className='overview-item'>
              <span className='title'>Total Clicks</span>
              {affiliateAnalyticsStatus === 'loading' ? (
                <div className='skelement totals-skeleton' />
              ) : (
                <div className={totalClicks === 0 ? 'text-grey value' : 'text-primaryBlue value'}>
                  {totalClicks ? totalClicks : 0}
                </div>
              )}
            </div>
            <div className='overview-item'>
              <span className='title'>Total Sales</span>
              {affiliateAnalyticsStatus === 'loading' ? (
                <div className='skelement totals-skeleton' />
              ) : (
                <div className={totalSales === 0 ? 'text-grey value' : 'text-primaryBlue value'}>
                  {totalSales ? totalSales : 0}
                </div>
              )}
            </div>
            <div className='overview-item'>
              <span className='title'>Total Revenue</span>
              {affiliateAnalyticsStatus === 'loading' ? (
                <div className='skelement totals-skeleton' />
              ) : (
                <div className={totalRevenue === 0 ? 'text-grey value' : 'text-primaryBlue value'}>
                  ${totalRevenue ? totalRevenue : 0}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className='inner'>
        <div className='inner-content'>
          <div className='affiliates'>
            <div className='affiliate-header'>
              <div className='affiliate-name'>Affiliate</div>
              <div className='clicks'>Clicks</div>
              <div className='sales'>Sales</div>
              <div className='revenue'>Revenue</div>
            </div>
            <div className='affiliate-list'>
              {affiliateAnalyticsStatus === 'success' && (
                <>
                  {affiliateAnalytics?.affiliateLinks?.length ? (
                    <>
                      {affiliateAnalytics?.affiliateLinks?.map((link, id) => (
                        <div className='affiliate' key={id}>
                          <div className='affiliate-name'>
                            <div className='url'>{link.urlPath}</div>
                            <div className='description'>{link.description}</div>
                          </div>
                          <div
                            className={
                              link.clicks === 0 ? 'text-grey clicks' : 'text-primaryBlue clicks'
                            }>
                            {link.clicks}
                          </div>
                          <div
                            className={
                              link.sales === 0 ? 'text-grey sales' : 'text-primaryBlue sales'
                            }>
                            {link.sales}
                          </div>
                          <div
                            className={
                              link.revenue === 0 ? 'text-grey revenue' : 'text-primaryBlue revenue'
                            }>
                            ${link.revenue}
                          </div>
                        </div>
                      ))}
                    </>
                  ) : (
                    <div className='no-results'>
                      <Empty description='No affiliate links yet.' />
                    </div>
                  )}
                </>
              )}
            </div>

            {affiliateAnalyticsStatus === 'loading' && (
              <div className='skeleton-list'>
                {Array.from(Array(5).keys()).map(item => (
                  <div className='skeleton' key={item}>
                    <div className='skeleton-name'>
                      <div className='skelement' />
                    </div>
                    <div className='elements'>
                      <div className='skelement element' />
                    </div>
                    <div className='elements'>
                      <div className='skelement element' />
                    </div>
                    <div className='elements'>
                      <div className='skelement element' />
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>

      <SideDrawer
        setOpenAffiliateDrawer={setOpenAffiliateDrawer}
        setCreateLinkModalOpen={setCreateLinkModalOpen}
        openAffiliateDrawer={openAffiliateDrawer}
      />

      <CreateLinkModal
        createLinkModalOpen={createLinkModalOpen}
        setCreateLinkModalOpen={setCreateLinkModalOpen}
        brandId={brandId}
      />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  height: 100%;
  /* height: calc(100vh - 50px); */
  color: black;
  width: 90vw;
  max-width: 480px;
  margin: 0 auto;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 15px;

  @media only screen and (min-width: ${props => props.theme.breakpointMobile}) {
    width: 100%;
    max-width: 1400px;
  }

  .text-grey {
    opacity: 0.5;
  }
  .text-primaryBlue {
    color: #027df0;
  }

  .inner {
    height: 100%;

    .inner-content {
      background-color: #fff;
      padding: 20px;
      border-radius: 10px;

      .filter-bar {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        gap: 15px;
        flex-wrap: wrap;
        padding: 0 0 15px 0;

        section {
          display: flex;
          gap: 12px;
          flex-wrap: wrap;
        }

        .filter {
          display: flex;
          align-items: center;

          label {
            padding-right: 8px;
            color: black;
            font-weight: bold;
            margin: 0;
          }
        }

        .creator-select {
          min-width: 250px;
        }
      }
    }
  }

  .overview {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    max-height: 100%;
    padding-bottom: 20px;
  }

  .overview-item {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #fff;
    padding: 12px;
    border: 1px solid #e6e6e6;
    border-radius: 5px;

    .title {
      font-size: 0.9rem;
      text-transform: uppercase;
      opacity: 0.5;
    }

    .value {
      font-size: 1.7rem;
    }
  }

  .affiliates {
    flex: 1;
    max-height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;

    .affiliate-header {
      font-size: 1.1rem;
      margin-bottom: 12px;
      box-shadow: 0px 5px 10px -10px #000000ab;
      padding-bottom: 12px;
    }

    .affiliate-list {
      max-height: 100%;
      overflow: auto;
      border-radius: 5px;
      ${props => props.theme.scrollbar}

      .affiliate {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 15px 0;
        background-color: #fff;

        .affiliate-name {
          .url {
            font-size: 1rem;
            // max 1 line with ellipsis text overflow
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .description {
            font-size: 0.8rem;
            color: #666;
            // max 3 line with ellipsis text overflow
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
        &:not(:last-child) {
          border-bottom: 1px solid #e6e6e6;
        }
      }

      .no-results {
        height: 100%;
        display: grid;
        place-items: center;
        padding: 120px 0;
      }
    }

    .affiliate-header,
    .affiliate-list .affiliate {
      display: flex;
      padding-left: 12px;
      padding-right: 12px;

      .affiliate-name {
        flex: 2;
      }

      .clicks,
      .sales,
      .revenue {
        font-size: 1.1rem;
        text-align: center;
        flex: 1;
      }
    }
  }

  .skeleton-list {
    max-height: 100%;
    border-radius: 5px;
    background-color: #fff;
    overflow: auto;
    ${props => props.theme.scrollbar}

    .skeleton {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 15px 0 15px 12px;
      background-color: #fff;

      .skeleton-name {
        flex: 2;
        display: grid;
        height: 72px;
      }

      .elements {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;

        .element {
          height: 40px;
          width: 70px;
        }
      }

      &:not(:last-child) {
        border-bottom: 1px solid #e6e6e6;
      }
    }
  }

  .totals-skeleton {
    height: 35px;
    width: 70px;
  }

  .skelement {
    border-radius: 5px;
    background-image: linear-gradient(90deg, #fafafa 0px, #ffffff 100px, #fafafa 300px);
    background-size: 100vw 100%;
    animation: shine 1.5s infinite ease-in-out;
  }

  @keyframes shine {
    0% {
      background-position-x: -20vw;
    }
    95%,
    100% {
      background-position-x: 85vw;
    }
  }
`

export default AffiliateLinksView
