import React from 'react'
import styled from 'styled-components/macro'

export const RankedList = ({ data }) => {
  // recommended use: data with icons, should be in order of highest value to lowest value to show ranking, limit to 5-7 items
  return (
    <Wrapper>
      {data.map((item, index) => (
        <div key={index} className='list-item'>
          <div className='info'>
            {item.icon && <img className='icon' src={item.icon} alt='' />}
            <span className='label'>{item.label}</span>
          </div>
          <span className='value'>{item.percentage.toFixed(1)}%</span>
        </div>
      ))}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  font-size: 14px;

  .list-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
  }

  .info {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  .icon {
    height: 20px;
    width: 20px;
  }
  .label {
    color: ${props => props.theme.crcoGrey};
    margin-top: 2px;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .value {
    color: #4d9ce6;
  }
`
