import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

const CreatorAvatar = ({ className = '', id = 8, size = 64, color, url, initials = '' }) => {
  const [validUrl, setValidUrl] = useState(false)

  const colorList = [
    '#81ccff', // light blue
    '#ff992f', // orange
    '#fc65b8', // pink
    '#FF7661', // salmon
    '#80ee8e', // lime
    '#bf84ff', // purple
    '#87e8d2', // teal
    '#027DF0', // blue
  ]

  useEffect(() => {
    setValidUrl(false)
    if (url) {
      const img = new Image()
      img.onload = () => setValidUrl(true)
      img.onerror = () => setValidUrl(false)
      img.src = url
    }
  }, [url])

  return (
    <Avatar
      className={className}
      style={{
        backgroundImage: `url(${validUrl && url})`,
        backgroundColor: `${color || colorList[id % 8]}`,
        width: `${size}px`,
        height: `${size}px`,
        minWidth: `${size}px`,
        fontSize: `${size / 3}px`,
        fontWeight: 'bold',
      }}>
      {!validUrl && initials}
    </Avatar>
  )
}

const Avatar = styled.div`
  overflow: hidden;
  font-family: 'Campton-Medium';
  border-radius: 50%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  color: #fff;
  display: grid;
  place-items: center;
  padding-top: 2px;
`

export default CreatorAvatar
