import { Button, Empty, Result } from 'antd'
import React from 'react'
import { useQuery } from 'react-query'

import LoadingLogo from '../../components/general/LoadingLogo'
import { useAnalyticsApi } from '../../custom-hooks/analyticsApi'

/**
 * Finds the correct data from the data response for the provided widget,
 * and applies mapping to array data.
 */
const getData = (data, widget) => {
  const result = data?.widgets?.find(w => w.widgetId === widget.id)?.result

  if (Array.isArray(result)) {
    return result.map(r => ({
      ...r,
      count: Number(r.followerCount),
      percentage: Number(r.percentage),
    }))
  }

  return result
}

export const AnalyticsView = ({
  widgets,
  activeView,
  setActiveView,
  brandData,
  setSettingsOpen,
}) => {
  const analyticsApi = useAnalyticsApi()

  const { data, status } = useQuery(['analytics-view-data', activeView.id], () =>
    analyticsApi.view.viewDataCreate(activeView.id, brandData.id)
  )

  return (
    <>
      {status === 'success' &&
        (widgets.length ? (
          <div className='widgets-inner'>
            {widgets
              .filter(widget => widget.visible)
              .map((widget, index) => (
                <widget.component
                  key={index}
                  widget={{ index, ...widget }}
                  activeView={activeView}
                  setActiveView={setActiveView}
                  brandData={brandData}
                  data={getData(data, widget)}
                />
              ))}
          </div>
        ) : (
          <div className='empty'>
            <Empty description='No widgets.' />
            <Button type='primary' onClick={() => setSettingsOpen(true)}>
              Edit View
            </Button>
          </div>
        ))}

      {status === 'loading' && (
        <div className='loading-wrapper'>
          <LoadingLogo />
        </div>
      )}

      {status === 'error' && (
        <div className='error-wrapper'>
          <Result
            status='500'
            title='An Error Occured'
            subTitle='Sorry, something went wrong. Please refresh the page or try again later.'
          />
        </div>
      )}
    </>
  )
}
