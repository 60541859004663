import { InfoCircleOutlined } from '@ant-design/icons'
import { Empty, Tag, Tooltip } from 'antd'
import numeral from 'numeral'
import React from 'react'
import styled from 'styled-components'
import * as yup from 'yup'

import { BrandIdFilter } from '../filters/BrandIdFilter'
import { CampaignIdFilter } from '../filters/CampaignIdFilter'
import { DateRangeFilter } from '../filters/DateRangeFilter'
import { ManualDateRangeFilter } from '../filters/ManualDateRangeFilter'
import { SocialChannelFilter } from '../filters/SocialChannelFilter'
import { ManageWidget } from '../ManageWidget'
import { useCampaignOptions } from '../utils'
import { MetricsWrapper, WidgetCard } from '../WidgetCard'

export const ContentEngagement = ({ widget, activeView, setActiveView, brandData, data }) => {
  const campaignOptions = useCampaignOptions(brandData)

  const defaultValues = {
    title: widget.defaultTitle,
    ...widget.defaultFilters,
  }

  const initialValues = {
    title: widget.title || defaultValues.title,
    brandIds: widget.filters?.brandIds || defaultValues.brandIds,
    campaignIds: widget.filters?.campaignIds || defaultValues.campaignIds,
    fromDate: widget.filters?.fromDate || defaultValues.fromDate,
    toDate: widget.filters?.toDate || defaultValues.toDate,
    dateRange:
      widget.filters?.fromDate ||
      defaultValues.fromDate ||
      widget.filters?.toDate ||
      defaultValues.toDate
        ? 'custom'
        : widget.filters?.dateRange || defaultValues.dateRange,
    socialChannels: widget.filters?.socialChannels || defaultValues.socialChannels,
  }

  const validationSchema = yup.object().shape({
    title: yup.string().required('Required').max(50, 'Must be 50 characters or less'),
    brandIds: yup.array(),
    campaignIds: yup.array(),
    dateRange: yup.string(),
    socialChannels: yup.array(),
  })

  const constructData = values => {
    return {
      title: values.title,
      filters: {
        brandIds: values.brandIds,
        campaignIds: values.campaignIds,
        fromDate: values.fromDate,
        toDate: values.toDate,
        dateRange: values.dateRange,
        socialChannels: values.socialChannels,
      },
    }
  }

  const Filters = ({ setFieldValue }) => {
    return (
      <>
        {!!brandData.childBrands?.length && <BrandIdFilter brandData={brandData} />}
        <CampaignIdFilter campaignOptions={campaignOptions} />
        <SocialChannelFilter />

        <div className='date-range-wrapper'>
          <ManualDateRangeFilter setFieldValue={setFieldValue} />
          <DateRangeFilter setFieldValue={setFieldValue} />
        </div>
      </>
    )
  }

  const Metrics = () => {
    const metrics = [
      {
        title: 'Comments',
        per: 'comment',
        count: data?.countComments,
        roi: data?.totalComments,
      },
      {
        title: 'Likes',
        per: 'like',
        count: data?.countLikes,
        roi: data?.totalLikes,
      },
      {
        title: 'Views',
        per: 'view',
        count: data?.countViews,
        roi: data?.totalViews,
      },
      {
        title: 'Reach',
        per: 'reach',
        count: data?.countReach,
        roi: data?.totalReach,
      },
      {
        title: 'Impressions',
        per: 'impression',
        count: data?.countImpressions,
        roi: data?.totalImpressions,
      },
      {
        title: 'Saves',
        per: 'save',
        count: data?.countSaves,
        roi: data?.totalSaves,
      },
      {
        title: 'Shares',
        per: 'share',
        count: data?.countShares,
        roi: data?.totalShares,
      },
      {
        title: 'Avg. Engagement',
        count: data?.avgEngagement,
        tooltip: 'The average rate of engagement across all content.',
      },
    ].map(metric => ({
      tooltip: `ROI valued at $${(metric.roi / metric.count).toFixed(2)} per ${metric.per}.`,
      ...metric,
    }))

    return (
      <MetricsWrapper>
        {data && !!Object.keys(data)?.length ? (
          <Cards>
            {metrics.map((metric, i) => (
              <div key={i} className='metric-card'>
                <div className='metric-header'>
                  <p className='title'>{metric.title}</p>

                  {/* FIXME: tooltip is misaligned on right side */}
                  <Tooltip title={metric.tooltip}>
                    <InfoCircleOutlined style={{ color: '#bbb' }} />
                  </Tooltip>
                </div>

                <p className='value primary'>
                  {numeral(metric.count).format(
                    !metric.value || metric.title === 'Avg. Engagement' ? '' : '0.0a'
                  )}
                  {metric.title === 'Avg. Engagement' && '%'}
                </p>

                {!!metric.roi && (
                  <Tag color='blue'>
                    $
                    {metric.roi.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}{' '}
                    ROI
                  </Tag>
                )}
              </div>
            ))}
          </Cards>
        ) : (
          <div className='no-data'>
            <Empty description='No data.' />
          </div>
        )}
      </MetricsWrapper>
    )
  }

  const settingsProps = {
    widget,
    Filters,
    defaultValues,
    initialValues,
    validationSchema,
    constructData,
    activeView,
    setActiveView,
    brandId: brandData.id,
  }

  const cardProps = {
    widget,
    settings: <ManageWidget {...settingsProps} />,
    metrics: <Metrics />,
    size: 'large',
    brandData,
  }

  return <WidgetCard {...cardProps} />
}

const Cards = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  .metric-card {
    background: #f6f6f6;
    border-radius: 10px;
    padding: 20px;
    flex: 1;
  }

  .metric-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    margin-bottom: 5px;
  }

  .title {
    color: ${props => props.theme.crcoGrey};
    font-size: 14px;
    margin: 0;
    white-space: nowrap;

    .anticon {
      margin-right: 5px;
    }
  }

  .value {
    color: #23262f;
  }

  .ant-tag {
    margin-top: 10px;
  }

  @media only screen and (min-width: ${props => props.theme.breakpointTablet}) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  @media only screen and (min-width: ${props => props.theme.breakpointDesktop}) {
    grid-template-columns: repeat(4, 1fr);
  }
`
