import { EditOutlined, StarFilled, TeamOutlined } from '@ant-design/icons'
import {
  Checkbox as AntCheckbox,
  Drawer,
  Empty,
  Spin,
  Modal,
  Button,
  Tag,
  message,
  Dropdown,
  Tooltip,
} from 'antd'
import axios from 'axios'
import moment from 'moment'
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { queryCache, useInfiniteQuery, useQuery } from 'react-query'
import styled from 'styled-components/macro'

import { EditOptInViewDrawer } from './EditOptInViewDrawer'
import { NODE_URL } from '../../../../constants'
import { BrandContext } from '../../../../contexts/BrandContext'
import { CampaignContext } from '../../../../contexts/CampaignContext'
import { InfluencerContextProvider } from '../../../../contexts/InfluencerContext'
import { OptInContextProvider } from '../../../../contexts/OptInContext'
import { ActionMenuWrapper } from '../../../../GlobalStyles'
import { BulkMessageModal } from '../../../../layout/chat/BulkMessageModal'
import { ActionMenuIcon } from '../../../general/ActionMenuIcon'
import OrderSteps from '../../../steps/OrderSteps'
import { CreatorProfileActions } from '../../influencer-profile/CreatorProfileActions'
import OptInProfile from '../../influencer-profile/OptInProfile'
import { OptIn } from '../opt-ins/OptIn'

export const OptInList = ({
  campaign,
  formData,
  setFormData,
  filtersOpen,
  setFiltersOpen,
  activeView,
  setActiveView,
  setNewTitle,
  unsavedChanges,
  optInViews,
}) => {
  // #region Contexts
  const { brandId, fetchBrandData } = useContext(BrandContext)
  const { fetchOptIns } = useContext(CampaignContext)
  // #endregion Contexts

  // #region State
  const [optIns, setOptIns] = useState([])
  const [profileOpen, setProfileOpen] = useState(false)
  const [stepsOpen, setStepsOpen] = useState(false)
  const [selectedOptIn, setSelectedOptIn] = useState(false)
  const [deleteModalVisible, setDeleteModalVisible] = useState(false)
  const [allSelected, setAllSelected] = useState(false)
  const [selectionsInitialized, setSelectionsInitialized] = useState(false)
  const [excludedOptInIds, setExcludedOptInIds] = useState([])
  const [selectAllText, setSelectAllText] = useState(undefined)
  const [indeterminateSelection, setIndeterminateSelection] = useState(false)
  const [bulkMessageModalOpen, setBulkMessageModalOpen] = useState(false)
  const [numSelected, setNumSelected] = useState(0)
  const [dropdownOpen, setDropdownOpen] = useState(false)
  // #endregion State

  // #region Refs
  const selectedOptInRef = useRef(false)
  const scrollRef = useRef(false)
  // #endregion Refs

  // #region Constants
  const campaignId = campaign.id

  const sortOptions = [
    { label: 'Newest opt-ins', value: 'created-desc' },
    { label: 'Oldest opt-ins', value: 'created-asc' },
    { label: 'Newest activations', value: 'activated-desc' },
    { label: 'Newest cancellations', value: 'cancelled-desc' },
    { label: 'Most followers', value: 'reach-desc' },
    { label: 'Most engagement', value: 'engagement-desc' },
  ]
  // #endregion Constants

  // #region Queries
  const { data, status, fetchMore, canFetchMore, isFetchingMore } = useInfiniteQuery(
    ['opt-ins', { campaignId, formData }],
    fetchOptIns,
    {
      getFetchMore: lastPage => lastPage.nextCursor,
    }
  )

  const { data: brandData } = useQuery(['brand', brandId], fetchBrandData)
  // #endregion Queries

  // #region Functions
  const handleScroll = useCallback(
    currentScroll => {
      const distance = currentScroll
        ? currentScroll.scrollHeight - (currentScroll.scrollTop + currentScroll.offsetHeight)
        : 0
      if (distance < 400 && canFetchMore && !isFetchingMore) {
        fetchMore()
      }
    },
    [canFetchMore, fetchMore, isFetchingMore]
  )

  const handleSelectedOptIn = id => {
    for (let i = 0; i < data.length; i++) {
      for (let j = 0; j < data[i]?.optIns.length; j++) {
        if (data[i]?.optIns[j]?.id === id) {
          let next = false
          let prev = false

          // check for next one
          if (data[i].optIns[j + 1]) {
            next = data[i].optIns[j + 1].id
          } else if (data[i + 1]?.length) {
            next = data[i + 1].optIns[0].id
          }

          //check for previous one
          if (j > 0) {
            prev = data[i].optIns[j - 1].id
          } else if (i > 0) {
            prev = data[i - 1].optIns[data[i - 1].optIns.length - 1].id
          }

          setSelectedOptIn({
            ...data[i].optIns[j],
            prev,
            next,
          })

          if (selectedOptInRef.current) {
            selectedOptInRef.current.scrollIntoView({ behaviour: 'smooth' })
          }
          break
        }
      }
    }
  }

  const handleDeleteView = async id => {
    setFiltersOpen(false)
    try {
      await axios.delete(`${NODE_URL}/brand/${brandId}/campaign/opt-in-view/${id}`)
      queryCache.invalidateQueries(['opt-in-views', campaign.id])
      message.success('View deleted')
      setDeleteModalVisible(false)
      setActiveView(undefined)
    } catch (err) {
      message.error('Error deleting view')
    }
  }

  const handleSelectAll = () => {
    setAllSelected(!allSelected)
    setExcludedOptInIds([])
  }

  const handleSelect = (e, id) => {
    if (e.target.checked) {
      setExcludedOptInIds(excludedOptInIds.filter(selection => selection !== id))
      // check if all are selected now and if so setAllSelected to true
      if (excludedOptInIds.length === 1) setAllSelected(true)
    } else {
      setExcludedOptInIds([...excludedOptInIds, id])
    }
  }
  // #endregion Functions

  // #region Effects
  useEffect(() => {
    if (data) {
      setOptIns(data.map(({ optIns }) => optIns).flat())
    }
  }, [data])

  useEffect(() => {
    if (scrollRef.current) {
      handleScroll(scrollRef.current)
    }
  }, [data])

  useEffect(() => {
    // reset the selected opt in when opt-ins data updates (e.g. when completing a step)
    if (selectedOptIn) {
      handleSelectedOptIn(selectedOptIn.id)
    }
  }, [data])

  useEffect(() => {
    // ensure steps modal / profile is closed if current data doesn't include the selected opt-in
    if (selectedOptIn && !optIns.find(optIn => optIn.id === selectedOptIn.id)) {
      setSelectedOptIn(false)
      setStepsOpen(false)
      setProfileOpen(false)
    }
  }, [selectedOptIn, optIns])

  useEffect(() => {
    if (data && !allSelected) {
      // exclude all selections to control the checkbox state
      setExcludedOptInIds(data.map(({ optIns }) => optIns.map(optIn => optIn.id)).flat())
      setSelectionsInitialized(true)
    }
  }, [data, allSelected])

  useEffect(() => {
    // reset state when switching campaigns or views
    setAllSelected(false)
    setSelectionsInitialized(false)
    setExcludedOptInIds([])
    setSelectAllText(undefined)
    setIndeterminateSelection(false)
  }, [campaignId, activeView?.id])

  useEffect(() => {
    if (data) {
      const { totalResults } = data[0]

      // calculate number of results that haven't loaded yet
      const remaining = totalResults - data.reduce((acc, page) => acc + page.optIns.length, 0)

      // calculate number of selected results, accounting for pagination
      const selected = totalResults - excludedOptInIds.length - remaining * !allSelected

      setNumSelected(selected)
      setSelectAllText(`${selected} of ${totalResults} selected`)
      setIndeterminateSelection(selected > 0 && selected < totalResults)
    }
  }, [data, allSelected, excludedOptInIds])
  // #endregion Effects

  return (
    <Wrapper>
      <div className='inner'>
        <div className='filters'>
          {status === 'success' && (
            <>
              <Tooltip title={allSelected ? 'Unselect all' : 'Select all'}>
                <AntCheckbox
                  className='select-all'
                  onChange={handleSelectAll}
                  indeterminate={indeterminateSelection}
                  checked={allSelected}>
                  {selectAllText}
                </AntCheckbox>
              </Tooltip>
              <BulkMessageModal
                campaignId={parseInt(campaignId)}
                numSelected={numSelected}
                allSelected={allSelected}
                excludedOptInIds={excludedOptInIds}
                optIns={data?.map(({ optIns }) => optIns).flat()}
                bulkMessageModalOpen={bulkMessageModalOpen}
                setBulkMessageModalOpen={setBulkMessageModalOpen}
                optInFilters={formData}
              />
            </>
          )}

          {formData && (
            <div className='applied-filters'>
              {formData.search ? (
                <Tag closable onClose={() => setFormData(prev => ({ ...prev, search: '' }))}>
                  Search: {formData.search}
                </Tag>
              ) : (
                ''
              )}
              {formData.sort ? (
                <Tag closable onClose={() => setFormData(prev => ({ ...prev, sort: '' }))}>
                  Sort by: {sortOptions.find(option => option.value === formData.sort).label}
                </Tag>
              ) : (
                ''
              )}
              {formData.fromDate && formData.toDate ? (
                <Tag
                  closable
                  onClose={() => setFormData(prev => ({ ...prev, fromDate: '', toDate: '' }))}>
                  {moment(formData.fromDate).format('ll')} to {moment(formData.toDate).format('ll')}
                </Tag>
              ) : formData.fromDate ? (
                <Tag closable onClose={() => setFormData(prev => ({ ...prev, fromDate: '' }))}>
                  From date: {moment(formData.fromDate).format('ll')}
                </Tag>
              ) : formData.toDate ? (
                <Tag closable onClose={() => setFormData(prev => ({ ...prev, toDate: '' }))}>
                  To date: {moment(formData.toDate).format('ll')}
                </Tag>
              ) : (
                ''
              )}
              {formData.status?.length > 0 ? (
                <Tag
                  closable
                  onClose={() =>
                    setFormData(prev => ({
                      ...prev,
                      status: null,
                      // clear status specific filters
                      currentStep: null,
                      actionable: null,
                      cancelledReason: null,
                    }))
                  }>
                  Status:{' '}
                  {formData.status
                    .map(status => status.charAt(0).toUpperCase() + status.slice(1))
                    .join(', ')}
                </Tag>
              ) : (
                ''
              )}
              {!!formData.favoritedDate && (
                <Tag
                  closable
                  onClose={() => setFormData(prev => ({ ...prev, favoritedDate: null }))}>
                  <StarFilled style={{ color: '#fadb14' }} /> Favorites
                </Tag>
              )}
              {formData.currentStep?.length > 0 ? (
                <Tag closable onClose={() => setFormData(prev => ({ ...prev, currentStep: null }))}>
                  Current step: #
                  {campaign.steps.find(step => step.id === formData.currentStep[0]).placement}
                </Tag>
              ) : (
                ''
              )}
              {formData.actionable ? (
                <Tag closable onClose={() => setFormData(prev => ({ ...prev, actionable: null }))}>
                  Action Required
                </Tag>
              ) : (
                ''
              )}
              {formData.cancelledReason ? (
                <Tag
                  closable
                  onClose={() => setFormData(prev => ({ ...prev, cancelledReason: null }))}>
                  {formData.cancelledReason.charAt(0).toUpperCase() +
                    formData.cancelledReason.slice(1)}
                </Tag>
              ) : (
                ''
              )}
              {formData.minFollowers ? (
                <Tag
                  closable
                  onClose={() => setFormData(prev => ({ ...prev, minFollowers: null }))}>
                  Min followers: {formData.minFollowers}
                </Tag>
              ) : (
                ''
              )}
              {formData.maxFollowers ? (
                <Tag
                  closable
                  onClose={() => setFormData(prev => ({ ...prev, maxFollowers: null }))}>
                  Max followers: {formData.maxFollowers}
                </Tag>
              ) : (
                ''
              )}
              {formData.minEngagement ? (
                <Tag
                  closable
                  onClose={() => setFormData(prev => ({ ...prev, minEngagement: null }))}>
                  Min engagement: {formData.minEngagement}%
                </Tag>
              ) : (
                ''
              )}
              {formData.maxEngagement ? (
                <Tag
                  closable
                  onClose={() => setFormData(prev => ({ ...prev, maxEngagement: null }))}>
                  Max engagement: {formData.maxEngagement}%
                </Tag>
              ) : (
                ''
              )}
              {formData.requestedPaymentStatus ? (
                <Tag
                  closable
                  onClose={() => setFormData(prev => ({ ...prev, requestedPaymentStatus: null }))}>
                  Payment: Offer{' '}
                  {formData.requestedPaymentStatus.charAt(0).toUpperCase() +
                    formData.requestedPaymentStatus.slice(1)}
                </Tag>
              ) : (
                ''
              )}
            </div>
          )}

          {activeView && (
            <>
              <Button className='edit-btn' type='link' onClick={() => setFiltersOpen(true)}>
                {unsavedChanges && <span className='unsaved-indicator' />}
                <EditOutlined />
              </Button>
              <EditOptInViewDrawer
                campaign={campaign}
                activeView={activeView}
                setActiveView={setActiveView}
                filtersOpen={filtersOpen}
                setFiltersOpen={setFiltersOpen}
                formData={formData}
                setFormData={setFormData}
                setNewTitle={setNewTitle}
                sortOptions={sortOptions}
                unsavedChanges={unsavedChanges}
                optInViews={optInViews}
                setDeleteModalVisible={setDeleteModalVisible}
              />
              <Modal
                title={`Delete View: "${activeView.title}"`}
                open={deleteModalVisible}
                okText='Delete'
                okButtonProps={{ danger: true }}
                onOk={() => {
                  handleDeleteView(activeView.id)
                }}
                onCancel={() => setDeleteModalVisible(false)}>
                <p>Are you sure you want to delete this view?</p>
              </Modal>
            </>
          )}

          <Dropdown
            overlay={
              <ActionMenuWrapper>
                <Button
                  onClick={() => {
                    setBulkMessageModalOpen(true)
                    setDropdownOpen(false)
                  }}
                  type='link'
                  icon={<TeamOutlined />}
                  disabled={numSelected <= 1}>
                  Bulk message
                </Button>
              </ActionMenuWrapper>
            }
            onOpenChange={() => setDropdownOpen(!dropdownOpen)}
            open={dropdownOpen}>
            <ActionMenuIcon open={dropdownOpen} />
          </Dropdown>
        </div>

        {status === 'success' &&
          (optIns.length ? (
            <>
              <div
                className='list'
                ref={scrollRef}
                onScroll={() => handleScroll(scrollRef.current)}>
                {optIns.map(optIn => (
                  <InfluencerContextProvider key={optIn.id}>
                    <OptInContextProvider>
                      <OptIn
                        optIn={optIn}
                        campaign={campaign}
                        setShowProfile={setProfileOpen}
                        selectedOptIn={selectedOptIn}
                        setSelectedOptIn={handleSelectedOptIn}
                        selectedOptInRef={selectedOptInRef}
                        setStepsOpen={setStepsOpen}
                        // ensure selections are only shown as selected after initialization
                        selected={selectionsInitialized && !excludedOptInIds.includes(optIn.id)}
                        handleSelect={handleSelect}
                        formData={formData}
                        setOptIns={setOptIns}
                      />
                    </OptInContextProvider>
                  </InfluencerContextProvider>
                ))}
                {isFetchingMore && <Spin />}
              </div>

              {/* CREATOR PROFILE */}
              <InfluencerContextProvider>
                <OptInContextProvider>
                  <Drawer
                    title={`
                     ${
                       selectedOptIn?.status?.charAt(0).toUpperCase() +
                       selectedOptIn?.status?.slice(1)
                     }
                     Opt-In: ${selectedOptIn?.user?.firstName} ${selectedOptIn?.user?.lastName}`}
                    width={window.innerWidth > 1200 ? 1200 : window.innerWidth}
                    closable={true}
                    onClose={() => {
                      setProfileOpen(false)
                      !stepsOpen && setSelectedOptIn(false)
                    }}
                    destroyOnClose={true}
                    open={profileOpen}
                    extra={
                      <CreatorProfileActions
                        optIn={selectedOptIn}
                        setSelectedOptIn={handleSelectedOptIn}
                      />
                    }>
                    {selectedOptIn && (
                      <OptInProfile
                        key={selectedOptIn.id}
                        optIn={selectedOptIn}
                        campaign={campaign}
                        setSelectedOptIn={handleSelectedOptIn}
                        close={() => {
                          setProfileOpen(false)
                          setSelectedOptIn(false)
                        }}
                      />
                    )}
                  </Drawer>
                </OptInContextProvider>
              </InfluencerContextProvider>

              {/* STEPS MODAL */}
              <Modal
                open={stepsOpen}
                onCancel={() => {
                  setStepsOpen(false)
                  !profileOpen && setSelectedOptIn(false)
                }}
                footer={null}
                width={800}>
                {selectedOptIn &&
                  data.map(group =>
                    group.optIns.map(
                      optIn =>
                        selectedOptIn.id === optIn.id && (
                          <OrderSteps
                            brand={brandData}
                            optIn={selectedOptIn}
                            setSelectedOptIn={handleSelectedOptIn}
                            setProfileOpen={setProfileOpen}
                            key={optIn.id}
                          />
                        )
                    )
                  )}
              </Modal>
            </>
          ) : (
            <div className='no-results'>
              <Empty description='No opt-ins found.' />
            </div>
          ))}

        {status === 'loading' && (
          <div className='loading'>
            <Spin />
          </div>
        )}
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  flex: 1;
  position: relative;

  & > .inner {
    flex: 1;
    position: absolute;
    top: 10px;
    bottom: 24px;
    left: 24px;
    right: 24px;
    display: flex;
    flex-direction: column;
  }

  .filters {
    display: flex;
    align-items: center;
    gap: 12px;
    padding: 0 10px 10px 10px;
    box-shadow: 0px 10px 10px -10px rgba(0, 0, 0, 0.15);
    min-height: 50px;
    z-index: 1;
    position: relative;
    overflow: auto;
    ::-webkit-scrollbar {
      height: 6px;
      width: 6px;
    }
    ::-webkit-scrollbar-track {
      background-color: #fff;
      border-radius: 5px;
    }
    ::-webkit-scrollbar-thumb {
      background-color: #eee;
      border-radius: 5px;
      cursor: pointer;
    }
    .select-all {
      margin-left: 11px;
    }
    .edit-btn {
      font-size: 18px;
      margin: 0;
      padding: 0;
      border: 0;
      display: grid;
      place-content: center;
      position: relative;
      .unsaved-indicator {
        position: absolute;
        top: 3px;
        right: -3px;
        width: 11px;
        height: 11px;
        border-radius: 50%;
        background: orange;
        border: 2px solid #fff;
        z-index: 1;
      }
    }
    .applied-filters {
      margin-left: auto;
      display: flex;
    }
  }

  .headings {
    font-size: 18px;
    color: #080830;
    display: grid;
    grid-template-columns: 1fr 1fr 2fr 0.5fr 0.5fr;
    margin: 0 16px;

    span:not(:first-child) {
      text-align: center;
    }
  }

  .list {
    background: #f2f2f2;
    height: 100%;
    width: 100%;
    overflow: auto;
    display: flex;
    flex-direction: column;
    padding: 10px;
    gap: 10px;
    border: 1px solid #eee;
    border-top: none;
    border-radius: 0 0 5px 5px;
    ${props => props.theme.scrollbar}
  }

  .no-results {
    background: #f2f2f2;
    height: 100%;
    width: 100%;
    border: 1px solid #eee;
    border-top: none;
    border-radius: 0 0 5px 5px;
    display: grid;
    place-items: center;
  }

  .loading {
    background: #f2f2f2;
    height: 100%;
    width: 100%;
    border: 1px solid #eee;
    border-top: none;
    border-radius: 0 0 5px 5px;
    display: grid;
    place-content: center;
  }
`
