import { UploadOutlined } from '@ant-design/icons'
import { Typography, Upload } from 'antd'
import { Form, Formik } from 'formik'
import { Input } from 'formik-antd'
import React, { useImperativeHandle, useState, useRef, forwardRef } from 'react'
import styled from 'styled-components'
import * as yup from 'yup'

import { Wrapper } from './base'
import FormItem from '../../../forms/FormItem'
const REQUIRED_JSON_KEYS = [
  'type',
  'project_id',
  'private_key_id',
  'private_key',
  'client_email',
  'client_id',
  'auth_uri',
  'token_uri',
  'auth_provider_x509_cert_url',
  'client_x509_cert_url',
  'universe_domain',
]

export const UploadCredentials = forwardRef(function main({ context }, ref) {
  // state
  const [credentialsFile, setCredentialsFile] = useState(
    context?.credentials || context?.integration?.credentials || null
  )
  const [fileList, setFileList] = useState(
    credentialsFile ? [{ uid: '0', status: 'done', name: 'credentials.json' }] : []
  )
  const [propertyId, setPropertyId] = useState(
    context?.propertyId || context?.integration?.propertyId || null
  )
  const formRef = useRef()
  // external methods
  useImperativeHandle(ref, () => ({
    async validate() {
      const a = propertyId?.length == 9 && !!credentialsFile
      if (!a) {
        formRef?.current.setTouched(true)
        await formRef?.current.submitForm()
        return false
      }
      return { credentials: credentialsFile, propertyId }
    },
  }))
  // internal helper
  const validateFile = async file => {
    const err =
      'The file you uploaded is invalid. Please ensure you’re uploading the credentials.json file you downloaded from Step 1.'
    try {
      setFileList([{ uid: '0', status: 'uploading', name: file.name }])
      const arrayBuffer = await file.arrayBuffer()
      const enc = new TextDecoder('utf-8')
      const obj = JSON.parse(enc.decode(arrayBuffer))
      // can't find any missing keys?
      if (!REQUIRED_JSON_KEYS.find(k => !Object.keys(obj).includes(k))) {
        setFileList([
          { uid: '0', status: 'done', name: file.name, response: 'File uploaded successfully.' },
        ])
        return obj
      }
      setFileList([
        { uid: '0', status: 'error', name: file.name, response: `${err} - Missing JSON keys` },
      ])
    } catch (e) {
      setFileList([{ uid: '0', status: 'error', name: file.name, response: `${err} - ${e}` }])
    }
    return false
  }

  // handlers
  const handleFile = async ({ file }) => {
    if (!file?.arrayBuffer) return formRef?.current.setFieldValue('credentials', null)
    const _credentialsFile = await validateFile(file)
    formRef?.current.setFieldValue('credentials', _credentialsFile)
    setCredentialsFile(_credentialsFile)
  }

  const handleClearFile = () => {
    setFileList([])
    setCredentialsFile(null)
    formRef?.current.setFieldValue('credentials', null)
  }
  // form
  const validationSchema = yup.object().shape({
    propertyId: yup
      .string()
      .required('Property ID is required')
      .length(9, 'Must be 9 characters long'),
    credentials: yup.mixed().required('Credentials file is required'),
  })

  return (
    <Wrapper>
      <Typography.Title level={5}>Step 2: Upload Credentials & GA4 Property ID</Typography.Title>
      <p>
        Now, upload the JSON file you downloaded from Google. This will allow us to securely
        integrate your analytics.
      </p>
      <Formik
        enableReinitialized
        innerRef={formRef}
        validateOnMount
        initialValues={{
          propertyId: propertyId || '',
          credentials: credentialsFile,
        }}
        onSubmit={() => true}
        validationSchema={validationSchema}>
        {() => {
          return (
            <Form className='ga4PropertyId-form'>
              <FormItem name='credentials' required>
                <Upload.Dragger
                  name='credentials'
                  accept='application/json'
                  beforeUpload={() => false}
                  onChange={handleFile}
                  onRemove={handleClearFile}
                  multiple={false}
                  fileList={fileList}>
                  <UploadWrapper>
                    <UploadOutlined />
                    <p>Upload JSON File</p>
                  </UploadWrapper>
                </Upload.Dragger>
              </FormItem>
              <p>
                Additionally, please enter your GA4 Property ID to connect your specific Google
                Analytics data. You can find this in your Google Analytics account under Admin &gt;
                Property &gt; Property Details.
              </p>
              <FormItem name='propertyId'>
                <Input
                  required
                  name='propertyId'
                  placeholder='Enter GA4 Property ID'
                  style={{ width: '100%' }}
                  onChange={e => setPropertyId(e.target.value)}
                />
              </FormItem>
            </Form>
          )
        }}
      </Formik>
    </Wrapper>
  )
})

export const UploadWrapper = styled.div`
  .anticon {
    font-size: 1.3rem;
  }
`
export const TipWrapper = styled.div`
  margin-top: 6px;
  font-size: 12px;
  line-height: 14px;
  color: rgba(0, 0, 0, 0.5);
`
