import { DownOutlined } from '@ant-design/icons'
import { Popover, Tag, Row, Col, Spin } from 'antd'
import axios from 'axios'
import { Select, Input, InputNumber, Radio } from 'formik-antd'
import React, { useState, useRef } from 'react'
import styled from 'styled-components'

import FormItem from '../../components/forms/FormItem'
import { NODE_URL } from '../../constants'

export const SocialListeningFilters = ({ formData, setFormData, filtersExpanded }) => {
  const [fetchingLocations, setFetchingLocations] = useState(false)
  const [locations, setLocations] = useState([])
  const lastLocationFetchID = useRef(0)

  const fetchLocations = val => {
    if (val.length > 2) {
      lastLocationFetchID.current += 1
      const fetchID = lastLocationFetchID.current
      setFetchingLocations(true)
      setLocations([])
      axios.get(`${NODE_URL}/socialdata-countries/?platform=instagram&val=${val}`).then(res => {
        if (fetchID !== lastLocationFetchID.current) {
          return
        }
        setFetchingLocations(false)
        setLocations(
          res.data?.map(location => ({
            text: location.title,
            value: location.id,
          }))
        )
      })
    }
  }

  const tagRender = ({ value, closable, onClose }, prefix) => {
    let newVal = value?.replace(prefix, '')
    return (
      <Tag value={newVal} closable={closable} onClose={onClose}>
        {prefix + newVal}
      </Tag>
    )
  }

  // #region POPOVER FILTERS
  const dateFilter = () => {
    return (
      <div style={{ width: 275 }}>
        <FormItem name='date' size='small'>
          <p>Find posts created within a specific time frame.</p>
          <Radio.Group
            name='date'
            defaultValue='last-year'
            value={formData.date}
            onChange={e => {
              setFormData({
                ...formData,
                date: e.target.value,
              })
            }}
            style={{ display: 'flex', flexDirection: 'column' }}>
            <Radio value='last-year'>Last year</Radio>
            <Radio value='last-6-months'>Last 6 months</Radio>
            <Radio value='last-3-months'>Last 3 months</Radio>
            <Radio value='last-month'>Last month</Radio>
            <Radio value='last-week'>Last week</Radio>
          </Radio.Group>
        </FormItem>
      </div>
    )
  }

  const locationFilter = () => {
    return (
      <div style={{ width: 275 }}>
        <FormItem name='location' size='small'>
          <p>Find posts by made by creators in a specific location.</p>
          <Select
            name='location'
            labelInValue
            autoComplete='none'
            placeholder='Enter at least 3 characters'
            notFoundContent={fetchingLocations ? <Spin size='small' /> : null}
            filterOption={false}
            onSearch={val => fetchLocations(val)}
            showArrow
            showSearch
            allowClear
            value={formData.location}
            onChange={e => {
              setFormData({
                ...formData,
                location: e,
              })
            }}
            style={{ width: '100%' }}
            options={locations.map(item => ({ label: item.text, value: item.value }))}
          />
        </FormItem>
      </div>
    )
  }

  const likesFilter = () => {
    return (
      <div style={{ width: 275 }}>
        <FormItem name='likes' size='small'>
          <p>Find posts by the amount of likes.</p>
          <Row span={24}>
            <Col span={11}>
              <InputNumber
                name='minLikes'
                min={0}
                placeholder='Min'
                value={formData.minLikes}
                onChange={e => {
                  setFormData({
                    ...formData,
                    minLikes: e,
                  })
                }}
              />
            </Col>
            <Col span={2} style={{ textAlign: 'center' }}>
              <b> - </b>
            </Col>
            <Col span={11}>
              <InputNumber
                name='maxLikes'
                min={0}
                placeholder='Max'
                value={formData.maxLikes}
                onChange={e => {
                  setFormData({
                    ...formData,
                    maxLikes: e,
                  })
                }}
              />
            </Col>
          </Row>
        </FormItem>
      </div>
    )
  }

  const hashtagsFilter = () => {
    return (
      <div style={{ width: 275 }}>
        <FormItem name='hashtags' size='small'>
          <p>Find posts by hashtags. Please separate each tag with a space or comma.</p>
          <Select
            name='hashtags'
            mode='tags'
            allowClear
            placeholder='e.g. creatorco'
            style={{ width: '100%' }}
            tokenSeparators={[',', ' ']}
            tagRender={props => tagRender(props, '#')}
            showArrow
            value={formData.hashtags}
            onChange={e => {
              setFormData({
                ...formData,
                hashtags: e,
              })
            }}
          />
        </FormItem>
      </div>
    )
  }

  const mentionsFilter = () => {
    return (
      <div style={{ width: 275 }}>
        <FormItem name='mentions' size='small'>
          <p>Find posts by mentions. Please separate each tag with a space or comma.</p>
          <Select
            name='mentions'
            mode='tags'
            allowClear
            placeholder='e.g. creator.co.community'
            style={{ width: '100%' }}
            tokenSeparators={[',', ' ']}
            tagRender={props => tagRender(props, '@')}
            showArrow
            value={formData.mentions}
            onChange={e => {
              setFormData({
                ...formData,
                mentions: e,
              })
            }}
          />
        </FormItem>
      </div>
    )
  }

  const sponsorFilter = () => {
    return (
      <div style={{ width: 275 }}>
        <FormItem name='sponsor' size='small'>
          <p>Find posts by the username of a sponsor.</p>
          <Input
            name='sponsor'
            placeholder='username'
            prefix='@'
            allowClear
            value={formData.sponsor}
            onChange={e => {
              setFormData({
                ...formData,
                sponsor: e.target.value,
              })
            }}
          />
        </FormItem>
      </div>
    )
  }

  const hashtagFilter = () => {
    return (
      <div style={{ width: 275 }}>
        <FormItem name='hashtag' size='small'>
          <p>
            Find posts by a specific hashtag (we recommended using unique hashtags on your
            campaigns).
          </p>
          <Input
            name='hashtag'
            placeholder='hashtag'
            allowClear
            prefix='#'
            onChange={e => {
              setFormData({
                ...formData,
                hashtag: e.target.value,
              })
            }}
          />
        </FormItem>
      </div>
    )
  }
  // #endregion POPOVER FILTERS

  return (
    <Wrapper>
      <div
        className={`filters ${
          formData.postType === 'sponsored' && filtersExpanded ? 'expanded' : ''
        }`}>
        <Popover content={dateFilter} trigger='hover' placement='bottom'>
          <span className={`filter-button ${formData.date ? 'filter-selected' : ''}`}>
            Date <DownOutlined />
          </span>
        </Popover>
        <Popover content={locationFilter} trigger='hover' placement='bottom'>
          <span className={`filter-button ${formData.location ? 'filter-selected' : ''}`}>
            Location <DownOutlined />
          </span>
        </Popover>
        <Popover content={likesFilter} trigger='hover' placement='bottom'>
          <span
            className={`filter-button ${
              formData.minLikes || formData.maxLikes ? 'filter-selected' : ''
            }`}>
            Likes <DownOutlined />
          </span>
        </Popover>
        <Popover content={hashtagsFilter} trigger='hover' placement='bottom'>
          <span
            className={`filter-button ${formData.hashtags?.length > 0 ? 'filter-selected' : ''}`}>
            Hashtags <DownOutlined />
          </span>
        </Popover>
        <Popover content={mentionsFilter} trigger='hover' placement='bottom'>
          <span
            className={`filter-button ${formData.mentions?.length > 0 ? 'filter-selected' : ''}`}>
            Mentions <DownOutlined />
          </span>
        </Popover>
        <Popover content={sponsorFilter} trigger='hover' placement='bottom'>
          <span className={`filter-button ${formData.sponsor ? 'filter-selected' : ''}`}>
            Sponsor <DownOutlined />
          </span>
        </Popover>
      </div>
      <div
        className={`filters ${
          ['top', 'recent'].includes(formData.postType) && filtersExpanded ? 'expanded' : ''
        }`}>
        <Popover content={hashtagFilter} trigger='hover' placement='bottom'>
          <span className={`filter-button ${formData.hashtag ? 'filter-selected' : ''}`}>
            Hashtag <DownOutlined />
          </span>
        </Popover>
      </div>

      <div className={`applied-filters ${filtersExpanded && 'expanded'}`}>
        {formData.date && (
          <Tag>
            Date:
            <span className='tag-values'>
              {formData.date === 'last-year'
                ? 'Last year'
                : formData.date === 'last-6-months'
                  ? 'Last 6 months'
                  : formData.date === 'last-3-months'
                    ? 'Last 3 months'
                    : formData.date === 'last-month'
                      ? 'Last month'
                      : 'Last week'}
            </span>
          </Tag>
        )}

        {formData.location && (
          <Tag
            closable
            onClose={() => {
              setFormData({ ...formData, location: undefined })
            }}>
            Location:
            <span className='tag-values'>{formData.location.label}</span>
          </Tag>
        )}

        {(formData.minLikes || formData.maxLikes) && (
          <Tag
            closable
            onClose={() => {
              setFormData({ ...formData, date: undefined })
            }}>
            Likes:
            <span className='tag-values'>
              Min: {formData.minLikes || 'N/A'} - Max: {formData.maxLikes || 'N/A'}
            </span>
          </Tag>
        )}

        {formData.hashtags?.length > 0 ? (
          <Tag
            closable
            onClose={() => {
              setFormData({ ...formData, hashtags: undefined })
            }}>
            Hashtags:
            <span className='tag-values'>{formData.hashtags.map(tag => `#${tag}`).join(', ')}</span>
          </Tag>
        ) : (
          ''
        )}

        {formData.mentions?.length > 0 ? (
          <Tag
            closable
            onClose={() => {
              setFormData({ ...formData, mentions: undefined })
            }}>
            Mentions:
            <span className='tag-values'>{formData.mentions.map(tag => `@${tag}`).join(', ')}</span>
          </Tag>
        ) : (
          ''
        )}

        {formData.sponsor && (
          <Tag
            closable
            onClose={() => {
              setFormData({ ...formData, sponsor: undefined })
            }}>
            Sponsor:
            <span className='tag-values'>@{formData.sponsor}</span>
          </Tag>
        )}

        {formData.hashtag && (
          <Tag
            closable
            onClose={() => {
              setFormData({ ...formData, hashtag: undefined })
            }}>
            Hashtag:
            <span className='tag-values'>#{formData.hashtag}</span>
          </Tag>
        )}
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  .filters {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    overflow: hidden;
    max-height: 0;
    opacity: 0;
    visibility: hidden;
    transition: 0.2s ease-in-out;
    &.expanded {
      max-height: 500px;
      opacity: 1;
      visibility: visible;
      margin-top: 20px;
    }
  }

  .filter-button {
    background: #f6f6f6;
    border: 1px solid #e6e6e6;
    color: #999;
    flex: 1;
    max-width: 200px;
    border-radius: 5px;
    padding: 6px 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    transition: 0.2s ease-in-out;
    cursor: pointer;
    &:hover {
      color: #080830;
    }
    &.filter-selected {
      background: #e5f3ff;
      border-color: #a3cdf4;
      color: #027df0;
    }
  }

  .applied-filters {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 10px;
    overflow: hidden;
    max-height: 0;
    opacity: 0;
    visibility: hidden;
    transition: 0.2s ease-in-out;
    &.expanded {
      margin-top: 20px;
      max-height: 500px;
      opacity: 1;
      visibility: visible;
      margin-top: 20px;
    }
    .ant-tag {
      background: #e5f3ff;
      border: none;
      border-radius: 3px;
      padding: 5px 10px;
      max-width: 300px;
      margin: 0;
      overflow: hidden;
      .tag-values {
        text-overflow: ellipsis;
        overflow: hidden;
        display: inline-block;
        vertical-align: top;
        max-width: 200px;
        margin-left: 5px;
      }
      span {
        font-family: 'Campton-Light', Verdana, sans-serif;
      }
    }
  }
`
