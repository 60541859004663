import { useMemo } from 'react'

export const useCampaignOptions = brandData =>
  useMemo(
    () =>
      brandData.campaigns
        ?.filter(campaign => ['publish', 'paused'].includes(campaign.status))
        .map(campaign => ({ label: campaign.title, value: campaign.id })),
    [brandData]
  )
