import {
  CopyOutlined,
  DeleteOutlined,
  EditOutlined,
  EyeInvisibleOutlined,
  UndoOutlined,
  SaveOutlined,
} from '@ant-design/icons'
import { Button, Tag, message } from 'antd'
import { Formik, Form } from 'formik'
import { Input } from 'formik-antd'
import React, { useState } from 'react'
import { queryCache } from 'react-query'

import { SettingsWrapper } from './WidgetCard'
import FormItem from '../../components/forms/FormItem'
import { useAnalyticsApi } from '../../custom-hooks/analyticsApi'

export const ManageWidget = ({
  widget,
  Filters, // component
  defaultValues,
  initialValues,
  validationSchema,
  constructData,
  activeView,
  setActiveView,
  brandId,
}) => {
  const [duplicatingWidget, setDuplicatingWidget] = useState(false)
  const [hidingWidget, setHidingWidget] = useState(false)
  const [deletingWidget, setDeletingWidget] = useState(false)
  const analyticsApi = useAnalyticsApi()

  const handleUpdateWidget = async values => {
    const data = await constructData(values)

    analyticsApi.widget
      .widgetUpdate(brandId, widget.id, {
        title: data.title,
        filters: data.filters,
        visible: true,
      })
      .then(res => {
        message.success('Widget updated')
        queryCache.invalidateQueries(['analytics-views', brandId])
        queryCache.invalidateQueries(['analytics-view-data', activeView.id])

        // update state to reflect changes to widget
        setActiveView({
          ...activeView,
          widgets: activeView.widgets?.map(w => (w.id === widget.id ? res : w)),
        })
      })
      .catch(() => {
        message.error('Error updating widget')
      })
  }

  const handleDuplicate = async () => {
    setDuplicatingWidget(true)

    analyticsApi.widget
      .widgetDuplicateCreate(widget.id, brandId)
      .then(res => {
        message.success('Widget duplicated')
        queryCache.invalidateQueries(['analytics-views', brandId])
        queryCache.invalidateQueries(['analytics-view-data', activeView.id])

        // update state to reflect duplicated widget
        setActiveView({
          ...activeView,
          widgets: [...activeView.widgets, res],
        })
      })
      .catch(() => {
        message.error('Error duplicating widget')
      })
      .finally(() => setDuplicatingWidget(false))
  }

  const handleHide = async () => {
    setHidingWidget(true)
    analyticsApi.widget
      .widgetUpdate(brandId, widget.id, {
        title: widget.title,
        filters: widget.filters,
        visible: false,
      })
      .then(res => {
        message.success('Widget hidden')
        queryCache.invalidateQueries(['analytics-views', brandId])

        // update state to reflect hidden widget
        setActiveView({
          ...activeView,
          widgets: activeView.widgets?.map(w => (w.id === widget.id ? res : w)),
        })
      })
      .catch(() => {
        message.error('Error hiding widget')
      })
      .finally(() => setHidingWidget(false))
  }

  const handleDelete = async () => {
    setDeletingWidget(true)

    analyticsApi.widget
      .widgetDelete(widget.id, brandId)
      .then(() => {
        message.success('Widget deleted')
        queryCache.invalidateQueries(['analytics-views', brandId])

        // update state to reflect removed widget
        setActiveView({
          ...activeView,
          widgets: activeView.widgets?.filter(w => w.id !== widget.id),
        })
      })
      .catch(() => {
        message.error('Error deleting widget')
      })
      .finally(() => setDeletingWidget(false))
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleUpdateWidget}>
      {({ values, setValues, setFieldValue, submitForm, isSubmitting }) => (
        <Form>
          <SettingsWrapper>
            <div>
              <FormItem size='small' label='Title' name='title'>
                <Input
                  name='title'
                  maxLength={50}
                  minLength={1}
                  prefix={<EditOutlined />}
                  placeholder={widget.defaultTitle}
                />
              </FormItem>
              {widget.title !== widget.defaultTitle && (
                <Tag>Default title: {widget.defaultTitle}</Tag>
              )}
            </div>

            <Filters values={values} setFieldValue={setFieldValue} />

            <div className='buttons'>
              <Button
                type='primary'
                loading={isSubmitting}
                onClick={submitForm}
                icon={<SaveOutlined />}
                disabled={JSON.stringify(values) === JSON.stringify(initialValues)}>
                Save
              </Button>
              <Button
                type='secondary'
                disabled={JSON.stringify(values) === JSON.stringify(initialValues)}
                onClick={() => {
                  setValues(initialValues)
                }}>
                Reset
              </Button>
            </div>

            <div className='border' />

            <div className='misc-actions'>
              <Button
                type='link'
                icon={<UndoOutlined />}
                disabled={JSON.stringify(values) === JSON.stringify(defaultValues)}
                loading={isSubmitting}
                onClick={() => {
                  setValues(defaultValues)
                  submitForm()
                }}>
                Default Settings
              </Button>
              <Button
                type='link'
                onClick={handleDuplicate}
                icon={<CopyOutlined />}
                loading={duplicatingWidget}>
                Duplicate Widget
              </Button>
              <Button
                type='link'
                onClick={handleHide}
                icon={<EyeInvisibleOutlined />}
                loading={hidingWidget}>
                Hide Widget
              </Button>
              <Button
                type='link'
                danger
                onClick={handleDelete}
                icon={<DeleteOutlined />}
                loading={deletingWidget}>
                Delete Widget
              </Button>
            </div>
          </SettingsWrapper>
        </Form>
      )}
    </Formik>
  )
}
