import { Empty } from 'antd'
import numeral from 'numeral'
import React from 'react'
import * as yup from 'yup'

import { BrandIdFilter } from '../filters/BrandIdFilter'
import { CampaignIdFilter } from '../filters/CampaignIdFilter'
import { SocialChannelFilter } from '../filters/SocialChannelFilter'
import { ManageWidget } from '../ManageWidget'
import { useCampaignOptions } from '../utils'
import { MetricsWrapper, WidgetCard } from '../WidgetCard'

export const CreatorStats = ({ widget, activeView, setActiveView, brandData, data }) => {
  const campaignOptions = useCampaignOptions(brandData)

  const defaultValues = {
    title: widget.defaultTitle,
    ...widget.defaultFilters,
  }

  const initialValues = {
    title: widget.title || defaultValues.title,
    brandIds: widget.filters?.brandIds || defaultValues.brandIds,
    campaignIds: widget.filters?.campaignIds || defaultValues.campaignIds,
    socialChannels: widget.filters?.socialChannels || defaultValues.socialChannels,
  }

  const validationSchema = yup.object().shape({
    title: yup.string().required('Required').max(50, 'Must be 50 characters or less'),
    brandIds: yup.array(),
    campaignIds: yup.array(),
    socialChannels: yup.array(),
  })

  const constructData = values => {
    return {
      title: values.title,
      filters: {
        brandIds: values.brandIds,
        campaignIds: values.campaignIds,
        socialChannels: values.socialChannels,
      },
    }
  }

  const Filters = () => {
    return (
      <>
        {!!brandData.childBrands?.length && <BrandIdFilter brandData={brandData} />}
        <CampaignIdFilter campaignOptions={campaignOptions} />
        <SocialChannelFilter />
      </>
    )
  }

  const Metrics = () => {
    return (
      <MetricsWrapper>
        {data && !!Object.keys(data)?.length ? (
          <>
            <div>
              <p className='value primary'>
                {numeral(data.followerCount).format(data.followerCount > 999 && '0.0a')}
              </p>
              <p className='label'>total followers</p>
            </div>
            <div>
              <p className='value primary'>
                {numeral(data.avgEngagement).format(data.avgEngagement >= 1 && '0.0a')}%
              </p>
              <p className='label'>avg. engagement</p>
            </div>
          </>
        ) : (
          <div className='no-data'>
            <Empty description='No data.' />
          </div>
        )}
      </MetricsWrapper>
    )
  }

  const settingsProps = {
    widget,
    Filters,
    defaultValues,
    initialValues,
    validationSchema,
    constructData,
    activeView,
    setActiveView,
    brandId: brandData.id,
  }

  const cardProps = {
    widget,
    settings: <ManageWidget {...settingsProps} />,
    metrics: <Metrics />,
    brandData,
  }

  return <WidgetCard {...cardProps} />
}
