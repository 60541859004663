import { CopyOutlined } from '@ant-design/icons'
import { Tooltip, Typography } from 'antd'
import React, { useImperativeHandle, useState, forwardRef } from 'react'
import CopyToClipboard from 'react-copy-to-clipboard'
import styled from 'styled-components'

import { Wrapper } from './base'
import { useAnalyticsApi } from '../../../../custom-hooks/analyticsApi'

export const Submit = forwardRef(function main({ context }, ref) {
  const analyticsApi = useAnalyticsApi()
  const [copied, setCopied] = useState(false)

  useImperativeHandle(ref, () => ({
    validate() {
      return true
    },
    async submit() {
      const brandId = context.brandId
      const obj = {
        credentials: context.credentials,
        propertyId: context.propertyId,
      }
      try {
        const resp = await (context.integration?.id
          ? analyticsApi.integration.integrationGa4Update(brandId, context.integration?.id, obj)
          : analyticsApi.integration.integrationGa4NewCreate(brandId, obj))
        return { integration: resp, error: null }
      } catch (e) {
        return {
          error: {
            message:
              e?.response?.data?.err ||
              'Error while testing Google Analytics integration credentials. Please try again.',
            description: e?.response?.data?.details || '',
          },
        }
      }
    },
  }))

  const handleCopy = () => {
    setCopied(true)
    setTimeout(() => setCopied(false), 700)
  }

  return (
    <Wrapper>
      <Typography.Title level={5}>Step 3: Create a New User in GA4 Property</Typography.Title>
      <p>
        Almost there! We&apos;ve decoded your uploaded JSON file. Please create a new user in your
        GA4 property using the email we found and with VIEWER role. This ensures seamless data
        integration.
      </p>
      <CopyEmail onCopy={handleCopy} text={context.credentials.client_email}>
        <Tooltip open={copied} title='Copied!'>
          <div className='email'>{context.credentials.client_email}</div>
          <div className='icon'>
            <CopyOutlined />
          </div>
        </Tooltip>
      </CopyEmail>
      <p>
        If you need help, follow this guide to{' '}
        <a
          target='_blank'
          rel='noopener noreferrer'
          href='https://support.google.com/analytics/answer/9305788#zippy=%2Cin-this-article'>
          add a user
        </a>
        .
      </p>
    </Wrapper>
  )
})

const CopyEmail = styled(CopyToClipboard)`
  border: 1px solid #e6e6e6;
  border-radius: 5px;
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
  transition: 0.2s ease-in-out;
  cursor: pointer;
  .email {
    color: ${props => props.theme.crcoTechBlue};
    padding: 10px;
    flex: 1;
  }
  .icon {
    border-left: 1px solid #e6e6e6;
    padding: 10px;
    display: grid;
    place-content: center;
  }
  &:hover {
    background: #f7f7f7;
  }
`
