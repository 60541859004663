import { PlusOutlined, SearchOutlined } from '@ant-design/icons'
import { Button, Empty, Modal, Spin, message } from 'antd'
import axios from 'axios'
import { Form, Formik } from 'formik'
import { Input } from 'formik-antd'
import React, { useContext, useState, useRef } from 'react'
import { useQuery } from 'react-query'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components/macro'

import CampaignList from '../components/campaign-dashboard/CampaignList.js'
import FormItem from '../components/forms/FormItem'
import { LimitedAccessAlert } from '../components/general/LimitedAccessAlert'
import { BOOK_DEMO_URL, NODE_URL } from '../constants'
import { BrandContext } from '../contexts/BrandContext'
import { UserContext } from '../contexts/UserContext'
import { useDocumentTitle } from '../custom-hooks/useDocumentTitle'

const CampaignDashboard = ({ brandName }) => {
  const history = useHistory()
  const { brandId, fetchCampaigns, fetchBrandData } = useContext(BrandContext)
  const { role } = useContext(UserContext)
  const [campaignFilters, setCampaignFilters] = useState()
  const [newCampaignModalOpen, setNewCampaignModalOpen] = useState(false)
  const submitRef = useRef(0)

  useDocumentTitle(
    role === 'administrator'
      ? brandName
        ? `${brandName} - Campaigns | Brand Dashboard`
        : 'Campaigns | Brand Dashboard'
      : 'My Campaigns | Brand Dashboard'
  )

  const {
    data: campaigns,
    status,
    refetch,
  } = useQuery(['campaigns', brandId, campaignFilters], fetchCampaigns)
  const { data: brandData } = useQuery(['brand', brandId], fetchBrandData)

  const handleNewCampaign = async title => {
    await axios
      .post(`${NODE_URL}/brand/${brandId}/campaign`, {
        title,
      })
      .then(({ data }) => history.push(`/campaign-builder/${data.campaignId}`))
      .catch(() => {
        message.error('Sorry, something went wrong.')
      })
  }

  return (
    <Wrapper>
      <div className='inner'>
        <div className='tab-header'>
          <h1 className='header-text'>
            {role === 'administrator'
              ? brandName
                ? `${brandName} Campaigns`
                : 'Campaigns'
              : 'My Campaigns'}
          </h1>
          <div className='actions'>
            <div className='search'>
              <Formik
                initialValues={{}}
                onSubmit={values => {
                  submitRef.current++
                  const thisSubmit = submitRef.current
                  setTimeout(() => {
                    if (thisSubmit === submitRef.current) {
                      setCampaignFilters(prev => ({ ...prev, ...values }))
                    }
                  }, 300)
                }}>
                {({ submitForm }) => (
                  <Form>
                    <Input
                      onChange={submitForm}
                      name='search'
                      placeholder='Search campaigns'
                      prefix={<SearchOutlined />}
                      allowClear
                    />
                  </Form>
                )}
              </Formik>
            </div>
            {!brandData?.chargebeePastDue && (
              <Button
                type='primary'
                icon={<PlusOutlined />}
                onClick={() => setNewCampaignModalOpen(true)}>
                New Campaign
              </Button>
            )}
          </div>
        </div>
        {status === 'success' && (
          <>
            {!campaigns.length ? ( // no campaigns
              campaignFilters ? (
                <div className='no-results'>
                  <Empty description='No campaigns match your search.' />
                </div>
              ) : (
                <div className='no-campaigns'>
                  <Empty description={`No campaigns yet.`} />
                  <Button
                    type='primary'
                    icon={<PlusOutlined />}
                    onClick={() => setNewCampaignModalOpen(true)}
                    style={{ marginBottom: '30px' }}>
                    Build Campaign
                  </Button>
                  <p>
                    Not sure how to build a campaign?{' '}
                    <a href={BOOK_DEMO_URL} target='_blank' rel='noopener noreferrer'>
                      Talk to an expert.
                    </a>
                  </p>
                </div>
              )
            ) : (
              <CampaignList campaigns={campaigns} setCampaignFilters={setCampaignFilters} />
            )}
          </>
        )}
        <Modal
          open={newCampaignModalOpen}
          onCancel={() => setNewCampaignModalOpen(false)}
          destroyOnClose={true}
          title='New Campaign'
          footer={false}>
          {brandData?.activeSubscriptions?.length || !brandData?.extraData?.blockAccess ? (
            <Formik
              initialValues={{ title: '' }}
              onSubmit={({ title }) => handleNewCampaign(title)}>
              {({ values, submitForm, isSubmitting }) => (
                <Form>
                  <FormItem
                    name='title'
                    label="Let's name your campaign"
                    subtext="What is your campaign promoting? Be descriptive but brief. Try not to include your
                brand's name.">
                    <Input name='title' placeholder='Campaign title' />
                  </FormItem>
                  <Button
                    type='primary'
                    onClick={submitForm}
                    loading={isSubmitting}
                    disabled={!values.title}>
                    Continue
                  </Button>
                </Form>
              )}
            </Formik>
          ) : (
            <LimitedAccessAlert />
          )}
        </Modal>
        {status === 'loading' && (
          <div className='loading'>
            <Spin />
          </div>
        )}
        {status === 'error' && (
          <div className='error'>
            <Empty description='Oops!' />
            <p>Something went wrong while fetching your campaigns.</p>
            <Button type='primary' onClick={refetch}>
              Try Again
            </Button>
          </div>
        )}
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  height: 100%;
  padding: 20px;
  .inner {
    background: #fff;
    max-width: 1400px;
    margin: 0 auto;
    height: calc(100vh - 90px);
    overflow: hidden;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    .loading,
    .no-results,
    .no-campaigns,
    .error {
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 20px;
    }
    .tab-header {
      padding: 20px;
      display: flex;
      flex-direction: column;
      gap: 20px;
      position: sticky;
      top: 0;
      z-index: 2;
      .header-text {
        display: flex;
        align-items: center;
        gap: 12px;
      }
      h1 {
        font-family: 'Gilroy-ExtraBold', sans-serif;
        font-size: 1.5rem;
        margin: 0;
      }
      .actions {
        display: flex;
        grid-gap: 12px;
      }
    }
    .search {
      flex: 1;
    }
  }
`

export default CampaignDashboard
