import { Result as AResult } from 'antd'
import React, { useImperativeHandle, forwardRef } from 'react'

import { Wrapper } from './base'

export const Result = forwardRef(function main({ context }, ref) {
  useImperativeHandle(ref, () => ({
    validate() {
      return true
    },
  }))
  /* Error state */
  if (context.error) {
    return (
      <Wrapper>
        <AResult
          status='error'
          title='Failed'
          subTitle={
            <>
              {context.error.message}
              <br />
              {context.error.description}
            </>
          }
        />
      </Wrapper>
    )
  }
  /* Success/completed state */
  return (
    <Wrapper>
      <AResult
        status='success'
        title='Success'
        subTitle='You successfully configured Google Analytics 4 integration!'
      />
    </Wrapper>
  )
})
