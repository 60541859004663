import { HeartFilled, InfoCircleOutlined, LinkOutlined, LoadingOutlined } from '@ant-design/icons'
import { Button, Empty, Rate, Tooltip } from 'antd'
import React, { useContext } from 'react'
import { useInfiniteQuery } from 'react-query'
import styled from 'styled-components'

import { InfluencerContext } from '../../../contexts/InfluencerContext'
import { theme } from '../../../GlobalStyles'
import instagram from '../../../images/icons/social/instagram-color-square.svg'
import tiktok from '../../../images/icons/social/tiktok-color-square.svg'
import youtube from '../../../images/icons/social/youtube-color-square.svg'
import { getAverageRating } from '../../../utils'
const socialIcons = { instagram, youtube, tiktok }

const CreatorCampaignHistory = ({ creatorId, name }) => {
  const { fetchCampaignHistory } = useContext(InfluencerContext)
  const {
    data: history,
    status,
    isFetchingMore,
    fetchMore,
    canFetchMore,
  } = useInfiniteQuery(['campaign-history', creatorId], fetchCampaignHistory, {
    getFetchMore: lastGroup => {
      return lastGroup.nextPage
    },
  })

  return (
    <Wrapper>
      {status === 'success' && (
        <div className='inner'>
          {history[0].totalResults ? (
            <>
              {history.map(group =>
                group.campaigns.map(campaign => (
                  <div key={campaign.slug} className='campaign'>
                    <div className='details'>
                      <p className='campaign-title'>
                        {campaign.socialChannels?.map((channel, i) => (
                          <img src={socialIcons[channel]} alt={channel} key={i} />
                        ))}
                        {campaign.title}
                      </p>
                      {campaign.postUrls?.map((url, i) => (
                        <a
                          className='shoutout-link'
                          href={url}
                          key={url}
                          target='_blank'
                          rel='noopener noreferrer'>
                          <LinkOutlined /> {`View Shoutout ${i > 1 ? i : ''}`}
                        </a>
                      ))}
                    </div>
                    <div className='rating'>
                      {campaign.rating ? (
                        <>
                          <Rate
                            disabled={true}
                            value={getAverageRating(campaign.rating)}
                            character={<HeartFilled />}
                            style={{ color: theme.crcoCoral }}
                            allowHalf
                          />
                          <Tooltip
                            title={
                              <TooltipText>
                                <p>
                                  <b>Average Rating: {getAverageRating(campaign.rating)}</b>
                                </p>
                                <p>Content: {campaign.rating.content || 0}</p>
                                <p>Creativity: {campaign.rating.creativity || 0}</p>
                                <p>Communication: {campaign.rating.communication || 0}</p>
                                <p>Overall: {campaign.rating.overall || 0}</p>
                              </TooltipText>
                            }>
                            <InfoCircleOutlined />
                          </Tooltip>
                        </>
                      ) : (
                        <div style={{ color: theme.crcoGrey }}>No rating.</div>
                      )}
                    </div>
                  </div>
                ))
              )}
              {canFetchMore && (
                <Button type='link' loading={isFetchingMore} onClick={() => fetchMore()}>
                  Load More
                </Button>
              )}
            </>
          ) : (
            <Empty
              style={{
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                margin: '20px auto',
                maxWidth: '300px',
              }}
              description={`${name} hasn't done any campaigns yet.`}
            />
          )}
        </div>
      )}
      {status === 'loading' && (
        <div className='loading'>
          <LoadingOutlined spin />
        </div>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  margin: 20px 0;
  max-height: 600px;
  overflow-y: auto;
  .campaign {
    border-bottom: 1px solid #e6e6e6;
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    padding-bottom: 15px;
    .details {
      flex: 2;
      display: flex;
      flex-direction: column;
      margin-right: 10px;
      .campaign-title {
        display: flex;
        gap: 8px;
        margin: 0;
        img {
          height: 20px;
          margin-right: 3px;
        }
      }
      .shoutout-link {
        width: fit-content;
        margin-top: 5px;
        color: ${props => props.theme.crcoTechBlue};
        &:hover {
          text-decoration: underline;
        }
      }
    }
    .rating {
      flex: 1;
      display: flex;
      align-items: center;
      gap: 10px;
      justify-content: flex-end;
    }
  }
  .loading {
    font-size: 1.2rem;
    color: #999;
    display: grid;
    place-content: center;
    padding: 20px;
  }
`

const TooltipText = styled.div`
  p {
    margin: 0;
  }
`

export default CreatorCampaignHistory
