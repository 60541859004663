import {
  DownloadOutlined,
  ClockCircleOutlined,
  LoadingOutlined,
  ExclamationCircleOutlined,
  CheckCircleOutlined,
  FileAddOutlined,
} from '@ant-design/icons'
import { Button, Carousel, Alert, message } from 'antd'
import axios from 'axios'
import moment from 'moment'
import React, { useState, useContext, useEffect } from 'react'
import CopyToClipboard from 'react-copy-to-clipboard'
import { useQuery } from 'react-query'
import styled from 'styled-components'

import { NODE_URL } from '../../../../constants'
import { UserContext } from '../../../../contexts/UserContext'
import {
  downloadFile,
  downloadFilesZip,
  findExtension,
} from '../../../../custom-hooks/fileDownload'
import instagram from '../../../../images/icons/social/instagram-color-square.svg'
import tiktok from '../../../../images/icons/social/tiktok-color-square.svg'
import youtube from '../../../../images/icons/social/youtube-color-square.svg'
import { socialProfileToUrl } from '../../../../utils'
import CreatorAvatar from '../../../general/CreatorAvatar'

const socialIcons = { instagram, youtube, tiktok }

export const ExpandedShoutout = ({ socialPost }) => {
  const { role } = useContext(UserContext)

  const socialProfile = socialPost.user.socialProfiles.find(
    profile => profile.platform === socialPost.platform
  )

  const [downloadAssetsLoading, setDownloadAssetsLoading] = useState(false)
  const [connectionStatus, setConnectionStatus] = useState(undefined)

  const postEngagement =
    Math.round(
      ((socialPost.likes +
        socialPost.dislikes +
        socialPost.comments +
        socialPost.saves +
        socialPost.shares) /
        (socialProfile?.followerCount || 1)) *
        10000
    ) / 100

  const phylloAccountId = socialProfile?.phylloData?.account?.id
  const { data: phylloAccountData, status: phylloAccountStatus } = useQuery(
    ['phyllo-data', phylloAccountId],
    async () => {
      if (!phylloAccountId) return { status: 'NOT_CONNECTED' }
      const { data: phylloData } = await axios.get(`${NODE_URL}/phyllo-account/${phylloAccountId}`)
      return phylloData
    },
    {
      refetchInterval: false,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
    }
  )

  useEffect(() => {
    if (!phylloAccountData) return
    setConnectionStatus(phylloAccountData.status)
  }, [phylloAccountData])

  const downloadAssets = async () => {
    if (
      (socialPost.format !== 'COLLECTION' && !socialPost.mediaUrl) ||
      (socialPost.format === 'COLLECTION' && !socialPost.mediaUrls)
    )
      return message.error(
        'No media found for this post, we might still be fetching it. If this persists please contact support.',
        10
      )

    try {
      setDownloadAssetsLoading(true)

      if (socialPost.format === 'COLLECTION') {
        const files = socialPost.mediaUrls.map((url, i) => ({
          url,
          fileName: `carousel-media-${i + 1}.${findExtension(url)}`,
        }))

        return await downloadFilesZip(`${socialProfile?.username}-shoutouts.zip`, files)
      }

      const fileName = `${socialProfile?.username}-shoutout.${findExtension(socialPost.mediaUrl)}`
      await downloadFile(fileName, socialPost.mediaUrl)
    } catch (err) {
      message.error(
        'Download failed, please try again. If this issue persists please contact support.',
        10
      )
    } finally {
      setDownloadAssetsLoading(false)
    }
  }

  const SocialStat = ({ label, value, subStat, children }) => {
    return (
      <>
        {!!value && (
          <div className={`stat ${subStat ? 'sub-stat' : ''}`}>
            <div className='stat-inner'>
              <span className='label'>{label}</span>
              <span className='value'>{value.toLocaleString()}</span>
            </div>
            {children}
          </div>
        )}
      </>
    )
  }

  return (
    <Wrapper>
      {role === 'administrator' && (
        <div className='admin-bar'>
          <span>For admins:</span>
          <div className='ids'>
            <CopyToClipboard text={socialPost.id}>
              <div className='id'>Social Post #{socialPost.id || 'N/A'}</div>
            </CopyToClipboard>
          </div>
        </div>
      )}

      <div className='inner'>
        <section className='post'>
          <div className='header'>
            {socialProfile && (
              <a
                className='user-info'
                href={socialProfileToUrl(socialProfile)}
                target='_blank'
                rel='noopener noreferrer'>
                <CreatorAvatar
                  size={50}
                  id={socialPost.user.id}
                  url={socialProfile.profilePicUrl}
                  initials={`${socialPost.user.firstName
                    ?.charAt(0)
                    .toUpperCase()} ${socialPost.user.lastName?.charAt(0).toUpperCase()}`}
                />
                <div className='text'>
                  <p className='full-name'>
                    {socialPost.user.firstName} {socialPost.user.lastName}
                  </p>
                  <div className='social-info'>
                    <span className='username'>{socialProfile.username}</span>
                    <span
                      className={`connection-status ${
                        connectionStatus === 'NOT_CONNECTED' ||
                        connectionStatus === 'SESSION_EXPIRED'
                          ? 'not-connected'
                          : ''
                      }`}>
                      {phylloAccountStatus === 'success' && (
                        <>
                          {connectionStatus === 'CONNECTED' ? (
                            <CheckCircleOutlined />
                          ) : (
                            <ExclamationCircleOutlined />
                          )}
                          {connectionStatus === 'CONNECTED' && 'Connected'}
                          {connectionStatus === 'NOT_CONNECTED' && 'Not Connected'}
                          {connectionStatus === 'SESSION_EXPIRED' && 'Connection Expired'}
                        </>
                      )}
                      {phylloAccountStatus === 'loading' && <LoadingOutlined spin />}
                    </span>
                  </div>
                </div>
              </a>
            )}
          </div>

          <div className='content'>
            <div className='media'>
              {socialPost.platform === 'youtube' ? (
                <iframe
                  title='shorts'
                  height={300}
                  src={`https://www.youtube.com/embed/${socialPost.externalId}`}
                />
              ) : socialPost.format === 'COLLECTION' ? (
                <Carousel autoplay>
                  {socialPost.mediaUrls.map((item, i) => (
                    <div key={i} className='image'>
                      <img key={item.pk} src={item} alt='' />
                    </div>
                  ))}
                </Carousel>
              ) : (
                <img src={socialPost.thumbnailUrl} alt='social post thumbnail' />
              )}
            </div>
            <div className='caption'>
              {socialPost.description?.split('\n').map((item, i) => (
                <p key={i}>{item}</p>
              ))}
            </div>
            <div className='content-row'>
              <div className='info'>
                <div>
                  {socialPost?.posted ? moment(socialPost?.posted).format('lll') : 'Date Unknown'}
                </div>
                {socialPost?.extraSubmission && (
                  <div>
                    <FileAddOutlined /> Additional content
                  </div>
                )}
              </div>
              <div className='actions'>
                <a href={socialPost.url} target='_blank' rel='noopener noreferrer'>
                  <Button
                    type='secondary'
                    icon={
                      <img
                        className='icon'
                        src={socialIcons[socialPost.platform]}
                        alt=''
                        style={{ height: '20px', width: '20px', marginRight: '8px' }}
                      />
                    }>
                    View Post
                  </Button>
                </a>
                <Button
                  icon={<DownloadOutlined />}
                  type='primary'
                  loading={downloadAssetsLoading}
                  onClick={() => downloadAssets()}>
                  Download Assets
                </Button>
              </div>
            </div>
          </div>
        </section>

        <section className='metrics-section'>
          <div className='header'>
            <span className='title'>Metrics</span>
            <span className='updated'>
              {!!socialPost.lastFetched &&
                `Last Updated: ${moment(socialPost.lastFetched).format('lll')}`}
            </span>
          </div>
          {phylloAccountStatus === 'success' &&
            ['NOT_CONNECTED', 'SESSION_EXPIRED'].includes(connectionStatus) && (
              <Alert
                message={`Social account ${
                  connectionStatus === 'NOT_CONNECTED' ? 'not connected' : 'connection expired'
                }. Metrics will not be accurate.`}
                type={connectionStatus === 'NOT_CONNECTED' ? 'error' : 'warning'}
                showIcon
              />
            )}
          {phylloAccountStatus === 'success' &&
            connectionStatus === 'CONNECTED' &&
            role === 'administrator' &&
            !socialPost?.phylloId && (
              <Alert
                message='Phyllo ID not found. Metrics will not be accurate.'
                type='warning'
                showIcon
              />
            )}
          {!socialPost?.mediaUrl && !socialPost?.thumbnail && !socialPost?.mediaUrls?.length && (
            <Alert
              message={
                <>
                  <ClockCircleOutlined /> Please wait while we fetch the post media and/or metrics.
                </>
              }
              type='info'
              showIcon
            />
          )}

          <div className='stats'>
            <SocialStat label='Impressions' value={socialPost.impressions} />
            <SocialStat label='Reach' value={socialPost.reach} />
            <SocialStat label='Likes' value={socialPost.likes} />
            <SocialStat label='Dislikes' value={socialPost.dislikes} />
            <SocialStat label='Comments' value={socialPost.comments} />
            <SocialStat label='Views' value={(socialPost.views || 0) + (socialPost.replays || 0)}>
              {!!socialPost.replays && (
                <>
                  <SocialStat label='Unique Views' value={socialPost.views} subStat={true} />
                  <SocialStat label='Replays' value={socialPost.replays} subStat={true} />
                </>
              )}
            </SocialStat>
            <SocialStat label='Shares' value={socialPost.shares} />
            <SocialStat label='Saves' value={socialPost.saves} />
            <SocialStat label='Engagement Rate' value={postEngagement && `${postEngagement}%`} />
          </div>
        </section>
      </div>
    </Wrapper>
  )
}

// styling shared with ExpandedStory.js
export const Wrapper = styled.div`
  background: #e9f4fc;
  padding: 20px;
  min-height: 100%;
  .admin-bar {
    background: #e9f4fc;
    border: 1px dotted ${props => props.theme.crcoTechBlue};
    border-radius: 5px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    padding: 10px;
  }
  .ids {
    color: rgba(0, 0, 0, 0.5);
    display: flex;
    gap: 10px;
    font-size: 0.8rem;
    margin-left: auto;
    .id {
      transition: 0.2s ease-in-out;
      cursor: pointer;
      &:hover {
        color: ${props => props.theme.crcoTechBlue};
      }
    }
  }

  .inner {
    display: flex;
    flex-direction: column;
  }
  .post {
    background: #fff;
    box-shadow: 4px 8px 20px 4px rgba(154, 175, 193, 0.12);
    border-radius: 10px;
    padding: 20px;
    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 12px;
      .user-info {
        display: flex;
        align-items: center;
        gap: 12px;
        color: #23262f;
        .text {
          .full-name {
            font-size: 1.1rem;
          }
          .social-info {
            display: flex;
            align-items: center;
            gap: 8px;
          }
          .username {
            color: ${props => props.theme.crcoTechBlue};
          }
          .connection-status {
            font-size: 0.8rem;
            opacity: 0.5;

            &.not-connected {
              color: red;
            }
            .anticon {
              margin-right: 5px;
            }
          }
          p {
            margin: 0;
          }
        }
      }
    }
    .content {
      .content-row {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      .actions {
        display: flex;
        align-items: center;
        gap: 12px;
      }
      .info {
        color: #999;
        font-size: 0.8rem;
      }
      .media {
        background: #eee;
        margin-bottom: 20px;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 10px;
        iframe,
        img {
          width: 100%;
        }
        iframe {
          border: none;
        }
        .placeholder {
          display: grid;
          place-content: center;
          padding: 40px;
        }
      }
      .caption {
        font-family: 'Campton-Light', sans-serif;
        margin: 12px 0;
        max-height: 300px;
        overflow: auto;
        padding-right: 12px;
        ${props => props.theme.scrollbar}
      }
      .view-post {
        color: #027df0;
        cursor: pointer;
        display: flex;
        align-items: center;
        .icon {
          height: 20px;
          width: 20px;
          margin-right: 8px;
        }
      }
    }
  }
  .ant-carousel {
    background-color: #f7f7f7;
    width: 100%;
    .image {
      min-height: 300px;
      max-height: 600px;
      overflow: hidden;
      border-radius: 10px;
      display: grid;
      place-content: center;
      img {
        max-width: 100%;
      }
    }
    .slick-dots {
      padding: 6px;
      background-color: #00000026;
      border-radius: 5px;
      & > li {
        height: auto;
        width: 15px;
        margin-right: 8px;
        margin-left: 8px;
        &.slick-active {
          width: 30px;
        }
        button {
          height: 15px;
          border-radius: 12px;
        }
      }
    }
  }

  .metrics-section {
    background: #fff;
    border-radius: 10px;
    margin-top: 20px;
    padding: 20px;
    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 12px;
      .title {
        font-size: 1.2rem;
        font-weight: bold;
      }
      .updated {
        color: #999;
        font-size: 0.8rem;
      }
    }
    .stats {
      display: flex;
      flex-direction: column;
      .stat {
        &:not(:last-child) {
          border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        }
        &.sub-stat {
          border-bottom: none;
          .label {
            padding-left: 20px;
            font-size: 0.8rem;
          }
          .value {
            font-size: 0.9rem;
            font-weight: normal;
            color: grey;
          }
        }
        .stat-inner {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 8px 0;
        }
        .label {
          color: rgba(0, 0, 0, 0.4);
        }
        .value {
          font-size: 1.1rem;
          color: ${props => props.theme.crcoTechBlue};
          font-weight: bold;
        }
      }
    }
  }
`
