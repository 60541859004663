import React from 'react'
import styled from 'styled-components'

const BRAND_ACTIVATE_INFLUENCER = ({ optInStep }) => {
  return (
    <Wrapper>
      {optInStep?.completed ? (
        <span>You&apos;ve activated this creator!</span>
      ) : (
        <span>Activate or decline this creator.</span>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: grid;
  place-items: center;
  height: 80px;
`

export default BRAND_ACTIVATE_INFLUENCER
