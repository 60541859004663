import { Empty, Table } from 'antd'
import React from 'react'
import * as yup from 'yup'

import { BrandIdFilter } from '../filters/BrandIdFilter'
import { CampaignStatusFilter } from '../filters/CampaignStatusFilter'
import { DateRangeFilter } from '../filters/DateRangeFilter'
import { ManualDateRangeFilter } from '../filters/ManualDateRangeFilter'
import { SocialChannelFilter } from '../filters/SocialChannelFilter'
import { ManageWidget } from '../ManageWidget'
import { MetricsWrapper, WidgetCard } from '../WidgetCard'

export const CampaignPerformance = ({ widget, activeView, setActiveView, brandData, data }) => {
  const defaultValues = {
    title: widget.defaultTitle,
    ...widget.defaultFilters,
  }

  const initialValues = {
    title: widget.title || defaultValues.title,
    brandIds: widget.filters?.brandIds || defaultValues.brandIds,
    campaignStatuses: widget.filters?.campaignStatuses || defaultValues.campaignStatuses,
    socialChannels: widget.filters?.socialChannels || defaultValues.socialChannels,
    fromDate: widget.filters?.fromDate || defaultValues.fromDate,
    toDate: widget.filters?.toDate || defaultValues.toDate,
    dateRange:
      widget.filters?.fromDate ||
      defaultValues.fromDate ||
      widget.filters?.toDate ||
      defaultValues.toDate
        ? 'custom'
        : widget.filters?.dateRange || defaultValues.dateRange,
  }

  const validationSchema = yup.object().shape({
    title: yup.string().required('Required').max(50, 'Must be 50 characters or less'),
    brandIds: yup.array(),
    campaignStatuses: yup.array(),
    socialChannels: yup.array(),
    dateRange: yup.string(),
  })

  const constructData = values => {
    return {
      title: values.title,
      filters: {
        brandIds: values.brandIds,
        campaignStatuses: values.campaignStatuses,
        socialChannels: values.socialChannels,
        fromDate: values.fromDate,
        toDate: values.toDate,
        dateRange: values.dateRange,
      },
    }
  }

  const Filters = ({ setFieldValue }) => {
    return (
      <>
        {!!brandData.childBrands?.length && <BrandIdFilter brandData={brandData} />}
        <CampaignStatusFilter />
        <SocialChannelFilter />

        <div className='date-range-wrapper'>
          <ManualDateRangeFilter setFieldValue={setFieldValue} />
          <DateRangeFilter setFieldValue={setFieldValue} />
        </div>
      </>
    )
  }

  const Metrics = () => {
    const columns = [
      {
        title: 'Campaign Title',
        key: 'campaignTitle',
        sorter: (a, b) => a.campaignTitle.localeCompare(b.campaignTitle),
      },
      ...(brandData.childBrands?.length
        ? [
            // agencies only
            {
              title: 'Brand',
              key: 'brand',
            },
          ]
        : []),
      {
        title: 'Opt-Ins',
        key: 'createdCount',
      },
      {
        title: 'Activated',
        key: 'activatedCount',
      },
      {
        title: 'Completed',
        key: 'completedCount',
      },
      {
        title: 'Declined',
        key: 'cancelledCount',
      },
      {
        title: 'Content',
        key: 'totalPosts',
      },
    ].map(col => ({
      sorter: (a, b) => a[col.key] - b[col.key],
      dataIndex: col.key,
      ...col,
    }))

    return (
      <MetricsWrapper className='overflow-auto'>
        {data?.length ? (
          <Table
            columns={columns}
            dataSource={data}
            pagination={{
              defaultPageSize: 5,
              hideOnSinglePage: true,
              showSizeChanger: true,
              pageSizeOptions: ['5', '10', '20'],
            }}
          />
        ) : (
          <div className='no-data'>
            <Empty description='No data.' />
          </div>
        )}
      </MetricsWrapper>
    )
  }

  const settingsProps = {
    widget,
    Filters,
    defaultValues,
    initialValues,
    validationSchema,
    constructData,
    activeView,
    setActiveView,
    brandId: brandData.id,
  }

  const cardProps = {
    widget,
    settings: <ManageWidget {...settingsProps} />,
    metrics: <Metrics />,
    size: 'large',
    brandData,
  }

  return <WidgetCard {...cardProps} />
}
