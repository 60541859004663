import React, { useEffect, useContext } from 'react'
import { Redirect } from 'react-router-dom'

import { UserContext } from '../contexts/UserContext'

export const Logout = () => {
  const { logoutHandler } = useContext(UserContext)
  useEffect(() => {
    logoutHandler()
  }, [logoutHandler])
  return <Redirect to='/' />
}
