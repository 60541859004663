import { DownloadOutlined } from '@ant-design/icons'
import { Button, Carousel } from 'antd'
import JSZip from 'jszip'
import JSZipUtils from 'jszip-utils'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import { getProfilePicUrl } from '../../../../utils'
import CreatorAvatar from '../../../general/CreatorAvatar'

const ShoutoutPreview = ({ optIn }) => {
  const [carousel, setCarousel] = useState([])
  const profilePicUrl = getProfilePicUrl(optIn)
  const username = `${optIn.user.firstName}_${optIn.user.lastName}`

  const download = async () => {
    if (optIn.extraData.shoutoutPreview.images?.length === 1) {
      const type = optIn.extraData.shoutoutPreview.images[0]?.split('?')[0]?.split('.')?.pop()
      let element = document.createElement('a')
      const blob = await (await fetch(optIn.extraData.shoutoutPreview?.images[0])).blob()
      element.href = URL.createObjectURL(blob)
      element.download = username + '_shoutout.' + type
      element.click()
    } else {
      // Assign new zip object
      const zip = new JSZip()
      let count = 0

      const getBinContent = (i, type) =>
        JSZipUtils.getBinaryContent(optIn.extraData.shoutoutPreview?.images[i], (err, data) => {
          if (err) {
            return
          }
          zip.file('image_' + (i + 1) + '.' + type, data, { binary: true })
          count++
          if (count === optIn.extraData.shoutoutPreview?.images.length) {
            zip.generateAsync({ type: 'blob' }).then(blobdata => {
              // create zip blob file
              var zipblob = new Blob([blobdata])
              var elem = document.createElement('a')
              elem.href = URL.createObjectURL(zipblob)
              elem.download = username + '_shoutouts.zip'
              elem.click()
            })
          }
        })

      // Loop through the filelist to get each filename and pass each file to zip object
      for (let i = 0; i < optIn.extraData.shoutoutPreview?.images.length; i++) {
        const type = optIn.extraData.shoutoutPreview.images[i]?.split('?')[0]?.split('.')?.pop()
        getBinContent(i, type)
      }
    }
  }

  useEffect(() => {
    for (let i = 0; i < optIn.extraData.shoutoutPreview?.images?.length || 0; i++) {
      var type = optIn.extraData.shoutoutPreview?.images[i].split('?')[0].split('.').pop()
      let element = ''

      if (type === 'mp4') {
        element = (
          <div className='image'>
            <a
              target='_blank'
              rel='noopener noreferrer'
              href={optIn.extraData.shoutoutPreview?.images[i] || ''}>
              {optIn.extraData.shoutoutPreview?.images[i]}
            </a>
          </div>
        )
      } else {
        element = (
          <div className='image'>
            <a
              target='_blank'
              rel='noopener noreferrer'
              href={optIn.extraData.shoutoutPreview?.images[i] || ''}>
              <img key={i} src={optIn.extraData.shoutoutPreview?.images[i]} alt='preview' />
            </a>
          </div>
        )
      }

      setCarousel(oldArr => [...oldArr, element])
    }
  }, [optIn])

  return (
    <Wrapper>
      <div className='user-info'>
        <CreatorAvatar
          size={50}
          id={optIn.user.id}
          url={profilePicUrl}
          initials={`
                  ${optIn.user.firstName?.charAt(0).toUpperCase()} ${optIn.user.lastName
                    ?.charAt(0)
                    .toUpperCase()}
                `}
        />
        <div className='username'>
          {optIn.user.firstName} {optIn.user.lastName}
        </div>
      </div>
      <Carousel className='carousel'>
        {carousel.map((item, i) => (
          <div className='carousel-item' key={i}>
            {item}
          </div>
        ))}
      </Carousel>
      <div className='caption'>
        {optIn.extraData.shoutoutPreview?.caption?.split('\n').map((item, index) => (
          <p key={index}>{item}</p>
        ))}
      </div>
      <Button icon={<DownloadOutlined />} type='primary' onClick={() => download()}>
        Download Assets
      </Button>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  padding: 40px;
  .user-info {
    display: flex;
    align-items: center;
    gap: 12px;
    margin-bottom: 20px;
  }
  .username {
    font-family: 'Gilroy-ExtraBold', sans-serif;
    font-size: 18px;
  }
  .carousel {
    background-color: #f7f7f7;
    border-radius: 10px;
    .image {
      min-height: 300px;
      max-height: 600px;
      overflow: hidden;
      border-radius: 10px;
      display: grid;
      place-content: center;
      img {
        max-width: 100%;
      }
    }
    .slick-dots {
      padding: 6px;
      background-color: #00000026;
      border-radius: 5px;
      & > li {
        height: auto;
        width: 15px;
        margin-right: 8px;
        margin-left: 8px;
        &.slick-active {
          width: 30px;
        }
        button {
          height: 15px;
          border-radius: 12px;
        }
      }
    }
  }
  .caption {
    margin: 20px 0;
  }
`

export default ShoutoutPreview
