import React from 'react'
import { HorizontalBar as Bar, defaults } from 'react-chartjs-2'
import 'chartjs-plugin-datalabels'

defaults.global.defaultFontFamily = 'Campton-Light'

const HorizontalBar = ({ data, title, initial, total, longLabel }) => {
  return (
    <Bar
      data={data}
      options={{
        maintainAspectRatio: false,
        responsive: true,
        title: {
          display: title ? true : false,
          text: title,
          fontSize: 13,
        },
        layout: {
          padding: {
            left: longLabel ? 80 : 60,
            right: 40,
          },
        },
        tooltips: {
          callbacks: {
            label: function (tooltipItem, data) {
              var index = tooltipItem.index
              var currentValue = data.datasets[tooltipItem.datasetIndex].data[index]
              return Math.round(total * (currentValue / 100))
            },
          },
        },
        legend: {
          display: false,
        },
        scales: {
          yAxes: [
            {
              ticks: {
                mirror: true,
                padding: longLabel ? 80 : 60,
              },
              gridLines: {
                display: false,
              },
            },
          ],
          xAxes: [
            {
              offset: true,
              ticks: {
                display: false,
              },
              gridLines: {
                display: false,
              },
            },
          ],
        },
        plugins: {
          datalabels: {
            display: true,
            clamp: true,
            anchor: 'end',
            align: 'end',
            color: '#027DF0',

            formatter: function (value) {
              return value + '%'
            },
          },
        },
        animation: {
          duration: initial.current ? 2000 : 0,
          onProgress: function () {
            initial.current = false
          },
        },
      }}
    />
  )
}

export default HorizontalBar
