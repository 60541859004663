import {
  CopyOutlined,
  DeleteOutlined,
  EditOutlined,
  EyeInvisibleOutlined,
  EyeOutlined,
  InfoCircleOutlined,
  MenuOutlined,
  PlusOutlined,
  SaveOutlined,
} from '@ant-design/icons'
import { Button, Tooltip, Select as AntSelect, Switch, message, Modal, Alert } from 'antd'
import { Formik, Form } from 'formik'
import { Input } from 'formik-antd'
import { useFlags } from 'launchdarkly-react-client-sdk'
import React, { useState } from 'react'
import { queryCache } from 'react-query'
import * as yup from 'yup'

import { BrandIdFilter } from './filters/BrandIdFilter'
import { CampaignIdFilter } from './filters/CampaignIdFilter'
import { CampaignStatusFilter } from './filters/CampaignStatusFilter'
import { DateRangeFilter } from './filters/DateRangeFilter'
import { ManualDateRangeFilter } from './filters/ManualDateRangeFilter'
import { SocialChannelFilter } from './filters/SocialChannelFilter'
import { useCampaignOptions } from './utils'
import { SettingsWrapper } from './WidgetCard'
import { widgetComponents } from './widgetComponents'
import FormItem from '../../components/forms/FormItem'
import { DraggableList } from '../../components/general/DraggableList'
import { useAnalyticsApi } from '../../custom-hooks/analyticsApi'

export const ManageView = ({
  views,
  activeView,
  setActiveView,
  widgets,
  setWidgets,
  brandData,
  handleDuplicateView,
  duplicatingView,
  deletingView,
  handleDeleteView,
}) => {
  const flags = useFlags()
  const [newWidget, setNewWidget] = useState(undefined)
  const [addingWidget, setAddingWidget] = useState(false)
  const [confirmDeleteView, setConfirmDeleteView] = useState(false)
  const analyticsApi = useAnalyticsApi()

  const campaignOptions = useCampaignOptions(brandData)

  const widgetOptions = widgetComponents.map(widget => ({
    label: widget.defaultTitle,
    value: widget.componentId,
  }))

  // remove GoogleAnalyticsSalesVsPosts from widgetOptions if access not enabled through LD
  if (!flags['googleAnalyticsWidget']) {
    widgetOptions.splice(
      widgetOptions.findIndex(widget => widget.value === 'GoogleAnalyticsSalesVsPosts'),
      1
    )
  }

  const initialValues = {
    title: activeView.title,
    brandIds: activeView.filters?.brandIds || [],
    campaignIds: activeView.filters?.campaignIds || [],
    campaignStatuses: activeView.filters?.campaignStatuses || [],
    socialChannels: activeView.filters?.socialChannels || [],
    fromDate: activeView.filters?.fromDate,
    toDate: activeView.filters?.toDate,
    dateRange: activeView.filters?.dateRange || 'allTime',
  }

  const validationSchema = yup.object().shape({
    title: yup.string().required('Required').max(50, 'Must be 50 characters or less'),
    brandIds: yup.array(),
    campaignIds: yup.array(),
    campaignStatuses: yup.array(),
    socialChannels: yup.array(),
    dateRange: yup.string(),
  })

  const handleUpdateView = async values => {
    const filters = {
      brandIds: values.brandIds,
      campaignIds: values.campaignIds,
      campaignStatuses: values.campaignStatuses,
      socialChannels: values.socialChannels,
      fromDate: values.fromDate,
      toDate: values.toDate,
      dateRange: values.dateRange,
    }

    const data = {
      title: values.title,
      filters,
    }

    analyticsApi.view
      .viewUpdate(brandData.id, activeView.id, {
        title: data.title,
        filters: data.filters,
      })
      .then(res => {
        message.success('View updated')
        queryCache.invalidateQueries(['analytics-views', brandData.id])

        // update frontend state to reflect changes to view
        setActiveView({
          widgets: activeView.widgets,
          ...res,
        })

        // if filters changed, reset widgets & refetch data
        if (JSON.stringify(activeView.filters) !== JSON.stringify(filters)) {
          queryCache.invalidateQueries(['analytics-view-data', activeView.id])

          setWidgets(
            activeView.widgets.map(widget => {
              const component = widgetComponents.find(
                ({ componentId }) => componentId === widget.componentId
              )
              return {
                ...component,
                ...widget,
              }
            })
          )
        }
      })
      .catch(() => {
        message.error('Error updating view')
      })
  }

  // updates widget visibility in backend
  const handleUpdateWidget = async (widgetId, values) => {
    // deep clone obj (so we dont change source)
    const widgetData = JSON.parse(JSON.stringify(values))
    if (widgetData.defaultFilters) delete widgetData.defaultFilters
    analyticsApi.widget
      .widgetUpdate(brandData.id, widgetId, {
        title: widgetData.title,
        filters: widgetData.filters,
        visible: widgetData.visible,
      })
      .then(res => {
        queryCache.invalidateQueries(['analytics-views', brandData.id])
        queryCache.invalidateQueries(['analytics-view-data', activeView.id])
        // update frontend state to reflect changes to widget visibility
        setActiveView({
          ...activeView,
          widgets: widgets.map(widget => (widget.id === widgetId ? res : widget)),
        })
      })
      .catch(() => {
        message.error('Error updating widget')
      })
  }

  const handleAddWidget = async () => {
    if (!newWidget || widgets.length >= 30) return
    setAddingWidget(true)

    const data = {
      defaultTitle: widgetComponents.find(widget => widget.componentId === newWidget).defaultTitle,
      componentId: newWidget,
      filters: activeView.filters,
      viewId: activeView.id,
    }

    analyticsApi.widget
      .widgetNewCreate(brandData.id, {
        defaultTitle: data.defaultTitle,
        componentId: data.componentId,
        viewId: data.viewId,
      })
      .then(res => {
        setNewWidget(undefined)
        queryCache.invalidateQueries(['analytics-views', brandData.id])
        queryCache.invalidateQueries(['analytics-view-data', activeView.id])

        // update frontend state to reflect new widget being added
        setActiveView({
          ...activeView,
          widgets: [...activeView.widgets, res],
        })

        const newWidget = widgetComponents.find(widget => widget.componentId === res.componentId)

        setWidgets([
          ...widgets,
          {
            ...newWidget,
            ...res,
          },
        ])
      })
      .catch(() => {
        message.error('Error adding widget')
      })
      .finally(() => setAddingWidget(false))
  }

  // drag/drop functionality for widgets
  const onDragEnd = async result => {
    if (!result.destination) return

    const widget = widgets[result.source.index]
    if (!widget) return

    await analyticsApi.widget.widgetMoveUpdate(widget.id, brandData.id, {
      destination: result.destination.index + 1,
    })

    // reorder widgets
    const reorderedItems = Array.from(widgets)
    const [removed] = reorderedItems.splice(result.source.index, 1)
    reorderedItems.splice(result.destination.index, 0, removed)

    setWidgets(reorderedItems)

    // update frontend state to reflect changes to widget orders
    setActiveView({
      ...activeView,
      widgets: reorderedItems,
    })

    queryCache.invalidateQueries(['analytics-views', brandData.id])
  }

  const getDragItemStyle = (isDragging, draggableStyle) => ({
    userSelect: 'none',
    borderRadius: '5px',
    background: isDragging ? '#eee' : '#f6f6f6',
    boxShadow: isDragging ? '3px 3px 10px rgba(0,0,0,0.15)' : 'none',
    transition: '0.2s ease-in-out',
    ...draggableStyle,
  })
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleUpdateView}>
      {({ values, setValues, setFieldValue, submitForm, isSubmitting }) => (
        <Form>
          <SettingsWrapper>
            <FormItem size='small' label='Title' name='title'>
              <Input name='title' maxLength={50} minLength={1} prefix={<EditOutlined />} />
            </FormItem>

            <div className='section-header'>
              <div className='title-wrapper'>
                <h3>Filters</h3>
                <Tooltip title='These filters will be applied to all widgets in this view, where applicable. You can override these filters for individual widgets in their settings.'>
                  <InfoCircleOutlined />
                </Tooltip>
              </div>
            </div>

            {!!brandData.childBrands?.length && <BrandIdFilter brandData={brandData} />}
            <CampaignIdFilter campaignOptions={campaignOptions} />
            <CampaignStatusFilter />
            <SocialChannelFilter />

            <div className='date-range-wrapper'>
              <ManualDateRangeFilter setFieldValue={setFieldValue} />
              <DateRangeFilter setFieldValue={setFieldValue} />
            </div>

            <div className='buttons'>
              <Button
                type='primary'
                onClick={submitForm}
                loading={isSubmitting}
                icon={<SaveOutlined />}
                disabled={JSON.stringify(values) === JSON.stringify(initialValues)}>
                Save
              </Button>
              <Button
                type='secondary'
                disabled={JSON.stringify(values) === JSON.stringify(initialValues)}
                onClick={() => {
                  setValues(initialValues)
                }}>
                Reset
              </Button>
            </div>

            <div className='border' />

            <div className='section-header'>
              <div className='title-wrapper'>
                <h3>Widgets</h3>
                <Tooltip title='Toggle visibility of widgets in this view. You can also drag and drop to change the order of your widgets.'>
                  <InfoCircleOutlined />
                </Tooltip>
              </div>
              <p className='item-count'>{widgets.length} / 30</p>
            </div>

            <DraggableList
              items={widgets.map(widget => ({
                ...widget,
                content: (
                  <div className='toggle-wrapper' key={widget.id}>
                    <p className='toggle-label'>{widget.title || widget.defaultTitle}</p>
                    <div className='actions'>
                      <Switch
                        name={`widget-${widget.id}`}
                        defaultChecked={widget.visible}
                        onChange={() => {
                          handleUpdateWidget(widget.id, { ...widget, visible: !widget.visible })
                          setFieldValue(`widget-${widget.id}`, !widget.visible)
                        }}
                        checkedChildren={
                          <div className='toggle-icon'>
                            <EyeOutlined />
                          </div>
                        }
                        unCheckedChildren={
                          <div className='toggle-icon'>
                            <EyeInvisibleOutlined />
                          </div>
                        }
                      />
                      <div className='drag-icon'>
                        <MenuOutlined />
                      </div>
                    </div>
                  </div>
                ),
              }))}
              onDragEnd={onDragEnd}
              getDragItemStyle={getDragItemStyle}
            />

            {widgets.length >= 30 && (
              <Alert type='warning' message='Max 30 widgets in view.' showIcon />
            )}

            <div className='add-widget-wrapper'>
              <AntSelect
                getPopupContainer={trigger => trigger.parentNode}
                placeholder='New Widget'
                style={{ width: '100%' }}
                allowClear
                options={widgetOptions}
                value={newWidget}
                onChange={e => {
                  setNewWidget(e)
                }}
              />
              <Button
                type='link'
                icon={<PlusOutlined />}
                onClick={handleAddWidget}
                // loading={addingWidget} // FIXME: loading isn't smooth (probably due to fetching new data)
                disabled={!newWidget || addingWidget || widgets.length >= 30}>
                Add
              </Button>
            </div>

            <div className='border' />

            <div className='misc-actions'>
              <Button
                type='link'
                onClick={handleDuplicateView}
                icon={<CopyOutlined />}
                loading={duplicatingView}>
                Duplicate View
              </Button>
              <Button
                type='link'
                danger
                onClick={() => setConfirmDeleteView(true)}
                icon={<DeleteOutlined />}
                disabled={views.length === 1}>
                Delete View
              </Button>
            </div>

            <Modal
              title='Delete View'
              open={confirmDeleteView}
              okText='Delete'
              okButtonProps={{ danger: true }}
              confirmLoading={deletingView}
              onOk={handleDeleteView}
              onCancel={() => setConfirmDeleteView(false)}>
              <p>
                Are you sure you want to delete this view? All widgets within this view will also be
                deleted.
              </p>
            </Modal>
          </SettingsWrapper>
        </Form>
      )}
    </Formik>
  )
}
