import { Result, Button } from 'antd'
import React from 'react'
import { Link } from 'react-router-dom'

// the fallback UI which renders when an error is caught
export const ErrorFallBack = () => {
  return (
    <div className='dashboard-result'>
      <Result
        status='500'
        title='An Error Occured'
        subTitle='Sorry, something went wrong. Please refresh the page or try again later.'
        extra={
          <>
            <Button type='primary' onClick={() => window.location.reload(false)}>
              <Link to='/'>Go Home</Link>
            </Button>
            <Button type='secondary' onClick={() => window.location.reload(false)}>
              Refresh
            </Button>
          </>
        }
      />
    </div>
  )
}
