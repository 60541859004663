import { Button, message } from 'antd'
import React from 'react'
import styled from 'styled-components'

const ShopifyInstall = () => {
  //fetch the url params
  const urlParams = new URLSearchParams(window.location.search)
  const shop = urlParams.get('shop')
  const hmac = urlParams.get('hmac')
  const host = urlParams.get('host')

  const handleOAuthAndRedirect = () => {
    if (!shop || !hmac || !host) {
      message.error('Invalid Shopify store URL')
      return
    }
    localStorage.setItem('shop', shop)
    localStorage.setItem('hmac', hmac)
    localStorage.setItem('host', host)
    window.location.replace('/')
  }

  return (
    <Wrapper>
      <div className='container'>
        <h1 className='heading'>Connect Your Shopify Store to Creator.co</h1>
        <div className='description'>
          <p>
            Let&apos;s link your Shopify store, <b>{shop}</b>, to Creator.co for seamless
            collaborations and order management.
          </p>

          <ul>
            <li>Directly place orders to ship products to creators.</li>
            <li>Streamline your order fulfillment process for efficient delivery.</li>
            <li>Monitor your store&apos;s products and inventory on our dashboard.</li>
          </ul>

          <div className='body'>
            <h2>Start in Just Two Steps:</h2>
            <ol>
              <li>
                Click &quot;Get Started&quot; below to authorize the Creator.co app on your Shopify
                store.
              </li>
              <li>
                You&apos;ll then be redirected to your brand dashboard, where you can complete the
                store connection.
              </li>
            </ol>
          </div>

          <Button type='primary' onClick={handleOAuthAndRedirect}>
            Get Started
          </Button>
        </div>
      </div>
    </Wrapper>
  )
}

export default ShopifyInstall

const Wrapper = styled.div`
  padding: 20px;
  display: grid;
  place-content: center;
  flex: 1;
  .container {
    background: #fff;
    color: #23262f;
    font-size: 16px;
    box-shadow: 4px 8px 20px 4px rgba(154, 175, 193, 0.12);
    border-radius: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 40px 20px;
    margin: auto;
    max-width: 400px;
  }

  h1 {
    font-family: 'Gilroy-ExtraBold', sans-serif;
    font-size: 24px;
  }

  h2 {
    font-size: 18px;
    margin-top: 30px;
    font-weight: bold;
  }

  ul,
  ol {
    color: #777e90;
    text-align: left;
    font-size: 0.9rem;
  }
`
