import { CheckCircleOutlined, SettingOutlined } from '@ant-design/icons'
import { Button, Empty, Tag } from 'antd'
import React, { useContext } from 'react'
import { useQuery } from 'react-query'
import { useHistory } from 'react-router'
import styled from 'styled-components'

import { BrandContext } from '../../contexts/BrandContext'

export const BrandSubscription = () => {
  const { brandId, fetchBrandData, openBilling } = useContext(BrandContext)
  const { data: brandData } = useQuery(['brand', brandId], fetchBrandData)
  const history = useHistory()

  return (
    <Wrapper className='account-section' id='subscription'>
      <div className='section-header'>
        <h2 className='section-title'>
          Subscription{brandData.activeSubscriptions.length > 1 && 's'}
        </h2>
        <div className='buttons'>
          <Button
            type={
              brandData.activeSubscriptions.length || !!brandData.allSubscriptions.length
                ? 'secondary'
                : 'primary'
            }
            onClick={() => history.push('/pricing')}>
            View Plans
          </Button>
          {!!brandData.allSubscriptions.length && (
            <Button onClick={openBilling} icon={<SettingOutlined />} type='primary'>
              Billing
            </Button>
          )}
        </div>
      </div>

      <div className='section-body'>
        {brandData.activeSubscriptions?.length > 0 ? (
          brandData.activeSubscriptions.map((plan, i) => (
            <div key={i}>
              <Tag icon={<CheckCircleOutlined />} color='success'>
                Active
              </Tag>
              {plan === 'self-serve'
                ? 'Self-Serve'
                : plan === 'start-up'
                  ? 'Start-Up'
                  : plan === 'trailblazer'
                    ? 'Trailblazer'
                    : 'Bespoke'}
            </div>
          ))
        ) : (
          <div>
            <Empty description='No active subscriptions.' image={Empty.PRESENTED_IMAGE_SIMPLE} />
          </div>
        )}
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  h3 {
    font-family: 'Campton-Medium', sans-serif;
    font-weight: bold;
    font-size: 18px;
  }
  .invoices {
    td {
      padding: 12px 12px 0 0;
      border-top: 1px solid lightgray;
    }
  }
`
