import { PlusOutlined, MinusCircleOutlined } from '@ant-design/icons'
import { Button, Tabs, Input as AntInput, Select as AntSelect, Radio as AntRadio } from 'antd'
import { Formik, Form } from 'formik'
import { Input, Radio, Select } from 'formik-antd'
import React from 'react'
import uuid from 'react-uuid'
import styled from 'styled-components'

import FormItem from '../../components/forms/FormItem'
const { Option } = Select

const DynamicFormInput = ({ inputType, values, setFieldValue, input }) => {
  const handleSelectChange = e => {
    setFieldValue('customForm', [
      ...values.customForm.map(value =>
        value.uuid !== input.uuid
          ? value
          : {
              ...input,
              selectInputType: e.target.value,
            }
      ),
    ])
  }

  let selectOptions = []
  const handleOptionsChange = event => {
    selectOptions = event
    setFieldValue('customForm', [
      ...values.customForm.map(value =>
        value.uuid !== input.uuid
          ? value
          : {
              ...input,
              selectOptions,
            }
      ),
    ])
  }

  if (inputType === 'text' || !inputType) {
    return <AntInput placeholder='The actionee will type a short response here.' disabled />
  } else if (inputType === 'textArea') {
    return <AntInput.TextArea placeholder='The actionee will type a long response here.' disabled />
  } else if (inputType === 'select') {
    return (
      <>
        <AntRadio.Group
          onChange={handleSelectChange}
          optionType='button'
          name='optionsRadio'
          defaultValue={input.selectInputType || 'singleSelect'}>
          <Radio name='optionsRadio' value='singleSelect'>
            Single Select
          </Radio>
          <Radio name='optionsRadio' value='multiSelect'>
            Multi-Select
          </Radio>
        </AntRadio.Group>
        <AntSelect
          mode='tags'
          placeholder='Input Options'
          options={selectOptions}
          onChange={handleOptionsChange}
          value={input.selectOptions}
          allowClear
          notFoundContent='No options added yet.'
        />
      </>
    )
  }
}

const AdvancedSettings = ({ values, setFieldValue }) => {
  const addInput = () => {
    setFieldValue('customForm', [
      ...(values?.customForm || []),
      {
        uuid: uuid(),
        label: '',
        inputType: 'text',
      },
    ])
  }

  const deleteInput = input => {
    setFieldValue(
      'customForm',
      values.customForm.filter(value => value.uuid !== input.uuid)
    )
  }

  return (
    <div>
      {values?.customForm?.map((input, key) => (
        <div className='form-box' key={key}>
          <div className='form-inputs'>
            <AntInput
              type='text'
              placeholder='Input Label'
              value={input?.label}
              className='label'
              onChange={e => {
                setFieldValue('customForm', [
                  ...values.customForm.map(value =>
                    value.uuid !== input.uuid
                      ? value
                      : {
                          ...input,
                          label: e.target.value,
                        }
                  ),
                ])
              }}
            />
            <AntSelect
              value={input?.inputType || 'text'}
              onChange={e => {
                setFieldValue('customForm', [
                  ...values.customForm.map(value =>
                    value.uuid !== input.uuid
                      ? value
                      : {
                          ...input,
                          inputType: e,
                        }
                  ),
                ])
              }}
              style={{ width: '50%' }}>
              <Option value='text'>Single Line</Option>
              <Option value='textArea'>Multi-Line</Option>
              <Option value='select'>Selection</Option>
            </AntSelect>
            <Button
              type='link'
              onClick={() => deleteInput(input)}
              icon={<MinusCircleOutlined />}
              danger
            />
          </div>
          <DynamicFormInput
            inputType={input.inputType}
            values={values}
            setFieldValue={setFieldValue}
            input={input}
          />
        </div>
      ))}

      <Button type='link' onClick={addInput} style={{ marginBottom: '15px' }}>
        <PlusOutlined />
        Custom Input
      </Button>
    </div>
  )
}

const BasicInfo = ({ values }) => {
  return (
    <>
      <div className='header'>
        <h2>Basic Info</h2>
      </div>
      <FormItem label='Title' info='Label visible on the dashboard for brands and creators.'>
        <Input required name='customStepTitle' />
      </FormItem>
      <FormItem label='Actionee' info='Identifies the party responsible for completing this step.'>
        <Radio name='actionee' value='creator' checked={values.actionee === 'creator'}>
          Creator
        </Radio>
        <Radio name='actionee' value='brand' checked={values.actionee === 'brand'}>
          Brand
        </Radio>
      </FormItem>
      <FormItem
        label='Instructions'
        info='Instructions for the responsible party on how to complete this step.'>
        <Input.TextArea required name='instructions' />
      </FormItem>

      <h2>Statuses</h2>
      <h3>Incomplete</h3>
      <FormItem
        label='Brand'
        info='Status text to display to the brand BEFORE the step has been completed.'>
        <Input required name='brandStatus' />
      </FormItem>
      <FormItem
        label='Creator'
        info='Status text to display to the creator BEFORE the step has been completed.'>
        <Input required name='influencerStatus' />
      </FormItem>

      <h3>Complete</h3>
      <FormItem
        label='Brand'
        info='Status text to display to the brand AFTER the step has been completed.'>
        <Input required name='brandCompletedStatus' />
      </FormItem>
      <FormItem
        label='Creator'
        info='Status text to display to the creator AFTER the step has been completed.'>
        <Input required name='influencerCompletedStatus' />
      </FormItem>
    </>
  )
}

export const CreateEditStep = ({
  handleCreateSubmit,
  handleEditSubmit,
  setCopyCustomStepOpen,
  copyCustomStepOpen,
  step,
}) => {
  return (
    <Wrapper>
      <Formik
        onSubmit={values => {
          step?.id ? handleEditSubmit(values, step?.id) : handleCreateSubmit(values)
        }}
        initialValues={{
          // Basic Info
          customStepTitle: step?.customStepTitle,
          actionee: step?.actionee,
          instructions: step?.instructions,
          brandStatus: step?.stepSettings?.brandStatus,
          influencerStatus: step?.stepSettings?.influencerStatus,
          brandCompletedStatus: step?.stepSettings?.brandCompletedStatus,
          influencerCompletedStatus: step?.stepSettings?.influencerCompletedStatus,
          // Advanced Settings
          customForm: step?.customForm,
        }}>
        {({ isSubmitting, values, setValues, setFieldValue }) => (
          <Form className='custom-step'>
            <Tabs
              items={[
                {
                  label: 'Details',
                  key: 'details',
                  children: (
                    <BasicInfo
                      setCopyCustomStepOpen={setCopyCustomStepOpen}
                      copyCustomStepOpen={copyCustomStepOpen}
                      step={step}
                      values={values}
                      setValues={setValues}
                      isSubmitting={isSubmitting}
                    />
                  ),
                },
                {
                  label: 'Advanced Settings',
                  key: 'advanced settings',
                  children: (
                    <AdvancedSettings
                      values={values}
                      setValues={setValues}
                      setFieldValue={setFieldValue}
                      step={step}
                    />
                  ),
                },
              ]}
            />
            <Button loading={isSubmitting} htmlType='submit' type='primary'>
              Save
            </Button>
          </Form>
        )}
      </Formik>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  padding: 12px;
  .check-item {
    display: block;
    cursor: pointer;
  }
  .header {
    display: flex;
    justify-content: space-between;
  }
  .form-box {
    background-color: #f6f6f6;
    border-radius: 5px;
    padding: 10px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    .form-inputs {
      display: flex;
      width: 100%;
      gap: 10px;
    }
  }
`
