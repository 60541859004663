import { Formik, Form } from 'formik'
import { InputNumber } from 'formik-antd'
import React from 'react'
import * as yup from 'yup'

import FormItem from '../../components/forms/FormItem'

export const CampaignMetrics = ({ formData, setFormData, formRef, sectionRef, submitCampaign }) => {
  const schema = yup.object().shape({
    likeValue: yup.number().required('Required'),
    commentValue: yup.number().required('Required'),
    viewValue: yup.number().required('Required'),
    saveValue: yup.number().required('Required'),
    shareValue: yup.number().required('Required'),
    reachValue: yup.number().required('Required'),
    impressionValue: yup.number().required('Required'),
  })

  const initialValues = {
    likeValue: formData.likeValue,
    commentValue: formData.commentValue,
    viewValue: formData.viewValue,
    saveValue: formData.saveValue,
    shareValue: formData.shareValue,
    reachValue: formData.reachValue,
    impressionValue: formData.impressionValue,
  }

  return (
    <section ref={sectionRef}>
      <Formik
        innerRef={formRef}
        initialValues={initialValues}
        validationSchema={schema}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={() => {}}>
        {() => (
          <Form>
            <h2 className='header'>Metrics</h2>
            <h3>ROI Tracking</h3>
            <p className='header-subtext'>
              In order to track the ROI of your campaign, please confirm the values you place on any
              engagement generated by your creators. For your convenience, we have prefilled values
              based on industry standards.
            </p>
            <FormItem name='likeValue' label='Value per like'>
              <InputNumber
                name='likeValue'
                style={{ width: '150px' }}
                placeholder='$0'
                formatter={value =>
                  value > 0 ? `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',') : ''
                }
                onChange={e => {
                  setFormData({ ...formData, likeValue: e })
                  submitCampaign(false)
                }}
              />
            </FormItem>
            <FormItem name='commentValue' label='Value per comment'>
              <InputNumber
                name='commentValue'
                style={{ width: '150px' }}
                placeholder='$0'
                formatter={value =>
                  value > 0 ? `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',') : ''
                }
                onChange={e => {
                  setFormData({ ...formData, commentValue: e })
                  submitCampaign(false)
                }}
              />
            </FormItem>
            <FormItem name='viewValue' label='Value per view'>
              <InputNumber
                name='viewValue'
                style={{ width: '150px' }}
                placeholder='$0'
                formatter={value =>
                  value > 0 ? `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',') : ''
                }
                onChange={e => {
                  setFormData({ ...formData, viewValue: e })
                  submitCampaign(false)
                }}
              />
            </FormItem>
            <FormItem name='saveValue' label='Value per save'>
              <InputNumber
                name='saveValue'
                style={{ width: '150px' }}
                placeholder='$0'
                formatter={value =>
                  value > 0 ? `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',') : ''
                }
                onChange={e => {
                  setFormData({ ...formData, saveValue: e })
                  submitCampaign(false)
                }}
              />
            </FormItem>
            <FormItem name='shareValue' label='Value per share'>
              <InputNumber
                name='shareValue'
                style={{ width: '150px' }}
                placeholder='$0'
                formatter={value =>
                  value > 0 ? `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',') : ''
                }
                onChange={e => {
                  setFormData({ ...formData, shareValue: e })
                  submitCampaign(false)
                }}
              />
            </FormItem>
            <FormItem name='reachValue' label='Value per reach'>
              <InputNumber
                name='reachValue'
                style={{ width: '150px' }}
                placeholder='$0'
                formatter={value =>
                  value > 0 ? `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',') : ''
                }
                onChange={e => {
                  setFormData({ ...formData, reachValue: e })
                  submitCampaign(false)
                }}
              />
            </FormItem>
            <FormItem name='impressionValue' label='Value per impression'>
              <InputNumber
                name='impressionValue'
                style={{ width: '150px' }}
                placeholder='$0'
                formatter={value =>
                  value > 0 ? `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',') : ''
                }
                onChange={e => {
                  setFormData({ ...formData, impressionValue: e })
                  submitCampaign(false)
                }}
              />
            </FormItem>
          </Form>
        )}
      </Formik>
    </section>
  )
}
