import { LoadingOutlined, DeleteOutlined } from '@ant-design/icons'
import React, { useContext, useState, useRef, useEffect } from 'react'
import { useInfiniteQuery } from 'react-query'
import styled from 'styled-components/macro'

import { ArchivedCreator } from './ArchivedCreator'
import { BrandContext } from '../../../contexts/BrandContext'

export const ArchivedCreators = () => {
  const { brandId, fetchArchivedCreators } = useContext(BrandContext)
  const [expanded, setExpanded] = useState(false)
  const scrollRef = useRef()
  const [totalArchived, setTotalArchived] = useState(0)

  const { data, isFetching, fetchMore, canFetchMore } = useInfiniteQuery(
    ['archived-creators', brandId],
    fetchArchivedCreators,
    {
      getFetchMore: lastGroup => lastGroup.nextCursor,
    }
  )

  useEffect(() => {
    if (data) {
      setTotalArchived(data[0].totalResults)
    }
  }, [data])

  const handleScroll = () => {
    const scrollDistance = scrollRef.current.scrollTop
    const outerHeight = scrollRef.current.offsetHeight
    const innerHeight = scrollRef.current.scrollHeight
    const actualDistance = innerHeight - (scrollDistance + outerHeight)
    if (actualDistance < 100 && canFetchMore && !isFetching) {
      fetchMore()
    }
  }

  return (
    <Wrapper>
      <div
        className={`list-details ${totalArchived === 0 && 'disabled'}`}
        onClick={() => setExpanded(!expanded)}
        onKeyDown={() => setExpanded(!expanded)}
        role='button'
        tabIndex={0}>
        <p className='title'>
          <DeleteOutlined />
          Archived Creators
        </p>
        <span className='num'>({totalArchived})</span>
      </div>

      {totalArchived > 0 && (
        <div
          className={`creators ${expanded && 'expanded'}`}
          onScroll={handleScroll}
          ref={scrollRef}>
          {data.map(group =>
            group.archivedCreators.map(creator => (
              <ArchivedCreator creator={creator} key={creator.id} />
            ))
          )}
          {isFetching && (
            <div className='loading'>
              <LoadingOutlined spin />
            </div>
          )}
        </div>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  border: 1px solid #e6e6e6;
  border-radius: 5px;
  overflow: hidden;
  .list-details {
    background: #fff;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    padding: 10px;
    z-index: 1;
    transition: 0.2s ease-in-out;
    cursor: pointer;
    &:hover {
      background: #f6f6f6;
    }
    &.disabled {
      pointer-events: none;
      color: #999;
      .title .arrow {
        color: #ccc;
      }
    }
    .title {
      margin: 0;
      .anticon {
        margin-right: 8px;
        transition: 0.2s ease-in-out;
      }
    }
  }
  .creators {
    background: #f6f6f6;
    max-height: 0;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 0 20px;
    border-radius: 0 0 5px 5px;
    transition: 0.3s ease-in-out;
    &.expanded {
      max-height: 600px;
      overflow: auto;
      padding: 20px;
      ${props => props.theme.scrollbar}
    }
    .loading {
      display: grid;
      place-content: center;
    }
  }
  .label {
    margin: 0;
    font-family: 'Campton-Light', Verdana, sans-serif;
    font-size: 0.8rem;
  }
`
