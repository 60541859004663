import { Tooltip } from 'antd'
import React from 'react'
import { PieChart } from 'react-minimal-pie-chart'
import styled from 'styled-components/macro'

export const PieChartContainer = ({ data }) => {
  // remove any data with value of 0 to prevent rendering in pie chart
  const filteredData = data.filter(item => item.value > 0)

  return (
    <Wrapper>
      {!!filteredData?.length && (
        <div className='pie'>
          <PieChart
            data={filteredData}
            startAngle={-90}
            lineWidth={20}
            paddingAngle={18}
            rounded
            // FIXME: issue with react renders causing this to animate multiple times
            // animate
            label={({ dataEntry }) => `${dataEntry.value?.toFixed(0) || 0}%`}
            labelStyle={index => ({
              fill: filteredData[index].color,
              fontSize: '7px',
              fontFamily: 'Campton-Medium',
            })}
            labelPosition={60}
          />
        </div>
      )}

      <div className='legend'>
        {data.map((item, i) => {
          // capitalize title
          const title = item.title.charAt(0).toUpperCase() + item.title.slice(1)

          return (
            <Tooltip
              key={i}
              placement='left'
              title={`${title}: ${item.value}% (${item.count.toLocaleString()} total)`}>
              <div className='legend-item'>
                <div className='color' style={{ backgroundColor: item.color }}></div>
                <div className='label'>
                  {title} ({item.value?.toFixed(0) || 0}%)
                </div>
              </div>
            </Tooltip>
          )
        })}
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 40px;

  .pie {
    width: 200px;
    height: 200px;
  }

  .legend {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .legend-item {
      display: flex;
      align-items: center;
      gap: 10px;
      cursor: default;

      .color {
        height: 15px;
        width: 15px;
        border-radius: 50%;
      }

      .label {
        white-space: nowrap;
        color: ${props => props.theme.crcoGrey};
        font-size: 14px;
      }
    }
  }
`
