import { Result, Tabs } from 'antd'
import React, { useContext, useState } from 'react'
import { useQuery } from 'react-query'
import styled from 'styled-components/macro'

import AffiliateCreatorsView from './AffiliateCreatorsView'
import AffiliateLinksView from './AffiliateLinksView'
import { BrandContext } from '../../contexts/BrandContext'
import { InfluencerContextProvider } from '../../contexts/InfluencerContext'
import { UserContext } from '../../contexts/UserContext'
import { useDocumentTitle } from '../../custom-hooks/useDocumentTitle'

const Affiliate = () => {
  const { role } = useContext(UserContext)
  const { brandId, fetchBrandData, fetchAffiliateAnalytics, fetchFilterAffiliates } =
    useContext(BrandContext)

  const [filters, setFilters] = useState({})

  const { data: brandData } = useQuery(['brand', brandId], fetchBrandData)

  const { data: affiliateAnalytics, status: affiliateAnalyticsStatus } = useQuery(
    ['affiliate-analytics', brandId, filters],
    fetchAffiliateAnalytics
  )

  const { data: affiliateFilters, status: affiliateFilterStatus } = useQuery(
    ['affiliate-filters', brandId],
    fetchFilterAffiliates
  )

  useDocumentTitle(
    role === 'administrator'
      ? brandData?.name
        ? `${brandData?.name} - Affiliate Metrics | Brand Dashboard`
        : 'Affiliate Metrics | Brand Dashboard'
      : 'My Affiliate Metrics | Brand Dashboard'
  )

  const affiliateViewsProps = {
    brandId,
    setFilters,
    affiliateFilters,
    affiliateFilterStatus,
    affiliateAnalytics,
    affiliateAnalyticsStatus,
  }

  return (
    <InfluencerContextProvider>
      <Wrapper>
        {brandData?.affiliateSubscription === true && (
          <>
            <div className='tab-header-wrapper'>
              <h1 className='header-text'>Affiliate Metrics</h1>
            </div>
            <Tabs
              items={[
                {
                  key: 'links-view',
                  label: 'Link View',
                  children: <AffiliateLinksView {...affiliateViewsProps} />,
                },
                {
                  key: 'creator-view',
                  label: 'Creator View',
                  children: <AffiliateCreatorsView {...affiliateViewsProps} />,
                },
              ]}
            />
          </>
        )}

        {!brandData?.affiliateSubscription && (
          <div className='dashboard-result'>
            <Result
              status='warning'
              title='Subscription Required'
              subTitle={
                <span>
                  In order to access the affiliate program, please subscribe to one of our{' '}
                  <a href='https://brands.creator.co/pricing'>subscription plans.</a>
                </span>
              }
            />
          </div>
        )}
      </Wrapper>
    </InfluencerContextProvider>
  )
}

const Wrapper = styled.div`
  background-color: #fff;

  .tab-header-wrapper {
    max-width: 1400px;
    width: 100%;
    margin: 0 auto;

    .header-text {
      font-weight: bold;
      background-color: #fff;
      border-radius: 5px;
      padding: 20px;
      font-size: 1.5rem;
      width: 100%;
      margin: 0;
    }
  }

  .ant-tabs-nav {
    margin: 0;
    border-bottom: 1px solid #dbdbee;
  }

  .ant-tabs-nav-wrap {
    max-width: 1400px;
    margin: 0 auto -1px auto;
  }

  .ant-tabs-tab {
    background: #dbdbee;
    color: ${props => props.theme.crcoMidnight};
    font-size: 16px;
    padding: 4px 18px;
    border-radius: 5px 5px 0 0;
    margin-left: 20px;
    cursor: pointer;
    transition: 0.2s ease-in-out !important;
    border: 1px solid transparent;
    border-bottom: none;

    &.ant-tabs-tab-active {
      background: #f4f4f8;
      border-color: #dbdbee;

      .ant-tabs-tab-btn {
        color: ${props => props.theme.crcoTechBlue};
      }
    }
  }

  .ant-tabs-content-holder {
    background-color: #f4f4f8;

    .ant-tabs-content {
      height: 100%;
    }

    .ant-tabs.tabpane {
      height: 100%;
    }
  }
`

export default Affiliate
