import { Empty } from 'antd'
import { Select } from 'formik-antd'
import React from 'react'
import { ComposedChart, Legend, Line, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import * as yup from 'yup'

import FormItem from '../../../components/forms/FormItem'
import { theme } from '../../../GlobalStyles'
import { BrandIdFilter } from '../filters/BrandIdFilter'
import { CampaignIdFilter } from '../filters/CampaignIdFilter'
import { DateRangeFilter } from '../filters/DateRangeFilter'
import { ManualDateRangeFilter } from '../filters/ManualDateRangeFilter'
import { ManageWidget } from '../ManageWidget'
import { useCampaignOptions } from '../utils'
import { MetricsWrapper, WidgetCard } from '../WidgetCard'

export const AffiliateGenericEvents = (program, { disableCampaignFilter } = {}) => {
  const component = ({ widget, activeView, setActiveView, brandData, data }) => {
    const campaignOptions = useCampaignOptions(brandData)

    const defaultValues = {
      title: widget.defaultTitle,
      ...widget.defaultFilters,
    }

    const initialValues = {
      title: widget.title || defaultValues.title,
      brandIds: widget.filters?.brandIds || defaultValues.brandIds,
      campaignIds: widget.filters?.campaignIds || defaultValues.campaignIds,
      fromDate: widget.filters?.fromDate || defaultValues.fromDate,
      toDate: widget.filters?.toDate || defaultValues.toDate,
      dateRange:
        widget.filters?.fromDate ||
        defaultValues.fromDate ||
        widget.filters?.toDate ||
        defaultValues.toDate
          ? 'custom'
          : widget.filters?.dateRange || defaultValues.dateRange,
      affiliateMetrics: widget.filters?.affiliateMetrics || defaultValues.affiliateMetrics,
    }

    const validationSchema = yup.object().shape({
      title: yup.string().required('Required').max(50, 'Must be 50 characters or less'),
      brandIds: yup.array(),
      campaignIds: yup.array(),
      dateRange: yup.string(),
      affiliateMetrics: yup.array(),
    })

    const constructData = values => {
      return {
        title: values.title,
        filters: {
          brandIds: values.brandIds,
          campaignIds: values.campaignIds,
          fromDate: values.fromDate,
          toDate: values.toDate,
          dateRange: values.dateRange,
          affiliateMetrics: values.affiliateMetrics,
        },
      }
    }

    const metricOptions = [
      { label: 'Click Count', value: 'ClickCount', color: '#027df0', yAxisId: 'left' },
      { label: 'Sales Count', value: 'SalesCount', color: '#c367dd', yAxisId: 'left' },
      { label: 'Sales Total', value: 'SalesTotal', color: '#ff53a2', yAxisId: 'right' },
      { label: 'Commission Total', value: 'CommissionTotal', color: '#ff705a', yAxisId: 'right' },
    ]

    const Filters = ({ setFieldValue }) => {
      return (
        <>
          {!!brandData.childBrands?.length && <BrandIdFilter brandData={brandData} />}
          {disableCampaignFilter ? null : <CampaignIdFilter campaignOptions={campaignOptions} />}

          <div className='date-range-wrapper'>
            <ManualDateRangeFilter setFieldValue={setFieldValue} />
            <DateRangeFilter setFieldValue={setFieldValue} />
          </div>

          <FormItem
            size='small'
            name='affiliateMetrics'
            label='Affiliate Metrics'
            subtext={`Select the metrics you'd like to compare`}>
            <Select
              getPopupContainer={trigger => trigger.parentNode}
              virtual={false}
              name='affiliateMetrics'
              placeholder='Select'
              mode='multiple'
              style={{ width: '100%' }}
              options={metricOptions}
            />
          </FormItem>
        </>
      )
    }

    const Metrics = () => {
      const formattedData = data?.map(dataPoint => {
        return {
          name: new Date(dataPoint.date).toLocaleDateString('en-CA', {
            day: '2-digit',
            month: 'short',
          }),
          SalesCount: Number(dataPoint.salesCount),
          SalesTotal: Number(dataPoint.salesTotal),
          CommissionTotal: Number(dataPoint.commissionTotal),
          ClickCount: Number(dataPoint.clickCount),
        }
      })

      const TrendLineChart = () => {
        return (
          <ResponsiveContainer width='100%' minWidth={600} height={400}>
            <ComposedChart data={formattedData}>
              <XAxis dataKey='name' stroke={theme.crcoGrey} />
              <YAxis
                yAxisId='left'
                orientation='left'
                stroke={theme.crcoLavender}
                label={{ value: 'Count', angle: -90, position: 'insideLeft' }}
              />
              <YAxis
                yAxisId='right'
                orientation='right'
                stroke={'#4D9CE6'}
                label={{ value: 'Total', angle: 90, position: 'insideRight' }}
              />
              <Tooltip
                cursor={false} // removes the cursor (vertical line that appears when you hover over the chart)
              />
              <Legend />
              {metricOptions
                .filter(
                  metric =>
                    widget.filters?.affiliateMetrics?.find(option => option === metric.value) ||
                    !widget.filters?.affiliateMetrics?.length
                )
                .map(metric => (
                  <Line
                    key={metric.label}
                    yAxisId={metric.yAxisId}
                    type='monotone'
                    name={metric.label}
                    dataKey={metric.value}
                    stroke={metric.color}
                    isAnimationActive={false}
                  />
                ))}
            </ComposedChart>
          </ResponsiveContainer>
        )
      }

      return (
        <MetricsWrapper className='overflow-auto'>
          {data?.length ? (
            <TrendLineChart />
          ) : (
            <div className='no-data'>
              <Empty description='No data.' />
            </div>
          )}
        </MetricsWrapper>
      )
    }

    const settingsProps = {
      widget,
      Filters,
      defaultValues,
      initialValues,
      validationSchema,
      constructData,
      activeView,
      setActiveView,
      brandId: brandData.id,
    }

    const cardProps = {
      widget,
      settings: <ManageWidget {...settingsProps} />,
      metrics: <Metrics />,
      size: 'large',
      brandData,
    }

    return <WidgetCard {...cardProps} />
  }

  component.displayName = `AffiliateGenericEvents-${program}`

  return component
}
