import { QuestionCircleOutlined } from '@ant-design/icons'
import { Tooltip } from 'antd'
import { Field, ErrorMessage } from 'formik'
import React from 'react'
import styled from 'styled-components/macro'

import { ErrorMessage as SASErrorMessage } from './ErrorMessage'

const FormItem = ({
  children,
  name,
  label,
  sublabel,
  subtext,
  width = '',
  size = '',
  info = '',
  validate,
}) => {
  return (
    <Wrapper className='creator-form-item' size={size} width={width}>
      <Field validate={validate} name={name}>
        {({ form }) => {
          const hasError = form.errors[name] && form.touched[name]
          return (
            <div>
              <label className='item-label' style={{ color: !hasError || 'red' }}>
                {info && (
                  <Tooltip title={info}>
                    <span className='info'>
                      <QuestionCircleOutlined />{' '}
                    </span>
                  </Tooltip>
                )}
                {label && <span>{label} </span>}
                {sublabel && <span className='sublabel'>{sublabel} </span>}
              </label>
              {subtext && <p className='subtext'>{subtext}</p>}
              {children}
            </div>
          )
        }}
      </Field>
      <div style={{ margin: '4px 0 12px' }}>
        {name && (
          <ErrorMessage name={name} component='div' render={msg => <SASErrorMessage msg={msg} />} />
        )}
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: ${props => props.width};
  margin-bottom: ${props => (props.size === 'small' ? '0px' : '32px')};
  .item-label {
    font-size: 0.9rem;
    display: block;
    text-align: left;
    margin: 0;
    .info {
      color: ${props => props.theme.crcoTechBlue};
    }
    .sublabel {
      color: #828282;
      font-weight: normal;
    }
    .required {
      color: red;
    }
  }
  .subtext {
    font-family: 'Campton-Light', Verdana, sans-serif;
    // TODO: update styling (need to check where this is used to ensure it looks good)
    // color: ${props => props.theme.crcoGrey};
  }
  .ant-input,
  .ant-input-number,
  .ant-select .ant-select-selector {
    color: black;
    &.ant-input-disabled,
    &.ant-input-number-disabled {
      color: gray;
      cursor: auto;
      resize: none;
    }
    &:focus {
      border-color: #5ebeff;
    }
  }
  .ant-input-number {
    display: block;
    width: 100%;
    max-width: 200px;
  }
  .ant-slider {
    margin: 12px 24px 40px;
    .ant-slider-track {
      background-color: ${props => props.theme.crcoLettuce};
    }
  }
`

export default FormItem
