import React from 'react'

import instagram from '../../images/icons/social/instagram-color-square.svg'
import { Wrapper } from '../creator-search/search/CreatorSearchGuide'

export const SocialListeningGuide = () => {
  return (
    <Wrapper>
      <section>
        <h2>What is it?</h2>
        <p>
          Social listening is a powerful tool that allows you to monitor social media channels for
          mentions of your brand, competitors, or product. It&apos;s a great way to get a pulse on
          what people are saying about your brand and industry, and to identify opportunities to
          engage with your audience.
        </p>
        <p>
          <img
            src={instagram}
            alt='Instagram'
            style={{ height: '20px', width: '20px', marginRight: '8px' }}
          />
          Please note, this feature is currently only available for <b>Instagram</b>.
        </p>
      </section>
      <section>
        <h2>How it Works</h2>
        <p>
          Once your campaign is published, a new list will automatically be created with the first
          hashtag set as a requirement on your campaign. We&apos;ll start listening for posts
          containing that hashtag in the background.
        </p>
        <p>
          Posts are fetched <b>every 30 minutes</b>, so please check back later if the list is
          empty. If you still aren&apos;t seeing results, you may need to change the hashtag to
          something more unique to your campaign.
        </p>
        <p>
          Additionally, you may create unlimited lists to monitor posts using other hashtags and
          mentions by clicking the <span className='blue'>+ New</span> button above your lists. You
          can even add your competitors&apos; social media handles to monitor their posts.
        </p>
      </section>
      <section>
        <h2>Types of posts</h2>
        <p>
          <b>Sponsored Posts</b> allow you to set more specific filters such as location and likes,
          but you will only see results for posts that are sponsored.
        </p>
        <p>
          <b>Top Posts</b> are posts that have a high engagement rate.
        </p>
        <p>
          <b>Recent Posts</b> are posts that have been posted recently.
        </p>
      </section>
    </Wrapper>
  )
}
