import { Button } from 'antd'
import { Formik, Form } from 'formik'
import { Select, Input } from 'formik-antd'
import React from 'react'
import * as yup from 'yup'

import arrow from '../../images/login/arrow-shape.svg'
import FormItem from '../forms/FormItem'
const { Option } = Select

// SIGN UP STEP 2 - BRAND INFO
export const BrandInfoForm = ({ formData, setFormData, prevStep, nextStep }) => {
  const validationSchema = yup.object().shape({
    brandName: yup.string().required('Required').min(3, 'Too short'),
    brandWebsite: yup
      .string()
      .required('Required')
      .matches(
        /[-a-zA-Z0-9@:%.+~#=]{1,256}\.[a-zA-Z0-9()]{1,}\b([-a-zA-Z0-9()@:%+.~#?&//=]*)/gi,
        'Must be a valid URL'
      ),
    companyType: yup.string().required('Required'),
    affiliateInfo: yup.array().required('Required'),
    ecommercePlatform: yup.array().required('Required'),
  })

  return (
    <Formik
      initialValues={formData}
      validationSchema={validationSchema}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={values => {
        setFormData(prev => ({ ...prev, ...values }))
        nextStep()
      }}>
      {({ values, setFieldValue, submitForm }) => (
        <Form>
          <img src={arrow} alt='' className='arrow' />
          <h1>Welcome {formData.firstName}!</h1>
          <p className='subtext'>Now we just need some info about your brand.</p>

          <FormItem size='small' name='brandName'>
            <p className='form-label'>Company Info</p>
            <Input name='brandName' placeholder='Company name' />
          </FormItem>

          <FormItem size='small' name='brandWebsite'>
            <Input autoComplete='url' name='brandWebsite' placeholder='Company website' />
          </FormItem>

          <FormItem size='small' name='companyType'>
            <Select placeholder='Company size' style={{ width: '100%' }} name='companyType'>
              <Option value='young-brand'>Young Brand (Up to 10 employees)</Option>
              <Option value='medium-brand'>Medium Brand (11-25 employees)</Option>
              <Option value='large-brand'>Large Brand (26-99 employees)</Option>
              <Option value='enterprise'>Enterprise</Option>
              <Option value='boutique-agency'>Boutique Agency (Up to 20 employees)</Option>
              <Option value='large-agency'>Large Agency (21+ employees)</Option>
            </Select>
          </FormItem>

          <FormItem size='small' name='affiliateInfo'>
            <p className='form-label'>Which affiliate platform do you use?</p>
            <Select
              mode='multiple'
              style={{ width: '100%' }}
              name='affiliateInfo'
              placeholder='Select'
              showArrow
              showSearch={false}
              allowClear
              onChange={value => {
                value.includes('None') && setFieldValue('affiliateInfo', ['None'])
              }}>
              <Option value='Rakuten'>Rakuten</Option>
              <Option value='Refersion'>Refersion</Option>
              <Option value='Awin'>Awin</Option>
              <Option value='ShareASale'>ShareASale</Option>
              <Option value='ImpactRadius'>Impact Radius</Option>
              <Option value='Other'>Other</Option>
              <Option value='None'>None</Option>
            </Select>
            <Input
              hidden={values?.affiliateInfo?.includes('Other') ? false : true}
              required={values?.affiliateInfo?.includes('Other') ? true : false}
              name='affiliateOther'
              placeholder='Other Affiliate Networks'
              style={{ marginTop: '10px' }}
            />
          </FormItem>
          <FormItem size='small' name='ecommercePlatform'>
            <p className='form-label'>Which Ecommerce platform do you use?</p>
            <Select
              mode='multiple'
              style={{ width: '100%' }}
              name='ecommercePlatform'
              placeholder='Select'
              showArrow
              showSearch={false}
              allowClear
              onChange={value => {
                value.includes('None') && setFieldValue('ecommercePlatform', ['None'])
              }}>
              <Option value='Shopify'>Shopify</Option>
              <Option value='WooCommerce'>WooCommerce</Option>
              <Option value='Squarespace'>Squarespace</Option>
              <Option value='BigCommerce'>BigCommerce</Option>
              <Option value='Other'>Other</Option>
              <Option value='None'>None</Option>
            </Select>
            <Input
              hidden={values?.ecommercePlatform?.includes('Other') ? false : true}
              required={values?.ecommercePlatform?.includes('Other') ? true : false}
              name='ecommercePlatformOther'
              placeholder='Other Ecommerce Platforms'
              style={{ marginTop: '10px' }}
            />
          </FormItem>

          <div className='nav-buttons'>
            <Button type='secondary' onClick={() => prevStep()}>
              Back
            </Button>
            <Button
              type='primary'
              onClick={submitForm}
              disabled={
                // all fields must be filled out
                !values.brandName ||
                !values.brandWebsite ||
                !values.companyType ||
                !values.affiliateInfo ||
                !values.ecommercePlatform ||
                // if affiliateInfo is Other, affiliateOther must be filled out
                (values.affiliateInfo.includes('Other') && !values.affiliateOther) ||
                // if ecommercePlatform is Other, ecommercePlatformOther must be filled out
                (values.ecommercePlatform.includes('Other') && !values.ecommercePlatformOther)
              }>
              Continue
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  )
}
