import { Modal, Row, Button, Steps } from 'antd'
import React, { createRef, useState } from 'react'

import { EnableAPI } from './steps/EnableAPI'
import { Result } from './steps/Result'
import { Submit } from './steps/Submit'
import { UploadCredentials } from './steps/UploadCredentials'

export const GA4SetupModal = ({ onClose, brandId, integration }) => {
  const refs = Array(4).fill(createRef())
  const [loading, setLoading] = useState(false)
  const [context, setContext] = useState({ brandId, integration })

  const steps = [
    <EnableAPI context={context} ref={refs[0]} key={0} />,
    <UploadCredentials context={context} ref={refs[1]} key={1} />,
    <Submit context={context} ref={refs[2]} key={2} />,
    <Result context={context} ref={refs[3]} key={3} />,
  ]

  const [currentStep, setCurrentStep] = useState(0)
  const [visible, setVisible] = useState(true)

  const handleNext = async () => {
    if (currentStep != 3) {
      setLoading(true)
      const step = refs[currentStep].current
      const valid = await step.validate()
      const submit = !valid || !step.submit ? {} : await step.submit()
      if (!!valid && submit) {
        setContext({ ...context, ...valid, ...submit })
        setCurrentStep(currentStep + 1)
      }
      setLoading(false)
    } else {
      if (context.error) {
        setCurrentStep(1)
      } else {
        setVisible(false)
      }
    }
  }
  const handlePrevious = () => {
    if (!currentStep) setVisible(false)
    else setCurrentStep(currentStep - 1)
  }

  return (
    <Modal
      title='Google Analytics Setup'
      open={visible}
      onCancel={() => {
        if (!loading) setVisible(false)
      }}
      destroyOnClose={true}
      afterClose={onClose}
      footer={
        <Row type='flex' justify='end'>
          {currentStep < 3 && (
            <Button type='secondary' onClick={handlePrevious} disabled={loading}>
              {currentStep == 0 && 'Cancel'}
              {currentStep != 0 && currentStep != 3 && 'Go Back'}
            </Button>
          )}
          <Button type='primary' onClick={handleNext} disabled={loading} loading={loading}>
            {(currentStep == 0 || currentStep == 1) && 'Next Step'}
            {currentStep == 2 && 'Verify My Integration'}
            {currentStep == 3 && (context.error ? 'Retry' : 'Done')}
          </Button>
        </Row>
      }>
      <Steps current={currentStep}>
        {steps.map((_, i) => (
          <Steps.Step
            key={i}
            status={i < currentStep ? 'finish' : i == currentStep ? 'process' : 'wait'}
          />
        ))}
      </Steps>
      {steps[currentStep]}
    </Modal>
  )
}
