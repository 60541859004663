import { Tooltip } from 'antd'
import React from 'react'
import styled from 'styled-components/macro'

export const VerticalBarChart = ({ data, color = '#027df0' }) => {
  // recommended use: items where the data labels are short and aren't ranked from highest-lowest, limit to 5-7 items
  return (
    <Wrapper>
      <div className='chart'>
        {data.map((item, i) => (
          <div className='chart-column' key={i}>
            <div className='value' style={{ color }}>
              {item.percentage?.toFixed(0) || 0}%
            </div>
            <Tooltip
              key={i}
              title={`${item.label}: ${
                item.percentage || 0
              }% (${item.count.toLocaleString()} total)`}
              placement='bottom'>
              {!!item.percentage && <Bar color={color} height={item.percentage} />}
            </Tooltip>
            <div className='label'>{item.label}</div>
          </div>
        ))}
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  .chart {
    height: 250px;
    white-space: nowrap;
    display: flex;
    align-items: flex-end;
    // justify-content: space-evenly;
    gap: 20px;
  }
  .chart-column {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
  }
  .value {
    margin: 0;
    line-height: 1;
    font-size: 12px;
  }
  .label {
    color: ${props => props.theme.crcoGrey};
    margin: 10px 0 0 0;
  }
`

const Bar = styled.div`
  background: ${props => props.color};
  height: ${props => props.height}%;
  width: 15px;
  border-radius: 15px;
  margin-top: 5px;
`
