import { Select } from 'formik-antd'
import React from 'react'

import FormItem from '../../../components/forms/FormItem'

export const SocialChannelFilter = () => {
  return (
    <FormItem size='small' label='Social Channels' name='socialChannels'>
      <Select
        getPopupContainer={trigger => trigger.parentNode}
        virtual={false}
        name='socialChannels'
        placeholder='Select'
        mode='multiple'
        style={{ width: '100%' }}
        options={[
          { label: 'Instagram', value: 'instagram' },
          { label: 'TikTok', value: 'tiktok' },
          { label: 'YouTube', value: 'youtube' },
        ]}
      />
    </FormItem>
  )
}
