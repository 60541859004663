import { LoadingOutlined, SearchOutlined } from '@ant-design/icons'
import { Input as AntInput, Button, List, InputNumber, Col, Row, message } from 'antd'
import { ConfigProvider, CountryPhoneInput } from 'antd-country-phone-input'
import axios from 'axios'
import { Form, Formik } from 'formik'
import { Input, Select } from 'formik-antd'
import React, { useContext, useEffect, useState } from 'react'
import { queryCache, useInfiniteQuery, useQuery } from 'react-query'
import styled from 'styled-components'
import en from 'world_countries_lists/data/countries/en/world.json'

import { NODE_URL } from '../../../constants'
import { BrandContext } from '../../../contexts/BrandContext'
import shopifyIcon from '../../../images/icons/shopify-icon.svg'
import FormItem from '../../forms/FormItem'

const CreateShopifyOrder = ({ optIn, close }) => {
  const { brandId, fetchBrandData } = useContext(BrandContext)
  const { data: brandData } = useQuery(['brand', brandId], fetchBrandData)

  const [currentStep, setCurrentStep] = useState(1)
  const [selectedProducts, setSelectedProducts] = useState([])
  const [countries, setCountries] = useState([])
  const [states, setStates] = useState([])

  const [search, setSearch] = useState('')
  const {
    data: productsData,
    status: productsStatus,
    isFetchingMore,
    fetchMore,
    canFetchMore,
  } = useInfiniteQuery(
    ['shopify-products', brandId, search],
    async (key, brandId, search, page = 1) => {
      const data = await axios
        .get(
          `${NODE_URL}/brand/${brandId}/shopify/store/${brandData?.shopifyStore?.id}/products/${page}`,
          {
            params: {
              search,
            },
          }
        )
        .then(res => res.data)
      return data
    },
    { getFetchMore: lastGroup => lastGroup.nextPage }
  )

  useEffect(() => {
    axios.get('../../json/countries.json').then(res => {
      setCountries(res.data.countries)
    })

    axios.get('../../json/states.json').then(res => {
      setStates(res.data.states)
    })
  }, [])

  const handleScroll = e => {
    if (e.target.scrollTop + e.target.clientHeight === e.target.scrollHeight && canFetchMore) {
      fetchMore()
    }
  }

  const searchRef = React.useRef(0)
  const handleSearch = e => {
    const search = e.target.value

    searchRef.current += 1
    const currentSearch = searchRef.current
    setTimeout(() => {
      if (currentSearch === searchRef.current) {
        setSearch(search)
      }
    }, 500)
  }

  return (
    <Wrapper>
      {currentStep === 1 && (
        <div className='products'>
          <div className='products-header'>
            <AntInput
              placeholder='Search products'
              prefix={<SearchOutlined />}
              allowClear
              onChange={handleSearch}
            />
          </div>
          <div className='products-body'>
            {productsStatus === 'success' &&
              (productsData[0]?.products?.length ? (
                <>
                  <List
                    loading={isFetchingMore}
                    className='products-list'
                    onScroll={handleScroll}
                    dataSource={productsData.flatMap(group => group.products)}
                    rowKey={product => product.id}
                    renderItem={product => (
                      <List.Item key={product.id} className='product'>
                        <img
                          className='product-image'
                          src={product.imageUrl || shopifyIcon}
                          alt={product.title}
                        />
                        <span className='product-title'>{product.title}</span>
                        <div className='variants'>
                          {product.variations.map(variation => (
                            <div className='variant' key={variation.id}>
                              <span>{variation.title}</span>
                              <InputNumber
                                value={
                                  selectedProducts.find(v => v.id === variation.shopifyId)
                                    ?.quantity || 0
                                }
                                onChange={e => {
                                  setSelectedProducts(prev => {
                                    const updated = [...prev]
                                    const index = updated.findIndex(
                                      v => v.id === variation.shopifyId
                                    )

                                    if (!e) {
                                      updated.splice(index, 1)
                                      return updated
                                    }

                                    if (index !== -1) {
                                      updated[index].quantity = e
                                    } else {
                                      updated.push({ id: variation.shopifyId, quantity: e })
                                    }
                                    return updated
                                  })
                                }}
                              />
                            </div>
                          ))}
                        </div>
                      </List.Item>
                    )}
                  />
                </>
              ) : (
                <div>
                  No products found on your store. Please add products to your store or connect your
                  store and try again.
                </div>
              ))}
            {productsStatus === 'loading' && (
              <div className='loading'>
                <LoadingOutlined spin />
              </div>
            )}
          </div>

          <div className='navigation'>
            <Button
              disabled={!selectedProducts.length}
              type='primary'
              onClick={() => setCurrentStep(2)}>
              Continue
            </Button>
          </div>
        </div>
      )}

      {currentStep === 2 && (
        <div>
          <Formik
            onSubmit={async values => {
              await axios
                .post(`${NODE_URL}/brand/${brandId}/shopify/order`, {
                  optInId: optIn.id,
                  lineItems: selectedProducts,
                  customer: {
                    ...values,
                    phone: `+${values.phoneCode}${values.phone}`,
                  },
                })
                .then(() => {
                  message.success('Order placed successfully!')
                  close()
                  queryCache.invalidateQueries('opt-ins')
                })
                .catch(err => {
                  message.error(err.response?.data?.err || 'An unexpected error occurred.')
                  return err
                })
            }}
            initialValues={{
              firstName: optIn.user.firstName,
              lastName: optIn.user.lastName,
              phone: optIn.user.phone,
              phoneCode: optIn.user.phoneCode || 1,
              phoneShort: optIn.user.phoneShort || 'US',
              email: optIn.user.email,
              address1: optIn.user.creatorProfile.shippingAddress1,
              address2: optIn.user.creatorProfile.shippingAddress2,
              city: optIn.user.creatorProfile.shippingCity,
              country: optIn.user.creatorProfile.shippingCountry,
              province: optIn.user.creatorProfile.shippingRegion,
              zip: optIn.user.creatorProfile.shippingPostcode,
            }}>
            {({ values, setValues, isSubmitting }) => (
              <Form className='shipping-form'>
                <p className='shipping-form-instructions'>
                  Please confirm the creator&apos;s shipping information.
                </p>
                <Row gutter={16}>
                  <Col span={12}>
                    <FormItem size='small' name='firstName' label='First Name'>
                      <Input name='firstName' />
                    </FormItem>
                  </Col>
                  <Col span={12}>
                    <FormItem size='small' name='lastName' label='Last Name'>
                      <Input name='lastName' />
                    </FormItem>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={12}>
                    <FormItem size='small' name='email' label='Email'>
                      <Input name='email' />
                    </FormItem>
                  </Col>
                  <Col span={12}>
                    <FormItem size='small' name='phone' label='Phone'>
                      <ConfigProvider locale={en}>
                        <CountryPhoneInput
                          inline
                          name='phone'
                          value={{
                            phone: values.phone,
                            short: values.phoneShort,
                            code: values.phoneCode,
                          }}
                          onChange={ev => {
                            setValues(prev => ({
                              ...prev,
                              phone: ev.phone,
                              phoneShort: ev.short,
                              phoneCode: ev.code,
                            }))
                          }}
                        />
                      </ConfigProvider>
                    </FormItem>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={12}>
                    <FormItem size='small' name='address1' label='Address 1'>
                      <Input name='address1' />
                    </FormItem>
                  </Col>
                  <Col span={12}>
                    <FormItem size='small' name='address2' label='Address 2'>
                      <Input name='address2' />
                    </FormItem>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={12}>
                    <FormItem size='small' name='country' label='Country'>
                      <Select
                        name='country'
                        getPopupContainer={trigger => trigger.parentNode}
                        optionFilterProp='label'
                        showSearch>
                        {countries.map(country => (
                          <Select.Option
                            key={country.country_code}
                            value={country.country_code}
                            label={country.name}>
                            {country.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </FormItem>
                  </Col>
                  <Col span={12}>
                    <FormItem size='small' name='province' label='Region'>
                      <Select
                        getPopupContainer={trigger => trigger.parentNode}
                        virtual={false}
                        showSearch
                        name='province'
                        optionFilterProp='label'>
                        {states
                          .filter(state => state.country_code === values.country)
                          .map(state => (
                            <Select.Option key={state.id} value={state.name} label={state.name}>
                              {state.name}
                            </Select.Option>
                          ))}
                      </Select>
                    </FormItem>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={12}>
                    <FormItem size='small' name='city' label='City'>
                      <Input name='city' />
                    </FormItem>
                  </Col>
                  <Col span={12}>
                    <FormItem size='small' name='zip' label='Zip'>
                      <Input name='zip' />
                    </FormItem>
                  </Col>
                </Row>
                <div className='navigation'>
                  <Button type='secondary' onClick={() => setCurrentStep(1)}>
                    Back
                  </Button>

                  <Button type='primary' htmlType='submit' loading={isSubmitting}>
                    Create Order
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  .products {
    .products-body {
      height: 600px;
      margin-top: 24px;
    }
    .navigation {
      display: flex;
      justify-content: flex-end;
      margin-top: 24px;
    }
  }
  .products-list {
    margin-top: 24px;
    height: 100%;
    overflow-y: auto;

    .product {
      padding: 12px;
      display: flex;
      justify-content: flex-start;
      gap: 16px;
      .product-image {
        width: 50px;
        height: 50px;
        border-radius: 50%;
      }
      .product-title {
        flex: 1;
        font-size: 16px;
        font-weight: bold;
      }
      .variants {
        display: flex;
        flex-direction: column;
        gap: 12px;
        .variant {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          gap: 8px;
        }
      }
    }
  }
  .loading {
    height: 100%;
    font-size: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .shipping-form {
    .shipping-form-instructions {
      color: ${props => props.theme.crcoGrey};
      margin-bottom: 20px;
    }

    .ant-select {
      width: 100%;
    }

    .navigation {
      display: flex;
      justify-content: space-between;
      margin-top: 24px;
    }
  }
`

export default CreateShopifyOrder
