import React from 'react'
import { Pie as PieChart, Chart, defaults } from 'react-chartjs-2'
import 'chartjs-plugin-datalabels'

defaults.global.defaultFontFamily = 'Campton-Light'

const Pie = ({ data, title, initial, total, legendPosition = 'top' }) => {
  return (
    <PieChart
      data={data}
      height={100}
      options={{
        maintainAspectRatio: false,
        title: {
          display: title ? true : false,
          text: title,
          fontSize: 13,
        },
        tooltips: {
          callbacks: {
            label: function (tooltipItem, data) {
              var index = tooltipItem.index
              var currentValue = data.datasets[tooltipItem.datasetIndex].data[index]
              return Math.round(total * (currentValue / 100))
            },
          },
        },
        legend: {
          display: true,
          position: legendPosition,
          labels: {
            generateLabels: function (chart) {
              var data = chart.data
              if (data.labels.length && data.datasets.length) {
                return data.labels.map(function (label, i) {
                  var meta = chart.getDatasetMeta(0)
                  var ds = data.datasets[0]
                  var arc = meta.data[i]
                  var custom = arc?.custom || {}
                  var getValueAtIndexOrDefault = Chart.helpers.getValueAtIndexOrDefault
                  var arcOpts = chart.options.elements.arc
                  var fill = custom.backgroundColor
                    ? custom.backgroundColor
                    : getValueAtIndexOrDefault(ds.backgroundColor, i, arcOpts.backgroundColor)
                  var stroke = custom.borderColor
                    ? custom.borderColor
                    : getValueAtIndexOrDefault(ds.borderColor, i, arcOpts.borderColor)
                  var bw = custom.borderWidth
                    ? custom.borderWidth
                    : getValueAtIndexOrDefault(ds.borderWidth, i, arcOpts.borderWidth)
                  return {
                    // And finally :
                    text: label + ' ' + ds.data[i] + '%',
                    fillStyle: fill,
                    strokeStyle: stroke,
                    lineWidth: bw,
                    hidden: isNaN(ds.data[i]) || meta.data[i].hidden,
                    index: i,
                  }
                })
              }
              return []
            },
          },
        },
        plugins: {
          datalabels: {
            display: false,
          },
        },
        animation: {
          duration: initial.current ? 1500 : 0,
          onProgress: function () {
            initial.current = false
          },
        },
      }}
    />
  )
}

export default Pie
