import { Typography } from 'antd'
import React, { useImperativeHandle, forwardRef } from 'react'
import styled from 'styled-components'

import { Wrapper } from './base'
import ga4SetupInitial from '../../../../images/ga4_setup_initial.png'

export const EnableAPI = forwardRef(function main(props, ref) {
  useImperativeHandle(ref, () => ({
    validate() {
      return true
    },
  }))
  return (
    <Wrapper>
      <Typography.Title level={5}>Step 1: Enable API & Download Credentials</Typography.Title>
      <p>
        First, go to{' '}
        <a
          target='_blank'
          rel='noopener noreferrer'
          href='https://developers.google.com/analytics/devguides/reporting/data/v1/quickstart-client-libraries#step_1_enable_the_api'>
          Google&apos;s API Quickstart
        </a>
        , click &quot;Enable the Google Analytics Data API v1&quot; (see screenshot below), and
        download the JSON file with your credentials.
      </p>
      <ImgWrapper src={ga4SetupInitial} alt='' />
    </Wrapper>
  )
})

export const ImgWrapper = styled.img`
  margin-top: 20px;
  box-sizing: border-box;
  border: 1px solid #e6e6e6;
  filter: drop-shadow(0px 5px 5px rgba(0, 0, 0, 0.1));
  border-radius: 10px;
`
