import { UndoOutlined, CheckCircleOutlined } from '@ant-design/icons'
import { Popconfirm } from 'antd'
import React from 'react'

const SkipStep = ({ children, optInStep, isSubmitting, setValues, submitForm }) => {
  // TODO: move skip step functionality here and clean up redundant forms in individual steps
  // add to OrderSteps component action bar (conditionally rendered depending on whether step is skippable)
  return (
    <Popconfirm
      title={`Are you sure you want to mark this step as
                      ${optInStep?.completed ? 'incomplete' : 'complete'}?`}
      okText='Confirm'
      okButtonProps={{
        loading: isSubmitting,
        size: 'middle',
        type: 'link',
        icon: optInStep?.completed ? <UndoOutlined /> : <CheckCircleOutlined />,
      }}
      cancelButtonProps={{ size: 'middle', type: 'text', danger: true }}
      onConfirm={() => {
        setValues({ stepStatus: optInStep?.completed ? 'incomplete' : 'complete' })
        submitForm()
      }}>
      {children}
    </Popconfirm>
  )
}

export default SkipStep
