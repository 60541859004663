import { Switch, Button, message } from 'antd'
import axios from 'axios'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router'
import { Link } from 'react-router-dom'
import styled from 'styled-components/macro'

import { AgencyPricing } from './AgencyPricing'
import { BrandPricing } from './BrandPricing'
import { Testimonials } from './Testimonials'
import { Footer } from '../../components/Footer'
import { BOOK_DEMO_URL, NODE_URL } from '../../constants'
import { UserContext } from '../../contexts/UserContext'
import { useDocumentTitle } from '../../custom-hooks/useDocumentTitle'
import arrowSquiggle from '../../images/pricing/arrow-squiggle.svg'
import blob from '../../images/pricing/blob.svg'
import curve from '../../images/pricing/curve-bg.svg'
import hashtag from '../../images/pricing/hashtag.svg'
import introBg from '../../images/pricing/intro-background.svg'
import background from '../../images/pricing/plans-bg.svg'
import squiggleArrow from '../../images/pricing/squiggle-arrow.svg'
import ugcBackground from '../../images/pricing/ugc-bg.svg'
import videoBg from '../../images/pricing/video-bg.png'
import content1 from '../../images/ugc/content1.png'
import content2 from '../../images/ugc/content2.png'
import content3 from '../../images/ugc/content3.png'
import content4 from '../../images/ugc/content4.png'
import content5 from '../../images/ugc/content5.png'
import content6 from '../../images/ugc/content6.png'
import content7 from '../../images/ugc/content7.png'
import content8 from '../../images/ugc/content8.png'
import content9 from '../../images/ugc/content9.png'

const Pricing = ({ brandId }) => {
  const { nodeToken } = useContext(UserContext)
  // const { brandId, fetchBrandData } = useContext(BrandContext)
  // const { data: brandData } = useQuery(['brand', brandId], fetchBrandData)
  useDocumentTitle('Plans & Pricing | Brands')
  const [agency, setAgency] = useState(false)
  const [yearly, setYearly] = useState(false)
  const [loading, setLoading] = useState('')
  const history = useHistory()
  const { planId } = useParams()

  const ugc = [
    content1,
    content2,
    content3,
    content4,
    content5,
    content6,
    content7,
    content8,
    content9,
  ]

  const borderRadiusStyles = [
    '30px 0 30px 0',
    '30px 30px 30px 0',
    '30px 0 30px 30px',
    '30px 0 30px 30px',
    '30px 30px 0 30px',
    '0 30px 0 30px',
    '0 30px 0 30px',
    '0 30px 30px 30px',
    '30px 0 30px 30px',
  ]

  const openPayment = useCallback(
    planId => {
      if (brandId) {
        setLoading(planId)

        let chargebeeInstance = window.Chargebee.getInstance()
        chargebeeInstance.closeAll()

        let plan = `${planId}-${
          yearly ? (planId === 'self-serve-2' ? 'annually' : 'yearly') : 'monthly'
        }`

        axios
          .get(`${NODE_URL}/checkout-subscription/${brandId}/?planId=${plan}`, {
            headers: { Authorization: `Bearer ${nodeToken}` },
          })
          .then(res => {
            setLoading('')
            chargebeeInstance.openCheckout({
              hostedPage: () => {
                return new Promise(function (resolve) {
                  resolve(res.data)
                })
              },
              error: () => {
                message.error('An error occurred while processing your payment. Please try again.')
              },
              success: hostedPageId => {
                // add tokens to brand and email admin
                axios
                  .post(
                    `${NODE_URL}/checkout-subscription/${brandId}`,
                    { hostedPageId },
                    {
                      headers: { Authorization: `Bearer ${nodeToken}` },
                    }
                  )
                  .then(() => {
                    chargebeeInstance.closeAll()
                    // Google analytics payment event
                    window.dataLayer.push({
                      event: 'submit_payment_js_br',
                    })
                    history.push('/account')
                    history.go()
                  })
                  .catch(() => {
                    message.error(
                      'An error occurred while processing your payment. Please try again.'
                    )
                  })
              },
              close: () => {}, // TODO: cleanup?
            })
          })
          .catch(() => {
            message.error('An error occurred while processing your payment. Please try again.')
          })
      } else {
        history.push(`/signup/${planId}_${yearly ? 'annually' : 'monthly'}`)
      }
    },
    [brandId, history, yearly, nodeToken]
  )

  // #region Affiliate
  const [affiliateLoading, setAffiliateLoading] = useState(false)
  const openAffiliatePayment = useCallback(() => {
    setAffiliateLoading(true)
    if (brandId) {
      let chargebeeInstance = window.Chargebee.getInstance()
      chargebeeInstance.closeAll()

      axios
        .get(`${NODE_URL}/checkout-affiliate/${brandId}/`, {
          headers: { Authorization: `Bearer ${nodeToken}` },
        })
        .then(res => {
          setAffiliateLoading(false)
          chargebeeInstance.openCheckout({
            hostedPage: () => {
              return new Promise(function (resolve) {
                resolve(res.data)
              })
            },
            error: () => {
              message.error('An error occurred while processing your payment. Please try again.')
            },
            success: hostedPageId => {
              // add tokens to brand and email admin
              axios
                .post(
                  `${NODE_URL}/checkout-affiliate/${brandId}`,
                  { hostedPageId },
                  {
                    headers: { Authorization: `Bearer ${nodeToken}` },
                  }
                )
                .then(() => {
                  chargebeeInstance.closeAll()
                  // Google analytics payment event
                  window.dataLayer.push({
                    event: 'submit_payment_js_br',
                  })
                  history.push('/account')
                  history.go()
                })
                .catch(() => {
                  message.error(
                    'An error occurred while processing your payment. Please try again.'
                  )
                })
            },
            close: () => {},
          })
        })
        .catch(() => {
          message.error('An error occurred while processing your payment. Please try again.')
        })
    } else {
      history.push(`/signup/affiliate`)
    }
  }, [brandId, history, nodeToken])
  // #endregion

  // #region Addons
  const [addonLoading, setAddonLoading] = useState(false)
  const openAddonPayment = useCallback(
    addon => {
      setAddonLoading(addon)

      if (!brandId) history.push(`/signup/${addon}`)

      let chargebeeInstance = window.Chargebee.getInstance()
      chargebeeInstance.closeAll()

      axios
        .get(`${NODE_URL}/checkout-addon/${brandId}/`, {
          params: { addon },
          headers: { Authorization: `Bearer ${nodeToken}` },
        })
        .then(res => {
          setAddonLoading(false)
          chargebeeInstance.openCheckout({
            hostedPage: () => {
              return new Promise(function (resolve) {
                resolve(res.data)
              })
            },
            error: () => {
              message.error('An error occurred while processing your payment. Please try again.')
            },
            success: hostedPageId => {
              // add tokens to brand and email admin
              axios
                .post(
                  `${NODE_URL}/checkout-addon/${brandId}`,
                  { hostedPageId },
                  {
                    headers: { Authorization: `Bearer ${nodeToken}` },
                  }
                )
                .then(() => {
                  chargebeeInstance.closeAll()
                  // Google analytics payment event
                  window.dataLayer.push({
                    event: 'submit_payment_js_br',
                  })
                  history.push('/account')
                  history.go()
                })
                .catch(() => {
                  message.error(
                    'An error occurred while processing your payment. Please try again.'
                  )
                })
            },
            close: () => {},
          })
        })
        .catch(() => {
          message.error('An error occurred while processing your payment. Please try again.')
        })
    },
    [brandId, history, nodeToken]
  )
  // #endregion

  useEffect(() => {
    if (planId) {
      if (planId === 'affiliate') {
        openAffiliatePayment()
      } else {
        const [plan, period] = planId.split('_')
        setYearly(period === 'annually')
        openPayment(plan)
      }
    }
  }, [planId, openPayment, openAffiliatePayment])

  // intersection observer for scrolling fade-in animations
  useEffect(() => {
    const faders = document.querySelectorAll('.fade-in')

    const appearOptions = {
      // threshold controls how much of the target should be visible before the effect runs
      threshold: 0.5,
    }

    const appearOnScroll = new IntersectionObserver(function (entries, appearOnScroll) {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('appear')
          appearOnScroll.unobserve(entry.target)
        }
      })
    }, appearOptions)

    faders.forEach(fader => {
      appearOnScroll.observe(fader)
    })
  })

  return (
    <Wrapper>
      <div className='inner'>
        <section className='banner'>
          <img className='background' src={blob} alt='' />
          <div className='text'>
            <h1>Plans & Pricing</h1>
            <p>
              From DIY to fully-managed, we offer award-winning, bespoke influencer and affiliate
              campaigns.
            </p>
            <Button type='primary'>
              {nodeToken ? (
                <a href={BOOK_DEMO_URL} target='_blank' rel='noopener noreferrer'>
                  Schedule a Call
                </a>
              ) : (
                <Link to='/signup'>Learn More</Link>
              )}
            </Button>
          </div>
          <div className='video' style={{ backgroundImage: `url(${videoBg})` }}>
            <iframe
              src={`https://www.youtube.com/embed/bZbjBSwhlEo`}
              frameBorder='0'
              allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
              allowFullScreen
              title='Introduction to Creator.co'
            />
          </div>
        </section>
        {/* {!brandData?.extraData?.shopifyRegistration ? ( */}
        <>
          <section className='plans'>
            <div className='plans-intro'>
              <img className='background-1' src={introBg} alt='' />
              <div className='intro-inner'>
                <h2>Choose the plan that best suits your needs.</h2>
                <div className='buttons'>
                  <button
                    className={`brand ${!agency && 'active'}`}
                    onClick={() => setAgency(false)}>
                    Brand Pricing
                  </button>
                  <button
                    className={`agency ${agency && 'active'} agency`}
                    onClick={() => setAgency(true)}>
                    Agency Pricing
                  </button>
                </div>
                <div className={`sub-info ${agency && 'hidden'}`}>
                  <label htmlFor='toggle'>
                    <span className={`toggle-option ${!yearly && 'active'}`}>Monthly</span>
                    <Switch onChange={() => setYearly(!yearly)} />
                    <span className={`toggle-option ${yearly && 'active'}`}>Annual (save 15%)</span>
                  </label>
                  <img className='arrow' src={arrowSquiggle} alt='' />
                </div>
                <p className={`info-text ${!agency && 'hidden'}`}>
                  Agency Pricing includes at least two brands for free <br />
                  (valued at over $11,000 per year),
                  <br />
                  plus all features in our Self-Serve plan.
                </p>
                <p className={`info-text ${agency && 'hidden'}`}>
                  Minimum 3-month subscription required for all plans, plus one-time $395 onboarding
                  fee.
                </p>
              </div>
            </div>
            {agency ? (
              <AgencyPricing />
            ) : (
              <BrandPricing
                yearly={yearly}
                openPayment={openPayment}
                loading={loading}
                openAffiliatePayment={openAffiliatePayment}
                openAddonPayment={openAddonPayment}
                addonLoading={addonLoading}
                affiliateLoading={affiliateLoading}
              />
            )}
            <img className='background-2' src={background} alt='' />
          </section>

          <section className='terms'>
            {!agency ? (
              <p>
                * All prices are quoted in USD. A minimum subscription period of three months is
                mandatory for all plans, with a discount of 15% offered on yearly subscriptions. A
                30-day notice is necessary to cancel any subscription. In addition, there is an
                automatic 5% processing fee built into the platform for all influencer payments.
              </p>
            ) : (
              <p>
                * All prices are quoted in USD. A minimum subscription period of 12 months is
                required for all licensing plans. Additionally, all influencer payments are subject
                to an automatic 5% payment processing fee to the agency, which is built into the
                platform. The global license white-label package includes a logo, color application,
                and unique domain connection. It is important to note that all code, design, and
                database is the intellectual property of Creator.co. See licensing agreement for
                details.
              </p>
            )}
          </section>

          <Testimonials />

          <section className='ugc-banner fade-in'>
            <div className='background'>
              <img src={curve} alt='' />
            </div>
            <img className='hashtag' src={hashtag} alt='' />
            <div className='inner'>
              <h2>Craving some awesome UGC for your brand?</h2>
              <p className='subtext'>Subscribe to gain access to our talented creators!</p>
              <div className='button-group-wrapper'>
                <img className='arrow' src={squiggleArrow} alt='' />
                <Button type='primary'>
                  {nodeToken ? (
                    <a href={BOOK_DEMO_URL} target='_blank' rel='noopener noreferrer'>
                      Book a Demo Today
                    </a>
                  ) : (
                    <Link to='/signup'>Create Account</Link>
                  )}
                </Button>
              </div>
            </div>
          </section>

          <section className='ugc-gallery'>
            <div className='background'>
              <img src={ugcBackground} alt='' />
            </div>
            {ugc.map((image, index) => (
              <div
                key={index}
                className='image-frame fade-in'
                style={{
                  background: `url(${image}) center center / cover`,
                  borderRadius: borderRadiusStyles[index],
                }}
              />
            ))}
          </section>
        </>
        <Footer />
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  // MOBILE
  background: #fff;
  color: #23262f;
  font-family: 'Poppins-Regular', sans-serif;
  max-width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  .inner {
    flex: 1;
    height: 100%;
    width: 100%;
    overflow-x: hidden;
    overflow-y: auto;
  }
  section {
    max-width: 1800px;
  }
  .banner {
    width: 90%;
    background: #dcecfb;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 20px auto;
    position: relative;
    overflow: hidden;
    .background {
      display: none;
    }
    .text {
      max-width: 550px;
      padding: 40px 20px 20px 20px;
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 20px;
    }
    h1 {
      font-family: 'Gilroy-ExtraBold', Verdana, sans-serif;
      font-size: 2rem;
      margin: 0;
    }
    p {
      font-family: 'Poppins-Medium', sans-serif;
      color: #777e90;
      font-size: 1rem;
      margin: 0;
    }
    button {
      background: #23262f;
      color: #fff;
      border: none;
      padding: 8px 30px;
      font-size: 1rem;
      transition: 0.2s ease-in-out;
      height: 42px;
      &:hover {
        background: ${props => props.theme.crcoTechBlue};
      }
    }
    .video {
      aspect-ratio: 1;
      width: 100%;
      max-width: 500px;
      padding: 20px;
      position: relative;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      display: flex;
      align-items: center;
      margin: 20px;
      iframe {
        width: 100%;
        aspect-ratio: 16/9;
        border-radius: 10px;
      }
    }
  }
  .testimonials {
    background: linear-gradient(
      to right,
      ${props => props.theme.crcoCottonCandyBlue},
      ${props => props.theme.crcoCottonCandyPink}
    );
    padding: 40px 20px;
    margin-top: 40px;
    .slick-track {
      display: flex;
      gap: 20px;
    }
    .testimonial {
      .inner {
        background: linear-gradient(to bottom left, rgba(255, 255, 255, 0.3), #fff);
        max-width: 500px;
        height: 400px;
        margin: auto;
        padding: 20px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        border-radius: 10px;
        .brand-logo {
          opacity: 0.85;
          max-height: 50px;
        }
        .message {
          margin: 30px 0;
          font-size: 0.8rem;
        }
        .brand-info {
          .name {
            font-weight: bold;
          }
          .job-title {
            margin: 5px 0;
            font-size: 0.8rem;
          }
        }
      }
    }
    .slick-dots {
      bottom: -35px;
    }
  }
  .plans {
    width: 100%;
    max-width: 1600px;
    margin: auto;
    position: relative;
  }
  .plans-intro {
    position: relative;
    .background-1 {
      display: none;
    }
    .intro-inner {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      margin: 40px 20px;
      z-index: 1;
      position: relative;
    }
    h2 {
      font-family: 'Gilroy-ExtraBold', Verdana, sans-serif;
      margin: 0;
      font-size: 24px;
    }
    .buttons {
      background: #ffffff;
      box-shadow: 0px 0px 16px rgba(33, 35, 43, 0.15);
      border-radius: 100px;
      padding: 7px;
      display: flex;
      gap: 12px;
      margin: 30px 0;
      button {
        background: #fff;
        border: none;
        color: ${props => props.theme.crcoMidnight};
        font-family: 'Gilroy-ExtraBold', Verdana, sans-serif;
        font-size: 1rem;
        border-radius: 20px;
        padding: 8px 15px;
        transition: 0.2s ease-in-out;
        &.active {
          background: ${props => props.theme.crcoMidnight};
          color: #fff;
        }
      }
    }
    .sub-info {
      max-width: 400px;
      margin: 0;
      position: relative;
      padding-top: 35px;
      .arrow {
        position: absolute;
        top: -15px;
        right: -50px;
      }
      &.hidden {
        display: none;
      }
      label {
        margin: 0;
        .toggle-option {
          flex: 1;
          font-family: 'Poppins-Regular', sans-serif;
          cursor: pointer;
          &.active {
            font-weight: bold;
          }
        }
      }
      button {
        margin: 0 12px;
      }
    }
    .info-text {
      margin: 10px 0 0 0;
      font-size: 0.8rem;
      opacity: 0.5;
      max-width: 400px;
      &.hidden {
        display: none;
      }
    }
  }
  .background-2 {
    display: none;
    @media only screen and (min-width: ${props => props.theme.breakpointDesktop}) {
      display: block;
      position: absolute;
      max-width: 1800px;
      top: 8%;
      right: 0;
    }
  }
  .price-cards {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
    &.agency {
      margin-bottom: 20px;
      .subtext {
        margin: 20px 0 0 0;
      }
      .price {
        margin: 0 0 15px 0;
      }
    }
  }
  .price-card {
    background: #fff;
    box-shadow: 4px 8px 20px 4px rgba(154, 175, 193, 0.12);
    border-radius: 40px;
    flex: 1;
    padding: 30px;
    display: flex;
    flex-direction: column;
    border: 2px solid #f3f3f3;
    position: relative;

    .flag {
      display: flex;
      visibility: hidden;
      color: #fff;
      justify-content: flex-end;
      position: absolute;
      right: 40px;
      top: 0;
      span {
        padding: 4px 12px;
        display: inline-block;
        border-radius: 0 0 8px 8px;
      }
      &.active {
        span {
          background-color: ${props => props.theme.crcoTechBlue};
        }
      }
      &:not(.active).popular {
        span {
          background-color: ${props => props.theme.crcoLavender};
        }
      }
    }

    &.active {
      border-color: ${props => props.theme.crcoTechBlue};

      .flag.active {
        visibility: visible;
      }

      .ant-btn {
        display: none;
      }
    }

    &.popular {
      border-color: ${props => props.theme.crcoLavender};

      .flag.popular {
        visibility: visible;
      }
    }

    p {
      color: #777e90;
      font-family: 'Avenir', Verdana, sans-serif;
      font-weight: 600;
      font-size: 13px;
      margin: 0;
    }
    .intro {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding-bottom: 20px;
      border-bottom: 1px solid #e6e6e6;
      margin-bottom: 30px;
    }
    .plan-name {
      color: #23262f;
      font-family: 'Gilroy-ExtraBold', Verdana, sans-serif;
      font-size: 1.4rem;
    }
    .stylized {
      font-family: 'Poppins-Medium', sans-serif;
      color: #777e90;
      font-size: 14px;
    }
    .price {
      margin: 15px 0;
      font-size: 12px;
      .value {
        font-family: 'Gilroy-ExtraBold', Verdana, sans-serif;
        font-size: 32px;
        margin-right: 8px;
      }
    }
    .details {
      display: flex;
      flex-direction: column;
      flex: 1;
      p {
        color: #23262f;
      }
      ul {
        font-family: 'Campton-Light', Verdana, sans-serif;
        margin: 0;
        padding: 0;
        list-style: none;
        margin-bottom: 30px;
      }
      li {
        color: #777e90;
        font-family: 'Avenir', sans-serif;
        font-weight: 600;
        margin: 20px 0;
        display: flex;
        align-items: center;
        gap: 8px;
      }
      .icon {
        height: 28px;
        width: 28px;
      }
    }
    .add-on {
      display: flex;
      flex-direction: column;
      padding-bottom: 20px;
      border-bottom: 1px solid #e6e6e6;
      margin-bottom: 20px;

      .cta {
        margin-top: 16px;
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
      }
    }
    a {
      margin-top: auto;
    }
    button {
      width: 100%;
      height: 44px;
      border-radius: 44px;
      background: #dcedf9;
      color: ${props => props.theme.crcoTechBlue};
      border: none;
      font-family: 'Poppins-Bold', sans-serif;
      font-size: 1rem;
      margin-top: auto;
      &.desktop {
        display: none;
      }
    }
    &.add-ons {
      margin: 0 20px 20px 20px;
      z-index: 1;
      position: relative;
    }
  }
  .pricing-tables {
    display: flex;
    flex-direction: column;
    overflow: auto;
    gap: 20px;
    padding: 30px 0;
  }
  .pricing-table {
    min-width: 100%;
    width: fit-content;
    background: #fff;
    display: flex;
    border-radius: 40px;
    box-shadow: 4px 8px 20px 4px rgba(154, 175, 193, 0.12);
    margin: 0 20px;
    white-space: nowrap;
    .column {
      display: flex;
      flex-direction: column;
      flex: 1;
      &:first-child {
        border-right: 1px solid #e6e6e6;
      }
      &.features {
        min-width: 350px; // make columns equal width across all tables
      }
      &.plan {
        min-width: 220px; // make columns equal width across all tables
        .cell {
          display: grid;
          place-content: center;
          text-align: center;
          &.fee {
            font-family: 'Poppins-Bold', sans-serif;
          }
        }
      }
      .anticon-info-circle {
        font-size: 1rem !important;
        color: #ccc;
      }
    }
    .cell {
      border-bottom: 1px solid #e6e6e6;
      &:last-child {
        border: none;
      }
      flex: 1;
      font-size: 14px;
      min-height: 80px;
      &.title {
        font-family: 'Gilroy-ExtraBold', Verdana, sans-serif;
        display: flex;
        align-items: center;
        font-size: 24px;
        padding-left: 30px;
      }
      &.plan-name {
        font-family: 'Gilroy-ExtraBold', Verdana, sans-serif;
        font-size: 16px;
      }
      &.plan-price {
        font-size: 1rem;
        font-weight: bold;
        color: ${props => props.theme.crcoTechBlue};
      }
      &.feature {
        font-size: 1rem;
        display: flex;
        align-items: center;
        padding-left: 30px;
      }
      .icon {
        height: 25px;
        width: 25px;
      }
      .subtext {
        font-family: 'Poppins-Medium', sans-serif;
        font-size: 12px;
        color: #231d4f;
        opacity: 0.5;
      }
    }
  }
  .fade-in {
    opacity: 0;
    transform: translateY(50%);
    transition: 0.6s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  }
  .fade-in.appear {
    opacity: 1;
    transform: translateY(0);
  }
  .ugc-banner {
    width: 90%;
    background: #dcedf9;
    border-radius: 16px;
    margin: 40px auto;
    position: relative;
    text-align: center;
    z-index: 1;
    .background {
      border-radius: 0 16px 16px 0;
      position: absolute;
      height: 100%;
      width: 100%;
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
      overflow: hidden;
      img {
        height: 100%;
        margin-right: -130px;
      }
    }
    .hashtag {
      position: absolute;
      top: -30px;
      left: 10%;
      height: 60px;
    }
    .inner {
      padding: 40px 20px;
      display: flex;
      flex-direction: column;
      gap: 20px;
      position: relative;
      z-index: 1;
    }
    h2 {
      font-family: 'Gilroy-ExtraBold', Verdana, sans-serif;
      font-size: 28px;
      max-width: 700px;
      margin: auto;
    }
    p {
      display: none;
      color: #777e90;
      font-size: 18px;
    }
    .button-group-wrapper {
      display: flex;
      flex-direction: column;
      gap: 20px;
      position: relative;
      width: fit-content;
      margin: auto;
    }
    .arrow {
      height: 60px;
      transform: rotate(45deg);
    }
    button {
      background: #23262f;
      color: #fff;
      border: none;
      padding: 8px 30px;
      font-size: 1rem;
      transition: 0.2s ease-in-out;
      height: 42px;
      &:hover {
        background: ${props => props.theme.crcoTechBlue};
      }
    }
  }
  .ugc-gallery {
    width: 90%;
    background: #fff;
    display: grid;
    gap: 10px;
    grid-template-columns: repeat(3, 1fr);
    position: relative;
    margin: 0 auto 40px auto;
    .background {
      position: absolute;
      width: 100vw;
      bottom: 0;
    }
    .image-frame {
      padding-bottom: 100%;
      width: 100%;
      height: 100px;
    }
  }
  .terms {
    padding: 20px;
    z-index: 1;
    text-align: center;
    p {
      color: #666;
      max-width: 800px;
      font-size: 13px;
      margin: auto;
    }
  }
  // TABLET
  @media only screen and (min-width: ${props => props.theme.breakpointTablet}) {
    .banner {
      border-radius: 30px;
      margin: 40px auto;
      .text {
        padding: 60px 20px 20px 20px;
        gap: 30px;
      }
      h1 {
        font-size: 3rem;
      }
      .video {
        padding: 40px;
        iframe {
          border-radius: 20px;
        }
      }
    }
    .testimonials {
      .testimonial {
        .inner {
          .message {
            font-size: 0.9rem;
          }
        }
      }
    }
    .plans-intro {
      .intro-inner {
        margin: 40px;
      }
      .background-1 {
        display: block;
        position: absolute;
        top: -10%;
        right: -30%;
        max-width: 100%;
      }
      h2 {
        font-size: 30px;
      }
    }
    .price-cards {
      padding: 20px 30px;
      gap: 30px;
      &.brand {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        justify-items: center;
        margin: 0 auto;
        max-width: 1000px;
      }
      &.agency {
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        margin-bottom: 30px;
        .price-card {
          min-width: 350px;
          max-width: 450px;
        }
      }
    }
    .price-card {
      &.add-ons {
        margin: 10px 30px 30px 30px;
        max-width: 100%;
        .intro {
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
        }
        button {
          width: fit-content;
          &.mobile {
            display: none;
          }
          &.desktop {
            display: block;
          }
        }
      }
    }
    .ugc-banner {
      .background img {
        margin-right: -50px;
      }
      .hashtag {
        height: 80px;
        top: -40px;
        left: 20%;
      }
      .inner {
        padding: 60px 40px;
      }
      h2 {
        font-size: 40px;
      }
      p {
        display: block;
        margin-bottom: 30px;
      }
      .arrow {
        height: 80px;
        position: absolute;
        transform: rotate(0deg);
        top: -50px;
        left: -90px;
      }
    }
    .ugc-gallery {
      width: 95%;
      gap: 20px;
      margin-bottom: 60px;
      .image-frame {
        height: 200px;
      }
    }
  }
  // DESKTOP
  @media only screen and (min-width: ${props => props.theme.breakpointDesktop}) {
    .banner {
      width: 95%;
      flex-direction: row;
      justify-content: space-evenly;
      border-radius: 40px;
      .background {
        display: block;
        position: absolute;
        width: 30%;
        top: 15%;
        left: 0;
      }
      .text {
        padding: 60px 20px;
        text-align: left;
        align-items: flex-start;
        z-index: 1;
        h1 {
          font-size: 60px;
        }
        p {
          font-size: 18px;
        }
        button {
          margin: 0;
        }
      }
      .video {
        max-width: 600px;
        margin: 40px 20px;
      }
    }
    .testimonials {
      border-radius: 20px;
      margin: 40px auto;
      .testimonial {
        .inner {
          max-width: 600px;
          height: 350px;
        }
      }
    }
    .plans {
      overflow: visible;
      .plans-intro {
        margin: 20px auto;
        .background-1 {
          right: 0;
          top: -50%;
        }
        h2 {
          font-size: 50px;
        }
        .sub-info {
          padding-top: 10px;
          .arrow {
            right: -165px;
            top: -70px;
            height: 75px;
          }
        }
      }
    }
    .price-cards {
      gap: 40px;
      padding: 20px 40px;
      &.agency {
        margin-bottom: 40px;
      }
    }
    .price-card {
      padding: 40px;
      &.add-ons {
        margin: 20px 40px 40px 40px;
        flex-direction: row;
        gap: 60px;
        .intro {
          flex-direction: column;
          align-items: flex-start;
        }
        .plan-name {
          font-size: 30px;
        }
        .intro,
        .add-on {
          flex: 1;
          padding: 0;
          margin: 0;
          border: none;
        }
        button {
          width: 100%;
        }
      }
    }
    .pricing-tables {
      padding: 30px 40px;
      gap: 40px;
    }
    .pricing-table {
      margin: 0;
      .column {
        &:first-child {
          margin-left: 40px;
        }
        &.features {
          min-width: 380px;
        }
      }
      .cell {
        &.title {
          font-size: 32px;
        }
        &.plan-name {
          font-size: 24px;
        }
        &.title,
        &.plan-name {
          min-height: 92px;
        }
        &.title,
        &.feature {
          padding: 0;
        }
      }
    }
    .ugc-banner {
      width: 95%;
      border-radius: 40px;
      .background img {
        margin: 0;
      }
      .inner {
        width: 100%;
        padding: 80px 60px;
      }
      h2 {
        font-size: 60px;
      }
    }
    .ugc-gallery {
      max-width: 1200px;
      gap: 30px;
      margin-top: 20px;
      margin-bottom: 80px;
      .image-frame {
        height: 300px;
      }
    }
  }
  @media only screen and (min-width: 1650px) {
    .price-cards {
      &.brand {
        grid-template-columns: repeat(4, minmax(0, 1fr));
        max-width: 1800px;
      }
    }
  }
`

export default Pricing
