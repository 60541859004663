import {
  EditOutlined,
  SearchOutlined,
  SaveOutlined,
  UndoOutlined,
  DeleteOutlined,
  CopyOutlined,
  CloseCircleFilled,
  StarFilled,
} from '@ant-design/icons'
import { Button, Drawer, message } from 'antd'
import axios from 'axios'
import { Formik, Form } from 'formik'
import { Input, Select, Checkbox, InputNumber, DatePicker } from 'formik-antd'
import React, { useState, useRef, useContext } from 'react'
import { queryCache } from 'react-query'
import styled from 'styled-components/macro'

import { NODE_URL } from '../../../../constants'
import { BrandContext } from '../../../../contexts/BrandContext'
import FormItem from '../../../forms/FormItem'

const { Option } = Select

export const EditOptInViewDrawer = ({
  campaign,
  activeView,
  setActiveView,
  filtersOpen,
  setFiltersOpen,
  formData,
  setFormData,
  setNewTitle,
  sortOptions,
  unsavedChanges,
  optInViews,
  setDeleteModalVisible,
}) => {
  const { brandId } = useContext(BrandContext)
  const [saving, setSaving] = useState(false)
  const submitRef = useRef(0)

  const statusOptions = [
    { label: 'Pending', value: 'pending' },
    { label: 'Activated', value: 'activated' },
    { label: 'Completed', value: 'completed' },
    { label: 'Cancelled', value: 'cancelled' },
  ]

  const handleUpdateView = async values => {
    setSaving(true)
    try {
      const view = await axios
        .put(`${NODE_URL}/brand/${brandId}/campaign/opt-in-view/${activeView.id}`, {
          title: values.title,
          filters: formData,
        })
        .then(res => res.data.view)
      queryCache.invalidateQueries(['opt-in-views', campaign.id])
      setNewTitle('') // reset state to clear unsaved changes indicator
      setActiveView(view)
      setFiltersOpen(false)
      message.success('View updated')
    } catch (err) {
      message.error('Error updating view')
    }
    setSaving(false)
  }

  const handleDuplicateView = async id => {
    setFiltersOpen(false)
    try {
      const res = await axios.post(
        `${NODE_URL}/brand/${brandId}/campaign/opt-in-view/${id}/duplicate`
      )
      queryCache.invalidateQueries(['opt-in-views', campaign.id])
      setActiveView(res.data)
    } catch (err) {
      message.error('Error duplicating view')
    }
  }

  const handleSubmit = async values => {
    // automatically applying filters to fetch opt ins (not saving view settings)
    submitRef.current++
    const thisSubmit = submitRef.current
    setTimeout(() => {
      if (thisSubmit === submitRef.current) {
        const optInFilters = Object.fromEntries(
          // ignore "title" input value (not a filter)
          Object.entries(values).filter(([key]) => key !== 'title')
        )
        setFormData(prev => ({
          ...prev,
          ...optInFilters,
        }))
      }
    }, 300)
  }

  return (
    <Drawer
      title={`Customize View: "${activeView.title}"`}
      open={filtersOpen}
      destroyOnClose
      onClose={() => setFiltersOpen(false)}
      width='450px'>
      <Formik
        initialValues={{
          title: activeView.title,
          ...formData,
        }}
        onSubmit={handleSubmit}>
        {({ values, setValues, setFieldValue, submitForm }) => (
          <Form>
            <Filters>
              <FormItem size='small' name='title' label='View Title' required>
                <Input
                  name='title'
                  maxLength={30}
                  minLength={1}
                  prefix={<EditOutlined />}
                  onChange={e => {
                    setNewTitle(e.target.value)
                  }}
                />
              </FormItem>

              <div className='border' />

              <h3>Filters</h3>

              <FormItem size='small' name='search' label='Search'>
                <Input
                  onChange={submitForm}
                  name='search'
                  placeholder='Name, email, username, or chat content'
                  allowClear
                  prefix={<SearchOutlined />}
                />
              </FormItem>
              <FormItem size='small' name='sort' label='Sort By'>
                <Select
                  name='sort'
                  placeholder='Select'
                  style={{ width: '100%' }}
                  onChange={submitForm}>
                  {sortOptions.map(option => (
                    <Option key={option.value} value={option.value}>
                      {option.label}
                    </Option>
                  ))}
                </Select>
              </FormItem>
              <div className='form-row-wrapper'>
                <FormItem size='small' name='fromDate' label='From Date' width={'100%'}>
                  <DatePicker
                    name='fromDate'
                    onChange={submitForm}
                    style={{ width: '100%' }}
                    allowClear
                    placeholder='Select'
                  />
                </FormItem>
                <FormItem size='small' name='toDate' label='To Date' width={'100%'}>
                  <DatePicker
                    name='toDate'
                    onChange={submitForm}
                    style={{ width: '100%' }}
                    allowClear
                    placeholder='Select'
                  />
                </FormItem>
              </div>
              <div>
                <FormItem size='small' name='status' label='Status'>
                  <Select
                    name='status'
                    mode='multiple'
                    placeholder='Select'
                    showArrow
                    allowClear
                    style={{ width: '100%' }}
                    onChange={e => {
                      setValues(prev => ({
                        ...prev,
                        status: e,
                        // reset status specific filters
                        currentStep: !e.includes('activated') ? null : prev.currentStep,
                        actionable: !e.includes('activated') ? null : prev.actionable,
                        cancelledReason: !e.includes('cancelled') ? null : prev.cancelledReason,
                      }))
                      submitForm()
                    }}>
                    {statusOptions.map(option => (
                      <Option key={option.value} value={option.value}>
                        {option.label}
                      </Option>
                    ))}
                  </Select>
                </FormItem>
                <Checkbox
                  name='favoritedDate'
                  onChange={e => {
                    setFieldValue('favoritedDate', e.target.checked ? true : null)
                    submitForm()
                  }}>
                  Favorites <StarFilled style={{ color: '#fadb14' }} />
                </Checkbox>
              </div>
              {formData.status?.includes('activated') && !!campaign?.steps?.length && (
                <div>
                  <FormItem
                    size='small'
                    name='currentStep'
                    label='Current Step'
                    info='For activated opt-ins'>
                    <Select
                      name='currentStep'
                      placeholder='Select'
                      style={{ width: '100%' }}
                      showArrow
                      allowClear
                      onChange={e => {
                        setFieldValue('currentStep', e ? [e] : null)
                        submitForm()
                      }}>
                      {campaign.steps.map(step => (
                        <Option key={step.id} value={step.id}>
                          {`#${step.placement}: ${
                            step.step?.title?.replace('Old: ', '') || step.customStepTitle
                          }`}
                        </Option>
                      ))}
                    </Select>
                  </FormItem>
                  <Checkbox name='actionable' onChange={submitForm}>
                    Action Required
                  </Checkbox>
                </div>
              )}
              {formData.status?.includes('cancelled') && (
                <FormItem size='small' name='cancelledReason' label='Cancelled Reason'>
                  <Select
                    name='cancelledReason'
                    placeholder='Select'
                    style={{ width: '100%' }}
                    showArrow
                    allowClear
                    onChange={submitForm}>
                    <Option key='declined' value='declined'>
                      Declined
                    </Option>
                    <Option key='expired' value='expired'>
                      Expired
                    </Option>
                  </Select>
                </FormItem>
              )}
              {!!campaign.socialChannels?.length && (
                <>
                  <div className='form-row-wrapper'>
                    <FormItem
                      size='small'
                      name='minFollowers'
                      label={`Min ${
                        campaign.socialChannels[0] === 'youtube' ? 'Subscribers' : 'Followers'
                      }`}>
                      <div className='input-wrapper'>
                        <InputNumber name='minFollowers' onChange={submitForm} allowClear />
                        <button
                          className='clear-input-btn'
                          disabled={!values.minFollowers}
                          onClick={() => {
                            setFieldValue('minFollowers', null)
                            submitForm()
                          }}>
                          <CloseCircleFilled />
                        </button>
                      </div>
                    </FormItem>
                    <FormItem
                      size='small'
                      name='maxFollowers'
                      label={`Max ${
                        campaign.socialChannels[0] === 'youtube' ? 'Subscribers' : 'Followers'
                      }`}>
                      <div className='input-wrapper'>
                        <InputNumber name='maxFollowers' onChange={submitForm} allowClear />
                        <button
                          className='clear-input-btn'
                          disabled={!values.maxFollowers}
                          onClick={() => {
                            setFieldValue('maxFollowers', null)
                            submitForm()
                          }}>
                          <CloseCircleFilled />
                        </button>
                      </div>
                    </FormItem>
                  </div>
                  <div className='form-row-wrapper'>
                    <FormItem size='small' name='minEngagement' label='Min Engagement'>
                      <div className='input-wrapper'>
                        <InputNumber
                          name='minEngagement'
                          onChange={submitForm}
                          allowClear
                          formatter={value => value && `${value}%`}
                          parser={value => value.replace('%', '')}
                        />
                        <button
                          className='clear-input-btn'
                          disabled={!values.minEngagement}
                          onClick={() => {
                            setFieldValue('minEngagement', null)
                            submitForm()
                          }}>
                          <CloseCircleFilled />
                        </button>
                      </div>
                    </FormItem>
                    <FormItem size='small' name='maxEngagement' label='Max Engagement'>
                      <div className='input-wrapper'>
                        <InputNumber
                          name='maxEngagement'
                          onChange={submitForm}
                          allowClear
                          formatter={value => value && `${value}%`}
                          parser={value => value.replace('%', '')}
                        />
                        <button
                          className='clear-input-btn'
                          disabled={!values.maxEngagement}
                          onClick={() => {
                            setFieldValue('maxEngagement', null)
                            submitForm()
                          }}>
                          <CloseCircleFilled />
                        </button>
                      </div>
                    </FormItem>
                  </div>
                </>
              )}

              {campaign.maxPaidAmount && campaign.metaData?.paymentBeta && (
                <FormItem size='small' name='requestedPaymentStatus' label='Payment Negotiation'>
                  <Select
                    name='requestedPaymentStatus'
                    placeholder='Select'
                    style={{ width: '100%' }}
                    showArrow
                    allowClear
                    onChange={submitForm}>
                    <Option value='pending'>Offer Pending</Option>
                    <Option value='accepted'>Offer Accepted</Option>
                    <Option value='declined'>Offer Declined</Option>
                  </Select>
                </FormItem>
              )}

              <div className='buttons'>
                <Button
                  type='primary'
                  icon={<SaveOutlined />}
                  loading={saving}
                  disabled={!unsavedChanges}
                  onClick={() => {
                    handleUpdateView(values)
                  }}>
                  Save Changes
                </Button>
                <Button
                  type='secondary'
                  icon={<UndoOutlined />}
                  disabled={!unsavedChanges}
                  onClick={() => {
                    setValues({ title: activeView.title, ...activeView?.filters })
                    setFormData({ ...activeView?.filters })
                  }}>
                  Reset
                </Button>
              </div>

              <div className='border' />

              <div className='misc-actions'>
                <Button
                  type='link'
                  onClick={() => {
                    handleDuplicateView(activeView.id)
                  }}>
                  <CopyOutlined /> Duplicate View
                </Button>
                {optInViews?.length > 1 && (
                  <Button
                    type='link'
                    style={{ color: 'red' }}
                    onClick={() => {
                      setDeleteModalVisible(true)
                    }}>
                    <DeleteOutlined /> Delete View
                  </Button>
                )}
              </div>
            </Filters>
          </Form>
        )}
      </Formik>
    </Drawer>
  )
}

const Filters = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 40px;
  h3 {
    font-size: 1.1rem;
    font-weight: bold;
    margin: 10px 0 0 0;
  }
  .form-row-wrapper {
    display: flex;
    gap: 20px;
  }
  .input-wrapper {
    display: flex;
    align-items: center;
    gap: 8px;
  }
  .clear-input-btn {
    background: transparent;
    border: 0;
    padding: 0;
    font-size: 0.8rem;
    color: #ccc;
    &:hover {
      color: #bbb;
    }
  }
  .buttons {
    display: flex;
    gap: 12px;
    margin-bottom: 10px;
  }
  .misc-actions {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
  }
`
