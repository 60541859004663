import React from 'react'
import styled from 'styled-components'

const OptInExtras = ({ optIn }) => {
  return (
    <Wrapper>
      <div className='extra-content'>
        <div className='title'>Extra Content</div>
        <div className='inner'>
          {optIn.extraData.extraContent ? (
            optIn.extraData.extraContent.map((item, i) => (
              <div className='file' key={i}>
                <a href={item.src} target='_blank' rel='noopener noreferrer'>
                  {item.thumbnail ? (
                    <img style={{ maxWidth: '100%' }} src={item.thumbnail[0]} alt={item.name} />
                  ) : (
                    <span>{item.name}</span>
                  )}
                </a>
                <div className='caption'>
                  {item.caption?.split('\n').map((item, index) => (
                    <p key={index}>{item}</p>
                  ))}
                </div>
              </div>
            ))
          ) : (
            <div className='no-results'>No extra content has been submitted.</div>
          )}
        </div>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  padding: 20px;
  .title {
    font-weight: bold;
    font-size: 20px;
    text-align: center;
    margin-bottom: 12px;
  }
  .extra-content {
    .inner {
      background-color: #f7f7f7;
      border-radius: 2px;
      padding: 12px;
    }

    .file {
      margin-bottom: 16px;

      .caption {
        margin: 12px 0;
        font-size: 16px;
      }

      textarea {
        margin-bottom: 12px;
      }
    }

    .no-results {
      min-height: 200px;
      display: grid;
      place-items: center;
      color: gray;
    }
  }
`

export default OptInExtras
