import React from 'react'

import ContactInformation from './ContactInformation'
import { useDocumentTitle } from '../../custom-hooks/useDocumentTitle'
import BrandEmailSettings from '../account/BrandEmailSettings'
import { Wrapper } from '../account/BrandSettings'

export const UserSettings = () => {
  useDocumentTitle('My Profile | Brand Dashboard')

  return (
    <Wrapper>
      <h1>My Profile Settings</h1>
      <ContactInformation />
      <BrandEmailSettings />
      {/* TODO: ? */}
      {/* <ChangePassword /> */}
    </Wrapper>
  )
}
