import { Empty } from 'antd'
import { Select } from 'formik-antd'
import numeral from 'numeral'
import React, { useContext } from 'react'
import { useQuery } from 'react-query'
import { ComposedChart, Legend, Line, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import * as yup from 'yup'

import { CustomizedDot } from './CustomizedDot'
import FormItem from '../../../components/forms/FormItem'
import { BrandContext } from '../../../contexts/BrandContext'
import { useAnalyticsApi } from '../../../custom-hooks/analyticsApi'
import { theme } from '../../../GlobalStyles'
import { BrandIdFilter } from '../filters/BrandIdFilter'
import { CampaignIdFilter } from '../filters/CampaignIdFilter'
import { DateRangeFilter } from '../filters/DateRangeFilter'
import { ManualDateRangeFilter } from '../filters/ManualDateRangeFilter'
import { ManageWidget } from '../ManageWidget'
import { useCampaignOptions } from '../utils'
import { MetricsWrapper, WidgetCard } from '../WidgetCard'

export const GoogleAnalyticsSalesVsPosts = ({
  widget,
  activeView,
  setActiveView,
  brandData,
  data,
}) => {
  const analyticsApi = useAnalyticsApi()
  const { brandId } = useContext(BrandContext)

  const campaignOptions = useCampaignOptions(brandData)

  const defaultValues = {
    title: widget.defaultTitle,
    ...widget.defaultFilters,
  }

  const initialValues = {
    title: widget.title || defaultValues.title,
    brandIds: widget.filters?.brandIds || defaultValues.brandIds,
    campaignIds: widget.filters?.campaignIds || defaultValues.campaignIds,
    fromDate: widget.filters?.fromDate || defaultValues.fromDate,
    toDate: widget.filters?.toDate || defaultValues.toDate,
    dateRange:
      widget.filters?.fromDate ||
      defaultValues.fromDate ||
      widget.filters?.toDate ||
      defaultValues.toDate
        ? 'custom'
        : widget.filters?.dateRange || defaultValues.dateRange,
    googleAnalyticsMetrics:
      widget.filters?.googleAnalyticsMetrics || defaultValues.googleAnalyticsMetrics,
    conversions: widget.filters?.conversions || defaultValues.conversions,
    events: widget.filters?.events || defaultValues.events,
  }

  const validationSchema = yup.object().shape({
    title: yup.string().required('Required').max(50, 'Must be 50 characters or less'),
    brandIds: yup.array(),
    campaignIds: yup.array(),
    dateRange: yup.string(),
    googleAnalyticsMetrics: yup.array(),
    conversions: yup.array(),
    events: yup.array(),
  })

  const { data: conversions, status: conversionStatus } = useQuery(
    ['analytics-conversions', brandId],
    () =>
      analyticsApi.integration
        .integrationGa4SKeysConversionsDetail(brandId)
        .then(d => d.conversions)
  )
  const { data: events, status: eventsStatus } = useQuery(
    ['google-analytics-events', brandId],
    () => analyticsApi.integration.integrationGa4SKeysEventsDetail(brandId).then(d => d.events)
  )

  const constructData = values => {
    return {
      title: values.title,
      filters: {
        brandIds: values.brandIds,
        campaignIds: values.campaignIds,
        fromDate: values.fromDate,
        toDate: values.toDate,
        dateRange: values.dateRange,
        googleAnalyticsMetrics: values.googleAnalyticsMetrics,
        conversions: values.conversions,
        events: values.events,
      },
    }
  }

  const labelForMetrics = (metric, label) => {
    if (metric === 'events' && widget?.filters?.events?.length > 0) {
      return `Events (${widget.filters.events.slice(0, 2).join(', ')}${
        widget.filters.events.length > 2 ? ', ...' : ''
      })`
    }
    if (metric === 'conversions' && widget?.filters?.conversions?.length > 0) {
      return `Conversions (${widget.filters.conversions.slice(0, 2).join(', ')}${
        widget.filters.conversions.length > 2 ? ', ...' : ''
      })`
    }
    return label
  }

  // using different hues for a11y
  const metricOptions = [
    { label: 'Events', value: 'events', color: '#027df0' },
    { label: 'Traffic', value: 'traffic', color: '#c367dd' },
    { label: 'Conversions', value: 'conversions', color: '#ff53a2' },
    { label: 'Reach', value: 'reach', color: '#ff705a' },
    { label: 'Impressions', value: 'impressions', color: '#ffa600' },
  ]

  const Filters = ({ values, setFieldValue }) => {
    return (
      <>
        {!!brandData.childBrands?.length && <BrandIdFilter brandData={brandData} />}
        <CampaignIdFilter campaignOptions={campaignOptions} />

        <div className='date-range-wrapper'>
          <ManualDateRangeFilter setFieldValue={setFieldValue} />
          <DateRangeFilter
            setFieldValue={setFieldValue}
            optionsOverride={[
              { label: 'All time', value: 'allTime' },
              { label: 'Last 60 days', value: 'last60days' },
              { label: 'Last 3 months', value: 'last3months' },
              { label: 'Last 6 months', value: 'last6months' },
              { label: 'Last 12 months', value: 'last12months' },
              { label: 'Custom', value: 'custom' },
            ]}
          />
        </div>

        <FormItem
          size='small'
          name='googleAnalyticsMetrics'
          label='Google Analytics Metrics'
          subtext={`Select the metrics you'd like to compare against the performance of your social media posts.`}>
          <Select
            getPopupContainer={trigger => trigger.parentNode}
            virtual={false}
            name='googleAnalyticsMetrics'
            placeholder='Select'
            mode='multiple'
            style={{ width: '100%' }}
            options={metricOptions}
            onChange={e => {
              if (!e.includes('conversions')) {
                setFieldValue('conversions', [])
              }
              if (!e.includes('events')) {
                setFieldValue('events', [])
              }
            }}
          />
        </FormItem>

        {values.googleAnalyticsMetrics?.includes('conversions') && (
          <FormItem size='small' name='conversions' label='Conversions'>
            <Select
              getPopupContainer={trigger => trigger.parentNode}
              virtual={false}
              name='conversions'
              placeholder='Select'
              mode='multiple'
              loading={conversionStatus === 'loading'}
              style={{ width: '100%' }}
              options={conversions?.map(v => ({ label: v, value: v }))}
            />
          </FormItem>
        )}

        {values.googleAnalyticsMetrics?.includes('events') && (
          <FormItem size='small' name='events' label='Events'>
            <Select
              getPopupContainer={trigger => trigger.parentNode}
              virtual={false}
              name='events'
              placeholder='Select'
              mode='multiple'
              loading={eventsStatus === 'loading'}
              style={{ width: '100%' }}
              options={events?.map(v => ({ label: v, value: v }))}
            />
          </FormItem>
        )}
      </>
    )
  }

  const Metrics = () => {
    const overviewData = [
      {
        label: 'Total Opt-Ins',
        value: numeral(data?.totals?.totalOptins || 0).format('0a'),
      },
      {
        label: 'Total Activated',
        value: numeral(data?.totals?.activatedOptins || 0).format('0a'),
      },
      {
        label: 'Average Following',
        value: numeral(data?.totals?.avgFollower || 0).format('0a'),
      },
      {
        label: 'Average Engagement',
        value: `${numeral(data?.totals?.avgEngagement || 0).format('0a')}%`,
      },
      {
        label: 'Total Content',
        value: numeral(data?.totals?.content || 0).format('0a'),
      },
      {
        label: 'Total Reach',
        value: numeral(data?.totals?.reach || 0).format('0a'),
      },
      {
        label: 'Impressions',
        value: numeral(data?.totals?.impressions || 0).format('0a'),
      },
      {
        label: 'Sales',
        value: numeral(data?.totals?.sales || 0).format('0a'),
      },
      // TODO: implement in backend?
      // {
      //   label: 'Leading Influencers',
      //   value: '@influencer1, @influencer2, @influencer3',
      // },
      // {
      //   label: 'Best Performing Link',
      //   value: 'https://google.com',
      // },
    ]

    return (
      <MetricsWrapper className='overflow-auto'>
        {data?.points?.length ? (
          <>
            <div className='overview'>
              {overviewData.map((data, index) => (
                <div key={index} className='overview-item'>
                  <p className='label'>{data.label}</p>
                  <p className='value'>{data.value}</p>
                </div>
              ))}
            </div>

            <ResponsiveContainer width='100%' minWidth={600} height={400}>
              <ComposedChart data={data?.points}>
                <XAxis dataKey='period' stroke={theme.crcoGrey} />
                <YAxis yAxisId='left' orientation='left' stroke={theme.crcoMidnight} />
                <YAxis
                  yAxisId='right'
                  orientation='right'
                  stroke={theme.crcoGrey}
                  tickFormatter={value => numeral(value).format('0a')}
                />
                <Tooltip
                  cursor={false} // removes the cursor (vertical line that appears when you hover over the chart)
                  formatter={value => value.toLocaleString()}
                />
                <Legend />
                <Line
                  yAxisId='left'
                  type='monotone'
                  name='Posts'
                  dataKey='posts'
                  stroke={theme.crcoMidnight}
                  isAnimationActive={false}
                  dot={<CustomizedDot />}
                />
                {metricOptions
                  .map(dataType => {
                    const val =
                      widget.filters?.googleAnalyticsMetrics?.find(
                        option => option === dataType.value
                      ) || !widget.filters?.googleAnalyticsMetrics?.length
                    if (!val) return
                    return (
                      <Line
                        key={dataType.label}
                        yAxisId='right'
                        type='monotone'
                        name={labelForMetrics(dataType.value, dataType.label)}
                        dataKey={dataType.value}
                        stroke={dataType.color}
                        isAnimationActive={false}
                      />
                    )
                  })
                  .filter(v => !!v)}
              </ComposedChart>
            </ResponsiveContainer>
          </>
        ) : widget.filters?.googleAnalyticsMetrics?.length ? (
          <div className='no-data'>
            <Empty description='No data.' />
          </div>
        ) : (
          <div className='no-data'>
            <Empty description='Please select metrics to compare.' />
          </div>
        )}
      </MetricsWrapper>
    )
  }

  const settingsProps = {
    widget,
    Filters,
    defaultValues,
    initialValues,
    validationSchema,
    constructData,
    activeView,
    setActiveView,
    brandId: brandData.id,
  }

  const cardProps = {
    widget,
    settings: <ManageWidget {...settingsProps} />,
    metrics: <Metrics />,
    size: 'large',
    brandData,
  }

  return <WidgetCard {...cardProps} />
}
