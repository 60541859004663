import { PlusOutlined } from '@ant-design/icons'
import { Button, message, Drawer, Empty } from 'antd'
import axios from 'axios'
import React, { useContext, useState } from 'react'
import { queryCache } from 'react-query'
import styled from 'styled-components'

import CampaignStep from './CampaignStep'
import StepList from './StepList'
import { NODE_URL } from '../../constants'
import { BrandContext } from '../../contexts/BrandContext'

const CampaignSteps = ({ campaignId, campaignSteps }) => {
  const { brandId } = useContext(BrandContext)
  const [steps, setSteps] = useState(campaignSteps)
  const [addStepOpen, setAddStepOpen] = useState(false)
  const [loading, setLoading] = useState(false)

  const orderedSteps = steps
    .filter(step => step.placement)
    .sort((a, b) => a.placement - b.placement)

  const saveSteps = async stepsData => {
    setLoading(true)
    const steps = stepsData.map(step => {
      const { ...stepData } = step
      return stepData
    })
    axios
      .put(`${NODE_URL}/brand/${brandId}/campaign/${campaignId}/steps`, { steps })
      .then(res => {
        const savedSteps = res.data?.steps
        setSteps(savedSteps)
        message.success({ content: 'Steps Updated', maxCount: 1 })
        queryCache.invalidateQueries('campaign')
      })
      .catch(err => {
        message.warning(err?.response?.data?.err || 'Something went wrong updating the steps')
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const addStep = async step => {
    const placedSteps = steps.map(step => step.placement).filter(i => !!i)
    const placement = placedSteps.length ? Math.max(...placedSteps) + 1 : 1
    const structuredSteps = [...steps, { placement, ...step }]
    await saveSteps(structuredSteps)
  }

  const swapSteps = async (step, move) => {
    const updatedSteps = steps.map(thisStep => {
      const thisPlacement = thisStep.placement,
        currentPlacement = step.placement
      if (thisStep.id === step.id) {
        return {
          ...thisStep,
          placement: thisPlacement + move,
        }
      } else if (thisPlacement === currentPlacement + move) {
        return {
          ...thisStep,
          placement: currentPlacement,
        }
      } else {
        return thisStep
      }
    })
    await saveSteps(updatedSteps)
  }

  return (
    <Wrapper>
      <div className='section-wrapper steps-section'>
        {orderedSteps?.length ? (
          orderedSteps.map((step, i) => (
            <CampaignStep
              key={step.id}
              step={step}
              steps={steps}
              index={i}
              brandId={brandId}
              setSteps={setSteps}
              saveSteps={saveSteps}
              setLoading={setLoading}
              swapSteps={move => swapSteps(step, move)}
              editStep={step => saveSteps([step])}
              close={() => setAddStepOpen(false)}
              campaignId={campaignId}
            />
          ))
        ) : (
          <Empty description='No steps added yet' />
        )}

        <div className='add-step-btn'>
          <Button
            loading={loading}
            onClick={() => setAddStepOpen(true)}
            icon={<PlusOutlined />}
            type='primary'>
            {loading ? 'Saving' : 'Add Step'}
          </Button>
        </div>
      </div>

      <Drawer
        open={addStepOpen}
        title='Add Step'
        onClose={() => setAddStepOpen(false)}
        destroyOnClose
        width={600}
        footer={null}>
        <StepList close={() => setAddStepOpen(false)} addStep={addStep} />
      </Drawer>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  margin: 20px 0;
  .add-step-btn {
    display: flex;
    justify-content: center;
    margin: 20px auto 0 auto;
  }
`

export default CampaignSteps
