import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons'
import { Button, Empty, message, Modal, Tag } from 'antd'
import axios from 'axios'
import { Formik } from 'formik'
import { Mentions, Form, Input } from 'formik-antd'
import React, { useState, useContext } from 'react'
import { queryCache, useQuery } from 'react-query'
import styled from 'styled-components'
import * as yup from 'yup'

import FormItem from '../../components/forms/FormItem'
import { NODE_URL } from '../../constants'
import { BrandContext } from '../../contexts/BrandContext'
import { UserContext } from '../../contexts/UserContext'

const ChatTemplate = () => {
  const { Option } = Mentions
  const { nodeToken } = useContext(UserContext)
  const [createModalOpen, setCreateModalOpen] = useState(false)
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)
  const [editModalOpen, setEditModalOpen] = useState(false)
  const { brandId, fetchBrandData } = useContext(BrandContext)

  const { data: brandData } = useQuery(['brand', brandId], fetchBrandData)

  const options = (
    <>
      <Option style={{ fontFamily: 'Campton-Medium' }} value='firstName}}'>
        FirstName
      </Option>
      <Option style={{ fontFamily: 'Campton-Medium' }} value='lastName}}'>
        LastName
      </Option>
      <Option style={{ fontFamily: 'Campton-Medium' }} value='fullName}}'>
        FullName
      </Option>
      <Option style={{ fontFamily: 'Campton-Medium' }} value='email}}'>
        Email
      </Option>
      <Option style={{ fontFamily: 'Campton-Medium' }} value='phone}}'>
        Phone
      </Option>
      <Option style={{ fontFamily: 'Campton-Medium' }} value='brandName}}'>
        BrandName
      </Option>
      <Option style={{ fontFamily: 'Campton-Medium' }} value='campaignName}}'>
        CampaignName
      </Option>
    </>
  )

  const handleDelete = templateId => {
    axios
      .delete(`${NODE_URL}/brand/${brandId}/message-templates/${templateId}`, {
        data: {
          type: 'brandTemplate',
        },
        headers: { Authorization: `Bearer ${nodeToken}` },
      })
      .then(res => {
        message.success(res.data.message)
        queryCache.invalidateQueries('brand')
      })
      .catch(err => {
        message.error(
          err?.response?.data?.error ||
            'Chat template could not be deleted. Please try again later.'
        )
      })
      .finally(() => {
        setDeleteModalOpen(false)
      })
  }

  return (
    <Wrapper className='account-section' id='chat-templates'>
      <div className='section-header'>
        <span className='section-title'>Chat Templates</span>
        <Button type='primary' onClick={() => setCreateModalOpen(true)} icon={<PlusOutlined />}>
          New
        </Button>
      </div>
      <div className='section-body'>
        <div className='templates-section'>
          <div className='templates'>
            {brandData && brandData?.messageTemplate?.length ? (
              brandData?.messageTemplate?.map((template, index) => (
                <>
                  <div className='template-container' key={index}>
                    <div className='template'>
                      <div className='template-label'>{template.label}</div>
                      <div className='template-name'>{template.template}</div>
                    </div>
                    <div className='template-actions'>
                      <Button onClick={() => setEditModalOpen(template.id)}>
                        <EditOutlined />
                      </Button>
                      <Button type='danger' onClick={() => setDeleteModalOpen(template.id)}>
                        <DeleteOutlined />
                      </Button>
                    </div>
                  </div>

                  <StyledModal
                    title='Delete Template'
                    open={deleteModalOpen === template.id}
                    onCancel={() => setDeleteModalOpen(false)}
                    okText={'Delete'}
                    onOk={() => handleDelete(template.id)}>
                    <p>Are you sure you want to delete this template?</p>
                  </StyledModal>

                  <StyledModal
                    title='Edit Template'
                    open={editModalOpen === template.id}
                    onCancel={() => setEditModalOpen(false)}
                    footer={null}>
                    <Formik
                      initialValues={{ label: template.label, template: template.template }}
                      validationSchema={yup.object().shape({
                        template: yup
                          .string()
                          .required('Please enter a template')
                          .min(10, 'Template is too short'),
                        label: yup
                          .string()
                          .required('Please enter a label')
                          .min(3, 'Label is too short'),
                      })}
                      onSubmit={(data, { setSubmitting }) => {
                        axios
                          .put(`${NODE_URL}/brand/${brandId}/message-templates/${template.id}`, {
                            type: 'brandTemplate',
                            label: data.label,
                            template: data.template,
                          })
                          .then(res => {
                            message.success(res.data.message)
                            queryCache.invalidateQueries('brand')
                          })
                          .catch(err => {
                            message.error(
                              err?.response?.data?.message ||
                                'Chat template could not be edited. Please try again later.'
                            )
                          })
                          .finally(() => {
                            setSubmitting(false)
                            setEditModalOpen(false)
                            setDeleteModalOpen(false)
                          })
                      }}>
                      {({ isSubmitting, dirty, resetForm }) => (
                        <Form>
                          <FormItem label='Label' name='label'>
                            <Input
                              name='label'
                              placeholder='e.g. Greeting'
                              initialValues={{ label: template.label }}
                            />
                          </FormItem>
                          <FormItem label='Message' name='chatTemplate'>
                            <Mentions
                              name='template'
                              initialValues={{ template: template.template }}
                              rows={6}
                              autoSize={{ minRows: 6, maxRows: 10 }}
                              placeholder='Example: Hello &#x7B;&#x7B;firstName&#x7D;&#x7D; becomes "Hello Jane"'
                              prefix={['{{']}>
                              {options}
                            </Mentions>
                          </FormItem>
                          <div className='nav-buttons'>
                            <Button
                              type='secondary'
                              disabled={isSubmitting || !dirty}
                              onClick={resetForm}>
                              Reset
                            </Button>
                            <Button
                              type='primary'
                              htmlType='submit'
                              disabled={isSubmitting || !dirty}
                              loading={isSubmitting}>
                              Save
                            </Button>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </StyledModal>
                </>
              ))
            ) : (
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description='No chat templates yet.' />
            )}
          </div>
          <CreateTemplateModal
            brandId={brandId}
            createModalOpen={createModalOpen}
            setCreateModalOpen={setCreateModalOpen}
          />
        </div>
      </div>
    </Wrapper>
  )
}

export const CreateTemplateModal = ({
  brandId,
  createModalOpen,
  setCreateModalOpen,
  chatId = '',
  campaign = '',
  setSelectedTemplateId,
}) => {
  const options = (
    <>
      <Option style={{ fontFamily: 'Campton-Medium' }} value='firstName}}'>
        FirstName
      </Option>
      <Option style={{ fontFamily: 'Campton-Medium' }} value='lastName}}'>
        LastName
      </Option>
      <Option style={{ fontFamily: 'Campton-Medium' }} value='fullName}}'>
        FullName
      </Option>
      <Option style={{ fontFamily: 'Campton-Medium' }} value='email}}'>
        Email
      </Option>
      <Option style={{ fontFamily: 'Campton-Medium' }} value='phone}}'>
        Phone
      </Option>
      <Option style={{ fontFamily: 'Campton-Medium' }} value='brandName}}'>
        BrandName
      </Option>
      <Option style={{ fontFamily: 'Campton-Medium' }} value='campaignName}}'>
        CampaignName
      </Option>
    </>
  )

  const handleSubmit = async data => {
    try {
      const res = await axios.post(`${NODE_URL}/brand/${brandId}/message-templates`, {
        label: data.label,
        template: data.template,
      })
      message.success('Chat template created successfully')
      // TODO: refactor and remove redundant queries (use new brand message templates endpoint with 'brand-message-templates' query key)
      queryCache.invalidateQueries('brand', brandId)
      queryCache.invalidateQueries('brand-message-templates', brandId)
      chatId && queryCache.invalidateQueries('chat', chatId)
      campaign && queryCache.invalidateQueries('campaigns', campaign)
      setCreateModalOpen(false)
      setSelectedTemplateId(res.data.newTemplate.id) // for bulk message modal
    } catch (err) {
      message.error(
        err.response.data.err || 'Chat template could not be created. Please try again later.'
      )
    }
  }

  return (
    <StyledModal
      title='New Template'
      onCancel={() => setCreateModalOpen(false)}
      footer={null}
      destroyOnClose={true}
      open={createModalOpen}>
      <p>
        Chat templates are designed to streamline your collaboration efforts, providing an efficient
        way to craft personalized messages to your creators.
      </p>
      <p>
        To incorporate predefined variables in your message, simply type <Tag>&#x7B;&#x7B;</Tag> and
        select the variable you wish to use.
      </p>

      <Formik
        initialValues={{ template: '', label: '' }}
        validationSchema={yup.object().shape({
          template: yup
            .string()
            .required('Please enter a template')
            .min(10, 'Template is too short'),
          label: yup.string().required('Please enter a label for your template'),
        })}
        onSubmit={handleSubmit}>
        {({ isSubmitting, resetForm, dirty }) => (
          <Form className='template-form'>
            <FormItem name='label' label='Label'>
              <Input name='label' placeholder='e.g. Greeting' />
            </FormItem>

            <FormItem name='template' label='Message'>
              <Mentions
                name='template'
                initialValues={{ template: '' }}
                rows={6}
                autoSize={{ minRows: 6, maxRows: 10 }}
                placeholder='Example: Hello &#x7B;&#x7B;firstName&#x7D;&#x7D; becomes "Hello Jane"'
                prefix={['{{']}>
                {options}
              </Mentions>
            </FormItem>

            <div className='nav-buttons'>
              <Button type='secondary' disabled={isSubmitting || !dirty} onClick={resetForm}>
                Reset
              </Button>
              <Button type='primary' htmlType='submit' disabled={!dirty} loading={isSubmitting}>
                Create
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </StyledModal>
  )
}

export const Wrapper = styled.div`
  .account-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .section-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;
  }

  .section-title {
    font-size: 18px;
  }

  .section-body {
    .no-templates {
      padding-bottom: 10px;
    }
  }

  .templates {
    overflow: auto;
    max-height: 300px;
    ${props => props.theme.scrollbar};
  }

  .template-container {
    display: flex;
    justify-content: space-between;
    align-items: 'center';

    margin-bottom: 10px;

    &:not(:last-child) {
      padding: 5px 0;
      border-bottom: #e6e6e6 solid 1px;
    }
    .template {
      margin-bottom: 10px;
      padding: 0 10px;
      font-family: 'Campton-Medium', Verdana, sans-serif;
      .template-label {
        font-size: 16px;
      }
      .template-subject {
        font-size: 14px;
        color: ${props => props.theme.crcoGrey};
        font-weight: bold;
      }
      .template-name {
        color: ${props => props.theme.crcoGrey};
        font-size: 14px;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        word-wrap: break-word;
        word-break: break-word;
      }
      .template-info {
        color: ${props => props.theme.crcoTechBlue};
        width: fit-content;
        cursor: default;
        margin-top: 10px;
      }
    }
    .template-actions {
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
  }

  .ant-btn-danger {
    background-color: white;
    color: red;

    &:hover {
      color: #610c04;
    }
  }

  .template-actions button {
    margin-left: 10px;
    border: none;
  }
`

export const StyledModal = styled(Modal)`
  .ant-modal-body {
    padding: 20px !important;
  }
  .ant-mentions {
    #template {
      overflow: auto;
      ${props => props.theme.scrollbar}
    }
  }
  .delete-prompt-message {
    margin-bottom: 20px;
  }
  .nav-buttons {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
  }
  .create-template-description {
    padding-bottom: 10px;

    span {
      margin-left: 5px;
      font-family: monospace;
      font-weight: bold;
    }
  }
`

export default ChatTemplate
