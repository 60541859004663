import { MoreOutlined } from '@ant-design/icons'
import React from 'react'
import styled from 'styled-components/macro'

// need to spread props for antd dropdown to render properly
// https://github.com/ant-design/ant-design/issues/15528
export const ActionMenuIcon = props => {
  return (
    <Wrapper {...props}>
      <MoreOutlined />
    </Wrapper>
  )
}

const Wrapper = styled.span`
  background: ${props => (props.open ? props.theme.crcoTechBlue : 'none')};
  color: ${props => (props.open ? '#fff' : props.theme.crcoMidnight)};
  font-size: 1.2rem;
  border-radius: 10px;
  display: grid;
  place-content: center;
  height: 30px;
  width: 30px;
  margin-left: 10px;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  &:hover {
    background: ${props => props.theme.crcoTechBlue};
    color: #fff;
  }
`
