import { SaveOutlined, CloseCircleOutlined, EditOutlined } from '@ant-design/icons'
import { Button, Alert, message } from 'antd'
import { CountryPhoneInput, ConfigProvider } from 'antd-country-phone-input'
import axios from 'axios'
import { Formik, Form } from 'formik'
import { Input } from 'formik-antd'
import React, { useState, useContext } from 'react'
import { useQuery, queryCache } from 'react-query'
import en from 'world_countries_lists/data/countries/en/world.json'
import * as yup from 'yup'

import FormItem from '../../components/forms/FormItem'
import { NODE_URL } from '../../constants'
import { UserContext } from '../../contexts/UserContext'

const ContactInformation = () => {
  const { fetchCurrentUserData, nodeToken } = useContext(UserContext)
  const { data: userData } = useQuery('current-user', fetchCurrentUserData)
  const [editMode, setEditMode] = useState(false)

  const initialValues = {
    firstName: userData?.firstName,
    lastName: userData?.lastName,
    phone: userData?.phone ? userData?.phone : '',
    code: userData?.phoneCode ? userData?.phoneCode : '1',
    short: userData?.phoneShort ? userData?.phoneShort : 'US',
    email: userData?.email,
  }

  const validationSchema = yup.object().shape({
    firstName: yup.string().required('Required'),
    lastName: yup.string().required('Required'),
    phone: yup.string().required('Required'),
    email: yup.string().email('Invalid email').required('Required'),
  })

  const handleSubmit = async (values, { setStatus }) => {
    const data = {
      firstName: values.firstName,
      lastName: values.lastName,
      phone: values.phone,
      phoneCode: values.code,
      phoneShort: values.short,
      email: values.email,
    }

    try {
      await axios.post(`${NODE_URL}/me/`, data, {
        headers: {
          Authorization: `Bearer ${nodeToken}`,
        },
      })

      message.success('Settings updated')
      queryCache.invalidateQueries('current-user')
      setEditMode(false)
    } catch (err) {
      setStatus(err?.response?.data?.msg || 'Sorry, something went wrong. Please try again later.')
    }
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}>
      {({ values, setValues, isSubmitting, status }) => (
        <Form className='account-section'>
          <div className='section-header'>
            <h2 className='section-title'>Contact Info</h2>
            {!editMode ? (
              <Button type='link' onClick={() => setEditMode(true)}>
                <EditOutlined /> Edit
              </Button>
            ) : (
              <div className='buttons'>
                <Button
                  danger
                  type='link'
                  onClick={() => setEditMode(false)}
                  icon={<CloseCircleOutlined />}>
                  Cancel
                </Button>
                <Button
                  type='link'
                  htmlType='submit'
                  loading={isSubmitting}
                  icon={<SaveOutlined />}>
                  Save
                </Button>
              </div>
            )}
          </div>
          <div className='section-body'>
            <div className='form-row-wrapper'>
              <FormItem name='firstName' label='First Name' width={'100%'}>
                <Input name='firstName' disabled={!editMode} />
              </FormItem>

              <FormItem name='lastName' label='Last Name' width={'100%'}>
                <Input name='lastName' disabled={!editMode} />
              </FormItem>
            </div>

            <div className='form-row-wrapper desktop'>
              <ConfigProvider locale={en}>
                <FormItem name='phone' label='Phone Number' width={'100%'}>
                  <CountryPhoneInput
                    inline
                    autoComplete='phone'
                    name='phone'
                    placeholder='Phone Number'
                    value={values}
                    disabled={!editMode}
                    onChange={e => {
                      setValues(prev => ({ ...prev, ...e }))
                    }}
                  />
                </FormItem>
              </ConfigProvider>

              <FormItem name='email' label='Email' width={'100%'}>
                <Input name='email' disabled={!editMode} />
              </FormItem>
            </div>

            {!status || <Alert type='error' message={status} showIcon />}
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default ContactInformation
