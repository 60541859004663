import { CloseCircleOutlined, UndoOutlined, SaveOutlined, SettingOutlined } from '@ant-design/icons'
import { Button, ConfigProvider, Drawer, Dropdown, message } from 'antd'
import axios from 'axios'
import { Form, Formik } from 'formik'
import { useFlags } from 'launchdarkly-react-client-sdk'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { useQuery, queryCache } from 'react-query'
import styled from 'styled-components'

import { AppliedFilters } from './AppliedFilters'
import AudienceFilters from './AudienceFilters'
import CreatorFilters from './CreatorFilters'
import { SavedFilters } from './SavedFilters'
import SearchResults from './SearchResults'
import { ActionMenuIcon } from '../../../components/general/ActionMenuIcon'
import { NODE_URL } from '../../../constants'
import 'react-slidedown/lib/slidedown.css'
import { BrandContext } from '../../../contexts/BrandContext'
import { UserContext } from '../../../contexts/UserContext'
import { ActionMenuWrapper } from '../../../GlobalStyles'
import AddContacts from '../AddContacts'
import AddReports from '../AddReports'

const AdvancedSearch = () => {
  const flags = useFlags()
  const { brandId, fetchBrandData } = useContext(BrandContext)
  const { nodeToken } = useContext(UserContext)

  const { data: brandData } = useQuery(['brand', brandId], fetchBrandData)
  const formRef = useRef('')
  const submitRef = useRef(0)
  const [socapiFilters, setSocapiFilters] = useState(null)
  const [customList, setCustomList] = useState([])
  const [savedFiltersOpen, setSavedFiltersOpen] = useState(false)
  const [savingFilters, setSavingFilters] = useState(false)
  const [dropdownOpen, setDropdownOpen] = useState(false)

  // get saved filters from local storage
  const localFormData = localStorage.getItem('creatorSearchFilters')

  // get the brand's latest campaign for setting default filters
  const latestCampaign = brandData.campaigns
    ?.reverse()
    .filter(campaign => campaign.status === 'publish')[0]

  const defaultPlatform = latestCampaign?.socialChannels?.[0] || 'instagram'

  const getAudienceLocations = () => {
    if (!latestCampaign?.countries?.length) return undefined

    const countries = []

    // Fetching necessary data for each country from IMAI
    latestCampaign.countries.forEach(async item => {
      const { data } = await axios.get(
        `${NODE_URL}/socialdata-countries/?platform=${defaultPlatform}&val=${item.country.countryName}`
      )

      // Structuring the data for the select component
      countries.push({ key: data[0].id, label: data[0].title, value: data[0].id })
    })

    return countries
  }

  const defaultFilters = {
    platform: defaultPlatform,
    sort: 'followers-desc',
    withContact: ['email'],
    creatorFollowersMin:
      latestCampaign?.[`${latestCampaign?.socialChannels?.[0]}Reach`] || undefined,
    creatorFollowersMax: 100000,
    creatorEngagementRate:
      latestCampaign?.[`${latestCampaign?.socialChannels?.[0]}Engagement`] || undefined,
    creatorAgeRange:
      latestCampaign?.minAge || latestCampaign?.maxAge
        ? [
            !latestCampaign?.minAge || latestCampaign?.minAge < 18 ? 18 : latestCampaign?.minAge,
            latestCampaign?.maxAge || 65,
          ]
        : undefined,
    audienceLocations: getAudienceLocations(),
  }

  // setting default filters based on local storage or brand's latest campaign requirements
  const initialFilters = localFormData ? JSON.parse(localFormData) : defaultFilters

  const [formData, setFormData] = useState(initialFilters)
  const initialPlatform = initialFilters.platform || 'instagram'
  const [platform, setPlatform] = useState(initialPlatform)

  const fetchSearchResults = async (key, { brandId, formData }, page = 1) => {
    const { data } = await axios.post(`${NODE_URL}/creator-search/${brandId}/`, {
      ...formData,
      brandSubscribed: brandData?.activeSubscriptions?.length > 0,
      page,
    })
    return data
  }

  useEffect(() => {
    const fetchData = async () => {
      const interests = await axios
        .get(`${NODE_URL}/brand/${brandId}/creator-search/interests`)
        .then(res => res.data.interests)
        .catch(err => {
          console.error(err)
          message.error('Error fetching interests')
        })
      const languages = await axios
        .get(`${NODE_URL}/brand/${brandId}/creator-search/languages`, {
          params: { platform },
        })
        .then(res => res.data.languages)
        .catch(err => {
          console.error(err)
          message.error('Error fetching languages')
        })

      setSocapiFilters({ languages, interests })
    }

    fetchData()
  }, [platform])

  useEffect(() => {
    if (formData) {
      // sync current filters to local storage
      localStorage.setItem('creatorSearchFilters', JSON.stringify(formData))
    }
  }, [formData])

  const handleDefaultFilters = () => {
    // set all state to default values (latest campaign requirements)
    setCustomList([])
    setFormData(defaultFilters)
    setPlatform(defaultPlatform)
  }

  const handleClearFilters = setValues => {
    const baseFilters = {
      platform: 'instagram',
      sort: 'followers-desc',
    }
    setFormData(baseFilters)
    setValues(baseFilters)
    setPlatform('instagram')
    setCustomList([])
  }

  const handleSaveFilters = async () => {
    setSavingFilters(true)

    const data = {
      title: `My ${formData?.platform} filters`,
      filters: formData,
    }

    await axios
      .post(`${NODE_URL}/brand/${brandId}/creator-search-filter/new`, data, {
        headers: { Authorization: `Bearer ${nodeToken}` },
      })
      .then(() => {
        message.success('Filters saved')
        queryCache.invalidateQueries(['creator-search-filters', brandId])
        setSavedFiltersOpen(true)
      })
      .catch(() => {
        message.error('Error saving filters')
      })
      .finally(() => {
        setSavingFilters(false)
      })
  }

  const FilterControls = ({ setValues }) => {
    return (
      <>
        {flags.savedCreatorFilters && (
          <>
            <Button
              type='link'
              icon={<SettingOutlined />}
              onClick={() => {
                setSavedFiltersOpen(true)
              }}>
              My Filters
            </Button>
            <Button
              type='link'
              icon={<SaveOutlined />}
              onClick={handleSaveFilters}
              loading={savingFilters}>
              Save
            </Button>
          </>
        )}
        <Button
          type='link'
          icon={<UndoOutlined />}
          onClick={() => {
            handleDefaultFilters()
            setValues(defaultFilters)
          }}>
          Default
        </Button>
        <Button
          type='link'
          danger
          icon={<CloseCircleOutlined />}
          onClick={() => {
            handleClearFilters(setValues)
          }}>
          Clear
        </Button>
      </>
    )
  }

  return (
    <Wrapper>
      <ConfigProvider getPopupContainer={node => node?.parentNode || document.body}>
        {/* <Alert
          message="We're having some technical issues with our Creator Search for Instagram right now. We’re on it and hope to have things back to normal soon. Sorry for any inconvenience and thanks for your patience!"
          type='warning'
          style={{ marginBottom: '16px' }}
          showIcon
        /> */}

        <div className='credits'>
          <AddReports />
          <AddContacts />
        </div>

        <Formik
          innerRef={formRef}
          initialValues={formData}
          onSubmit={(data, { setValues }) => {
            submitRef.current++
            const thisSubmit = submitRef.current

            setTimeout(
              () => {
                if (thisSubmit === submitRef.current) {
                  setCustomList([])
                  if (data.sort.includes('relevance') && !data.audienceRelevance) {
                    data.sort = 'followers-desc'
                  } else if (data.audienceRelevance) {
                    data.sort = 'audience_relevance-desc'
                  }
                  setValues(data)
                  setFormData(data)
                }
              },
              data.platform !== formData.platform ? 0 : 300
            )
          }}>
          {({ values, setValues, submitForm }) => {
            const formChange = (value, key) => {
              setValues({ ...values, [key]: value, export: false })
              submitForm()
            }

            const appliedFiltersProps = {
              socapiFilters,
              values,
              setValues,
              submitForm,
              closable: true,
            }

            return (
              <Form>
                <section className='filters-wrapper'>
                  <div className='section-header'>
                    <h1>Filters</h1>
                    <div className='filter-controls desktop'>
                      <FilterControls setValues={setValues} />
                    </div>
                    <div className='filter-controls mobile'>
                      <Dropdown
                        overlay={
                          <ActionMenuWrapper>
                            <FilterControls setValues={setValues} />
                          </ActionMenuWrapper>
                        }
                        onOpenChange={() => setDropdownOpen(!dropdownOpen)}
                        placement='bottomRight'>
                        <ActionMenuIcon open={dropdownOpen} />
                      </Dropdown>
                    </div>

                    <Drawer
                      title='My Filters'
                      open={savedFiltersOpen}
                      onClose={() => setSavedFiltersOpen(false)}>
                      <SavedFilters
                        setValues={setValues}
                        setFormData={setFormData}
                        submitForm={submitForm}
                        setSavedFiltersOpen={setSavedFiltersOpen}
                        handleSaveFilters={handleSaveFilters}
                        savingFilters={savingFilters}
                        socapiFilters={socapiFilters}
                      />
                    </Drawer>
                  </div>

                  <h2 className='filters-title'>Search by Creator</h2>
                  <CreatorFilters
                    platform={platform}
                    setPlatform={setPlatform}
                    values={values}
                    setValues={setValues}
                    formChange={formChange}
                    socapiFilters={socapiFilters}
                    submitForm={submitForm}
                  />
                  <AppliedFilters creator {...appliedFiltersProps} />

                  <h2 className='filters-title audience'>Search by Audience</h2>
                  <AudienceFilters
                    platform={platform}
                    values={values}
                    formChange={formChange}
                    socapiFilters={socapiFilters}
                  />
                  <AppliedFilters audience {...appliedFiltersProps} />
                </section>

                <SearchResults
                  formData={formData}
                  setFormData={setFormData}
                  customList={customList}
                  setCustomList={setCustomList}
                  fetchSearchResults={fetchSearchResults}
                  handleClearFilters={handleClearFilters}
                  setValues={setValues}
                />
              </Form>
            )
          }}
        </Formik>
      </ConfigProvider>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  color: #23262f;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px;
  max-width: 1400px;
  margin: 0 auto;

  .tab-header {
    background: #fff;
    border-radius: 10px;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 20px;
    margin-bottom: 20px;
    .header-text {
      display: flex;
      align-items: center;
      gap: 12px;
    }
    .platform-icon {
      margin-left: 10px;
      height: 20px;
    }
  }
  h1 {
    font-family: 'Gilroy-ExtraBold', sans-serif;
    font-size: 1.5rem;
    margin: 0;
  }

  .credits {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    margin-bottom: 20px;
  }

  .filters-wrapper {
    background: #fff;
    padding: 20px;
    border-radius: 10px;
  }
  .section-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 20px;
    h1 {
      font-size: 20px;
    }
  }
  .filter-controls {
    display: flex;
    align-items: center;
    gap: 12px;
    &.desktop {
      display: none;
    }
  }
  .filters-title {
    font-size: 14px;
    margin-bottom: 10px;
    color: ${props => props.theme.crcoGrey};
    text-transform: uppercase;
    &.audience {
      margin-top: 40px;
    }
  }

  .creator-filters,
  .audience-filters {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    position: relative;
    gap: 12px;
    margin-bottom: 20px;
    .filter {
      background: #f6f6f6;
      border: 1px solid #e6e6e6;
      color: #999;
      width: 100%;
      border-radius: 5px;
      padding: 6px 12px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      transition: 0.2s ease-in-out;
      cursor: pointer;
      &:hover {
        color: #080830;
      }
    }
  }
  .creator-filters {
    .filter {
      &.filter-selected {
        background: #e5f3ff;
        border-color: #a3cdf4;
        color: #027df0;
      }
    }
  }
  .audience-filters {
    .filter {
      &.filter-selected {
        background: #f1ecff;
        border-color: #d6c8ff;
        color: #8364d7;
      }
    }
  }

  .explainer {
    color: #999;
    display: block;
  }

  .export {
    display: flex;
    gap: 10px;
  }

  @media only screen and (min-width: ${props => props.theme.breakpointTablet}) {
    .filter-controls {
      &.mobile {
        display: none;
      }
      &.desktop {
        display: flex;
      }
    }
  }
`

export default AdvancedSearch
