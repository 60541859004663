import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons'
import { GoogleLogin } from '@react-oauth/google'
import { Alert, Button, Modal, Carousel, message } from 'antd'
import { Formik } from 'formik'
import { Form, Input } from 'formik-antd'
import React, { useContext, useState } from 'react'
import { Redirect, Link } from 'react-router-dom'
import styled from 'styled-components/macro'
import * as Yup from 'yup'

import { ForgotPass } from './ForgotPass'
import FormItem from './forms/FormItem'
import { ImageLoader } from '../components/general/ImageLoader'
import { UserContext } from '../contexts/UserContext'
import { useDocumentTitle } from '../custom-hooks/useDocumentTitle'
import arrow from '../images/login/arrow-shape.svg'
// case study carousel images
import image1 from '../images/login/image1.png'
import image2 from '../images/login/image2.png'
import image3 from '../images/login/image3.png'
import image4 from '../images/login/image4.png'
import image5 from '../images/login/image5.png'
import logo1 from '../images/login/logo1.png'
import logo2 from '../images/login/logo2.png'
import logo3 from '../images/login/logo3.png'
import logo4 from '../images/login/logo4.png'
import logo5 from '../images/login/logo5.png'

export const Login = () => {
  useDocumentTitle('Log in | Brand Dashboard')

  const { loginHandler } = useContext(UserContext)
  const [modalVisible, setModalVisible] = useState(false)
  const [googleStatus, setGoogleStatus] = useState(false)

  const handleGoogleLogin = async googleToken => {
    setGoogleStatus('')
    const result = await loginHandler({
      googleToken,
    })
    if (result?.error) {
      setGoogleStatus(result.error)
    }
  }

  const handleSubmit = async (data, { setSubmitting, setStatus }) => {
    setStatus('')
    const result = await loginHandler(data)
    if (result?.err) {
      setStatus(result.err)
      setSubmitting(false)
    }
  }

  const LoginSchema = Yup.object().shape({
    email: Yup.string().required('Required'),
    password: Yup.string().required('Required'),
  })

  const initialValues = { email: '', password: '' }

  const carouselContent = [
    {
      brand: 'Hex',
      logo: logo1,
      image: image1,
      caption: 'Hex saw their marketing goals exceed by partnering with Creators.',
      subtext:
        'In just a few months they generated over 1.4 million impressions, 183K+ views and 8.2K+ engagements.',
    },
    {
      brand: 'Think!',
      logo: logo2,
      image: image2,
      caption: 'Creators drove a 55% + sales increase at Walmart.',
      subtext: `The organic buzz paired with a thoughtful paid ads campaign drove incredible results, including many stores and even Walmart.ca to sell out of Think! Bars!`,
    },
    {
      brand: 'Scentuals',
      logo: logo3,
      image: image3,
      caption:
        'Creators helped Scentuals achieve a 70% + sales increase across all Superstores in Canada.',
      subtext: `The skills, guidance and support provided by a Marketing expert from Creator.co saved the brand an estimated 200+ hours and over $5000 in Creator payments.`,
    },
    {
      brand: 'Aura',
      logo: logo4,
      image: image4,
      caption:
        'The creator dashboard and search tool saved the brand 5-6 hours weekly on recruiting quality creators with a Self-Serve subscription.',
    },
    {
      brand: 'Meyenberg',
      logo: logo5,
      image: image5,
      caption: `By aligning with the right creators Meyenberg's instagram following increased from 7.8k to 14.6k by repurposing high-quality interactive content.`,
    },
  ]

  return !localStorage.getItem('nodeToken') ? (
    <Wrapper>
      <div className='inner'>
        <section className='carousel'>
          <Carousel autoplay>
            {carouselContent.map((item, index) => (
              <div className='testimonial-card' key={index}>
                <img className='logo' src={item.logo} alt={item.brand} />
                <div className='image'>
                  <ImageLoader src={item.image} alt='' />
                </div>
                <div className='text'>
                  <p className='caption'>{item.caption}</p>
                  <p className='subtext'>{item.subtext}</p>
                </div>
              </div>
            ))}
          </Carousel>
        </section>
        <section className='login-form-container'>
          <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={LoginSchema}
            validateOnBlur={false}
            validateOnChange={false}>
            {({ values, status, isSubmitting }) => (
              <Form>
                <img src={arrow} alt='' className='arrow' />
                <h1>Great to see you again!</h1>
                <p className='subtext'>Please log in to access your brand dashboard.</p>
                <FormItem name='email' size='small'>
                  <Input type='text' name='email' placeholder='Email Address' />
                </FormItem>
                <FormItem name='password' size='small' data-private>
                  <Input.Password
                    name='password'
                    placeholder='Password'
                    iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                  />
                </FormItem>
                <div className='forgot-pass'>
                  <ForgotPassword onClick={() => setModalVisible(true)}>
                    <p>Forgot password?</p>
                  </ForgotPassword>
                  <Modal
                    title='Reset Password'
                    open={modalVisible}
                    onCancel={() => setModalVisible(false)}
                    destroyOnClose
                    footer={null}>
                    <ForgotPass
                      loginEmail={values.email}
                      closeModal={() => setModalVisible(false)}
                    />
                  </Modal>
                </div>
                <div className='buttons'>
                  {status && (
                    <Alert message={status} type='error' showIcon style={{ width: '100%' }} />
                  )}
                  <Button type='primary' htmlType='submit' loading={isSubmitting}>
                    Sign In
                  </Button>
                  <div className='divider'>
                    <span /> OR <span />
                  </div>
                  {!googleStatus || (
                    <Alert message={googleStatus} type='error' style={{ margin: '16px 0' }} />
                  )}
                  <GoogleLogin
                    type='standard'
                    shape='pill'
                    size='medium'
                    onSuccess={credentialResponse => {
                      handleGoogleLogin(credentialResponse.credential)
                    }}
                    onError={() => {
                      message.error('Sorry, something went wrong.')
                    }}
                  />
                </div>
                <div className='sub-links'>
                  Don&apos;t have an account? <Link to='/signup'>Sign Up</Link>
                </div>
              </Form>
            )}
          </Formik>
          <div className='sub-links creator'>
            Are you a Creator? <a href='https://app.creator.co/login'>Log In Here</a>
          </div>
        </section>
      </div>
    </Wrapper>
  ) : (
    <Redirect to='/my-dashboard' />
  )
}

export const Wrapper = styled.div`
  background: #f4f4f8;
  color: #23262f;
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  font-family: 'Poppins-Regular', sans-serif;
  .inner {
    width: 100%;
    max-width: 1400px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .carousel {
    width: 100%;
    position: absolute;
    overflow: hidden;
    .testimonial-card {
      position: relative;
      aspect-ratio: 1;
      .image {
        height: 100%;
        display: flex;
        img {
          height: 100%;
          min-width: 100%;
          object-fit: cover;
        }
      }
    }
    .logo,
    .text {
      display: none;
    }
  }
  .login-form-container {
    width: 90%;
    flex: 1;
    position: relative;
    margin: 30vh 20px 20px 20px;
    z-index: 20;
    .arrow {
      height: 80px;
      position: absolute;
      top: -55px;
      left: 50%;
      transform: translateX(-50%);
    }
    h1 {
      font-family: 'Poppins-Bold', sans-serif;
      text-align: center;
      margin: 0;
      font-size: 1.5rem;
      line-height: 1.2;
    }
    .subtext {
      font-size: 12px;
      text-align: center;
      opacity: 0.7;
      margin: 15px 0;
    }
    form {
      background: #fff;
      box-shadow: 4px 8px 20px 4px rgba(154, 175, 193, 0.12);
      border-radius: 40px;
      display: flex;
      flex-direction: column;
      padding: 40px 20px;
      width: 100%;
      .ant-input-affix-wrapper {
        background: #f4f4f8;
        border-radius: 20px;
        padding: 0;
        border: none;
        .ant-input-suffix {
          position: absolute;
          right: 0;
          height: 100%;
          width: 30px;
        }
      }
      .ant-input {
        font-family: 'Poppins-Regular', sans-serif;
        background: #f4f4f8;
        border: none;
        border-radius: 20px;
        padding: 10px 20px;
      }
      // PHONE NUMBER INPUTS (signup)
      .antd-country-phone-input .ant-input-group-addon {
        background: #f4f4f8;
        border: none;
        border-radius: 20px 0 0 20px;
      }
      .ant-input-group {
        .ant-input {
          border-radius: 0 20px 20px 0;
        }
      }
    }
    // PHONE VERIFICATION
    .phone-input-wrapper {
      position: relative;
    }
    .verify-btn {
      position: absolute;
      top: 5px;
      right: 7px;
      z-index: 1;
    }
    .verify-btn:not(:disabled) {
      background: ${props => props.theme.crcoTechBlue};
      border-color: ${props => props.theme.crcoTechBlue};
    }
    .ant-checkbox-wrapper {
      font-family: 'Poppins-Regular', sans-serif;
      font-size: 12px;
    }
    .buttons {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 12px;
      margin: 25px 0;
      button {
        height: 44px;
        font-size: 1rem;
      }
      .ant-btn-primary {
        width: 100%;
      }
      .ant-btn-primary:not(:disabled) {
        background: ${props => props.theme.crcoMidnight};
        &:hover {
          border-color: ${props => props.theme.crcoMidnight};
        }
      }
    }
    .divider {
      width: 100%;
      display: flex;
      align-items: center;
      gap: 20px;
      opacity: 0.3;
      &.large {
        margin: 15px 0;
      }
      span {
        flex: 1;
        height: 1px;
        background-color: #555;
      }
    }
    .form-label {
      font-family: 'Poppins-Bold', sans-serif;
      margin: 10px 0;
    }
    .demo-vid {
      margin-bottom: 15px;
    }
    .ant-select-selector {
      border-radius: 20px;
      min-height: 42px;
      font-family: 'Poppins-Regular', sans-serif;
      display: flex;
      align-items: center;
      padding: 0 20px;
      border: none;
      background: #f4f4f8;
    }
    .ant-select-selection-overflow {
      margin: 5px 0;
      gap: 5px;
    }
    .ant-select-multiple .ant-select-selection-item {
      margin: 0;
    }
    .ant-select-multiple .ant-select-selection-placeholder {
      left: 20px;
    }
    .nav-buttons {
      display: flex;
      align-items: center;
      gap: 12px;
      margin-top: 30px;
      button {
        flex: 1;
        height: 44px;
        font-size: 1rem;
      }
    }
    .sub-links {
      text-align: center;
      &.creator {
        margin: 20px;
        color: ${props => props.theme.crcoTechBlue};
        a {
          color: ${props => props.theme.crcoTechBlue};
        }
      }
      a {
        color: #23262f;
        font-family: 'Poppins-Bold', sans-serif;
        transition: 0.2s ease-in-out;
        &:hover,
        &:focus {
          color: ${props => props.theme.crcoTechBlue};
        }
      }
    }
  }

  // TABLET
  @media only screen and (min-width: 500px) {
    justify-content: center;
    .carousel {
      max-width: 600px;
      margin: 20px;
      .testimonial-card {
        border-radius: 0 40px 0 40px;
        overflow: hidden;
        aspect-ratio: 4/3;
      }
      .logo {
        display: block;
        position: absolute;
        top: 40px;
        right: 40px;
        max-height: 100px;
        max-width: 100px;
      }
    }
    .login-form-container {
      width: 400px;
      h1 {
        font-size: 1.7rem;
      }
      .subtext {
        font-size: 1rem;
      }
    }
  }

  // DESKTOP
  @media only screen and (min-width: 1100px) {
    &.signup {
      .inner {
        flex-direction: row;
      }
    }
    .inner {
      flex: 1;
      flex-direction: row-reverse;
      justify-content: space-evenly;
      margin: auto;
    }
    .carousel {
      position: relative;
      margin: 30px;
      max-width: 50%;
      max-height: calc(100vh - 110px);
      border-radius: 0 40px 0 40px;
      overflow: hidden;
      .testimonial-card {
        aspect-ratio: 3/4;
        margin: 0;
        max-height: calc(100vh - 110px);
      }
      .text {
        background: linear-gradient(to bottom, transparent, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.6));
        font-family: 'Poppins-Medium', sans-serif;
        width: 100%;
        color: #fff;
        position: absolute;
        bottom: 0;
        padding: 30px 30px 80px 30px;
        height: 50%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        border-radius: 0 0 0 40px;
      }
      p {
        margin: 0;
      }
      .subtext {
        font-size: 1rem;
        opacity: 0.8;
      }
      .quote-marks {
        height: 40px;
        margin: 0 auto 20px 0;
      }
      .caption {
        font-size: 30px;
        font-family: 'Poppins-Bold', sans-serif;
        margin: 5px 0;
        font-weight: normal;
        line-height: 1.2;
        margin: 10px 0;
      }
      .creator-info {
        display: flex;
        align-items: center;
        gap: 12px;
        font-size: 18px;
      }
      .avatar {
        height: 40px;
        width: 40px;
      }
      .brand-info {
        .reviewer {
          font-size: 1rem;
          opacity: 0.8;
        }
      }
      .review-site {
        margin-top: 20px;
        font-family: 'Poppins-Bold', sans-serif;
        color: gold;
        display: flex;
        align-items: center;
        gap: 12px;
        font-size: 18px;
        img {
          height: 25px;
        }
      }
    }
    .login-form-container {
      margin: 80px 30px 30px 30px; // more margin on top to account for arrow
      max-width: 500px;
      form {
        padding: 60px 40px;
      }
      .form-row-wrapper {
        display: flex;
        gap: 12px;
      }
    }
  }
`

const ForgotPassword = styled.div`
  width: fit-content;
  cursor: pointer;
  p {
    font-family: 'Poppins-Bold', sans-serif;
    font-size: 11px;
    opacity: 0.5;
    transition: 0.2s ease-in-out;
    margin: 0 10px;
    &:hover {
      color: ${props => props.theme.crcoMidnight};
    }
  }
`
