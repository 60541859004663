import { googleLogout } from '@react-oauth/google'
import axios from 'axios'
import LogRocket from 'logrocket'
import React, { createContext, useState, useEffect } from 'react'
import { queryCache } from 'react-query'

import { NODE_URL } from '../constants'

export const UserContext = createContext(null)

export const UserContextProvider = props => {
  const [nodeToken, setNodeToken] = useState(localStorage.getItem('nodeToken'))
  const [role, setRole] = useState()
  const [lgLoaded, setLgLoaded] = useState(false)

  useEffect(() => {
    nodeToken && (axios.defaults.headers.common['Authorization'] = `Bearer ${nodeToken}`)
  }, [nodeToken])

  const fetchCurrentUserData = async () => {
    const { data } = await axios
      .get(`${NODE_URL}/me`, {
        headers: { Authorization: `Bearer ${nodeToken}` },
      })
      .catch(err => {
        if (err?.response?.status === 401 && nodeToken) {
          localStorage.removeItem('nodeToken')
          window.location.replace('/login')
        }
        return null
      })

    // Record brand user sessions on production with LogRocket
    if (!lgLoaded && data.role !== 'administrator' && process.env.NODE_ENV === 'production') {
      LogRocket.init('zbp1lp/creator-co', {
        // Sanitize the request body to avoid sending sensitive data to LogRocket (such as passwords)
        requestSanitizer: request => {
          if (request.url.toLowerCase().indexOf('/login') !== -1) request.body.password = null
          return request
        },
      })

      // Identify the user
      LogRocket.identify(data.id, {
        name: `${data.firstName} ${data.lastName}`,
        email: data.email,
        // User traits (can be used to search sessions)
        type: data.role,
      })

      // Prevent LogRocket from initializing multiple times
      setLgLoaded(true)
    }

    return data
  }

  const loginHandler = async data => {
    try {
      // Nodejs login
      const nodeRes = await axios.post(`${NODE_URL}/login`, data)

      // set Node Token
      localStorage.setItem('nodeToken', nodeRes.data.token)
      setNodeToken(nodeRes.data.token)
      window.location.replace('/campaigns')
    } catch (err) {
      return err.response.data
    }
  }

  const logoutHandler = () => {
    // log out of google
    googleLogout()
    setNodeToken('')
    localStorage.removeItem('nodeToken')
    delete axios.defaults.headers.common['Authorization']
    queryCache.removeQueries()
  }

  const userContextValues = {
    nodeToken,
    role,
    setRole,
    loginHandler,
    logoutHandler,
    fetchCurrentUserData,
  }

  return <UserContext.Provider value={userContextValues}>{props.children}</UserContext.Provider>
}
