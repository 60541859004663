import axios from 'axios'
import React, { createContext, useContext } from 'react'

import { BrandContext } from './BrandContext'
import { NODE_URL } from '../constants'

export const InfluencerContext = createContext(null)

export const InfluencerContextProvider = props => {
  const { brandId } = useContext(BrandContext)

  const fetchAverageRating = async (queryKey, creatorId) => {
    const { data } = await axios.get(
      `${NODE_URL}/brand/${brandId}/opt-in/creator/${creatorId}/average-rating`
    )
    return data
  }

  const fetchCampaignHistory = async (queryKey, creatorId, page = 1) => {
    const { data } = await axios.get(
      `${NODE_URL}/brand/${brandId}/opt-in/creator/${creatorId}/campaign-history/${page}`
    )
    return data
  }

  const influencerContextValues = {
    fetchAverageRating,
    fetchCampaignHistory,
  }

  return (
    <InfluencerContext.Provider value={influencerContextValues}>
      {props.children}
    </InfluencerContext.Provider>
  )
}
