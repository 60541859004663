import { DeleteOutlined, PlusOutlined } from '@ant-design/icons'
import { Drawer, Button, message, Empty } from 'antd'
import axios from 'axios'
import { Form, Formik } from 'formik'
import { Input, InputNumber } from 'formik-antd'
import React, { useContext } from 'react'
import { useQuery, queryCache } from 'react-query'
import styled from 'styled-components/macro'
import * as yup from 'yup'

import FormItem from '../../components/forms/FormItem'
import LoadingLogo from '../../components/general/LoadingLogo'
import { NODE_URL } from '../../constants'
import { BrandContext } from '../../contexts/BrandContext'

const getDateString = date => {
  const dateObj = new Date(date)
  return `${dateObj.toLocaleDateString('US-en', {
    month: 'long',
  })} ${dateObj.getDate()}, ${dateObj.getFullYear()}`
}

const SideDrawer = ({ setOpenAffiliateDrawer, setCreateLinkModalOpen, openAffiliateDrawer }) => {
  const { fetchAffiliateLinkData, brandId } = useContext(BrandContext)
  const { data: affiliateLinkData, status: affiliateLinkDataStatus } = useQuery(
    ['affiliate-link-data', brandId],
    fetchAffiliateLinkData
  )

  const validationSchema = yup.object().shape({
    url: yup.string().url().required('URL field is required'),
    description: yup.string().nullable(),
    commission: yup.number().positive('Number has to be a positive number'),
  })

  const handleEditSubmitForm = (values, linkId) => {
    axios
      .patch(`${NODE_URL}/brand/${brandId}/affiliates/link`, {
        values,
        linkId,
      })
      .then(() => {
        message.success('Affiliate Link Updated')
      })
      .catch(() => {
        message.error('Error updating affiliate link')
      })
      .finally(() => {
        queryCache.invalidateQueries('affiliate-link-data')
        queryCache.invalidateQueries('affiliate-analytics')
      })
  }

  const deleteAffiliateLink = linkId => {
    axios
      .delete(`${NODE_URL}/brand/affiliates/link/${linkId}`)
      .then(() => {
        message.success('Link Deleted')
      })
      .catch(() => {
        message.error('Error deleting affiliate link')
      })
      .finally(() => {
        queryCache.invalidateQueries('affiliate-link-data')
        queryCache.invalidateQueries('affiliate-analytics')
      })
  }

  return (
    <Drawer
      title='Affiliate Links'
      extra={
        <Button type='primary' onClick={() => setCreateLinkModalOpen(true)}>
          <PlusOutlined /> New Link
        </Button>
      }
      onClose={() => setOpenAffiliateDrawer(false)}
      open={openAffiliateDrawer}
      width={window.innerWidth > 600 ? 736 : '100%'}
      placement='right'>
      {affiliateLinkDataStatus === 'success' &&
        affiliateLinkData?.map(link => (
          <DrawerWrapper key={link.id}>
            <div className='link-wrapper'>
              <Formik
                initialValues={{
                  url: link.urlPath,
                  description: link.description,
                  commission: link.commission * 100,
                }}
                validationSchema={validationSchema}
                onSubmit={values => handleEditSubmitForm(values, link.id)}>
                {({ submitForm, setFieldValue }) => (
                  <Form>
                    <FormItem label='URL' name='url' size='small'>
                      <Input
                        type='text'
                        name='url'
                        className='url'
                        disabled={link.affiliateLinks.map(item => item.id).length}
                        onChange={e => {
                          setFieldValue(prev => ({ ...prev, url: e.target.value }))
                        }}
                      />
                    </FormItem>
                    <FormItem label='Description' name='description' size='small'>
                      <Input.TextArea
                        type='text'
                        name='description'
                        className='description'
                        onChange={e =>
                          setFieldValue(prev => ({ ...prev, description: e.target.value }))
                        }
                      />
                    </FormItem>
                    <FormItem label='Commission Amount' name='commission' size='small'>
                      <InputNumber
                        name='commission'
                        disabled={link.affiliateLinks.map(item => item.id).length}
                        formatter={value => `${value} %`}
                      />
                    </FormItem>
                    <div className='affiliate-link-actions'>
                      <Button type='primary' className='edit-url-submit' onClick={submitForm}>
                        Save
                      </Button>
                      <p className='date'>
                        Created {getDateString(link.created)}
                        <span>
                          {link.affiliateLinks.map(item => item.id).length ? ' - LIVE' : ''}
                        </span>
                      </p>
                      <Button
                        type='link'
                        className='delete-btn'
                        disabled={link.affiliateLinks.map(item => item.id).length}
                        onClick={() => {
                          deleteAffiliateLink(link.id)
                        }}>
                        <DeleteOutlined />
                      </Button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </DrawerWrapper>
        ))}
      {affiliateLinkDataStatus === 'loading' && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            height: '90%',
          }}>
          <LoadingLogo />
        </div>
      )}
      {affiliateLinkDataStatus === 'success' && !affiliateLinkData?.length && (
        <Empty
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            height: '90%',
          }}
          description='No links yet.'
        />
      )}
    </Drawer>
  )
}

const DrawerWrapper = styled.div`
  margin: 25px 40px;
  display: flex;
  flex-direction: column;
  gap: 25px;

  .link-wrapper {
    border-radius: 5px;
    background-color: #f7f7f7;
    padding: 20px;

    form {
      display: flex;
      flex-direction: column;
      gap: 7px;

      .url-wrapper,
      .description-wrapper {
        display: flex;
        flex-direction: column;

        h6 {
          font-weight: 700;
        }
      }

      .edit-url-submit {
        width: max-content;
      }
    }

    .affiliate-link-actions {
      display: flex;
      align-items: center;
      gap: 12px;
      .delete-btn {
        margin-left: auto;
        font-size: 1.1rem;
        color: red;
      }
      .delete-btn:hover {
        color: #ff7070;
      }
      .date {
        font-size: 0.8rem;
        opacity: 0.5;
        margin: 0;
      }
    }
  }
`

export default SideDrawer
