import { Drawer, message } from 'antd'
import axios from 'axios'
import React, { useContext } from 'react'
import { useQuery, queryCache } from 'react-query'
import styled from 'styled-components'

import CreateEditSequence from './CreateEditSequence'
import { NODE_URL } from '../../../constants'
import { BrandContext } from '../../../contexts/BrandContext'
import { UserContext } from '../../../contexts/UserContext'

const ManageSequenceDrawer = ({
  isOpen,
  setIsOpen,
  drawerMode, // 'create' or 'edit'
  sequenceId,
  setSequenceId,
}) => {
  const { brandId, fetchBrandData, fetchCampaigns, fetchCreatorLists } = useContext(BrandContext)
  const { nodeToken } = useContext(UserContext)

  const fetchSequenceData = async (key, brandId, seqId) => {
    if (!seqId || drawerMode === 'create') return null
    const { data } = await axios.get(`${NODE_URL}/brand/${brandId}/sequence/${seqId}`, {
      headers: { Authorization: `Bearer ${nodeToken}` },
    })
    return data
  }

  const { data: campaigns, status: campaignStatus } = useQuery(
    ['campaigns', brandId],
    fetchCampaigns
  )
  const activeCampaigns = campaigns?.filter(campaign => campaign.status === 'publish') || []

  const { data: creatorLists, status: creatorListStatus } = useQuery(
    ['creator-lists', brandId],
    fetchCreatorLists
  )

  const { data: brandData, status: brandStatus } = useQuery(['brand', brandId], fetchBrandData)

  const { data: sequence, status } = useQuery(['sequence', brandId, sequenceId], fetchSequenceData)

  const handleSubmit = async data => {
    const {
      sequenceTitle: title,
      creatorListTitle: creatorListId,
      campaignTitle: campaignId,
      steps,
    } = data

    const apiUrl =
      drawerMode === 'create'
        ? `${NODE_URL}/brand/${brandId}/sequence/new`
        : `${NODE_URL}/brand/${brandId}/sequence/${sequenceId}`
    const method = drawerMode === 'create' ? axios.post : axios.put

    try {
      await method(
        apiUrl,
        {
          title,
          campaignId,
          creatorListId,
          steps: steps.map(step => ({
            order: step.order,
            emailTemplateId: step.emailTemplateId,
            daysAfterPrevious: step.daysAfterPrevious,
            stepId: step.stepId,
          })),
        },
        {
          headers: { Authorization: `Bearer ${nodeToken}` },
        }
      )
      message.success(`Sequence successfully ${drawerMode === 'create' ? 'created' : 'updated'}`)
      queryCache.invalidateQueries('sequence')
      queryCache.invalidateQueries('sequence-data')
      setIsOpen(false)
      if (drawerMode === 'edit') setSequenceId(null)
    } catch (err) {
      message.error(`Error ${drawerMode === 'create' ? 'creating' : 'updating'} sequence`)
    }
  }

  return (
    <Drawer
      title={`${drawerMode === 'create' ? 'Create' : 'Edit'} Sequence`}
      open={isOpen}
      destroyOnClose
      onClose={() => {
        setIsOpen(false)
        if (drawerMode === 'edit') setSequenceId(null)
      }}
      width={window.innerWidth > 500 ? 500 : window.innerWidth}>
      <DrawerWrapper>
        <CreateEditSequence
          sequence={drawerMode === 'edit' ? sequence : undefined}
          status={drawerMode === 'edit' ? status : undefined}
          handleSubmit={handleSubmit}
          campaigns={activeCampaigns}
          campaignStatus={campaignStatus}
          creatorLists={creatorLists}
          creatorListStatus={creatorListStatus}
          brandData={brandData}
          brandStatus={brandStatus}
        />
      </DrawerWrapper>
    </Drawer>
  )
}

export default ManageSequenceDrawer

export const DrawerWrapper = styled.div`
  padding: 25px 40px;

  .ant-select {
    width: 100%;
  }

  .automated-emails-section {
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-bottom: 20px;

    .automated-emails-content {
      display: flex;
      flex-direction: column;
      gap: 15px;
    }

    .automated-emails-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-weight: bold;
      font-size: 1rem;
    }

    textarea.ant-input {
      padding: 15px;
    }

    .days-input-wrapper {
      display: flex;
      align-items: center;
      gap: 10px;

      .days-input {
        width: 70px;
      }
    }
  }

  .loading-logo {
    display: flex;
    justify-items: center;
    align-items: center;
    min-height: calc(100vh - 112px);
    flex-direction: column;
  }
`
