import { CloseCircleOutlined, BarsOutlined } from '@ant-design/icons'
import { useFlags } from 'launchdarkly-react-client-sdk'
import React, { useContext, useState } from 'react'
import { useQuery } from 'react-query'
import styled from 'styled-components/macro'

import AffiliateSettings from './AffiliateSettings'
import BrandGoogleAnalytics from './BrandGoogleAnalytics'
import BrandImapInfo from './BrandImapInfo'
import { BrandInformation } from './BrandInformation'
import BrandIntegrations from './BrandIntegrations'
import BrandSmtpInfo from './BrandSmtpInfo'
import { BrandSubscription } from './BrandSubscription'
import { BrandTeammates } from './BrandTeammates'
import { BrandWhiteLabelEmail } from './BrandWhiteLabelEmail'
import { ProductLists } from './products/ProductLists'
import ChatTemplate from '../../components/forms/ChatTemplate'
import EmailTemplate from '../../components/forms/EmailTemplate'
import LoadingLogo from '../../components/general/LoadingLogo'
import { BrandContext } from '../../contexts/BrandContext'
import { UserContext } from '../../contexts/UserContext'
import { useDocumentTitle } from '../../custom-hooks/useDocumentTitle'

export const BrandSettings = () => {
  const flags = useFlags()
  const [settingsNavVisible, setSettingsNavVisible] = useState(false)

  const { role } = useContext(UserContext)
  const { brandId, fetchBrandData } = useContext(BrandContext)

  const { status: brandStatus, data: brandData } = useQuery(['brand', brandId], fetchBrandData)

  useDocumentTitle(
    role === 'administrator'
      ? brandData?.name
        ? `${brandData.name} - Account | Brand Dashboard`
        : 'Account | Brand Dashboard'
      : 'My Account | Brand Dashboard'
  )
  return (
    <>
      {brandStatus === 'success' && (
        <Wrapper>
          <h1>My Account Settings</h1>
          <BrandInformation />
          <BrandSubscription />
          <BrandTeammates />
          <AffiliateSettings />
          {flags.shopifyOrderCreation && <BrandIntegrations />}
          {flags.productLists && <ProductLists />}
          <ChatTemplate />
          <EmailTemplate />
          <BrandWhiteLabelEmail />
          <BrandSmtpInfo />
          <BrandImapInfo />
          {flags.googleAnalyticsIntegration && <BrandGoogleAnalytics />}

          <div className='form-nav-wrapper'>
            <div
              className={`overlay ${settingsNavVisible && 'visible'}`}
              onClick={() => setSettingsNavVisible(false)}
              onKeyDown={() => setSettingsNavVisible(false)}
              role='button'
              tabIndex={0}
            />
            <div className='fab-wrapper'>
              <div
                className={`links ${settingsNavVisible && 'expand'}`}
                onClick={() => setSettingsNavVisible(false)}
                onKeyDown={() => setSettingsNavVisible(false)}
                role='button'
                tabIndex={0}>
                <a href='#brand-info'>Brand Info</a>
                <a href='#subscription'>Subscription</a>
                <a href='#brand-members'>Brand Members</a>
                <a href='#affiliate-program'>Affiliate Program</a>
                <a href='#shopify'>Shopify</a>
                {flags.productLists && <a href='#product-lists'>Product Lists</a>}
                <a href='#chat-templates'>Chat Templates</a>
                <a href='#email-templates'>Email Templates</a>
                <a href='#white-labeled-emails'>White-Labeled Emails</a>
                <a href='#smtp-settings'>SMTP Settings</a>
                <a href='#imap-settings'>IMAP Settings</a>
                <a href='#google-analytics'>Google Analytics</a>
              </div>
              <div
                className={`fab ${settingsNavVisible && 'close'}`}
                onClick={() => setSettingsNavVisible(!settingsNavVisible)}
                onKeyDown={() => setSettingsNavVisible(!settingsNavVisible)}
                role='button'
                tabIndex={0}>
                {settingsNavVisible ? <CloseCircleOutlined /> : <BarsOutlined />}
              </div>
            </div>
          </div>
        </Wrapper>
      )}
      {brandStatus === 'loading' && (
        <div style={{ display: 'grid', height: '100%', placeItems: 'center', minHeight: '400px' }}>
          <LoadingLogo />
        </div>
      )}
    </>
  )
}

// styling shared with UserSettings.js
export const Wrapper = styled.div`
  color: #23262f;
  width: 100%;
  max-width: 800px;
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 20px;
  margin: 0 auto;
  h1 {
    font-family: 'Gilroy-ExtraBold', sans-serif;
    font-size: 30px;
    margin-bottom: 30px;
  }
  .account-section {
    width: 100%;
    max-width: 800px;
    margin: 0 auto 50px;
    scroll-margin-top: 30px;
    .section-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 10px;
      .section-title {
        display: flex;
        align-items: center;
        gap: 8px;
        font-size: 18px;
        margin: 0;
        // leave space when automatically scrolling to this element
        scroll-margin-top: 30px;
        img {
          height: 20px;
          width: 20px;
        }
      }
      .tooltip-icon {
        color: #808080;
        font-size: 14px;
      }
      .info {
        color: ${props => props.theme.crcoTechBlue};
      }
    }
    .buttons {
      display: flex;
      gap: 15px;
    }
  }
  .section-body {
    background: #fff;
    padding: 20px;
    border-radius: 10px;
    .section-row {
      display: flex;
      gap: 20px;
    }
    .info-text {
      color: ${props => props.theme.crcoGrey};
    }
    .form-row-wrapper {
      width: 100%;
      .ant-input {
        min-width: 200px;
      }
    }
  }
  .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    align-items: center;
  }
  .deactivated {
    opacity: 0.3;
    font-size: 14px;
  }

  .form-nav-wrapper {
    &.hidden {
      display: none;
    }
    .overlay {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.5);
      visibility: hidden;
      opacity: 0;
      transition: 0.2s ease-in-out;
      cursor: pointer;
      z-index: 1;
      &.visible {
        visibility: visible;
        opacity: 1;
      }
    }
    .fab-wrapper {
      position: fixed;
      bottom: 20px;
      right: 20px;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      z-index: 2;
      .links {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        gap: 10px;
        max-height: 0;
        margin-bottom: 20px;
        visibility: hidden;
        opacity: 0;
        transition: 0.2s ease-in-out;
        z-index: 99;
        a {
          background: #fff;
          border-radius: 20px;
          padding: 5px 10px;
          font-size: 0.9rem;
          &:hover {
            color: ${props => props.theme.crcoMidnight};
          }
        }
        &.expand {
          max-height: 700px;
          visibility: visible;
          opacity: 1;
        }
      }
      .fab {
        background: ${props => props.theme.crcoTechBlue};
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
        color: #fff;
        border: none;
        display: grid;
        place-content: center;
        border-radius: 50%;
        height: 40px;
        width: 40px;
        z-index: 100;
        cursor: pointer;
        &.close {
          background: ${props => props.theme.crcoCoral};
        }
      }
    }
  }

  @media only screen and (min-width: ${props => props.theme.breakpointTablet}) {
    padding: 40px;
    .section-body {
      .form-row-wrapper:not(.desktop) {
        display: flex;
        gap: 12px;
      }
    }
  }

  @media only screen and (min-width: ${props => props.theme.breakpointDesktop}) {
    .section-body {
      .form-row-wrapper {
        &.desktop {
          display: flex;
          gap: 12px;
        }
      }
    }
  }
`
