import { Tabs } from 'antd'
import React, { useRef, useState } from 'react'
import styled from 'styled-components/macro'

import { EmailTemplatePreview } from './EmailTemplatePreview'
import { RichTextEditor } from './RichTextEditor'
import { uppercaseFirstChar } from '../../utils'

export const EmailTemplateVariables = ['Name', 'Username', 'Email', 'Brand Name', 'Campaign Name']

export const EmailTemplateEditor = ({ initialValue, isLegacy, validationRef }) => {
  const richTextEditorRef = useRef()
  const [latestEditorContent, setLatestEditorContent] = useState('')

  // Correct legacy values while quill does not correct raw values (eg.: {{name}}) and
  // auto replace by the new mention; Is legacy flag will be set down when first save
  // happens in the backend
  const correctedInitialValue = isLegacy
    ? initialValue.replace(
        /({{.*?}})/g,
        (_, token) =>
          `<span class="mention" data-index="0" data-denotation-char="{{" data-id="1" data-value="${uppercaseFirstChar(token).replace('{{', '')}"><span contenteditable="false">${uppercaseFirstChar(token).replace('{{', '')}</span></span>`
      )
    : initialValue

  // public interface
  if (validationRef)
    validationRef.current = {
      getHTMLContent: () => {
        const content = richTextEditorRef?.current?.getHTML()
        return content
      },
      getTextContent: () => {
        const content = richTextEditorRef?.current?.getText()
        return content
      },
    }

  // handlers
  const handleTabChange = key => {
    if (key == '1') {
      setLatestEditorContent(richTextEditorRef?.current?.getHTML())
    } else if (richTextEditorRef && richTextEditorRef.current) {
      richTextEditorRef.current.focus()
    }
  }

  return (
    <>
      <StyledTabs
        defaultActiveKey='0'
        type='card'
        onChange={handleTabChange}
        items={[
          {
            label: `Write`,
            key: '0',
            children: (
              <RichTextEditor
                initialValue={correctedInitialValue}
                editorRef={richTextEditorRef}
                variables={EmailTemplateVariables}
              />
            ),
          },
          {
            label: `Preview`,
            key: '1',
            className: 'preview',
            children: <EmailTemplatePreview template={latestEditorContent} />,
          },
        ]}
      />
    </>
  )
}

const StyledTabs = styled(Tabs)`
  border: 1px solid #d9d9d9;
  border-radius: 2px;

  // tab styles
  .ant-tabs-nav-wrap {
    margin-bottom: -1px; // hide the bottom border
  }
  .ant-tabs-nav {
    background: #f6f6f6;
    margin-bottom: 0px;
    border-bottom: 1px solid #d9d9d9;
    .ant-tabs-tab {
      background: transparent;
      border: none;
      border-right: 1px solid #d9d9d9;
      padding: 5px 20px;
      margin: 0 !important;
    }
    .ant-tabs-tab-active {
      background: #fff;
    }
  }
  .ant-tabs-tabpane.preview {
    box-sizing: border-box;
    padding: 20px;
  }

  // quill editor styles
  .ql-toolbar.ql-snow {
    border: none;
    border-bottom: 1px solid #d9d9d9;
    border-radius: 2px 2px 0 0;
  }
  .ql-container.ql-snow {
    border: none;
    font-family: 'Campton-Medium', sans-serif;
  }
  .ql-editor {
    min-height: 200px;
  }
`
