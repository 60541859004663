import { ExportOutlined, LeftOutlined, RightOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import React, { useContext } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import styled from 'styled-components'

import { UserContext } from '../../../contexts/UserContext'

export const CreatorProfileActions = ({ optIn, setSelectedOptIn }) => {
  const { role } = useContext(UserContext)

  return (
    <Wrapper>
      {/* ADMIN ACTIONS */}
      {role === 'administrator' && (
        <div className='admin'>
          <CopyToClipboard text={optIn?.user?.id}>
            <div className='id'>User #{optIn?.user?.id}</div>
          </CopyToClipboard>
          <CopyToClipboard text={optIn?.id}>
            <div className='id'>Opt-In #{optIn?.id}</div>
          </CopyToClipboard>
          <a
            target='blank'
            rel='noopener noreferrer'
            href={`${
              process.env.NODE_ENV === 'production'
                ? 'https://admin.creator.co'
                : 'http://localhost:3003'
            }/opt-in/${optIn?.id}`}>
            <ExportOutlined /> Admin
          </a>
        </div>
      )}

      {/* PREVIOUS/NEXT OPT-IN NAVIGATION */}
      <Button type='link' onClick={() => setSelectedOptIn(optIn.prev)} disabled={!optIn.prev}>
        <LeftOutlined /> Previous
      </Button>
      <Button type='link' onClick={() => setSelectedOptIn(optIn.next)} disabled={!optIn.next}>
        Next <RightOutlined />
      </Button>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  .admin {
    display: flex;
    align-items: center;
    gap: 16px;
    border-right: 1px solid #ddd;
    padding-right: 20px;
    margin-right: 8px;
    a {
      font-size: 14px;
    }
  }
  .id {
    color: rgba(0, 0, 0, 0.3);
    font-size: 14px;
    text-align: center;
    transition: 0.2s ease-in-out;
    cursor: pointer;
    &:hover {
      color: ${props => props.theme.crcoTechBlue};
    }
  }
`
