import { DownloadOutlined, LoadingOutlined, CheckCircleOutlined } from '@ant-design/icons'
import { Button, Empty, message } from 'antd'
import axios from 'axios'
import { Formik } from 'formik'
import moment from 'moment'
import React, { useContext, useRef, useState } from 'react'
import { useInfiniteQuery, queryCache } from 'react-query'

import { ResultsWrapper } from './CreatorSearch'
import { NODE_URL } from '../../constants'
import { BrandContext } from '../../contexts/BrandContext'
import placeholder from '../../images/placeholder.svg'

const SavedReports = () => {
  const [sort] = useState({
    value: 'date',
    order: 'desc',
  })

  const fetchReports = async (key, brandId, sort, order, page = 1) => {
    const { data } = await axios.get(`${NODE_URL}/audience-reports/${brandId}/?page=${page}`)

    return data
  }

  const updateReportName = async data => {
    const { postID, reportName } = data
    const updateReport = 'true'

    axios
      .post(`${NODE_URL}/saved-audience-reports/${brandId}`, {
        postID,
        reportName,
        updateReport,
      })
      .then(() => {
        queryCache.refetchQueries(['deep-insight-reports'])
        message.success('Report name change was successful!')
      })
      .catch(() => {
        message.error('Error updating report name')
      })
  }

  const { brandId } = useContext(BrandContext)
  const {
    data,
    status: reportsStatus,
    isFetchingMore,
    fetchMore,
    canFetchMore,
  } = useInfiniteQuery(['deep-insight-reports', brandId, sort.value, sort.order], fetchReports, {
    getFetchMore: lastGroup => {
      return lastGroup.nextPage
    },
  })

  const scrollRef = useRef(false)

  const handleScroll = currentScroll => {
    const distance =
      currentScroll.scrollHeight - (currentScroll.scrollTop + currentScroll.offsetHeight)
    distance < 300 && canFetchMore && !isFetchingMore && fetchMore()
  }

  return (
    <ResultsWrapper className='reports-table'>
      <Formik
        onSubmit={data => {
          updateReportName(data)
        }}
        initialValues={{}}>
        {() => {
          return (
            <>
              <div className='inner'>
                <div className='header'>
                  <div className='heading-item creator'>Creator</div>
                  <div className='heading-item'>Platform</div>
                  <div className='heading-item'>Date Generated</div>
                  <div className='heading-item'>Actions</div>
                </div>
                <div
                  className='results-inner'
                  ref={scrollRef}
                  onScroll={() => handleScroll(scrollRef.current)}>
                  {reportsStatus === 'success' && (
                    <>
                      {data?.length && data[0].reports?.length ? (
                        <>
                          {data.map((group, i) =>
                            group.reports.map(item => {
                              const platform = item.metaData?.platform
                              let nicePlatform = ''
                              switch (platform) {
                                case 'instagram':
                                  nicePlatform = 'Instagram'
                                  break
                                case 'youtube':
                                  nicePlatform = 'YouTube'
                                  break
                                case 'tiktok':
                                  nicePlatform = 'TikTok'
                                  break
                                default:
                                  nicePlatform = '-'
                              }
                              return (
                                <div className='result' key={i}>
                                  <a
                                    className='result-item creator'
                                    href={item?.metaData?.profile || ''}
                                    target='_blank'
                                    rel='noopener noreferrer'>
                                    <div
                                      className='avatar'
                                      style={
                                        item.metaData?.picture
                                          ? { backgroundImage: `url(${item.metaData?.picture})` }
                                          : {
                                              backgroundImage: `url(${placeholder})`,
                                              backgroundSize: '70%',
                                              border: '2px solid #e6e6e6',
                                            }
                                      }
                                    />
                                    <div className='info'>
                                      <div className='username'>
                                        {platform === 'YouTube'
                                          ? item.metaData?.name
                                          : item.metaData?.username}
                                        {item.metaData?.profile?.is_verified && (
                                          <span className='verified'>
                                            <CheckCircleOutlined />
                                          </span>
                                        )}
                                      </div>
                                      {platform !== 'YouTube' && (
                                        <div className='fullname'>
                                          {item.metaData?.profile?.fullname || item.metaData?.name}
                                        </div>
                                      )}
                                    </div>
                                  </a>
                                  <div className='result-item'>{nicePlatform}</div>
                                  <div className='result-item'>
                                    {moment(item.savedDate).format('ll')}
                                  </div>
                                  <div className='result-item actions'>
                                    {!item.url ? (
                                      <span>Report expired</span>
                                    ) : (
                                      <a
                                        href={item.url}
                                        target='_blank'
                                        rel='noopener noreferrer'
                                        download='foo.txt'>
                                        <Button type='link' icon={<DownloadOutlined />}>
                                          Download
                                        </Button>
                                      </a>
                                    )}
                                  </div>
                                </div>
                              )
                            })
                          )}
                        </>
                      ) : (
                        <Empty
                          style={{
                            height: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            flexDirection: 'column',
                            color: '#aeb8c2',
                          }}
                          description='No reports yet.'
                        />
                      )}
                      {isFetchingMore && (
                        <div style={{ textAlign: 'center', margin: '0 20px' }}>
                          <LoadingOutlined style={{ fontSize: '24px' }} spin />
                        </div>
                      )}
                    </>
                  )}
                  {reportsStatus === 'loading' && (
                    <div style={{ display: 'grid', placeItems: 'center', height: '100%' }}>
                      <LoadingOutlined style={{ fontSize: '30px' }} />
                    </div>
                  )}
                </div>
              </div>
            </>
          )
        }}
      </Formik>
    </ResultsWrapper>
  )
}

export default SavedReports
