import {
  UnorderedListOutlined,
  AppstoreOutlined,
  EyeOutlined,
  EyeInvisibleOutlined,
  LeftOutlined,
  RightOutlined,
} from '@ant-design/icons'
import { Empty, Button, Alert, Spin } from 'antd'
import axios from 'axios'
import moment from 'moment'
import numeral from 'numeral'
import React, { useEffect, useState, useRef } from 'react'
import { useQuery } from 'react-query'
import styled from 'styled-components'

import { ContentCard } from './ContentCard'
import { NODE_URL } from '../../constants'

export const ContentList = ({ selectedList, filtersExpanded, setFiltersExpanded }) => {
  const limit = 30
  const [page, setPage] = useState(1)
  const [view, setView] = useState('list')
  const [totalResults, setTotalResults] = useState(0)
  const contentListRef = useRef(null)

  const { data: statsData, status: statsStatus } = useQuery(
    ['social-listening-stats', selectedList.id],
    async () => {
      const { data } = await axios.get(`${NODE_URL}/social-listening-stats/${selectedList.id}`)
      return data
    }
  )

  const fetchContent = async (page = 1) => {
    const { data } = await axios.get(
      `${NODE_URL}/social-listening-content/${selectedList.id}/${page || 1}`,
      {
        params: { limit },
      }
    )
    return data
  }

  const {
    data: contentData,
    status: contentStatus,
    isFetching,
  } = useQuery(
    ['social-listening-content', selectedList.id, page],
    () => fetchContent(page),
    // avoid refetching when window is refocused to prevent showing loading spinner
    { refetchOnWindowFocus: false }
  )

  // reset page to 1 when selected list changes
  useEffect(() => {
    setPage(1)
  }, [selectedList])

  // maintain num of results when changing pages
  useEffect(() => {
    if (contentData) {
      setTotalResults(contentData.totalResults)
    }
  }, [contentData])

  // scroll to top of content list when changing pages after contentData is fetched
  useEffect(() => {
    if (contentListRef.current && contentData) {
      contentListRef.current.scrollTop = 0
    }
  }, [page, contentData])

  return (
    <Wrapper>
      <div className='container-header'>
        <span className='last-fetched'>
          Last fetched:{' '}
          {selectedList.lastFetched ? moment(selectedList.lastFetched).format('lll') : 'Never'}
        </span>

        <div className='toggle-btns'>
          <Button
            className='filters-toggle'
            type='link'
            onClick={() => setFiltersExpanded(!filtersExpanded)}>
            {filtersExpanded ? <EyeInvisibleOutlined /> : <EyeOutlined />} Filters
          </Button>
          <Button
            type='link'
            className={`toggle-btn ${view === 'list' && 'active'}`}
            onClick={() => setView('list')}>
            <UnorderedListOutlined />
          </Button>
          <Button
            type='link'
            className={`toggle-btn ${view === 'grid' && 'active'}`}
            onClick={() => setView('grid')}>
            <AppstoreOutlined />
          </Button>

          {!!totalResults && (
            <div className='pagination-controls'>
              <Button
                type='link'
                onClick={() => {
                  setPage(old => Math.max(old - 1, 1))
                }}
                disabled={page === 1}>
                <LeftOutlined />
              </Button>
              <span className='page-count'>
                {page * limit - limit + 1} - {Math.min(page * limit, totalResults || 0)} of{' '}
                {numeral(totalResults).format('0a')}
              </span>
              <Button
                type='link'
                onClick={() => {
                  setPage(old => (!contentData || !contentData.nextCursor ? old : old + 1))
                }}
                disabled={!contentData || !contentData.nextCursor}>
                <RightOutlined />
              </Button>
            </div>
          )}
        </div>
      </div>

      {statsStatus === 'success' && (
        <div className='overview'>
          <div className='stat-card'>
            <p className='label'>TOTAL LIKES</p>
            <p className={`num ${statsData?.totalLikes && 'blue-text'}`}>
              {numeral(statsData?.totalLikes).format('0a')}
            </p>
          </div>
          <div className='stat-card'>
            <p className='label'>TOTAL COMMENTS</p>
            <p className={`num ${statsData?.totalComments && 'blue-text'}`}>
              {numeral(statsData?.totalComments).format('0a')}
            </p>
          </div>
          <div className='stat-card'>
            <p className='label'>TOTAL VIEWS</p>
            <p className={`num ${statsData?.totalViews && 'blue-text'}`}>
              {numeral(statsData?.totalViews).format('0a')}
            </p>
          </div>
        </div>
      )}

      <div className='results'>
        {contentStatus === 'loading' || isFetching ? (
          <div className='loading'>
            <Spin />
          </div>
        ) : (
          contentStatus === 'success' &&
          (contentData?.totalResults > 0 ? (
            <div className={`content-list ${view}`} ref={contentListRef}>
              {contentData.posts.map((post, i) => (
                <ContentCard key={i} content={post.socialPost} view={view} />
              ))}
            </div>
          ) : (
            <div className='empty'>
              <Empty description='Listening for posts...' />
              <Alert
                type='info'
                showIcon
                message='Posts are fetched every 30 minutes. Please check back later or adjust your filters.'
              />
            </div>
          ))
        )}
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.section`
  background: #fff;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .overview {
    background: #f2f2f2;
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    padding: 10px;
    border: 1px solid #eee;
    border-radius: 5px 5px 0 0;
    border-bottom: 1px solid #ddd;
    .stat-card {
      background: #fff;
      flex: 1;
      white-space: nowrap;
      border: 1px solid #e6e6e6;
      padding: 5px 10px;
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 8px;
      p {
        margin: 0;
      }
      .label {
        font-size: 12px;
        opacity: 0.5;
      }
      .num {
        color: #999;
        font-size: 1.2rem;
        font-weight: 600;
        &.blue-text {
          color: ${props => props.theme.crcoTechBlue};
        }
      }
    }
  }

  .last-fetched {
    display: none;
    opacity: 0.5;
    font-size: 0.8rem;
  }
  .divider {
    width: 1px;
    height: 20px;
    background: #ddd;
  }

  .toggle-btns {
    display: flex;
    align-items: center;
    gap: 12px;
    .filters-toggle {
      background: #f6f6f6;
      color: ${props => props.theme.crcoMidnight};
      border-radius: 10px;
      padding: 4px 10px;
      &:focus {
        background: #f6f6f6;
      }
      &:hover {
        background: #e9f4fc;
        color: ${props => props.theme.crcoTechBlue};
      }
    }
    .toggle-btn {
      background: #f6f6f6;
      color: ${props => props.theme.crcoMidnight};
      border-radius: 10px;
      font-size: 1rem;
      display: grid;
      place-content: center;
      width: 30px;
      height: 30px;
      &:hover,
      &.active {
        background: #e9f4fc;
        color: ${props => props.theme.crcoTechBlue};
      }
    }
  }

  .pagination-controls {
    border: 1px solid #ddd;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    .page-count {
      color: ${props => props.theme.crcoGrey};
      font-size: 0.8rem;
    }
  }

  .results {
    height: 100%;
    width: 100%;
    overflow: hidden;
  }

  .content-list {
    background: #f2f2f2;
    height: 100%;
    max-height: 600px;
    width: 100%;
    overflow: auto;
    display: flex;
    padding: 10px;
    border: 1px solid #eee;
    border-radius: 0 0 5px 5px;
    border-top: none;
    ${props => props.theme.scrollbar}
    &.grid {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
      gap: 20px;
    }
    &.list {
      flex-direction: column;
      gap: 10px;
    }
  }

  .ant-alert {
    margin-top: 20px;
  }

  @media only screen and (min-width: ${props => props.theme.breakpointTablet}) {
    .last-fetched {
      display: block;
    }
  }

  @media only screen and (min-width: ${props => props.theme.breakpointDesktop}) {
    .content-list {
      max-height: 100%;
    }
  }
`
