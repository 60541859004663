import React, { useCallback, useRef, useState } from 'react'
import { HexColorPicker } from 'react-colorful'
import styled from 'styled-components'

import useClickOutside from './useClickOutside'

export const PopoverPicker = ({ color, setColor, disabled }) => {
  const popover = useRef()
  const [isOpen, setIsOpen] = useState(false)

  const close = useCallback(() => setIsOpen(false), [])
  useClickOutside(popover, close)

  return (
    <Wrapper>
      <div className='picker'>
        <div
          className='swatch'
          style={{ backgroundColor: color, cursor: disabled ? 'default' : 'pointer' }}
          onClick={() => !disabled && setIsOpen(true)}
          onKeyDown={() => !disabled && setIsOpen(true)}
          role='button'
          tabIndex={0}
        />

        {isOpen && (
          <div className='popover' ref={popover}>
            <HexColorPicker
              color={color}
              onChange={
                // set color in input
                e => setColor(e.toUpperCase())
              }
            />
          </div>
        )}
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  .picker {
    position: relative;
  }

  .swatch {
    width: 25px;
    height: 25px;
    border-radius: 5px;
    border: 1px solid #e6e6e6;
  }

  .popover {
    position: absolute;
    top: calc(100% + 5px);
    left: -3px;
    border-radius: 9px;
    box-shadow: ${props => props.theme.crcoBoxShadow};
    background-color: transparent;
  }

  .input {
    display: block;
    box-sizing: border-box;
    width: 90px;
    margin: 20px 55px 0;
    padding: 6px;
    border: 1px solid #ddd;
    border-radius: 4px;
    background: #eee;
    outline: none;
    font: inherit;
    text-transform: uppercase;
    text-align: center;
  }

  .input:focus {
    border: 2px solid #4298ef;
  }
`
