import { Spin } from 'antd'
import axios from 'axios'
import { Formik, Form } from 'formik'
import { Input, Checkbox, Select, InputNumber } from 'formik-antd'
import React, { useState, useRef, useEffect, useContext } from 'react'
import { useQuery } from 'react-query'

import FormItem from '../../components/forms/FormItem'
import { NODE_URL } from '../../constants'
import { GlobalContext } from '../../contexts/GlobalContext'
import { UserContext } from '../../contexts/UserContext'
import instagram from '../../images/icons/social/instagram-color-square.svg'
import tiktok from '../../images/icons/social/tiktok-color-square.svg'
import youtube from '../../images/icons/social/youtube-color-square.svg'
const socialIcons = { instagram, youtube, tiktok }
const { Option } = Select

export const InfluencerDescription = ({
  formData,
  setFormData,
  formRef,
  sectionRef,
  submitCampaign,
}) => {
  const { nodeToken } = useContext(UserContext)
  const { getCategories } = useContext(GlobalContext)
  const [fetchingCountries, setFetchingCountries] = useState(false)
  const [countries, setCountries] = useState([])
  const lastCountryFetchID = useRef(0)

  const { data: categories } = useQuery('categories', getCategories)

  const initialValues = {
    disableQualifications: formData.disableQualifications,
    minAge: formData.minAge,
    maxAge: formData.maxAge,
    instagramReach: formData.instagramReach,
    instagramEngagement: formData.instagramEngagement,
    youtubeReach: formData.youtubeReach,
    youtubeEngagement: formData.youtubeEngagement,
    tiktokReach: formData.tiktokReach,
    tiktokEngagement: formData.tiktokEngagement,
    genders: formData.genders,
    countries: formData.countries,
    regions: formData.regions,
    cities: formData.cities,
    interests: formData.interests,
    creatorProfession: formData.creatorProfession,
  }

  useEffect(() => {
    fetchCountries('')
  }, [])

  const fetchCountries = val => {
    lastCountryFetchID.current += 1
    const fetchID = lastCountryFetchID.current
    setFetchingCountries(true)
    setCountries([])
    axios
      .get(`${NODE_URL}/countries/?search=${val}`, {
        headers: {
          Authorization: `Bearer ${nodeToken}`,
        },
      })
      .then(res => {
        if (fetchID !== lastCountryFetchID.current) {
          return
        }

        setCountries(
          res.data.map(country => ({
            text: country.countryName,
            value: country.countryCode,
          }))
        )
      })
  }

  return (
    <section ref={sectionRef}>
      <h2 className='header'>Requirements</h2>
      <p className='header-subtext'>
        Set requirements for the type of creators you would like to have for this campaign.
      </p>
      <Formik
        innerRef={formRef}
        initialValues={initialValues}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={() => {}}>
        {({ values }) => (
          <Form>
            {formData.strategy === 'affiliate' && (
              <FormItem>
                <Checkbox
                  onChange={e => {
                    setFormData(prev => ({
                      ...prev,
                      disableQualifications: e.target.checked,
                    }))
                    submitCampaign(false)
                  }}
                  name='disableQualifications'>
                  Disable qualifications (allow anyone to participate in this campaign)
                </Checkbox>
              </FormItem>
            )}
            {(formData.strategy !== 'affiliate' || !values?.disableQualifications) && (
              <>
                <FormItem label='Age range' sublabel='(optional, default is 13+)'>
                  <div
                    className='age'
                    style={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
                    <InputNumber
                      name='minAge'
                      placeholder='Min'
                      style={{ width: '100px' }}
                      onChange={e => {
                        setFormData({ ...formData, minAge: e })
                        submitCampaign(false)
                      }}
                    />
                    -
                    <InputNumber
                      name='maxAge'
                      placeholder='Max'
                      style={{ width: '100px' }}
                      onChange={e => {
                        setFormData({ ...formData, maxAge: e })
                        submitCampaign(false)
                      }}
                    />
                  </div>
                </FormItem>
                <FormItem
                  label='Genders'
                  sublabel='(select all that apply)'
                  name='genders'
                  required>
                  <Checkbox.Group
                    name='genders'
                    defaultValue={['male', 'female', 'non-binary']}
                    onChange={e => {
                      setFormData({ ...formData, genders: e })
                      submitCampaign(false)
                    }}
                    options={[
                      { label: 'Male', value: 'male' },
                      { label: 'Female', value: 'female' },
                      { label: 'Non-binary', value: 'non-binary' },
                    ]}
                  />
                </FormItem>
                <FormItem label='Countries' name='countries'>
                  <Select
                    getPopupContainer={trigger => trigger.parentNode}
                    virtual={false}
                    name='countries'
                    mode='multiple'
                    labelInValue
                    placeholder='Select countries (enter at least 2 characters)'
                    notFoundContent={fetchingCountries ? <Spin size='small' /> : null}
                    filterOption={false}
                    onSearch={fetchCountries}
                    onChange={e => {
                      setFetchingCountries(false)
                      setFormData(prev => ({ ...prev, countries: e }))
                      submitCampaign(false)
                    }}
                    style={{ width: '100%' }}>
                    {countries.map(item => (
                      <Option key={item.value} value={item.value}>
                        {item.text}
                      </Option>
                    ))}
                  </Select>
                </FormItem>
                {/* Only display regions for bespoke campaigns */}
                {/* {!!values?.countries?.length && formData.strategy === 'bespoke' && (
                  <FormItem name='regions' label='Regions'>
                    <Select
                      getPopupContainer={trigger => trigger.parentNode}
                      virtual={false}
                      name='regions'
                      mode='multiple'
                      labelInValue
                      placeholder='Select regions'
                      notFoundContent={fetchingRegions ? <Spin size='small' /> : null}
                      filterOption={false}
                      onSearch={val => fetchRegions(val, values?.countries)}
                      onChange={() => {
                        setFetchingRegions(false)
                        setRegions([])
                      }}
                      style={{ width: '100%' }}>
                      {regions.map(item => (
                        <Option key={item.value} value={item.value}>
                          {item.text}
                        </Option>
                      ))}
                    </Select>
                  </FormItem>
                )} */}
                {/* {!!values.regions.length && (
                  <FormItem name='cities' label='Cities'>
                    <Select
                      name='cities'
                      mode='multiple'
                      labelInValue
                      placeholder='Select cities'
                      notFoundContent={fetchingCities ? <Spin size='small' /> : null}
                      filterOption={false}
                      onSearch={val => fetchCities(val, values.regions)}
                      onChange={() => {
                        setFetchingCities(false)
                        setCities([])
                      }}
                      style={{ width: '100%' }}>
                      {cities.map(item => (
                        <Option key={item.value} value={item.value}>
                          {item.text}
                        </Option>
                      ))}
                    </Select>
                  </FormItem>
                )} */}

                <FormItem label='Niches' sublabel='(areas of influence)' name='interests'>
                  <Select
                    getPopupContainer={trigger => trigger.parentNode}
                    virtual={false}
                    name='interests'
                    showSearch
                    optionFilterProp='label'
                    filterOption
                    mode='multiple'
                    loading={!categories?.length}
                    style={{ width: '100%' }}
                    onChange={e => {
                      setFormData(prev => ({ ...prev, interests: e }))
                      submitCampaign(false)
                    }}>
                    {categories?.map(category => (
                      <Option label={category.title} key={category.id} value={category.slug}>
                        {category.title}
                      </Option>
                    ))}
                  </Select>
                </FormItem>

                {formData.strategy !== 'affiliate' && (
                  <>
                    {/* FIXME: if profession is set and strategy is changed to anything other than bespoke, this still displays the original input */}
                    <div style={{ opacity: formData.strategy !== 'bespoke' ? '0.5' : '1' }}>
                      <FormItem
                        label='Profession'
                        sublabel='(only available for bespoke campaigns)'
                        name='creatorProfession'
                        size='small'>
                        <Input
                          name='creatorProfession'
                          disabled={formData.strategy !== 'bespoke'}
                          onChange={e => {
                            setFormData(prev => ({ ...prev, creatorProfession: e.target.value }))
                            submitCampaign(false)
                          }}
                        />
                      </FormItem>
                    </div>
                  </>
                )}

                {formData.socialChannels?.map(channel => (
                  <>
                    <h3>
                      <img
                        src={socialIcons[channel]}
                        alt={channel}
                        style={{ width: '20px', marginRight: '6px' }}
                      />
                      {channel === 'instagram'
                        ? 'Instagram'
                        : channel === 'tiktok'
                          ? 'TikTok'
                          : channel === 'youtube' && 'YouTube'}
                    </h3>
                    <div className='form-row'>
                      <FormItem
                        label={`${channel === 'youtube' ? 'Subscribers' : 'Followers'}`}
                        name={`${channel}Reach`}
                        size='small'>
                        <InputNumber
                          style={{ width: '100px' }}
                          onChange={e => {
                            setFormData(prev => ({ ...prev, [`${channel}Reach`]: e }))
                            submitCampaign(false)
                          }}
                          name={`${channel}Reach`}
                          formatter={value => (value ? `${value}+` : '')}
                          parser={value => value.replace('+', '')}
                        />
                      </FormItem>
                      <FormItem label='Engagement' name={`${channel}Engagement`} size='small'>
                        <InputNumber
                          min={0}
                          max={3}
                          placeholder='0%'
                          style={{ width: '100px' }}
                          onChange={e => {
                            setFormData(prev => ({ ...prev, [`${channel}Engagement`]: e }))
                            submitCampaign(false)
                          }}
                          name={`${channel}Engagement`}
                          formatter={value => (value ? `${value}%+` : '')}
                          parser={value => value.replace('%+', '')}
                        />
                      </FormItem>
                    </div>
                  </>
                ))}
              </>
            )}
          </Form>
        )}
      </Formik>
    </section>
  )
}
