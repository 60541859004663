import { FlagOutlined, LoadingOutlined, DeleteOutlined } from '@ant-design/icons'
import { Button, message, Modal, Tag, Tooltip } from 'antd'
import axios from 'axios'
import { Form, Formik } from 'formik'
import { Input, Select } from 'formik-antd'
import moment from 'moment'
import React, { useState, useEffect } from 'react'
import { useQuery, queryCache } from 'react-query'
import styled from 'styled-components/macro'
import * as yup from 'yup'

import { NODE_URL } from '../../../constants'
import FormItem from '../../forms/FormItem'

const FormItems = ({ values, setFieldValue }) => {
  const { data, status } = useQuery('all-creator-flags', async () => {
    const { data } = await axios.get(`${NODE_URL}/admin/creator-flags`)
    return data
  })

  const colorOptions = [
    'magenta',
    'red',
    'volcano',
    'orange',
    'gold',
    'lime',
    'green',
    'cyan',
    'blue',
    'geekblue',
    'purple',
  ]

  return (
    <>
      <div className='form-row'>
        <FormItem label='Label' name='label' width='100%' size='small'>
          <Select
            name='label'
            placeholder='e.g. Fake followers'
            mode='tags'
            maxCount={1}
            loading={status === 'loading'}
            options={
              data?.length &&
              data.map(flag => ({
                value: flag.label,
                label: flag.label,
              }))
            }
            onChange={value => {
              const selectedValue = value[value.length - 1] // get the last selected value (can only select one)
              const existingFlag = data?.length && data.find(flag => flag.label === selectedValue)
              if (existingFlag) {
                setFieldValue('color', existingFlag.color)
              }
              setFieldValue('label', selectedValue)
            }}
            tagRender={({ label, closable, onClose }) => (
              <Tag color={values.color} closable={closable} onClose={onClose}>
                {label}
              </Tag>
            )}
            style={{ width: '100%' }}
            listHeight={200}
            allowClear
          />
        </FormItem>

        <FormItem label='Color' name='color' width='100%' size='small'>
          <Select
            name='color'
            placeholder='Select'
            options={colorOptions.map(color => ({
              value: color,
              label: <Tag color={color}>{color.charAt(0).toUpperCase() + color.slice(1)}</Tag>,
            }))}
            style={{ width: '100%' }}
          />
        </FormItem>
      </div>

      <FormItem label='Comment' sublabel='(Optional)' name='comment'>
        <Input.TextArea name='comment' placeholder='e.g. Suspicious activity detected' showCount />
      </FormItem>
    </>
  )
}

// ADMINS ONLY
export const CreatorFlags = ({ userId }) => {
  const [modalOpen, setModalOpen] = useState(false)
  const [editingFlag, setEditingFlag] = useState(null)
  const [deleting, setDeleting] = useState(false)

  const { data, status } = useQuery(['creator-flags', userId], async () => {
    const { data } = await axios.get(`${NODE_URL}/admin/creator/${userId}/flags`)
    return data
  })

  const initialValues = { label: '', comment: '', color: '' }

  const validationSchema = yup.object().shape({
    label: yup.string().required('Required').min(2, 'Too short').max(50, 'Max 50 characters'),
    comment: yup.string().min(2, 'Too short').max(200, 'Max 200 characters').nullable(),
    color: yup.string().required('Required'),
  })

  const handleClose = () => {
    setModalOpen(false)
    setEditingFlag(null)
  }

  const handleSubmit = async (values, { resetForm }) => {
    try {
      if (editingFlag) {
        await axios.put(`${NODE_URL}/admin/creator/${userId}/flags/${editingFlag.id}`, values)
      } else {
        await axios.post(`${NODE_URL}/admin/creator/${userId}/flags`, values)
      }
      await queryCache.invalidateQueries(['creator-flags', userId])
      await queryCache.invalidateQueries('all-creator-flags')
      message.success(`Flag ${editingFlag ? 'updated' : 'added'}`)
      handleClose()
      resetForm()
    } catch (err) {
      console.log(err)
      message.error(err.response?.data?.err || 'An unexpected error occurred')
    }
  }

  const handleDelete = async () => {
    setDeleting(true)
    try {
      await axios.delete(`${NODE_URL}/admin/creator/${userId}/flags/${editingFlag.id}`)
      await queryCache.invalidateQueries(['creator-flags', userId])
      await queryCache.invalidateQueries('all-creator-flags')
      message.success('Flag removed')
      handleClose()
    } catch (err) {
      console.log(err)
      message.error(err.response?.data?.err || 'An unexpected error occurred')
    } finally {
      setDeleting(false)
    }
  }

  useEffect(() => {
    if (editingFlag) {
      setModalOpen(true)
    }
  }, [editingFlag])

  return (
    <Wrapper>
      <div className='flags'>
        {status === 'loading' && <LoadingOutlined spin />}
        {status === 'success' &&
          !!data?.length &&
          data.map((flag, i) => (
            <Tag key={i} color={flag.color} onClick={() => setEditingFlag(flag)}>
              <Tooltip
                title={
                  <TooltipTitle>
                    <p>&quot;{flag.comment || 'No additional info.'}&quot;</p>
                    <p>
                      {flag.editedByUser
                        ? `
                    Edited by: ${flag.editedByUser.firstName} ${flag.editedByUser.lastName}
                    `
                        : `Flagged by: ${flag.flaggedByUser.firstName} ${flag.flaggedByUser.lastName}`}
                      <br />
                      {moment(flag.updatedAt || flag.createdAt).format('MMM D, YYYY')}
                    </p>
                  </TooltipTitle>
                }>
                {flag.label}
              </Tooltip>
            </Tag>
          ))}
        <Button type='link' icon={<FlagOutlined />} onClick={() => setModalOpen(true)}>
          Flag Creator
        </Button>
      </div>

      <Formik
        enableReinitialize
        initialValues={editingFlag || initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}>
        {({ values, setFieldValue, isSubmitting, submitForm }) => (
          <Form>
            <Modal
              title={editingFlag ? 'Edit flag details' : 'Flag creator'}
              open={modalOpen}
              onCancel={handleClose}
              footer={[
                editingFlag && (
                  <Button
                    key='delete'
                    onClick={handleDelete}
                    type='link'
                    danger
                    icon={<DeleteOutlined />}
                    loading={deleting}
                    style={{ float: 'inline-start' }}>
                    Delete
                  </Button>
                ),
                <Button key='cancel' onClick={handleClose}>
                  Cancel
                </Button>,
                <Button key='submit' type='primary' loading={isSubmitting} onClick={submitForm}>
                  {editingFlag ? 'Update' : 'Submit'}
                </Button>,
              ]}>
              <ModalContentWrapper>
                <p>
                  Flag a creator to help other admins be aware of potential issues or to highlight
                  their positive qualities.
                </p>

                <FormItems values={values} setFieldValue={setFieldValue} />
              </ModalContentWrapper>
            </Modal>
          </Form>
        )}
      </Formik>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  margin-bottom: 20px;
  .title {
    color: ${props => props.theme.crcoGrey};
    margin: 0;
  }
  .flags {
    margin-top: 10px;
  }
  .ant-tag {
    cursor: pointer;
  }
`

const TooltipTitle = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  p {
    margin: 0;
  }
`

const ModalContentWrapper = styled.div`
  .form-row {
    width: 100%;
    display: flex;
    gap: 12px;
  }
`
