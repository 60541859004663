import { CheckCircleOutlined } from '@ant-design/icons'
import { Empty, Spin } from 'antd'
import axios from 'axios'
import moment from 'moment'
import numeral from 'numeral'
import React, { useContext, useRef } from 'react'
import { useInfiniteQuery } from 'react-query'
import styled from 'styled-components'

import { NODE_URL } from '../../../constants'
import { BrandContext } from '../../../contexts/BrandContext'
import instagram from '../../../images/icons/social/instagram-color-square.svg'
import tiktok from '../../../images/icons/social/tiktok-color-square.svg'
import youtube from '../../../images/icons/social/youtube-color-square.svg'
import placeholder from '../../../images/placeholder.svg'
import { socialProfileToUrl } from '../../../utils'
const socialIcons = { instagram, youtube, tiktok }

const SentInvites = () => {
  const sort = {
    value: 'date',
    order: 'desc',
  }

  const fetchInvites = async (key, brandId, sort, order, page = 1) => {
    const { data } = await axios.get(
      `${NODE_URL}/campaign-invites/${brandId}/?page=${page}&order=${order}`
    )
    return data
  }

  const { brandId } = useContext(BrandContext)
  const { data, status, isFetchingMore, fetchMore, canFetchMore } = useInfiniteQuery(
    ['search-invites', brandId, sort.value, sort.order],
    fetchInvites,
    {
      getFetchMore: lastGroup => {
        return lastGroup.nextPage
      },
    }
  )

  const scrollRef = useRef(false)
  const handleScroll = currentScroll => {
    const distance =
      currentScroll.scrollHeight - (currentScroll.scrollTop + currentScroll.offsetHeight)
    distance < 300 && canFetchMore && !isFetchingMore && fetchMore()
  }

  return (
    <Wrapper className='results'>
      <div className='inner'>
        <div className='header'>
          <div className='heading-item creator'>Creator</div>
          <div className='heading-item'></div>
          <div className='heading-item'></div>
          <div className='heading-item campaign'>Campaign</div>
          <div className='heading-item'>Date Invited</div>
          <div className='heading-item'>Delivery Status</div>
        </div>
        <div
          className='results-inner'
          ref={scrollRef}
          onScroll={() => handleScroll(scrollRef.current)}>
          {status === 'success' && (
            <>
              {data &&
                data.map((group, i) =>
                  group.invites?.length > 0 ? (
                    group.invites.map(item => {
                      let niceStatus = 'N/A'
                      if (item.metaData?.deliveryStatus) {
                        switch (item.metaData?.deliveryStatus) {
                          case 'request':
                            niceStatus = 'Sent'
                            break
                          case 'unique_opened':
                            niceStatus = 'First Open'
                            break
                          case 'soft_bounce':
                            niceStatus = 'Soft Bounce'
                            break
                          case 'hard_bounce':
                            niceStatus = 'Hard Bounce'
                            break
                          case 'invalid_email':
                            niceStatus = 'Invalid Email'
                            break
                          case 'spam':
                            niceStatus = 'Marked as Spam'
                            break
                          default:
                            niceStatus = `${item.metaData?.deliveryStatus[0].toUpperCase()}${item.metaData?.deliveryStatus.substring(
                              1
                            )}`
                            break
                        }
                      }

                      return (
                        <div className='result' key={i}>
                          {item.socialProfile ? (
                            <>
                              <a
                                className='result-item creator'
                                href={socialProfileToUrl(item.socialProfile)}
                                target='_blank'
                                rel='noopener noreferrer'>
                                <div
                                  className='avatar'
                                  style={{
                                    backgroundImage: `url(${item.socialProfile.profilePicUrl})`,
                                  }}
                                />
                                <div className='info'>
                                  <div className='username'>
                                    {item.socialProfile.username}
                                    {item.socialProfile.verified && (
                                      <span className='verified'>
                                        <CheckCircleOutlined />
                                      </span>
                                    )}
                                  </div>
                                  <div className='fullname'>{item.socialProfile.fullname}</div>
                                </div>
                              </a>
                              <img
                                className='platform'
                                src={socialIcons[item.socialProfile.platform]}
                                alt=''
                              />
                              <div className='result-item'>
                                {numeral(item.socialProfile.followerCount).format('Oa')}
                                <span className='label'>
                                  {item.socialProfile.platform === 'youtube'
                                    ? 'subscribers'
                                    : 'followers'}
                                </span>
                              </div>
                              <div className='result-item'>
                                {item.socialProfile.engagement.toFixed(2)}%
                                <span className='label'>engagement</span>
                              </div>
                              <div className='result-item campaign'>
                                <div className='campaign-name'>{item.campaign.title}</div>
                              </div>
                              <div className='result-item'>
                                {moment(item.createdAt).format('ll')}
                              </div>
                              <div className='result-item'>Sent</div>
                            </>
                          ) : (
                            // OLD CAMPAIGN INVITES
                            <>
                              <a
                                className='result-item creator'
                                href={
                                  item?.metaData?.profile?.url ||
                                  item?.metaData?.url ||
                                  item?.metaData?.platform === 'instagram'
                                    ? `https://instagram.com/${item?.metaData?.username}`
                                    : ''
                                }
                                target='_blank'
                                rel='noopener noreferrer'>
                                <div
                                  className='avatar'
                                  style={
                                    item?.metaData?.profile?.picture || item?.metaData?.profilePic
                                      ? {
                                          backgroundImage: `url(${
                                            item?.metaData?.profile?.picture ||
                                            item?.metaData?.profilePic
                                          })`,
                                        }
                                      : {
                                          backgroundImage: `url(${placeholder})`,
                                          backgroundSize: '70%',
                                          border: '2px solid #e6e6e6',
                                        }
                                  }
                                />
                                <div className='info'>
                                  <div className='username'>
                                    {item.metaData?.profile?.username || item?.metaData?.username}
                                    {item.metaData?.profile?.is_verified && (
                                      <span className='verified'>
                                        <CheckCircleOutlined />
                                      </span>
                                    )}
                                  </div>
                                  <div className='fullname'>
                                    {item.metaData?.profile?.fullname || item.metaData?.name}
                                  </div>
                                </div>
                              </a>
                              {item.metaData?.platform ? (
                                <img
                                  className='platform'
                                  src={socialIcons[item.metaData?.platform]}
                                  alt=''
                                />
                              ) : (
                                <div className='platform' />
                              )}
                              <div className='result-item'>
                                {numeral(
                                  (
                                    item?.metaData?.profile?.followers ||
                                    item?.metaData?.followerCount
                                  )
                                    ?.toString()
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                                ).format('Oa')}
                                <span className='label'>
                                  {item?.metaData?.profile?.url?.includes('youtube')
                                    ? 'subscribers'
                                    : 'followers'}
                                </span>
                              </div>
                              <div className='result-item'>
                                {item?.metaData?.profile?.engagement_rate
                                  ? Math.round(item?.metaData?.profile?.engagement_rate * 10000) /
                                    100
                                  : Math.round(item?.metaData?.engagement * 100) / 100}
                                %<span className='label'>engagement</span>
                              </div>
                              <div className='result-item campaign'>
                                <div className='campaign-name'>
                                  {item.metaData?.campaignTitle || '-'}
                                </div>
                              </div>
                              <div className='result-item'>
                                {moment(item?.savedDate).format('ll')}
                              </div>
                              <div className='result-item'>{niceStatus}</div>
                            </>
                          )}
                        </div>
                      )
                    })
                  ) : (
                    <Empty
                      key={i}
                      style={{
                        height: '100%',
                        minHeight: '50vh',
                        display: 'flex',
                        justifyContent: 'center',
                        flexDirection: 'column',
                        color: '#aeb8c2',
                      }}
                      description='No invites sent yet.'
                    />
                  )
                )}
              {isFetchingMore && (
                <div style={{ textAlign: 'center', margin: '0 20px' }}>
                  <Spin />
                </div>
              )}
            </>
          )}
          {status === 'loading' && (
            <div style={{ display: 'grid', placeItems: 'center', height: '100%' }}>
              <Spin />
            </div>
          )}
        </div>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  height: 100%;
  max-width: 1400px;
  margin: 0 auto;

  .inner {
    height: 100%;
    padding: 20px;
    background-color: #fff;
    min-height: fit-content;
    border-radius: 10px;
  }

  .platform {
    width: 24px;
    height: 24px;
    margin-left: 10px;
  }
`

export default SentInvites
