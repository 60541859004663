import { PlusOutlined, MinusCircleOutlined } from '@ant-design/icons'
import {
  Button,
  InputNumber as AntInputNumber,
  Select as AntSelect,
  Drawer,
  Alert,
  Divider,
  Spin,
} from 'antd'
import { Formik, Form } from 'formik'
import { Input, Select } from 'formik-antd'
import React, { useState } from 'react'
import * as yup from 'yup'

import CreateEmailTemplate from '../../../components/forms/CreateEmailTemplate'
import FormItem from '../../../components/forms/FormItem'

const SequenceSteps = ({
  brandData,
  brandStatus,
  setCreateEmailTemplateDrawer,
  values,
  setFieldValue,
}) => {
  const handleDeleteStepClick = (step, values) => {
    const { stepId } = step
    const newSteps = values?.steps?.filter(value => value.stepId !== stepId)
    setFieldValue('steps', [...newSteps])
  }

  const handleInputNumberChange = (e, step) => {
    setFieldValue('steps', [
      ...values.steps.map(value => {
        return value.stepId !== step.stepId
          ? value
          : {
              ...value,
              daysAfterPrevious: e,
            }
      }),
    ])
  }

  const handleSelectTemplateChange = (e, inputValues, step) => {
    setFieldValue('steps', [
      ...values.steps.map(value => {
        return value.stepId !== step.stepId
          ? value
          : {
              ...value,
              emailTemplateId: inputValues?.value || 0,
            }
      }),
    ])
  }

  return (
    <>
      {values?.steps.map(step => {
        return (
          <div key={step.stepId}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
              }}>
              <FormItem
                size='small'
                name='daysAfterPrevious'
                label={`Email #${step.order}`}
                sublabel={step.order !== 1 && 'if no reply'}>
                <div className='days-input-wrapper'>
                  <AntInputNumber
                    name='daysAfterPrevious'
                    className='days-input'
                    defaultValue={step.daysAfterPrevious}
                    disabled={step.order === 1}
                    min={0}
                    max={50}
                    onChange={e => handleInputNumberChange(e, step)}
                  />
                  <span>days after previous email</span>
                </div>
              </FormItem>
              <Button
                type='link'
                danger
                htmlType='button'
                onClick={() => handleDeleteStepClick(step, values)}>
                <MinusCircleOutlined />
              </Button>
            </div>
            <FormItem size='small' name='emailTemplateId'>
              <AntSelect
                name='emailTemplateId'
                placeholder='Select template'
                defaultValue={step.emailTemplateId || null}
                allowClear
                loading={brandStatus === 'loading'}
                notFoundContent={`No email templates found for ${brandData?.name}`}
                onChange={(e, inputValues) => {
                  handleSelectTemplateChange(e, inputValues, step)
                }}
                options={brandData?.emailTemplates?.map(template => ({
                  key: template.id,
                  label: template.label,
                  value: template.id,
                }))}
                dropdownRender={menu => {
                  return (
                    <>
                      {menu}
                      <Divider style={{ margin: '8px 0' }} />
                      <Button
                        htmlType='button'
                        type='link'
                        style={{
                          paddingLeft: '10px',
                          paddingRight: '10px',
                        }}
                        onClick={() => setCreateEmailTemplateDrawer(true)}>
                        <PlusOutlined /> New Template
                      </Button>
                    </>
                  )
                }}
              />
            </FormItem>
          </div>
        )
      })}
    </>
  )
}

const CreateEditSequence = ({
  sequence,
  status,
  handleSubmit,
  campaigns,
  campaignStatus,
  creatorLists,
  creatorListStatus,
  brandData,
  brandStatus,
}) => {
  const [errMsg, setErrMsg] = useState('')
  const [createEmailTemplateDrawer, setCreateEmailTemplateDrawer] = useState(false)

  const validationSchema = yup.object().shape({
    sequenceTitle: yup.string().required('Required'),
    campaignTitle: yup.number().required('Required'),
    creatorListTitle: yup.number().required('Required'),
    steps: yup.array().of(
      yup.object().shape({
        daysAfterPrevious: yup.number(),
        emailTemplateId: yup.number(),
      })
    ),
  })

  const addStepClick = (values, setFieldValue) => {
    if (values?.steps?.length === 5) {
      setErrMsg('Max number of emails reached')
      return
    }

    if (values?.steps?.length !== 0) {
      const steps = values?.steps
      setFieldValue('steps', [
        ...steps,
        {
          stepId: values.steps[values.steps.length - 1].stepId + 1,
          order: values.steps[values.steps.length - 1].order + 1,
          daysAfterPrevious: 0,
          emailTemplateId: 0,
        },
      ])
    } else {
      setFieldValue('steps', [
        {
          stepId: 1,
          order: 1,
          daysAfterPrevious: 0,
          emailTemplateId: 0,
        },
      ])
    }
  }

  if (status === 'loading') {
    return (
      <Spin
        size='large'
        fullscreen='true'
        style={{ display: 'grid', height: '80vh', placeItems: 'center', minHeight: '400px' }}
      />
    )
  }

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={{
        sequenceTitle: sequence?.title || '',
        campaignTitle: sequence?.campaign?.id || '',
        creatorListTitle: sequence?.creatorList?.id || '',
        steps: sequence?.steps || [],
      }}
      onSubmit={data => {
        handleSubmit(data)
      }}>
      {({ values, setFieldValue, dirty, isSubmitting }) => {
        return (
          <>
            <Form>
              <FormItem name='sequenceTitle' label='Sequence Title'>
                <Input
                  allowClear
                  required
                  name='sequenceTitle'
                  placeholder='e.g. Instagram Creator Outreach'
                />
              </FormItem>
              <FormItem name='campaignTitle' label='Campaign'>
                <Select
                  name='campaignTitle'
                  placeholder='Select'
                  allowClear
                  loading={campaignStatus === 'loading'}>
                  {campaigns?.map(campaign => {
                    return (
                      <Select.Option value={campaign.id} key={campaign.id}>
                        {campaign.title}
                      </Select.Option>
                    )
                  })}
                </Select>
              </FormItem>
              <FormItem name='creatorListTitle' label='Creator List'>
                <Select
                  name='creatorListTitle'
                  placeholder='Select'
                  allowClear
                  loading={creatorListStatus === 'loading'}>
                  {creatorLists?.map(list => (
                    <Select.Option value={list.id} key={list.id}>
                      {list.title}
                    </Select.Option>
                  ))}
                </Select>
              </FormItem>
              {values.campaignTitle && values.creatorListTitle && values.sequenceTitle && (
                <>
                  <div className='automated-emails-section'>
                    <div className='automated-emails-header'>
                      <p>Automated Emails</p>

                      <Button
                        onClick={() => addStepClick(values, setFieldValue)}
                        type='link'
                        htmlType='button'
                        icon={<PlusOutlined />}>
                        Add Email
                      </Button>
                    </div>

                    {errMsg && (
                      <Alert
                        type='warning'
                        description={errMsg}
                        onClose={() => setErrMsg(false)}
                        closable
                        showIcon
                      />
                    )}

                    <div className='automated-emails-content'>
                      <SequenceSteps
                        brandData={brandData}
                        setCreateEmailTemplateDrawer={setCreateEmailTemplateDrawer}
                        values={values}
                        setFieldValue={setFieldValue}
                        brandStatus={brandStatus}
                      />
                    </div>
                  </div>
                </>
              )}
              {values?.steps?.length !== 0 && (
                <Button htmlType='submit' disabled={!dirty} type='primary' loading={isSubmitting}>
                  {sequence ? 'Save' : 'Create'}
                </Button>
              )}
            </Form>
            <Drawer
              title='Create Email Template'
              open={createEmailTemplateDrawer}
              destroyOnClose
              width={600}
              maskClosable={false} /* avoid big loss */
              onCancel={() => setCreateEmailTemplateDrawer(false)}
              onClose={() => setCreateEmailTemplateDrawer(false)}
              footer={null}>
              <CreateEmailTemplate
                close={() => setCreateEmailTemplateDrawer(false)}
                campaignId={values?.campaignTitle}
              />
            </Drawer>
          </>
        )
      }}
    </Formik>
  )
}

export default CreateEditSequence
