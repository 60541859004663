import { Empty } from 'antd'
import React from 'react'
import * as yup from 'yup'

import { BrandIdFilter } from '../filters/BrandIdFilter'
import { CampaignIdFilter } from '../filters/CampaignIdFilter'
import { DateRangeFilter } from '../filters/DateRangeFilter'
import { ManualDateRangeFilter } from '../filters/ManualDateRangeFilter'
import { SocialChannelFilter } from '../filters/SocialChannelFilter'
import { ManageWidget } from '../ManageWidget'
import { useCampaignOptions } from '../utils'
import { MetricsWrapper, WidgetCard } from '../WidgetCard'

export const TotalPayouts = ({ widget, activeView, setActiveView, brandData, data }) => {
  const campaignOptions = useCampaignOptions(brandData)

  const defaultValues = {
    title: widget.defaultTitle,
    ...widget.defaultFilters,
  }

  const initialValues = {
    title: widget.title || defaultValues.title,
    brandIds: widget.filters?.brandIds || defaultValues.brandIds,
    campaignIds: widget.filters?.campaignIds || defaultValues.campaignIds,
    socialChannels: widget.filters?.socialChannels || defaultValues.socialChannels,
    fromDate: widget.filters?.fromDate || defaultValues.fromDate,
    toDate: widget.filters?.toDate || defaultValues.toDate,
    dateRange:
      widget.filters?.fromDate ||
      defaultValues.fromDate ||
      widget.filters?.toDate ||
      defaultValues.toDate
        ? 'custom'
        : widget.filters?.dateRange || defaultValues.dateRange,
  }

  const validationSchema = yup.object().shape({
    title: yup.string().required('Required').max(50, 'Must be 50 characters or less'),
    brandIds: yup.array(),
    campaignIds: yup.array(),
    socialChannels: yup.array(),
    dateRange: yup.string(),
  })

  const constructData = values => {
    return {
      title: values.title,
      filters: {
        brandIds: values.brandIds,
        campaignIds: values.campaignIds,
        socialChannels: values.socialChannels,
        fromDate: values.fromDate === null ? undefined : values.fromDate,
        toDate: values.toDate === null ? undefined : values.toDate,
        dateRange: values.dateRange,
      },
      visible: values.visible || true,
    }
  }

  const Filters = ({ setFieldValue }) => {
    return (
      <>
        {!!brandData.childBrands?.length && <BrandIdFilter brandData={brandData} />}
        <CampaignIdFilter campaignOptions={campaignOptions} />
        <SocialChannelFilter />

        <div className='date-range-wrapper'>
          <ManualDateRangeFilter setFieldValue={setFieldValue} />
          <DateRangeFilter setFieldValue={setFieldValue} />
        </div>
      </>
    )
  }

  const Metrics = () => {
    return (
      <MetricsWrapper>
        {data && !!Object.keys(data)?.length ? (
          <div>
            <p className='value primary'>
              $
              {data?.totalPayout?.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </p>
            <p className='label'>USD</p>
          </div>
        ) : (
          <div className='no-data'>
            <Empty description='No data.' />
          </div>
        )}
      </MetricsWrapper>
    )
  }

  const settingsProps = {
    widget,
    Filters,
    defaultValues,
    initialValues,
    validationSchema,
    constructData,
    activeView,
    setActiveView,
    brandId: brandData.id,
  }

  const cardProps = {
    widget,
    settings: <ManageWidget {...settingsProps} />,
    metrics: <Metrics />,
    brandData,
  }

  return <WidgetCard {...cardProps} />
}
