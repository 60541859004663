import { DatePicker } from 'formik-antd'
import React from 'react'

import FormItem from '../../../components/forms/FormItem'

export const ManualDateRangeFilter = ({ setFieldValue }) => {
  return (
    <div className='form-row-wrapper'>
      <FormItem size='small' name='fromDate' label='From Date' width={'100%'}>
        <DatePicker
          name='fromDate'
          style={{ width: '100%' }}
          allowClear
          placeholder='Select'
          onChange={e => {
            if (!e) return
            setFieldValue('dateRange', 'custom')
          }}
        />
      </FormItem>
      <FormItem size='small' name='toDate' label='To Date' width={'100%'}>
        <DatePicker
          name='toDate'
          style={{ width: '100%' }}
          allowClear
          placeholder='Select'
          onChange={e => {
            if (!e) return
            setFieldValue('dateRange', 'custom')
          }}
        />
      </FormItem>
    </div>
  )
}
