import { PlusOutlined } from '@ant-design/icons'
import { Alert, Button, Checkbox, Select, Modal, message } from 'antd'
import axios from 'axios'
import React, { useState, useRef } from 'react'
import { queryCache } from 'react-query'
import styled from 'styled-components'

import { NODE_URL } from '../../../../constants'
const { Option } = Select

export const ManualOptIn = ({ campaign }) => {
  const [manualOptInOpen, setManualOptInOpen] = useState(false)
  const [creatorUsers, setCreatorUsers] = useState([])
  const [fetchingCreatorUsers, setFetchingCreatorUsers] = useState(false)
  const [manualOptInUserId, setManualOptInUserId] = useState('')
  const [manualOptInLoading, setManualOptInLoading] = useState(false)
  const [manualOptInError, setManualOptInError] = useState(null)
  const [duplicateOptIn, setDuplicateOptIn] = useState(false)
  const [allowDuplicate, setAllowDuplicate] = useState(false)
  const [termsAccepted, setTermsAccepted] = useState(false)
  const userFetch = useRef(0)

  const clearManualOptInState = () => {
    setManualOptInOpen(false)
    setManualOptInUserId('')
    setTermsAccepted(false)
    setManualOptInError(null)
    setDuplicateOptIn(false)
    setAllowDuplicate(false)
  }

  const handleManualOptIn = async () => {
    setManualOptInLoading(true)
    setManualOptInError(null)
    try {
      await axios.post(`${NODE_URL}/brand/${campaign.brand.id}/campaign/${campaign.id}/opt-ins`, {
        userId: manualOptInUserId,
        allowDuplicate,
      })
      await queryCache.invalidateQueries('campaign')
      await queryCache.invalidateQueries('opt-ins')
      clearManualOptInState()
      message.success('Opt-in created successfully.')
    } catch (err) {
      setManualOptInError(err.response?.data?.err || 'An unknown error occurred')
      if (err.response?.data?.errCode === 'OP_NOT_ALLOWED') {
        setDuplicateOptIn(true)
      }
    }
    setManualOptInLoading(false)
  }

  const handleSearchCreators = async search => {
    // control subsequent fetches
    userFetch.current++
    const fetchId = userFetch.current
    setFetchingCreatorUsers(true)

    try {
      const res = await axios.get(`${NODE_URL}/creator-users`, {
        params: { search },
      })
      if (userFetch.current === fetchId) {
        setCreatorUsers(res.data.creatorUsers)
      }
    } catch (err) {
      if (userFetch.current === fetchId) {
        setCreatorUsers([])
      }
    } finally {
      if (userFetch.current === fetchId) {
        setFetchingCreatorUsers(false)
      }
    }
  }

  return (
    <>
      <Button
        className='opt-in-btn'
        type='link'
        icon={<PlusOutlined />}
        onClick={() => setManualOptInOpen(true)}>
        New Opt-In
      </Button>

      <Modal
        title='New Opt-In'
        open={manualOptInOpen}
        okText='Submit'
        onOk={handleManualOptIn}
        okButtonProps={{
          loading: manualOptInLoading,
          disabled: !manualOptInUserId || !termsAccepted,
        }}
        onCancel={clearManualOptInState}
        destroyOnClose>
        <p>
          Found a creator you&apos;d love to work with despite not meeting your campaign
          requirements? Opt them in manually and jumpstart your collaboration!
        </p>
        <ModalWrapper>
          <Select
            style={{ width: '100%' }}
            name='userId'
            loading={fetchingCreatorUsers}
            placeholder='Search creators by name, email, or username'
            showSearch
            filterOption={false}
            onSearch={val => handleSearchCreators(val)}
            onChange={val => setManualOptInUserId(val)}
            notFoundContent={fetchingCreatorUsers ? 'Loading...' : 'No creators found.'}>
            {creatorUsers.map((user, i) => (
              <Option key={i} value={user.id}>
                {`${user.firstName} ${user.lastName} (${user.email})`}
              </Option>
            ))}
          </Select>
          <Checkbox
            name='terms'
            onChange={e => {
              setTermsAccepted(e.target.checked)
            }}>
            I have read and agree to the{' '}
            <a
              href='https://creator.co/brand-terms/'
              target='_blank'
              rel='noreferrer noopener'
              onClick={ev => ev.stopPropagation()}>
              Terms & Conditions
            </a>{' '}
            and accept any liability which may arise from this manual opt-in.
          </Checkbox>
          {manualOptInError && (
            <Alert
              message={manualOptInError}
              type={duplicateOptIn ? 'warning' : 'error'}
              showIcon
              style={{ marginTop: '20px' }}
            />
          )}
          {duplicateOptIn && (
            <Checkbox name='allowDuplicate' onChange={e => setAllowDuplicate(e.target.checked)}>
              Create duplicate opt-in
            </Checkbox>
          )}
        </ModalWrapper>
      </Modal>
    </>
  )
}

const ModalWrapper = styled.div`
  .ant-checkbox-wrapper {
    color: ${props => props.theme.crcoGrey};
    margin: 20px 0 0 0;
    font-size: 0.8rem;
  }
`
