import { UploadOutlined } from '@ant-design/icons'
import { Upload, Button, message, Image } from 'antd'
import axios from 'axios'
import React, { useState, useContext } from 'react'
import Resizer from 'react-image-file-resizer'
import { queryCache, useQuery } from 'react-query'
import styled from 'styled-components'

import { NODE_URL } from '../../constants'
import { BrandContext } from '../../contexts/BrandContext'
import { UserContext } from '../../contexts/UserContext'

export const BrandLogo = () => {
  const { nodeToken } = useContext(UserContext)
  const { brandId, fetchBrandData } = useContext(BrandContext)
  const { data: brandData } = useQuery(['brand', brandId], fetchBrandData)

  const resizeFile = file => {
    const banner = new Promise(resolve => {
      Resizer.imageFileResizer(
        file,
        720,
        720,
        'JPEG',
        100,
        0,
        uri => {
          resolve(uri)
        },
        'file'
      )
    })
    const thumbnail = new Promise(resolve => {
      Resizer.imageFileResizer(
        file,
        400,
        400,
        'JPEG',
        80,
        0,
        uri => {
          resolve(uri)
        },
        'file'
      )
    })

    return Promise.all([banner, thumbnail])
  }

  const [heroFileList, setHeroFileList] = useState([])

  const handleUpload = async fileData => {
    const uid = fileData.file.uid
    const image = await resizeFile(fileData.file, 'primary')
    const formData = new FormData()
    formData.append('banner', image[0])
    formData.append('thumbnail', image[1])
    const result = await axios
      .post(`${NODE_URL}/brand-logo-upload/${brandId}?type=primary`, formData, {
        headers: {
          Authorization: `Bearer ${nodeToken}`,
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress: data => {
          const percent = Math.round((100 * data.loaded) / data.total)
          setHeroFileList(() => [
            {
              uid,
              name: fileData.file.name,
              percent,
              status: 'uploading',
            },
          ])
        },
      })
      .catch(() => {
        message.error('Error uploading image')
      })
    if (result?.data?.success) {
      message.success('Image uploaded')
      queryCache.invalidateQueries('brand')
      setHeroFileList([])
    }
  }

  return (
    <Wrapper className='account-section'>
      {brandData?.images.find(i => i.type === 'primary')?.sizes[1].url && (
        <div className='hero-image'>
          <Image
            width={300}
            preview={false}
            src={
              brandData?.images
                .find(i => i.type === 'primary')
                ?.sizes.find(s => s.size === 'thumbnail')?.url
            }
          />
        </div>
      )}
      <Upload
        customRequest={handleUpload}
        accept='image/jpg, image/jpeg, image/png'
        fileList={heroFileList}
        showUploadList={{
          showRemoveIcon: false,
        }}>
        <Button type='primary' icon={<UploadOutlined />} className='button-submit'>
          {brandData?.images.find(i => i.type === 'primary')?.sizes[1].url ? 'Replace' : 'Upload'}{' '}
          Logo
        </Button>
      </Upload>
    </Wrapper>
  )
}

const Wrapper = styled.section`
  .hero-image {
    margin-bottom: 20px;
  }
`
