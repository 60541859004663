import { Api } from '@creator.co/module-analytics-client'
import { useContext } from 'react'

import { UserContext } from '../contexts/UserContext'

export const useAnalyticsApi = () => {
  const { nodeToken } = useContext(UserContext)

  return new Api({
    baseURL: process.env.REACT_APP_ANALYTICS_URL,
    headers: {
      Authorization: `Bearer ${nodeToken}`,
    },
  })
}
