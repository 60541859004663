import { Radio } from 'formik-antd'
import React from 'react'

import FormItem from '../../../components/forms/FormItem'

export const DateRangeFilter = ({ setFieldValue, optionsOverride }) => {
  return (
    <FormItem size='small' name='dateRange' label='Date Range'>
      <Radio.Group
        name='dateRange'
        onChange={e => {
          if (e.target.value === 'custom') return
          setFieldValue('fromDate', null)
          setFieldValue('toDate', null)
        }}
        options={
          optionsOverride
            ? optionsOverride
            : [
                { label: 'All time', value: 'allTime' },
                { label: 'Last 7 days', value: 'last7days' },
                { label: 'Last 30 days', value: 'last30days' },
                { label: 'Last 90 days', value: 'last90days' },
                { label: 'Custom', value: 'custom' },
              ]
        }
      />
    </FormItem>
  )
}
