import { EyeTwoTone, EyeInvisibleOutlined } from '@ant-design/icons'
import { Button, Alert } from 'antd'
import axios from 'axios'
import { Formik, Form } from 'formik'
import { Input } from 'formik-antd'
import React, { useState } from 'react'
import styled from 'styled-components/macro'
import * as yup from 'yup'

import FormItem from './forms/FormItem'
import { NODE_URL } from '../constants'

export const ForgotPass = ({ loginEmail, closeModal }) => {
  const [email, setEmail] = useState(loginEmail)
  const [mailSent, setMailSent] = useState(false)
  const [resetSuccess, setResetSuccess] = useState(false)

  const newPasswordValidation = yup.object().shape({
    code: yup.string().required('Required'),
    password: yup.string().required('Required').min(8, 'Minimum 8 characters'),
    confirmPassword: yup
      .string()
      .required('Confirm your password')
      .oneOf([yup.ref('password'), null], 'Passwords must match'),
  })

  const initialValues1 = {
    email,
  }

  const initialValues2 = {
    code: '',
    password: '',
    confirmPassword: '',
  }

  const sendCode = async (data, { setSubmitting, setStatus }) => {
    setStatus('')
    await axios
      .post(`${NODE_URL}/public/password/reset`, { email: data.email })
      .then(() => {
        setMailSent(true)
        setSubmitting(false)
      })
      .catch(() => {
        setStatus('Email not found.')
        setMailSent(false)
        setSubmitting(false)
      })
  }

  const handleUpdatePassword = async ({ code, password }, { setSubmitting, setStatus }) => {
    setStatus('')
    await axios
      .post(`${NODE_URL}/public/password/update`, { code, email, password })
      .then(() => {
        setSubmitting(false)
        setResetSuccess(true)
      })
      .catch(err => {
        setStatus(err.response.data.message || 'Something went wrong.')
        setSubmitting(false)
      })
  }

  return !resetSuccess ? (
    <Wrapper>
      <Formik initialValues={initialValues1} onSubmit={sendCode}>
        {({ status, isSubmitting }) => (
          <Form>
            {status && <Alert message={status} type='error' />}
            <p>Please enter your email address to receive a password recovery code.</p>
            <div style={{ display: 'flex', gap: '12px' }}>
              <Input
                name='email'
                placeholder='Email Address'
                onChange={e => setEmail(e.target.value)}
              />
              <Button
                type={mailSent ? 'secondary' : 'primary'}
                htmlType='submit'
                loading={isSubmitting}>
                {mailSent ? 'Resend Code' : 'Send Code'}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
      {mailSent && (
        <div>
          <p style={{ color: '#027DF0', marginTop: '20px' }}>
            A password reset verification code has been sent to your email. Please enter it below to
            change your password.
          </p>
          <Formik
            initialValues={initialValues2}
            onSubmit={handleUpdatePassword}
            validationSchema={newPasswordValidation}>
            {({ status, isSubmitting }) => (
              <Form>
                {!status || (
                  <div>
                    <Alert message={status} type='error' />
                    <br />
                  </div>
                )}
                <FormItem name='code' label='Code'>
                  <Input name='code' />
                </FormItem>
                <FormItem name='password' label='New Password'>
                  <Input.Password
                    name='password'
                    autoComplete='new-password'
                    iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                  />
                </FormItem>
                <FormItem name='confirmPassword' label='Confirm Password'>
                  <Input.Password
                    name='confirmPassword'
                    autoComplete='new-password'
                    iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                  />
                </FormItem>
                <Button
                  type='primary'
                  htmlType='submit'
                  loading={isSubmitting}
                  style={{ marginTop: '20px' }}>
                  Submit
                </Button>
              </Form>
            )}
          </Formik>
        </div>
      )}
    </Wrapper>
  ) : (
    <ResetSuccess closeModal={closeModal} />
  )
}

const ResetSuccess = ({ closeModal }) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '20px' }}>
      <p>Password reset successful.</p>
      <Button onClick={closeModal}>Close</Button>
    </div>
  )
}

const Wrapper = styled.div``
