import { Button, message } from 'antd'
import axios from 'axios'
import { Form, Formik } from 'formik'
import { Input, Mentions, Select } from 'formik-antd'
import React, { useContext, useRef } from 'react'
import { queryCache, useQuery } from 'react-query'
import styled from 'styled-components'
import * as yup from 'yup'

import { NODE_URL } from '../../constants'
import { BrandContext } from '../../contexts/BrandContext'
import { toLowerCamelCase } from '../../utils'
import FormItem from '../forms/FormItem'
import { EmailTemplateEditor, EmailTemplateVariables } from '../general/EmailTemplateEditor'

const { Option } = Mentions
const CreateEmailTemplate = ({ close, campaignId, existingTemplate }) => {
  const options = EmailTemplateVariables.map((v, i) => (
    <Option key={i} style={{ fontFamily: 'Campton-Medium' }} value={`${toLowerCamelCase(v)}}}`}>
      {v}
    </Option>
  ))

  const { brandId, fetchBrandData, fetchActiveCampaigns } = useContext(BrandContext)
  const { data: brandData } = useQuery(['brand', brandId], fetchBrandData)
  const { data: campaigns } = useQuery(['active-campaigns', brandId], fetchActiveCampaigns)
  const templateEditorRef = useRef()

  // custom validation
  const handleTemplateValidation = () => {
    const value = templateEditorRef?.current?.getTextContent()?.trim()
    if (value?.length <= 0) return 'Please enter a template'
    if (value?.length <= 10) return 'Template is too short'
  }

  return (
    <Wrapper>
      <div className='instructions'>
        Email templates are designed to streamline your collaboration efforts, providing an
        efficient way to craft personalized emails to your creators. To begin, use this form to
        create a new email template. To incorporate predefined variables into your template, simply
        type <span>&#x7B;&#x7B;</span>.
      </div>
      <Formik
        initialValues={{
          label: existingTemplate?.label,
          subject: existingTemplate?.subject,
          template: existingTemplate?.template,
          campaigns: campaignId ? [campaignId] : existingTemplate?.metaData?.campaigns,
        }}
        validationSchema={yup.object().shape({
          label: yup.string().required('Please enter a label for your template'),
          subject: yup.string().required('Please enter a subject for your template'),
        })}
        onSubmit={(data, { setSubmitting, setStatus }) => {
          const template = templateEditorRef?.current?.getHTMLContent()
          axios
            .post(
              `${NODE_URL}/brand/${brandData.id}/emailTemplate/${existingTemplate?.id || 'NEW'}`,
              {
                ...data,
                template,
              }
            )
            .then(res => {
              res.data.success && message.success('Email template created successfully')
              queryCache.invalidateQueries('brand', brandData.id)
              setSubmitting(false)
              close()
              setStatus({ success: true })
            })
            .catch(() => {
              message.error('Email template could not be created. Please try again later.')
              setSubmitting(false)
              setStatus({ success: false })
            })
        }}>
        {({ isSubmitting }) => (
          <Form className='template-form'>
            <FormItem name='label' label='Template label'>
              <Input name='label' placeholder='e.g. Greeting' />
            </FormItem>

            <FormItem name='subject' label='Email subject'>
              <Mentions
                name='subject'
                initialValues={{ subject: '' }}
                placeholder={`Invitation by ${brandData?.name} to collaborate on {{campaignName}}`}
                prefix={['{{']}>
                {options}
              </Mentions>
            </FormItem>
            <FormItem name='template' label='Email template' validate={handleTemplateValidation}>
              <EmailTemplateEditor
                isLegacy={!(existingTemplate?.metaData?.isHTML || !existingTemplate)}
                initialValue={existingTemplate?.template}
                validationRef={templateEditorRef}
              />
            </FormItem>
            <FormItem
              label='Campaigns'
              subtext='When inviting a creator, for which campaigns would you like this message to be sent out by default?'
              name='campaigns'>
              <Select
                name='campaigns'
                showSearch
                placeholder='Select a campaign'
                optionFilterProp='children'
                mode='multiple'
                inline
                allowClear
                filterOption={(input, option) =>
                  option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                style={{ width: '100%' }}>
                {campaigns?.map(campaign => (
                  <Select.Option key={campaign.id} value={campaign.id} label={campaign.title}>
                    {campaign.title}
                  </Select.Option>
                ))}
              </Select>
            </FormItem>
            <div className='nav-buttons'>
              <Button type='primary' htmlType='submit' loading={isSubmitting}>
                {existingTemplate ? 'Update' : 'Create'}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  padding: 20px;
  .ant-mentions {
    #template {
      overflow: auto;
      ${props => props.theme.scrollbar}
    }
  }
  .delete-prompt-message {
    margin-bottom: 20px;
  }
  .nav-buttons {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
  }
  .instructions {
    margin-bottom: 30px;

    span {
      margin-left: 5px;
      font-family: monospace;
      font-weight: bold;
    }
  }
`

export default CreateEmailTemplate
