import React from 'react'

import { Wrapper } from '../creator-search/search/CreatorSearchGuide'

export const AnalyticsGuide = () => {
  return (
    <Wrapper>
      <section>
        <h2>What is it?</h2>
        <p>
          The Analytics Dashboard is a transformative feature that provides a detailed and
          customized glimpse into your campaign performance. It allows you to dive deep into
          insights related to your brand&apos;s campaigns, creators, content, and return on
          investment (ROI) all in one place.
        </p>
        <p>Harness the power of data-driven decisions with this innovative dashboard feature.</p>
      </section>
      <section>
        <h2>Customization at its Best</h2>
        <p>
          Set up your dashboard the way you like. Add various widgets that give you the insights you
          need. Whether it&apos;s an overview of your campaign performance, insights from specific
          creators, or granular content details, you have control over what you see.
        </p>
        <p>
          Rename views and widgets to resonate with your branding or naming conventions. Adjust,
          hide, or delete any widgets that aren&apos;t serving your current needs. With the
          Analytics Dashboard, your insights are tailored to your preferences.
        </p>
      </section>
      <section>
        <h2>Filtration for Precision</h2>
        <p>
          The Analytics Dashboard is not just about viewing data but viewing the right data. Apply
          various filters based on specific campaigns, the social channels you&apos;re targeting, or
          particular date ranges to zero in on the insights you need.
        </p>
        <p>
          Whether you&apos;re analyzing performance from a week-long campaign or tracking ROI over
          several months, the dashboard adjusts to show you the most relevant data.
        </p>
      </section>
      <section>
        <h2>Benefits and Insights</h2>
        <p>
          <b>Campaign Performance:</b> Track metrics that matter, such as engagement rates, clicks,
          and conversions. Monitor the health and effectiveness of your campaigns in real-time.
        </p>
        <p>
          <b>Creator Insights:</b> Understand how different creators contribute to your brand&apos;s
          story. Identify top-performing influencers and optimize collaboration.
        </p>
        <p>
          <b>Content Analysis:</b> Dive into the content that resonates best with your audience.
          Identify trends, top-performing posts, and areas for improvement.
        </p>
        <p>
          <b>ROI Calculations:</b> Measure the true value of your social media marketing efforts.
          Understand the financial impact and ensure a positive return on your investments.
        </p>
      </section>
    </Wrapper>
  )
}
