import { Select } from 'formik-antd'
import React from 'react'

import FormItem from '../../../components/forms/FormItem'

export const CampaignIdFilter = ({ campaignOptions }) => {
  return (
    <FormItem size='small' label='Campaigns' name='campaignIds'>
      <Select
        getPopupContainer={trigger => trigger.parentNode}
        virtual={false}
        name='campaignIds'
        placeholder='Select'
        mode='multiple'
        style={{ width: '100%' }}
        options={campaignOptions}
      />
    </FormItem>
  )
}
