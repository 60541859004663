import { Tabs } from 'antd'
import React from 'react'
import styled from 'styled-components'

import EmailSequences from './EmailSequences'
import SentInvites from './SentInvites.js'

const CampaignInvites = () => {
  const innerTabItems = [
    {
      key: '1',
      label: 'Email Sequences',
      children: <EmailSequences />,
    },
    {
      key: '2',
      label: 'Sent Invites',
      children: <SentInvites />,
    },
  ]

  return (
    <Wrapper>
      <Tabs defaultActiveKey='1' items={innerTabItems} />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  height: 100%;

  .ant-tabs {
    height: 100%;
  }

  .ant-tabs-nav-wrap {
    max-width: 1400px;
    margin: 20px auto 0 auto;
  }

  .ant-tabs-tab {
    font-size: 16px;
    padding: 4px 4px;
    margin-left: 20px;
  }

  .ant-tabs-ink-bar {
    display: block;
  }

  .ant-tabs-nav {
    margin: 0;
  }

  .ant-tabs-content-holder {
    .ant-tabs-content {
      height: 100%;

      .ant-tabs-tabpane {
        height: 100%;
      }
    }
  }
`

export default CampaignInvites
