import { Drawer } from 'antd'
import React, { useState } from 'react'
import styled from 'styled-components'

import { ExpandedShoutout } from '../content/ExpandedShoutout'

const NewShoutoutItem = ({ socialPost }) => {
  const [shoutoutOpen, setShoutoutOpen] = useState(false)

  return (
    <>
      <Wrapper onClick={() => setShoutoutOpen(true)}>
        <div className='inner'>
          <div
            className='thumbnail'
            style={{
              backgroundImage: `url(${socialPost.thumbnailUrl})`,
            }}
          />
        </div>
      </Wrapper>
      <Drawer
        title='ShoutOut Details'
        open={shoutoutOpen}
        width={600}
        onClose={() => setShoutoutOpen(false)}>
        <ExpandedShoutout socialPost={socialPost} />
      </Drawer>
    </>
  )
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  .inner {
    cursor: pointer;
    background-color: #fff;
    padding: 5px;
    border-radius: 5px;
    transition: 0.2s ease-in-out;
    border: 1px solid #e6e6e6;
    .thumbnail {
      border-radius: 5px;
      width: 110px;
      height: 110px;
      background-size: cover;
      background-position: center;
    }
  }
  &:hover {
    .inner {
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    }
  }
`

export default NewShoutoutItem
