import { ShoppingOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import moment from 'moment'
import React from 'react'
import styled from 'styled-components'

const OrderDetails = ({ optIn, openProductsModal }) => {
  return (
    <Wrapper>
      <div className='banner'>
        <Button
          type='link'
          icon={<ShoppingOutlined />}
          onClick={() => {
            openProductsModal()
          }}>
          Create New Order
        </Button>
      </div>
      <div className='orders'>
        {optIn?.extraData?.ordersData?.length ? (
          optIn?.extraData?.ordersData?.map(order => {
            return (
              <div className='order' key={order.orderId}>
                <div>
                  <span className='label'>Order ID:</span>
                  <span className='text'>{order.id}</span>
                </div>
                <div>
                  <span className='label'>Order Number:</span>
                  <span className='text'>{order.orderNumber}</span>
                </div>
                <div>
                  <span className='label'>Date:</span>
                  <span className='text'>{moment(order.orderCreatedAt).format('lll')}</span>
                </div>
              </div>
            )
          })
        ) : (
          <div className='no-orders'>No Orders Found</div>
        )}
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  // custom scrollbar

  padding: 1rem;

  .banner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
  }
  .heading {
    font-size: 1.5rem;
    font-weight: 600;
  }
  .orders {
    display: flex;
    flex-direction: column;

    .no-orders {
      font-size: 1.5rem;
      font-weight: 600;
      margin-bottom: 1rem;
    }

    .order {
      box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
      border-radius: 5px;
      padding: 10px;
      margin-bottom: 10px;
      .label {
        font-weight: 600;
      }
      .text {
        margin-left: 5px;
        color: ${props => props.theme.crcoGrey};
      }
      .order-url {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .status {
        text-transform: capitalize;
      }
    }
  }
`

export default OrderDetails
