import { Button, Alert } from 'antd'
import axios from 'axios'
import { Formik, Form } from 'formik'
import { Select, Input, Checkbox } from 'formik-antd'
import React, { useContext } from 'react'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import Cookies from 'universal-cookie'
import * as yup from 'yup'

import { NODE_URL } from '../../constants'
import { GlobalContext } from '../../contexts/GlobalContext'
import arrow from '../../images/login/arrow-shape.svg'
import FormItem from '../forms/FormItem'

const { TextArea } = Input
const { Option } = Select

// SIGN UP STEP 3 - MISC INFO
export const MiscInfoForm = ({ formData, setFormData, prevStep }) => {
  const cookies = new Cookies()
  const { planId } = useParams()
  const { getCategories } = useContext(GlobalContext)

  const { data: categories } = useQuery('categories', getCategories)

  const validationSchema = yup.object().shape({
    categories: yup.array().required('Please select at least 1'),
    creatorSize: yup.array().required('Please select at least 1'),
    reference: yup.string().required('Required').min(3, 'Minimum 3 characters'),
    brandGoal: yup.string().required('Required').min(10, 'Minimum 10 characters'),
    agreement: yup
      .boolean()
      .required('You must accept the terms')
      .oneOf([true], 'You must accept the terms'),
  })

  return (
    <Formik
      initialValues={formData}
      validationSchema={validationSchema}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={(values, { setSubmitting, setStatus }) => {
        setFormData(prev => ({ ...prev, ...values }))

        const shop = localStorage.getItem('shop')
        const hmac = localStorage.getItem('hmac')

        setStatus('')
        const request = {
          utmCampaign: cookies.get('utm_campaign') || '',
          utmMedium: cookies.get('utm_medium') || '',
          utmSource: cookies.get('utm_source') || '',
          utmContent: cookies.get('utm_content') || '',
          utmTerm: cookies.get('utm_term') || '',
          shopifyRegistration: shop && hmac,
          ...values,
        }
        axios
          .post(`${NODE_URL}/brand/register/new`, request)
          .then(async res => {
            localStorage.setItem('nodeToken', res.data.token)
            setSubmitting(false)
            window.dataLayer.push({
              event: 'submit_account_js_br',
            })
            // Google ads conversion trigger
            window.gtag('event', 'conversion', {
              send_to: 'AW-443209761/WkfECNrgqdcYEKGwq9MB',
            })

            if (planId) {
              window.location.replace(`/pricing/${planId}`)
            } else {
              window.location.replace(`/welcome`)
            }
          })
          .catch(err => {
            setSubmitting(false)
            setStatus(err.response?.data?.err || 'Sorry, something went wrong.')
          })
      }}>
      {({ status, isSubmitting, submitForm }) => (
        <Form>
          <img src={arrow} alt='' className='arrow' />
          <h1>Just a few more details.</h1>
          <p className='subtext'>Please let us know what you&apos;re looking for.</p>

          <div className='creator-info'>
            <FormItem size='small' name='categories'>
              <p className='form-label'>What kind of creators do you need?</p>
              <Select
                name='categories'
                mode='multiple'
                style={{ width: '100%' }}
                placeholder='Creator niche'
                allowClear
                showArrow
                showSearch={false}
                loading={!categories?.length}>
                {categories?.map(item => (
                  <Option key={item.id} value={item.slug}>
                    {item.title}
                  </Option>
                ))}
              </Select>
            </FormItem>

            <FormItem size='small' name='creatorSize'>
              <Select
                placeholder='Influencer size'
                style={{ width: '100%' }}
                name='creatorSize'
                mode='multiple'
                allowClear
                showArrow
                showSearch={false}>
                <Option value='nano'>Nano (500 - 5k)</Option>
                <Option value='micro'>Micro (5k - 50k)</Option>
                <Option value='trailblazer'>Trailblazer (50k - 100k)</Option>
                <Option value='macro'>Macro (100k - 1m)</Option>
                <Option value='celebrity'>Celebrity (1m+)</Option>
              </Select>
            </FormItem>
          </div>

          <FormItem size='small' name='reference'>
            <p className='form-label'>Where did you hear about us?</p>
            <Input name='reference' />
          </FormItem>

          <FormItem size='small' name='brandGoal'>
            <p className='form-label'>What are you looking to accomplish with Creator.co?</p>
            <TextArea rows='3' name='brandGoal' showCount />
          </FormItem>

          <FormItem size='small' name='agreement' required>
            <Checkbox name='agreement'>
              I have read and agree to the{' '}
              <a target='_blank' rel='noopener noreferrer' href='https://creator.co/brand-terms'>
                brand user agreement
              </a>
            </Checkbox>
          </FormItem>

          {status && <Alert message={status} type='error' showIcon />}
          <div className='nav-buttons'>
            <Button type='secondary' onClick={() => prevStep()}>
              Back
            </Button>
            <Button type='primary' onClick={submitForm} loading={isSubmitting}>
              Finish
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  )
}
