import { QuestionCircleOutlined } from '@ant-design/icons'
import { Tabs, Button, Drawer } from 'antd'
import React, { useContext, useState } from 'react'
import { useQuery } from 'react-query'
import { useHistory, useParams } from 'react-router-dom'
import styled from 'styled-components'

import CampaignInvites from './invites/CampaignInvites.js'
import { CreatorLists } from './lists/CreatorLists.js'
import Reports from './Reports.js'
import SearchBeta from './search/beta/Search.js'
import { CreatorSearchGuide } from './search/CreatorSearchGuide.js'
import Search from './search/Search.js'
import { LimitedAccessAlert } from '../../components/general/LimitedAccessAlert.js'
import LoadingLogo from '../../components/general/LoadingLogo.js'
import { BrandContext } from '../../contexts/BrandContext.js'
import { UserContext } from '../../contexts/UserContext.js'
import { useDocumentTitle } from '../../custom-hooks/useDocumentTitle.js'

const CreatorSearch = ({ beta }) => {
  const { tab } = useParams()
  const history = useHistory()
  const { role } = useContext(UserContext)
  const { brandId, fetchBrandData } = useContext(BrandContext)
  const { data: brandData, status: brandDataStatus } = useQuery(['brand', brandId], fetchBrandData)
  const [guideOpen, setGuideOpen] = useState(false)

  useDocumentTitle(
    role === 'administrator' && brandData?.name
      ? `${brandData.name} - Creator Search | Brand Dashboard`
      : 'Creator Search | Brand Dashboard'
  )

  return (
    <Wrapper>
      {brandDataStatus === 'success' && (
        <>
          <div className='tab-header-wrapper'>
            <div className='tab-header'>
              <div className='header-text'>
                <h1>Creator Recruitment</h1>
              </div>
              <Button
                type='link'
                icon={<QuestionCircleOutlined />}
                onClick={() => setGuideOpen(true)}>
                Help
              </Button>
              <Drawer
                title='Creator Search Guide'
                open={guideOpen}
                onClose={() => setGuideOpen(false)}>
                <CreatorSearchGuide />
              </Drawer>
            </div>
          </div>
          {brandData?.activeSubscriptions?.length ||
          !brandData?.extraData?.blockAccess ||
          role === 'administrator' ? ( // conditional rendering of tabs based on brand subscription status
            <Tabs
              defaultActiveKey=''
              activeKey={tab}
              onChange={key => history.push(`/creator-search/${key}`)}
              className='main-tabs'
              items={[
                {
                  key: '',
                  label: 'Search',
                  children: beta ? <SearchBeta /> : <Search />,
                },
                {
                  key: 'lists',
                  label: 'Creator Lists',
                  children: <CreatorLists />,
                },
                { key: 'deep-insights', label: 'Deep Insights', children: <Reports /> },
                {
                  key: 'invites',
                  label: 'Campaign Invites',
                  children: <CampaignInvites />,
                },
              ]}
            />
          ) : (
            <div className='limited-access'>
              <LimitedAccessAlert />
            </div>
          )}
        </>
      )}

      {brandDataStatus === 'loading' && (
        <div style={{ height: 'calc(100vh - 50px)', display: 'grid', placeItems: 'center' }}>
          <LoadingLogo />
        </div>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  background: #fff;
  color: #23262f;
  font-family: 'Campton-Medium', sans-serif;

  .tab-header-wrapper {
    max-width: 1400px;
    padding: 20px;
    margin: 0 auto;
  }
  .tab-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 20px;
    width: 100%;
    .header-text {
      display: flex;
      align-items: center;
      gap: 12px;
    }
    .platform-icon {
      margin-left: 10px;
      height: 20px;
    }
  }
  h1 {
    font-family: 'Gilroy-ExtraBold', sans-serif;
    font-size: 1.5rem;
    margin: 0;
  }

  /* Main tabs specific styles */
  // By using the '>' selector, we increase specificity to ensure that these styles are applied only to the first level of children under .main-tabs, thereby preventing nested tabs from inheriting these styles
  .main-tabs {
    height: calc(100vh - 162px);

    > .ant-tabs-nav {
      margin: 0;
      border-bottom: 1px solid #dbdbee;

      > .ant-tabs-nav-wrap {
        max-width: 1400px;
        margin: 0 auto -1px auto; // hide the bottom border

        > .ant-tabs-nav-list > .ant-tabs-tab {
          background: #dbdbee;
          color: ${props => props.theme.crcoMidnight};
          font-size: 16px;
          padding: 4px 18px;
          border-radius: 5px 5px 0 0;
          margin-left: 20px;
          cursor: pointer;
          transition: 0.2s ease-in-out !important;
          border: 1px solid transparent;
          border-bottom: none;

          &.ant-tabs-tab-active {
            background: #f4f4f8;
            border-color: #dbdbee;

            .ant-tabs-tab-btn {
              color: ${props => props.theme.crcoTechBlue};
            }
          }
        }
      }
    }

    > .ant-tabs-content-holder {
      background: #f4f4f8;

      > .ant-tabs-content {
        height: 100%;

        > .ant-tabs-tabpane {
          height: 100%;
        }
      }
    }
  }

  // Shared styling for deep insights and the sent invites tab within the campaign invites tab
  .results {
    position: relative;
    .inner {
      position: absolute;
      inset: 20px;
      display: flex;
      flex-direction: column;
    }
    .header {
      display: flex;
      align-items: center;
      text-align: center;
      border-bottom: 1px solid #e6e6e6;
      padding: 0 12px 12px 12px;
      margin-bottom: 10px;
      .heading-item {
        color: #666;
        flex: 1;
        &.creator {
          text-align: left;
          flex: 2;
        }
      }
    }
    .results-inner {
      flex: 1;
      min-height: 400px;
      overflow: auto;
      padding: 0 10px;
      ${props => props.theme.scrollbar}
    }
    .result {
      background: #fff;
      border-radius: 5px;
      margin-bottom: 8px;
      display: flex;
      align-items: center;
      text-align: center;
      padding: 8px;
      .result-item {
        flex: 1;
        display: grid;
        place-content: center;
        &.creator {
          flex: 2;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          padding: 5px;
          border-radius: 5px;
          transition: 0.2s ease-in-out;
          &:hover {
            background: #e9f4fc;
          }
          .avatar {
            background: #eee;
            height: 50px;
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            border-radius: 50%;
            margin-right: 16px;
            flex: 0 0 50px;
          }
          .info {
            text-align: left;
            word-break: break-word;
            .username {
              color: ${props => props.theme.crcoTechBlue};
              font-size: 0.9rem;
              display: flex;
              align-items: center;
              gap: 8px;
              .verified {
                background: ${props => props.theme.crcoTechBlue};
                color: #fff;
                border-radius: 50%;
                padding: 1px;
                display: grid;
                place-content: center;
                font-size: 0.8rem;
              }
            }
            .fullname {
              font-family: 'Campton-Light', Verdana, sans-serif;
              color: #000;
            }
          }
        }
        .label {
          font-family: 'Campton-Light', Verdana, sans-serif;
          font-size: 0.8rem;
          white-space: nowrap;
        }
        & > .title-button {
          span {
            white-space: break-spaces;
          }
        }
        &.filters {
          display: flex;
          flex-direction: column;
          .filters-inner {
            width: 100%;
            text-align: center;
          }
          .age-language {
            display: flex;
          }
          .ant-tag {
            background-color: #bdd9f9;
            border: none;
            padding: 5px;
            margin: 0 5px 5px 0;
            max-width: 100%;
            overflow: hidden;
            .tag-values {
              text-overflow: ellipsis;
              overflow: hidden;
              display: inline-block;
              vertical-align: top;
              max-width: 171px;
              margin-left: 5px;
            }
            .gender-tag {
              text-transform: lowercase;
              &::first-letter {
                text-transform: uppercase;
              }
            }
            span {
              font-family: 'Campton-light';
            }
            .ant-tag-close-icon {
              color: #fff;
              width: 100px;
              height: 10px;
            }
          }
        }
        &.actions {
          display: flex;
          flex-direction: column;
          align-items: center;
          .ant-btn {
            color: ${props => props.theme.crcoMidnight};
            padding: 0 5px;
            &:hover {
              background: #f7f7f7;
              color: ${props => props.theme.crcoTechBlue};
            }
          }
          .expired {
            color: red;
          }
        }
      }
    }
  }

  .not-subscribed {
    background: #f7f7f7;
    height: 100%;
    width: 100%;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    gap: 20px;
    .notice {
      font-size: 1.5rem;
      max-width: 600px;
    }
  }

  .limited-access {
    background-color: #f4f4f8;
    height: 100%;
    margin: auto;
  }
`

export const ResultsWrapper = styled.div`
  position: relative;

  &.reports-table {
    flex: 1;
    width: 100%;
    max-width: 1400px;
    margin: 0 auto;
  }

  &.invites-table {
    height: 100%;
    max-width: 1400px;
    margin: 0 auto;
    .platform {
      width: 24px;
      height: 24px;
      margin-left: 10px;
    }
  }

  .inner {
    position: absolute;
    inset: 20px;
    display: flex;
    flex-direction: column;
  }
  .header {
    display: flex;
    align-items: center;
    text-align: center;
    border-bottom: 1px solid #e6e6e6;
    padding: 0 12px 12px 12px;
    .heading-item {
      color: #666;
      flex: 1;
      &.creator {
        text-align: left;
        flex: 2;
      }
    }
  }
  .results-inner {
    background: #f2f2f2;
    flex: 1;
    min-height: 400px;
    overflow: auto;
    padding: 10px;
    border: 1px solid #eee;
    border-top: none;
    border-radius: 0 0 5px 5px;
    ${props => props.theme.scrollbar}
  }
  .result {
    background: #fff;
    border-radius: 5px;
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    text-align: center;
    padding: 8px;
    .result-item {
      flex: 1;
      display: grid;
      place-content: center;
      &.creator {
        flex: 2;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 5px;
        border-radius: 5px;
        transition: 0.2s ease-in-out;
        &:hover {
          background: #e9f4fc;
        }
        .avatar {
          background: #eee;
          height: 50px;
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
          border-radius: 50%;
          margin-right: 16px;
          flex: 0 0 50px;
        }
        .info {
          text-align: left;
          word-break: break-word;
          .username {
            color: ${props => props.theme.crcoTechBlue};
            font-size: 0.9rem;
            display: flex;
            align-items: center;
            gap: 8px;
            .verified {
              background: ${props => props.theme.crcoTechBlue};
              color: #fff;
              border-radius: 50%;
              padding: 1px;
              display: grid;
              place-content: center;
              font-size: 0.8rem;
            }
          }
          .fullname {
            font-family: 'Campton-Light', Verdana, sans-serif;
            color: #000;
          }
        }
      }
      .label {
        font-family: 'Campton-Light', Verdana, sans-serif;
        font-size: 0.8rem;
        white-space: nowrap;
      }
      & > .title-button {
        span {
          white-space: break-spaces;
        }
      }
      &.filters {
        display: flex;
        flex-direction: column;
        .filters-inner {
          width: 100%;
          text-align: center;
        }
        .age-language {
          display: flex;
        }
        .ant-tag {
          background-color: #bdd9f9;
          border: none;
          padding: 5px;
          margin: 0 5px 5px 0;
          max-width: 100%;
          overflow: hidden;
          .tag-values {
            text-overflow: ellipsis;
            overflow: hidden;
            display: inline-block;
            vertical-align: top;
            max-width: 171px;
            margin-left: 5px;
          }
          .gender-tag {
            text-transform: lowercase;
            &::first-letter {
              text-transform: uppercase;
            }
          }
          span {
            font-family: 'Campton-light';
          }
          .ant-tag-close-icon {
            color: #fff;
            width: 100px;
            height: 10px;
          }
        }
      }
      &.actions {
        display: flex;
        flex-direction: column;
        align-items: center;
        .ant-btn {
          color: ${props => props.theme.crcoMidnight};
          padding: 0 5px;
          &:hover {
            background: #f7f7f7;
            color: ${props => props.theme.crcoTechBlue};
          }
        }
        .expired {
          color: red;
        }
      }
    }
  }
`

export default CreatorSearch
