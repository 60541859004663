import { MinusOutlined } from '@ant-design/icons'
import { message } from 'antd'
import axios from 'axios'
import moment from 'moment'
import numeral from 'numeral'
import React from 'react'
import { queryCache } from 'react-query'
import styled from 'styled-components'

import { NODE_URL } from '../../constants'

export const ListItemCard = ({ item, selectedList, setSelectedList, setValues, brandId }) => {
  const numPosts = item.posts?.length || 0

  const handleDeleteList = async e => {
    e.stopPropagation()

    try {
      const result = await axios.post(`${NODE_URL}/social-listening-delete/${item.id}`)
      if (result.data.id === selectedList?.id) {
        setSelectedList(null)
        setValues({
          title: '',
        })
      }
      message.success('List deleted.')
      queryCache.invalidateQueries('social-listening-lists', brandId)
    } catch (err) {
      message.error('Something went wrong. Please try again later.')
    }
  }

  return (
    <Wrapper
      onClick={() => {
        setSelectedList(item)
        setValues({
          title: item.title,
          postType: item.postType,
          // sponsored posts only
          date: item.filters?.date,
          location: item.filters?.location,
          minLikes: item.filters?.minLikes,
          maxLikes: item.filters?.maxLikes,
          hashtags: item.filters?.hashtags,
          mentions: item.filters?.mentions,
          sponsor: item.filters?.sponsor,
          // top/recent posts only
          hashtag: item.filters?.hashtag,
        })
      }}>
      <div className={`card-inner ${selectedList?.id === item.id && 'selected'}`}>
        <p className='title'>{item.title}</p>
        <p className='type'>
          {item.postType} Posts{' '}
          <span className={`num-posts ${numPosts === 0 && 'none'}`}>
            ({numeral(numPosts).format('0a')})
          </span>
        </p>
        <p className='date'>
          {item.updated ? 'Updated' : 'Created'}{' '}
          {moment(item.updated ? item.updated : item.created).format('lll')}
        </p>
      </div>
      <button className='delete-btn' onClick={handleDeleteList}>
        <MinusOutlined />
      </button>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  position: relative;
  &:hover {
    .delete-btn {
      max-width: 20px;
    }
  }
  .card-inner {
    background: #fff;
    border: 1px solid transparent;
    padding: 10px;
    border-radius: 5px;
    flex: 1;
    transition: 0.2s ease-in-out;
    cursor: pointer;
    &:hover {
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    }
    &.selected {
      background: #e5f3ff;
      border-color: #a3cdf4;
      .title {
        color: ${props => props.theme.crcoTechBlue};
      }
    }
  }
  p {
    margin: 0;
  }
  .title {
    font-size: 1rem;
    transition: 0.2s ease-in-out;
  }
  .type {
    text-transform: capitalize;
    font-size: 0.8rem;
    color: #666;
  }
  .num-posts {
    font-size: 0.8rem;
    color: ${props => props.theme.crcoTechBlue};
    &.none {
      color: #999;
    }
  }
  .date {
    margin-top: 8px;
    font-size: 0.8rem;
    opacity: 0.4;
  }
  .delete-btn {
    background: #999;
    color: #fff;
    position: absolute;
    top: 5px;
    right: 5px;
    max-width: 0;
    overflow: hidden;
    display: grid;
    place-content: center;
    height: 20px;
    width: 20px;
    padding: 0;
    border-radius: 50%;
    font-size: 12px;
    border: none;
    transition: 0.2s ease-in-out;
    &:hover {
      background: ${props => props.theme.crcoCoral};
    }
  }
`
