import { InfoCircleOutlined, MoreOutlined } from '@ant-design/icons'
import { Drawer, Tag, Tooltip } from 'antd'
import moment from 'moment'
import React, { useState } from 'react'
import { useSpring, animated } from 'react-spring'
import styled from 'styled-components/macro'

export const WidgetCard = ({ widget, settings, metrics, size = 'small', tooltip, brandData }) => {
  const [settingsOpen, setSettingsOpen] = useState(false)
  const { filters } = widget

  const fadeIn = useSpring({
    opacity: 1,
    from: { opacity: 0 },
    delay:
      // stagger animation based on widget index in array of visible widgets
      100 * widget.index,
  })

  // FORMATTING FOR APPLIED FILTER TAGS
  const capitalizeFirstLetter = string => {
    return string.charAt(0).toUpperCase() + string.slice(1)
  }

  const formatSocialChannel = channel => {
    switch (channel) {
      case 'tiktok':
        return 'TikTok'
      case 'youtube':
        return 'YouTube'
      default:
        return capitalizeFirstLetter(channel)
    }
  }

  const formatDateFilters = (fromDate, toDate) => {
    if (fromDate && !toDate) {
      return `From: ${moment(fromDate).format('ll')}`
    }

    if (fromDate && toDate) {
      return `${moment(fromDate).format('ll')} to ${moment(toDate).format('ll')}`
    }

    if (!fromDate && toDate) {
      return `To: ${moment(toDate).format('ll')}`
    }
  }

  const formatDateRange = dateRange => {
    switch (dateRange) {
      case 'last7days':
        return 'Last 7 days'
      case 'last30days':
        return 'Last 30 days'
      case 'last60days':
        return 'Last 60 days'
      case 'last90days':
        return 'Last 90 days'
      case 'last3months':
        return 'Last 3 months'
      case 'last6months':
        return 'Last 6 months'
      case 'last12months':
        return 'Last 12 months'
      default:
        return ''
    }
  }

  return (
    <Wrapper size={size}>
      <animated.div className='widget-inner' style={fadeIn}>
        <div className='widget-header'>
          <div className='widget-title'>
            {tooltip && (
              <Tooltip title={tooltip}>
                <InfoCircleOutlined style={{ marginRight: '5px', color: '#bbb' }} />
              </Tooltip>
            )}
            {widget.title}
          </div>

          {/* EDIT WIDGET SETTINGS */}
          <button
            className={`settings-btn ${settingsOpen && 'open'}`}
            onClick={() => setSettingsOpen(true)}>
            <MoreOutlined />
          </button>
          <Drawer
            title='Edit Widget'
            open={settingsOpen}
            onClose={() => setSettingsOpen(false)}
            destroyOnClose
            width={window.innerWidth < 768 ? '100%' : '450px'}>
            {settings}
          </Drawer>
        </div>

        {/* METRICS */}
        {metrics}

        {/* APPLIED FILTERS */}
        {!!Object.keys(filters).length && (
          <div className='applied-filters'>
            {widget.defaultFilters.brandIds && !!filters.brandIds?.length && (
              <Tag>
                Brand{filters.brandIds.length !== 1 && 's'}:{' '}
                {filters.brandIds.length === 1
                  ? brandData.brandIds?.find(brand => brand.id === filters.brandIds[0]).name
                  : 'Multiple'}
              </Tag>
            )}

            {widget.defaultFilters.campaignStatuses && !!filters.campaignStatuses?.length && (
              <Tag>
                Campaign Status:{' '}
                {filters.campaignStatuses.length === 1
                  ? capitalizeFirstLetter(filters.campaignStatuses[0])
                  : 'Multiple'}
              </Tag>
            )}

            {widget.defaultFilters.campaignIds && !!filters.campaignIds?.length && (
              <Tag>
                Campaign{filters.campaignIds.length !== 1 && 's'}:{' '}
                {filters.campaignIds.length === 1
                  ? brandData.campaigns?.find(
                      campaign => campaign.id === Number(filters.campaignIds[0])
                    )?.title
                  : 'Multiple'}
              </Tag>
            )}

            {widget.defaultFilters.socialChannels && !!filters.socialChannels?.length && (
              <Tag>
                Social Channel{filters.socialChannels.length !== 1 && 's'}:{' '}
                {filters.socialChannels.length === 1
                  ? formatSocialChannel(filters.socialChannels[0])
                  : 'Multiple'}
              </Tag>
            )}

            {(widget.defaultFilters.fromDate !== undefined ||
              widget.defaultFilters.toDate !== undefined) &&
              (filters.fromDate || filters.toDate) && (
                <Tag>{formatDateFilters(filters.fromDate, filters.toDate)}</Tag>
              )}

            {widget.defaultFilters.dateRange &&
              filters.dateRange &&
              !['allTime', 'custom'].includes(filters.dateRange) && (
                <Tag>{formatDateRange(filters.dateRange)}</Tag>
              )}
          </div>
        )}
      </animated.div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  flex: ${props => (props.size === 'small' ? 1 : props.size === 'medium' ? 2 : 3)};
  max-width: 100%;

  .widget-inner {
    height: 100%;
    width: 100%;
    border-radius: 20px;
    background: #fff;
    box-shadow: 5px 5px 30px rgb(175 162 216 / 15%);
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .widget-header {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    margin-bottom: 10px;
  }

  .widget-title {
    margin: 3px 0 0 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-family: 'Poppins-Bold', sans-serif;
  }

  .settings-btn {
    background: transparent;
    color: #666;
    border: none;
    border-radius: 50%;
    height: 25px;
    width: 25px;
    padding: 0;
    margin: 0;
    font-size: 16px;
    display: grid;
    place-content: center;
    cursor: pointer;
    transition: 0.2s ease-in-out;
    &:hover,
    &.open {
      background: #eee;
    }
  }

  .applied-filters {
    margin-top: auto;
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    opacity: 0.5;
  }

  .ant-tag {
    border-radius: 5px;
    margin: 0;
    max-width: 100%;
    // handle text overflow
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  @media only screen and (min-width: ${props => props.theme.breakpointTablet}) {
    max-width: ${props =>
      props.size === 'small' ? '400px' : props.size === 'medium' ? '600px' : '100%'};
  }
`

export const MetricsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  gap: 20px;
  margin: auto 0;
  &.overflow-auto {
    overflow: auto;
  }

  .value {
    color: ${props => props.theme.crcoLavender};
    margin: 0;
    line-height: 1;
    &.primary {
      font-size: 30px;
    }
    &.secondary {
      font-size: 16px;
    }
    &.none {
      color: ${props => props.theme.crcoGrey};
      opacity: 0.5;
    }
  }

  .label {
    color: ${props => props.theme.crcoGrey};
    margin: 0;
  }

  .ant-table {
    color: ${props => props.theme.crcoGrey};
    white-space: nowrap;

    // make first column fixed width
    .ant-table-thead > tr > th:first-child,
    .ant-table-tbody > tr > td:first-child {
      width: 300px;
      min-width: 300px;
      max-width: 300px;
      // handle text overflow
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .overview {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    margin-bottom: 20px;
    .overview-item {
      background: #f6f6f6;
      padding: 10px;
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      gap: 5px;
      .label {
        font-size: 0.9rem;
        margin: 0;
      }
      .value {
        font-size: 1rem;
        margin: 0;
        word-break: break-word;
      }
    }
  }

  .recharts-surface {
    // fix cut-off issue with custom icons
    overflow: visible;
  }

  .no-data {
    padding: 20px;
    display: grid;
    place-content: center;
  }
`

export const SettingsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 40px;
  .section-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
    .title-wrapper {
      display: flex;
      align-items: center;
      gap: 12px;
    }
    .anticon {
      color: ${props => props.theme.crcoGrey};
    }
    .item-count {
      margin: 0;
      opacity: 0.3;
    }
  }
  h3 {
    font-family: 'Gilroy-ExtraBold', sans-serif;
    font-size: 18px;
    margin: 0;
  }
  .form-row-wrapper {
    display: flex;
    gap: 20px;
  }
  .date-range-wrapper {
    background: #f6f6f6;
    padding: 20px;
    border-radius: 10px;
  }
  .ant-radio-group,
  .ant-checkbox-group {
    display: flex;
    flex-direction: column;
  }
  .drag-drop-list {
    background: #f6f6f6;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    padding: 10px;
    .toggle-wrapper {
      padding: 10px;
      border-radius: 5px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 20px;
      transition: 0.2s ease-in-out;
      &:hover {
        background: #eee;
        .drag-icon {
          opacity: 1;
        }
      }
      .toggle-label {
        font-size: 0.9rem;
        margin: 0;
      }
      .actions {
        display: flex;
        align-items: center;
        gap: 10px;
      }
      .toggle-icon {
        display: grid;
        place-content: center;
      }
      .drag-icon {
        display: grid;
        place-content: center;
        opacity: 0.3;
        transition: 0.2s ease-in-out;
      }
    }
  }
  .add-widget-wrapper {
    display: flex;
    align-items: center;
    gap: 12px;
  }

  .buttons {
    display: flex;
    gap: 12px;
    margin-bottom: 10px;
  }
  .misc-actions {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
  }
`
