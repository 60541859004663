import { Select } from 'formik-antd'
import React from 'react'

import FormItem from '../../../components/forms/FormItem'

export const CampaignStatusFilter = () => {
  return (
    <FormItem size='small' label='Campaign Status' name='campaignStatuses'>
      <Select
        getPopupContainer={trigger => trigger.parentNode}
        virtual={false}
        name='campaignStatuses'
        placeholder='Select'
        mode='multiple'
        style={{ width: '100%' }}
        options={[
          { label: 'Active', value: 'publish' },
          { label: 'Pending', value: 'pending' },
          { label: 'Paused', value: 'paused' },
          { label: 'Draft', value: 'draft' },
        ]}
      />
    </FormItem>
  )
}
