import React from 'react'

import { Wrapper } from '../creator-search/search/CreatorSearchGuide'

export const CampaignBuilderGuide = () => {
  return (
    <Wrapper>
      <section>
        <h2>Introduction to Campaign Building</h2>
        <p>
          Welcome to our Campaign Builder! This powerful tool is designed to help you create and
          manage effective influencer campaigns. With features that allow you to specify the
          strategy (shoutout, giveaway, affiliate, etc.), social channels, compensation, creator
          requirements, and deliverables, our campaign builder provides an intuitive and
          comprehensive process to ensure your campaign&apos;s success.
        </p>
        <p>Let&apos;s delve into a step-by-step guide on how to build a campaign:</p>
      </section>
      <section>
        <h3>Setting up your Strategy</h3>
        <p>
          The first step towards creating a campaign is deciding on your preferred strategy. Whether
          you choose shoutout, giveaway, affiliate, or another method, our builder caters to all.
        </p>
        <p>
          You will also need to choose which social channels you want to run your campaign on, if
          applicable. Our platform currently supports <b>Instagram, TikTok, and YouTube</b>. You can
          run your campaign on one or more of these channels.
        </p>
        <p>
          Depending on the strategy and channels you choose, the builder will then display relevant
          fields to help you define your campaign.
        </p>
      </section>
      <section>
        <h3>Defining Compensation, Requirements, and Deliverables</h3>
        <p>
          The key to a successful campaign is clarity in terms of compensation, creator
          requirements, and deliverables. Follow the helpful hints and instructions provided
          throughout to ensure your requirements are clear and appealing to potential creators.
        </p>
      </section>
      <section>
        <h3>Submitting your Campaign</h3>
        <p>
          When you&apos;re happy with the information you&apos;ve provided, you can submit your
          campaign for review. Our team will assess your campaign, ensuring everything is in order.
          We may also provide tips and suggestions to make your campaign more successful.
        </p>
        <p>
          Once approved, your campaign will go live and creators will be able to opt-in through our
          Collaboration Hub.
        </p>
      </section>
      <section>
        <h3>Managing Opt-Ins</h3>
        <p>
          Upon launching your campaign, you can conveniently track and manage your opt-ins via the
          campaign dashboard. This interface will allow you to review profiles of creators showing
          interest in your campaign, negotiate payment terms (if applicable), maintain communication
          with your creators, and navigate through various stages of the campaign.
        </p>
        <p>
          In case of any questions or concerns, our team is always available to help ensure your
          campaigns run smoothly.
        </p>
      </section>
    </Wrapper>
  )
}
