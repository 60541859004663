import React from 'react'

import { isVideo } from '../../utils'
import VideoItem from '../VideoItem'

/* DOM helper function for recursive child replacement by classname */
const findAndReplaceByClassname = (className, node, replacement) => {
  // replace if matches
  if (node?.props?.className === className) return replacement
  // recursively try to find it
  if (node?.props?.children) {
    if (Array.isArray(node.props.children)) {
      const nc = node.props.children.map((n, i) => {
        return <span key={i}>{findAndReplaceByClassname(className, n, replacement)}</span>
      })
      node = React.cloneElement(node, {
        ...node.props,
        children: nc,
      })
    } else {
      /* non array children \o/ */
      node = React.cloneElement(node, {
        ...node.props,
        children: findAndReplaceByClassname(className, node.props.children, replacement),
      })
    }
  }
  return node
}

const UploadListItem = ({ originNode, file }) => {
  // is video? replace default preview image by video tag
  if (isVideo(file.url)) {
    originNode = findAndReplaceByClassname(
      'ant-upload-list-item-thumbnail ant-upload-list-item-file',
      originNode,
      <VideoItem url={file.url} />
    ) // Returns the elements
  }
  if (file.status === 'error') return originNode.props.children
  return originNode
}

export default UploadListItem
