import { DollarOutlined } from '@ant-design/icons'
import { Empty, Button } from 'antd'
import numeral from 'numeral'
import React, { useContext, useState, useEffect } from 'react'
import { useQuery } from 'react-query'
import { useParams, NavLink } from 'react-router-dom'
import styled from 'styled-components/macro'

import { Chat } from './Chat.js'
import { OptInList } from './OptInList.js'
import LoadingLogo from '../../components/general/LoadingLogo'
import { BrandContext } from '../../contexts/BrandContext.js'
import { UserContext } from '../../contexts/UserContext'
import { useDocumentTitle } from '../../custom-hooks/useDocumentTitle.js'
import affiliate from '../../images/icons/affiliate.svg'
import bespoke from '../../images/icons/bespoke.svg'
import giveaway from '../../images/icons/giveaway.svg'
import shoutout from '../../images/icons/shoutout.svg'
import instagram from '../../images/icons/social/instagram-color-square.svg'
import tiktok from '../../images/icons/social/tiktok-color-square.svg'
import youtube from '../../images/icons/social/youtube-color-square.svg'
const socialIcons = { instagram, youtube, tiktok }

export const Inbox = ({ brand }) => {
  const { campaignId, optInId } = useParams()
  const [activeCampaigns, setActiveCampaigns] = useState()
  const [totalUnread, setTotalUnread] = useState(0)
  const { role } = useContext(UserContext)
  const { brandId, fetchCampaigns } = useContext(BrandContext)

  const { data: campaigns, status, error } = useQuery(['campaigns', brandId], fetchCampaigns)

  useDocumentTitle(
    role === 'administrator'
      ? brand
        ? `${brand} - Inbox ${
            totalUnread > 0 ? `(${numeral(totalUnread).format('0a')})` : ''
          } | Brand Dashboard`
        : 'Inbox | Brand Dashboard'
      : 'My Inbox | Brand Dashboard'
  )

  useEffect(() => {
    if (campaigns) {
      setActiveCampaigns(
        campaigns.filter(campaign => campaign.status === 'publish' || campaign.status === 'paused')
      )
    }
  }, [campaigns])

  useEffect(() => {
    if (activeCampaigns) {
      setTotalUnread(
        activeCampaigns.reduce((acc, campaign) => {
          return acc + campaign.unreadMessageCount
        }, 0)
      )
    }
  }, [activeCampaigns])

  return (
    <Wrapper>
      {status === 'loading' && (
        <div style={{ display: 'grid', minHeight: 'calc(100vh - 112px)', placeItems: 'center' }}>
          <LoadingLogo />
        </div>
      )}
      {status === 'error' && (
        <div>
          <pre>{JSON.stringify(error)}</pre>
        </div>
      )}
      {status === 'success' &&
        (activeCampaigns && activeCampaigns.length > 0 ? (
          <div className='inbox-inner'>
            {/* TODO: add flyout menu for campaign selection and opt in / chat list when window width is below 1300px with functionality to expand/collapse */}
            <div className='campaign-list'>
              {activeCampaigns.map(campaign => (
                <Campaign to={`/inbox/${campaign.id}`} key={campaign.id} activeClassName='selected'>
                  <div>
                    <div className='campaign-details'>
                      <div className='info-row'>
                        <span className={`campaign-status ${campaign.status}`}>
                          {campaign.status === 'publish' ? 'Active' : 'Paused'}
                        </span>
                        {campaign.socialChannels?.map((channel, i) => (
                          <img className='icon' src={socialIcons[channel]} alt={channel} key={i} />
                        ))}
                        <img
                          className='icon'
                          src={
                            campaign.strategy === 'shoutout'
                              ? shoutout
                              : campaign.strategy === 'giveaway'
                                ? giveaway
                                : campaign.strategy === 'affiliate'
                                  ? affiliate
                                  : bespoke
                          }
                          alt=''
                        />
                        {campaign.maxPaidAmount && (
                          <span
                            style={{
                              color: '#027df0',
                            }}>
                            <DollarOutlined />
                          </span>
                        )}
                        <span className='id'>#{campaign.id}</span>
                      </div>
                      {campaign.unreadMessageCount > 0 && (
                        <span className='notification-badge'>{campaign.unreadMessageCount}</span>
                      )}
                    </div>
                    <p className='title'>{campaign.title}</p>
                  </div>
                </Campaign>
              ))}
            </div>
            {campaignId && <OptInList campaignId={campaignId} />}
            {optInId ? (
              <Chat optInId={parseInt(optInId)} />
            ) : (
              <div className='chat-placeholder'>
                <Empty description='No conversation selected.' />
              </div>
            )}
          </div>
        ) : (
          <div className='no-campaigns'>
            <Empty description='You have no active campaigns.' />
            <Button type='primary'>
              <NavLink to='/campaigns'>Back to Campaigns</NavLink>
            </Button>
          </div>
        ))}
    </Wrapper>
  )
}

const Wrapper = styled.main`
  height: 100%;
  .no-campaigns {
    background: ${props => props.theme.crcoOffWhite};
    color: #999;
    padding: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    button {
      margin-top: 20px;
    }
  }
  .inbox-inner {
    height: 100%;
    overflow-x: auto;
    display: grid;
    grid-template-columns: 350px 350px 1fr;
    .campaign-list {
      background: #f6f6f6;
      position: relative;
      max-height: calc(100vh - 50px);
      min-width: 350px;
      overflow: auto;
      ${props => props.theme.scrollBar};
    }
    .chat-placeholder {
      background: ${props => props.theme.crcoOffWhite};
      flex: 1;
      display: grid;
      place-content: center;
      padding: 10px;
      min-width: 400px;
    }
  }
`

const Campaign = styled(NavLink)`
  color: ${props => (props.status === 'paused' ? '#666' : '#000')};
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0;
  padding: 10px 10px 10px 20px;
  border-bottom: 1px solid #e6e6e6;
  // FIXME: need border on campaign list or opt-in list for when there are few campaigns, need to adjust styling so border isn't visible for selected campaign
  border-right: 1px solid #e6e6e6;
  font-size: 1rem;
  transition: 0.2s ease-in-out;
  cursor: pointer;

  & > div {
    width: 100%;
  }

  .campaign-details {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .info-row {
      display: flex;
      align-items: center;
      gap: 5px;
      .campaign-status {
        padding: 5px 10px 2px 10px;
        font-size: 12px;
        text-transform: uppercase;
        color: #fff;
        border-radius: 10px;
        line-height: 1;
        &.publish {
          background: #18d96e;
        }
        &.pending {
          background: #80ccff;
        }
        &.paused {
          background: #bbb;
        }
        &.draft {
          background: #fc9c44;
        }
      }
      .id {
        color: #b9b9b9;
        font-size: 0.8rem;
      }
    }
    .notification-badge {
      background: orange;
      border-radius: 10px;
      padding: 2px 5px 0 5px;
      color: #fff;
      font-size: 0.6rem;
    }
  }
  .title {
    margin: 5px 0;
    font-size: 14px;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .campaign-icons {
    display: flex;
    align-items: center;
    gap: 5px;
  }
  .icon {
    height: 17px;
    width: 17px;
  }
  &:hover {
    color: ${props => props.theme.crcoTechBlue};
  }
  &.selected {
    background: #fff;
    color: ${props => props.theme.crcoTechBlue};
    border-right: none;
  }
`
