import React from 'react'

export const CustomizedDot = props => {
  const { cx, cy, payload } = props

  const { platforms } = payload

  if (!platforms) return null

  // ALL PLATFORMS
  if (
    platforms.includes('instagram') &&
    platforms.includes('youtube') &&
    platforms.includes('tiktok')
  )
    return (
      <svg
        x={cx - 10}
        y={cy - 10}
        viewBox='0 0 1024 1024'
        fill='none'
        width={35}
        height={35}
        xmlns='http://www.w3.org/2000/svg'>
        <g clipPath='url(#clip0_1_24)'>
          <path
            d='M420.422 503.273C311.156 514.93 200.96 514.93 91.6945 503.273C70.4761 501.032 50.6668 491.586 35.5691 476.51C20.4715 461.433 10.9977 441.637 8.72721 420.422C-2.93007 311.156 -2.93007 200.96 8.72721 91.6945C10.9679 70.4761 20.4139 50.6669 35.4904 35.5692C50.5669 20.4715 70.3629 10.9977 91.5781 8.72727C200.844 -2.93 311.04 -2.93 420.305 8.72727C441.524 10.968 461.333 20.4139 476.431 35.4904C491.528 50.5669 501.002 70.3629 503.273 91.5782C514.93 200.844 514.93 311.04 503.273 420.305C501.032 441.524 491.586 461.333 476.509 476.431C461.433 491.528 441.637 501.002 420.422 503.273Z'
            fill='url(#paint0_linear_1_24)'
          />
          <path
            d='M320.669 96H191.331C166.057 96.0308 141.827 106.084 123.956 123.956C106.084 141.827 96.0308 166.057 96 191.331V320.669C96.0308 345.943 106.084 370.173 123.956 388.044C141.827 405.916 166.057 415.969 191.331 416H320.669C345.943 415.969 370.173 405.916 388.044 388.044C405.916 370.173 415.969 345.943 416 320.669V191.331C415.969 166.057 405.916 141.827 388.044 123.956C370.173 106.084 345.943 96.0308 320.669 96ZM383.796 320.669C383.8 328.96 382.17 337.171 378.999 344.831C375.828 352.492 371.178 359.453 365.315 365.315C359.453 371.178 352.492 375.828 344.831 378.999C337.171 382.17 328.96 383.8 320.669 383.796H191.331C183.04 383.8 174.829 382.17 167.169 378.999C159.508 375.828 152.547 371.178 146.685 365.315C140.822 359.453 136.172 352.492 133.001 344.831C129.83 337.171 128.2 328.96 128.204 320.669V191.331C128.204 174.589 134.855 158.532 146.693 146.693C158.532 134.855 174.589 128.204 191.331 128.204H320.669C337.412 128.204 353.468 134.855 365.307 146.693C377.145 158.532 383.796 174.589 383.796 191.331V320.669Z'
            fill='white'
          />
          <path
            d='M256 173.236C239.631 173.236 223.629 178.09 210.019 187.185C196.409 196.279 185.801 209.205 179.536 224.328C173.272 239.451 171.633 256.092 174.827 272.146C178.02 288.201 185.903 302.948 197.477 314.523C209.052 326.097 223.799 333.98 239.854 337.173C255.908 340.367 272.549 338.728 287.672 332.464C302.795 326.199 315.721 315.591 324.815 301.981C333.91 288.371 338.764 272.369 338.764 256C338.733 234.059 330.003 213.026 314.489 197.511C298.974 181.997 277.941 173.267 256 173.236ZM256 306.589C245.994 306.589 236.214 303.622 227.894 298.063C219.575 292.505 213.091 284.604 209.262 275.36C205.433 266.116 204.431 255.944 206.383 246.131C208.335 236.317 213.153 227.303 220.228 220.228C227.303 213.153 236.317 208.335 246.131 206.383C255.944 204.431 266.116 205.433 275.36 209.262C284.604 213.091 292.505 219.575 298.063 227.894C303.622 236.214 306.589 245.994 306.589 256C306.581 269.415 301.249 282.278 291.763 291.763C282.278 301.249 269.415 306.581 256 306.589Z'
            fill='white'
          />
          <path
            d='M339.229 193.687C350.524 193.687 359.68 184.531 359.68 173.236C359.68 161.942 350.524 152.785 339.229 152.785C327.934 152.785 318.778 161.942 318.778 173.236C318.778 184.531 327.934 193.687 339.229 193.687Z'
            fill='white'
          />
        </g>
        <g clipPath='url(#clip1_1_24)'>
          <path
            d='M676.422 759.273C567.156 770.93 456.96 770.93 347.694 759.273C326.476 757.032 306.667 747.586 291.569 732.51C276.471 717.433 266.998 697.637 264.727 676.422C253.07 567.156 253.07 456.96 264.727 347.695C266.968 326.476 276.414 306.667 291.49 291.569C306.567 276.472 326.363 266.998 347.578 264.727C456.844 253.07 567.04 253.07 676.305 264.727C697.524 266.968 717.333 276.414 732.431 291.49C747.528 306.567 757.002 326.363 759.273 347.578C770.93 456.844 770.93 567.04 759.273 676.305C757.032 697.524 747.586 717.333 732.509 732.431C717.433 747.528 697.637 757.002 676.422 759.273Z'
            fill='#010101'
          />
          <path
            d='M473.396 672C446.315 672 420.343 661.242 401.194 642.093C382.045 622.944 371.287 596.972 371.287 569.891C371.287 542.81 382.045 516.838 401.194 497.689C420.343 478.54 446.315 467.782 473.396 467.782H498.327V517.615H473.396C463.04 517.615 452.916 520.686 444.305 526.439C435.693 532.193 428.982 540.371 425.019 549.939C421.055 559.508 420.018 570.036 422.039 580.194C424.059 590.351 429.046 599.682 436.37 607.005C443.693 614.328 453.023 619.315 463.181 621.336C473.338 623.356 483.867 622.319 493.435 618.356C503.003 614.393 511.181 607.681 516.935 599.07C522.689 590.459 525.76 580.335 525.76 569.978V352H575.593V376.902C575.593 390.79 581.11 404.108 590.93 413.929C600.75 423.749 614.069 429.265 627.956 429.265H652.887V479.011H627.782C609.326 479.038 591.216 474.007 575.418 464.465V569.891C575.395 596.95 564.642 622.895 545.516 642.037C526.391 661.178 500.455 671.954 473.396 672Z'
            fill='white'
          />
        </g>
        <g clipPath='url(#clip2_1_24)'>
          <path
            d='M932.422 1015.27C823.156 1026.93 712.96 1026.93 603.695 1015.27C582.476 1013.03 562.667 1003.59 547.569 988.51C532.472 973.433 522.998 953.637 520.727 932.422C509.07 823.156 509.07 712.96 520.727 603.695C522.968 582.476 532.414 562.667 547.49 547.569C562.567 532.472 582.363 522.998 603.578 520.727C712.844 509.07 823.04 509.07 932.305 520.727C953.524 522.968 973.333 532.414 988.431 547.49C1003.53 562.567 1013 582.363 1015.27 603.578C1026.93 712.844 1026.93 823.04 1015.27 932.305C1013.03 953.524 1003.59 973.333 988.51 988.431C973.433 1003.53 953.637 1013 932.422 1015.27Z'
            fill='#E60000'
          />
          <path
            d='M920.524 691.025C914.124 671.331 892.945 659.404 873.455 656.669C803.349 649.222 732.651 649.222 662.545 656.669C643.055 659.404 621.818 671.215 615.476 691.025C605.498 741.867 605.498 794.163 615.476 845.004C621.876 864.669 643.055 876.625 662.545 879.36C732.651 886.807 803.349 886.807 873.455 879.36C892.945 876.625 914.182 864.815 920.524 845.004C930.502 794.163 930.502 741.867 920.524 691.025ZM729.716 829.324V706.676L829.556 768C795.898 788.684 763.229 808.727 729.716 829.324Z'
            fill='white'
          />
        </g>
        <defs>
          <linearGradient
            id='paint0_linear_1_24'
            x1='38.109'
            y1='473.891'
            x2='465.454'
            y2='46.5454'
            gradientUnits='userSpaceOnUse'>
            <stop stopColor='#E09B3D' />
            <stop offset='0.3' stopColor='#C74C4D' />
            <stop offset='0.6' stopColor='#C21975' />
            <stop offset='1' stopColor='#7024C4' />
          </linearGradient>
          <clipPath id='clip0_1_24'>
            <rect width='512' height='512' fill='white' />
          </clipPath>
          <clipPath id='clip1_1_24'>
            <rect width='512' height='512' fill='white' transform='translate(256 256)' />
          </clipPath>
          <clipPath id='clip2_1_24'>
            <rect width='512' height='512' fill='white' transform='translate(512 512)' />
          </clipPath>
        </defs>
      </svg>
    )

  // INSTAGRAM & TIKTOK
  if (platforms.includes('instagram') && platforms.includes('tiktok'))
    return (
      <svg
        x={cx - 10}
        y={cy - 10}
        viewBox='0 0 1024 1024'
        fill='none'
        width={35}
        height={35}
        xmlns='http://www.w3.org/2000/svg'>
        <g clipPath='url(#clip0_5_48)'>
          <path
            d='M420.422 503.273C311.156 514.93 200.96 514.93 91.6945 503.273C70.4761 501.032 50.6669 491.586 35.5692 476.509C20.4715 461.433 10.9977 441.637 8.72726 420.422C-2.93001 311.156 -2.93001 200.96 8.72726 91.6945C10.968 70.4761 20.4139 50.6668 35.4904 35.5691C50.5669 20.4715 70.3629 10.9977 91.5782 8.72721C200.844 -2.93007 311.04 -2.93007 420.305 8.72721C441.524 10.9679 461.333 20.4139 476.431 35.4904C491.528 50.5669 501.002 70.3629 503.273 91.5781C514.93 200.844 514.93 311.04 503.273 420.305C501.032 441.524 491.586 461.333 476.509 476.431C461.433 491.528 441.637 501.002 420.422 503.273Z'
            fill='url(#paint0_linear_5_48)'
          />
          <path
            d='M320.669 95.9999H191.331C166.057 96.0307 141.827 106.084 123.956 123.956C106.084 141.827 96.0308 166.057 96 191.331V320.669C96.0308 345.943 106.084 370.173 123.956 388.044C141.827 405.916 166.057 415.969 191.331 416H320.669C345.943 415.969 370.173 405.916 388.044 388.044C405.916 370.173 415.969 345.943 416 320.669V191.331C415.969 166.057 405.916 141.827 388.044 123.956C370.173 106.084 345.943 96.0307 320.669 95.9999ZM383.796 320.669C383.8 328.96 382.17 337.171 378.999 344.831C375.828 352.492 371.178 359.453 365.315 365.315C359.453 371.178 352.492 375.828 344.831 378.999C337.171 382.17 328.96 383.8 320.669 383.796H191.331C183.04 383.8 174.829 382.17 167.169 378.999C159.508 375.828 152.547 371.178 146.685 365.315C140.822 359.453 136.172 352.492 133.001 344.831C129.83 337.171 128.2 328.96 128.204 320.669V191.331C128.204 174.588 134.855 158.532 146.693 146.693C158.532 134.854 174.588 128.204 191.331 128.204H320.669C337.411 128.204 353.468 134.854 365.307 146.693C377.145 158.532 383.796 174.588 383.796 191.331V320.669Z'
            fill='white'
          />
          <path
            d='M256 173.236C239.631 173.236 223.629 178.09 210.019 187.185C196.409 196.279 185.801 209.205 179.536 224.328C173.272 239.451 171.633 256.092 174.827 272.146C178.02 288.201 185.903 302.948 197.477 314.523C209.052 326.097 223.799 333.98 239.854 337.173C255.908 340.367 272.549 338.728 287.672 332.464C302.795 326.199 315.721 315.591 324.815 301.981C333.91 288.371 338.764 272.369 338.764 256C338.733 234.059 330.003 213.026 314.489 197.511C298.974 181.997 277.941 173.267 256 173.236ZM256 306.589C245.994 306.589 236.214 303.622 227.894 298.063C219.575 292.504 213.091 284.604 209.262 275.36C205.433 266.116 204.431 255.944 206.383 246.131C208.335 236.317 213.153 227.303 220.228 220.228C227.303 213.153 236.317 208.335 246.131 206.383C255.944 204.431 266.116 205.433 275.36 209.262C284.604 213.091 292.505 219.575 298.063 227.894C303.622 236.214 306.589 245.994 306.589 256C306.581 269.415 301.249 282.278 291.763 291.763C282.278 301.249 269.415 306.581 256 306.589Z'
            fill='white'
          />
          <path
            d='M339.229 193.687C350.524 193.687 359.68 184.531 359.68 173.236C359.68 161.942 350.524 152.785 339.229 152.785C327.934 152.785 318.778 161.942 318.778 173.236C318.778 184.531 327.934 193.687 339.229 193.687Z'
            fill='white'
          />
        </g>
        <g clipPath='url(#clip1_5_48)'>
          <path
            d='M676.422 759.273C567.156 770.93 456.96 770.93 347.695 759.273C326.476 757.032 306.667 747.586 291.569 732.51C276.472 717.433 266.998 697.637 264.727 676.422C253.07 567.156 253.07 456.96 264.727 347.695C266.968 326.476 276.414 306.667 291.49 291.569C306.567 276.472 326.363 266.998 347.578 264.727C456.844 253.07 567.04 253.07 676.305 264.727C697.524 266.968 717.333 276.414 732.431 291.49C747.528 306.567 757.002 326.363 759.273 347.578C770.93 456.844 770.93 567.04 759.273 676.305C757.032 697.524 747.586 717.333 732.51 732.431C717.433 747.528 697.637 757.002 676.422 759.273Z'
            fill='#010101'
          />
          <path
            d='M473.396 672C446.315 672 420.344 661.242 401.194 642.093C382.045 622.944 371.287 596.972 371.287 569.891C371.287 542.81 382.045 516.838 401.194 497.689C420.344 478.54 446.315 467.782 473.396 467.782H498.327V517.615H473.396C463.04 517.615 452.916 520.686 444.305 526.439C435.694 532.193 428.982 540.371 425.019 549.939C421.055 559.508 420.018 570.036 422.039 580.194C424.059 590.351 429.047 599.682 436.37 607.005C443.693 614.328 453.023 619.315 463.181 621.336C473.338 623.356 483.867 622.319 493.435 618.356C503.003 614.393 511.181 607.681 516.935 599.07C522.689 590.459 525.76 580.335 525.76 569.978V352H575.593V376.902C575.593 390.79 581.11 404.108 590.93 413.929C600.75 423.749 614.069 429.265 627.956 429.265H652.887V479.011H627.782C609.326 479.038 591.216 474.007 575.418 464.465V569.891C575.395 596.95 564.642 622.895 545.517 642.037C526.391 661.178 500.455 671.954 473.396 672Z'
            fill='white'
          />
        </g>
        <defs>
          <linearGradient
            id='paint0_linear_5_48'
            x1='38.1091'
            y1='473.891'
            x2='465.455'
            y2='46.5454'
            gradientUnits='userSpaceOnUse'>
            <stop stopColor='#E09B3D' />
            <stop offset='0.3' stopColor='#C74C4D' />
            <stop offset='0.6' stopColor='#C21975' />
            <stop offset='1' stopColor='#7024C4' />
          </linearGradient>
          <clipPath id='clip0_5_48'>
            <rect width='512' height='512' fill='white' />
          </clipPath>
          <clipPath id='clip1_5_48'>
            <rect width='512' height='512' fill='white' transform='translate(256 256)' />
          </clipPath>
        </defs>
      </svg>
    )

  // INSTAGRAM & YOUTUBE
  if (platforms.includes('instagram') && platforms.includes('youtube'))
    return (
      <svg
        x={cx - 10}
        y={cy - 10}
        viewBox='0 0 1024 1024'
        fill='none'
        width={35}
        height={35}
        xmlns='http://www.w3.org/2000/svg'>
        <g clipPath='url(#clip0_5_96)'>
          <path
            d='M420.422 503.273C311.156 514.93 200.96 514.93 91.6946 503.273C70.4762 501.032 50.6669 491.586 35.5693 476.509C20.4716 461.433 10.9978 441.637 8.72733 420.422C-2.92994 311.156 -2.92994 200.96 8.72733 91.6945C10.968 70.4761 20.414 50.6668 35.4905 35.5691C50.567 20.4715 70.363 10.9977 91.5782 8.72721C200.844 -2.93007 311.04 -2.93007 420.305 8.72721C441.524 10.9679 461.333 20.4139 476.431 35.4904C491.528 50.5669 501.002 70.3629 503.273 91.5781C514.93 200.844 514.93 311.04 503.273 420.305C501.032 441.524 491.586 461.333 476.51 476.431C461.433 491.528 441.637 501.002 420.422 503.273Z'
            fill='url(#paint0_linear_5_96)'
          />
          <path
            d='M320.669 95.9999H191.331C166.057 96.0307 141.827 106.084 123.956 123.956C106.084 141.827 96.0308 166.057 96 191.331V320.669C96.0308 345.943 106.084 370.173 123.956 388.044C141.827 405.916 166.057 415.969 191.331 416H320.669C345.943 415.969 370.173 405.916 388.044 388.044C405.916 370.173 415.969 345.943 416 320.669V191.331C415.969 166.057 405.916 141.827 388.044 123.956C370.173 106.084 345.943 96.0307 320.669 95.9999ZM383.796 320.669C383.8 328.96 382.17 337.171 378.999 344.831C375.828 352.492 371.178 359.453 365.315 365.315C359.453 371.178 352.492 375.828 344.831 378.999C337.171 382.17 328.96 383.8 320.669 383.796H191.331C183.04 383.8 174.829 382.17 167.169 378.999C159.508 375.828 152.547 371.178 146.685 365.315C140.822 359.453 136.172 352.492 133.001 344.831C129.83 337.171 128.2 328.96 128.204 320.669V191.331C128.204 174.588 134.855 158.532 146.693 146.693C158.532 134.854 174.589 128.204 191.331 128.204H320.669C337.412 128.204 353.468 134.854 365.307 146.693C377.145 158.532 383.796 174.588 383.796 191.331V320.669Z'
            fill='white'
          />
          <path
            d='M256 173.236C239.631 173.236 223.629 178.09 210.019 187.185C196.409 196.279 185.801 209.205 179.536 224.328C173.272 239.451 171.633 256.092 174.827 272.146C178.02 288.201 185.903 302.948 197.477 314.523C209.052 326.097 223.799 333.98 239.854 337.173C255.908 340.367 272.549 338.728 287.672 332.464C302.795 326.199 315.721 315.591 324.815 301.981C333.91 288.371 338.764 272.369 338.764 256C338.733 234.059 330.003 213.026 314.489 197.511C298.974 181.997 277.941 173.267 256 173.236ZM256 306.589C245.994 306.589 236.214 303.622 227.894 298.063C219.575 292.504 213.091 284.604 209.262 275.36C205.433 266.116 204.431 255.944 206.383 246.131C208.335 236.317 213.153 227.303 220.228 220.228C227.303 213.153 236.317 208.335 246.131 206.383C255.944 204.431 266.116 205.433 275.36 209.262C284.604 213.091 292.505 219.575 298.063 227.894C303.622 236.214 306.589 245.994 306.589 256C306.581 269.415 301.249 282.278 291.763 291.763C282.278 301.249 269.415 306.581 256 306.589Z'
            fill='white'
          />
          <path
            d='M339.229 193.687C350.524 193.687 359.68 184.531 359.68 173.236C359.68 161.942 350.524 152.785 339.229 152.785C327.935 152.785 318.778 161.942 318.778 173.236C318.778 184.531 327.935 193.687 339.229 193.687Z'
            fill='white'
          />
        </g>
        <g clipPath='url(#clip1_5_96)'>
          <path
            d='M676.422 759.273C567.156 770.93 456.96 770.93 347.695 759.273C326.476 757.032 306.667 747.586 291.569 732.51C276.472 717.433 266.998 697.637 264.727 676.422C253.07 567.156 253.07 456.96 264.727 347.695C266.968 326.476 276.414 306.667 291.491 291.569C306.567 276.472 326.363 266.998 347.578 264.727C456.844 253.07 567.04 253.07 676.305 264.727C697.524 266.968 717.333 276.414 732.431 291.49C747.528 306.567 757.002 326.363 759.273 347.578C770.93 456.844 770.93 567.04 759.273 676.305C757.032 697.524 747.586 717.333 732.51 732.431C717.433 747.528 697.637 757.002 676.422 759.273Z'
            fill='#E60000'
          />
          <path
            d='M664.524 435.025C658.124 415.331 636.946 403.404 617.455 400.669C547.349 393.222 476.651 393.222 406.546 400.669C387.055 403.404 365.818 415.215 359.476 435.025C349.498 485.867 349.498 538.163 359.476 589.004C365.876 608.669 387.055 620.625 406.546 623.36C476.651 630.807 547.349 630.807 617.455 623.36C636.946 620.625 658.182 608.815 664.524 589.004C674.502 538.163 674.502 485.867 664.524 435.025ZM473.716 573.324V450.676L573.556 512C539.898 532.684 507.229 552.727 473.716 573.324Z'
            fill='white'
          />
        </g>
        <defs>
          <linearGradient
            id='paint0_linear_5_96'
            x1='38.1092'
            y1='473.891'
            x2='465.455'
            y2='46.5454'
            gradientUnits='userSpaceOnUse'>
            <stop stopColor='#E09B3D' />
            <stop offset='0.3' stopColor='#C74C4D' />
            <stop offset='0.6' stopColor='#C21975' />
            <stop offset='1' stopColor='#7024C4' />
          </linearGradient>
          <clipPath id='clip0_5_96'>
            <rect width='512' height='512' fill='white' />
          </clipPath>
          <clipPath id='clip1_5_96'>
            <rect width='512' height='512' fill='white' transform='translate(256 256)' />
          </clipPath>
        </defs>
      </svg>
    )

  // TIKTOK & YOUTUBE
  if (platforms.includes('tiktok') && platforms.includes('youtube'))
    return (
      <svg
        x={cx - 10}
        y={cy - 10}
        viewBox='0 0 1024 1024'
        fill='none'
        width={35}
        height={35}
        xmlns='http://www.w3.org/2000/svg'>
        <g clipPath='url(#clip0_5_72)'>
          <path
            d='M420.422 503.273C311.156 514.93 200.96 514.93 91.6945 503.273C70.4761 501.032 50.6668 491.586 35.5691 476.509C20.4715 461.433 10.9977 441.637 8.72721 420.422C-2.93007 311.156 -2.93007 200.96 8.72721 91.6945C10.9679 70.4761 20.4139 50.6668 35.4904 35.5691C50.5669 20.4715 70.3629 10.9977 91.5781 8.72721C200.844 -2.93007 311.04 -2.93007 420.305 8.72721C441.524 10.9679 461.333 20.4139 476.431 35.4904C491.528 50.5669 501.002 70.3629 503.273 91.5781C514.93 200.844 514.93 311.04 503.273 420.305C501.032 441.524 491.586 461.333 476.509 476.431C461.433 491.528 441.637 501.002 420.422 503.273Z'
            fill='#010101'
          />
          <path
            d='M217.396 416C190.315 416 164.343 405.242 145.194 386.093C126.045 366.944 115.287 340.972 115.287 313.891C115.287 286.81 126.045 260.838 145.194 241.689C164.343 222.54 190.315 211.782 217.396 211.782H242.327V261.614H217.396C207.04 261.614 196.916 264.686 188.305 270.439C179.693 276.193 172.982 284.371 169.019 293.939C165.055 303.508 164.018 314.036 166.039 324.194C168.059 334.351 173.046 343.682 180.37 351.005C187.693 358.328 197.023 363.315 207.181 365.336C217.338 367.356 227.867 366.319 237.435 362.356C247.003 358.393 255.181 351.681 260.935 343.07C266.689 334.459 269.76 324.335 269.76 313.978V95.9999H319.593V120.902C319.593 134.789 325.11 148.108 334.93 157.928C344.75 167.749 358.069 173.265 371.956 173.265H396.887V223.011H371.782C353.326 223.037 335.216 218.007 319.418 208.465V313.891C319.395 340.95 308.642 366.895 289.516 386.037C270.391 405.178 244.455 415.954 217.396 416Z'
            fill='white'
          />
        </g>
        <g clipPath='url(#clip1_5_72)'>
          <path
            d='M676.422 759.273C567.156 770.93 456.96 770.93 347.694 759.273C326.476 757.032 306.667 747.586 291.569 732.51C276.471 717.433 266.998 697.637 264.727 676.422C253.07 567.156 253.07 456.96 264.727 347.695C266.968 326.476 276.414 306.667 291.49 291.569C306.567 276.472 326.363 266.998 347.578 264.727C456.844 253.07 567.04 253.07 676.305 264.727C697.524 266.968 717.333 276.414 732.431 291.49C747.528 306.567 757.002 326.363 759.273 347.578C770.93 456.844 770.93 567.04 759.273 676.305C757.032 697.524 747.586 717.333 732.509 732.431C717.433 747.528 697.637 757.002 676.422 759.273Z'
            fill='#E60000'
          />
          <path
            d='M664.524 435.025C658.124 415.331 636.945 403.404 617.454 400.669C547.349 393.222 476.651 393.222 406.545 400.669C387.054 403.404 365.818 415.215 359.476 435.025C349.498 485.867 349.498 538.163 359.476 589.004C365.876 608.669 387.054 620.625 406.545 623.36C476.651 630.807 547.349 630.807 617.454 623.36C636.945 620.625 658.182 608.815 664.524 589.004C674.502 538.163 674.502 485.867 664.524 435.025ZM473.716 573.324V450.676L573.556 512C539.898 532.684 507.229 552.727 473.716 573.324Z'
            fill='white'
          />
        </g>
        <defs>
          <clipPath id='clip0_5_72'>
            <rect width='512' height='512' fill='white' />
          </clipPath>
          <clipPath id='clip1_5_72'>
            <rect width='512' height='512' fill='white' transform='translate(256 256)' />
          </clipPath>
        </defs>
      </svg>
    )

  // INSTAGRAM
  if (platforms.includes('instagram'))
    return (
      <svg
        x={cx - 10}
        y={cy - 10}
        viewBox='0 0 1024 1024'
        fill='none'
        width={35}
        height={35}
        xmlns='http://www.w3.org/2000/svg'>
        <g clipPath='url(#clip0_6_98)'>
          <path
            d='M420.422 503.273C311.156 514.93 200.96 514.93 91.6945 503.273C70.4761 501.032 50.6668 491.586 35.5691 476.51C20.4715 461.433 10.9977 441.637 8.72721 420.422C-2.93007 311.156 -2.93007 200.96 8.72721 91.6945C10.9679 70.4761 20.4139 50.6669 35.4904 35.5692C50.5669 20.4715 70.3629 10.9977 91.5781 8.72727C200.844 -2.93 311.04 -2.93 420.305 8.72727C441.524 10.968 461.333 20.4139 476.431 35.4904C491.528 50.5669 501.002 70.3629 503.273 91.5782C514.93 200.844 514.93 311.04 503.273 420.305C501.032 441.524 491.586 461.333 476.509 476.431C461.433 491.528 441.637 501.002 420.422 503.273Z'
            fill='url(#paint0_linear_6_98)'
          />
          <path
            d='M320.669 96H191.331C166.057 96.0308 141.827 106.084 123.956 123.956C106.084 141.827 96.0308 166.057 96 191.331V320.669C96.0308 345.943 106.084 370.173 123.956 388.044C141.827 405.916 166.057 415.969 191.331 416H320.669C345.943 415.969 370.173 405.916 388.044 388.044C405.916 370.173 415.969 345.943 416 320.669V191.331C415.969 166.057 405.916 141.827 388.044 123.956C370.173 106.084 345.943 96.0308 320.669 96ZM383.796 320.669C383.8 328.96 382.17 337.171 378.999 344.831C375.828 352.492 371.178 359.453 365.315 365.315C359.453 371.178 352.492 375.828 344.831 378.999C337.171 382.17 328.96 383.8 320.669 383.796H191.331C183.04 383.8 174.829 382.17 167.169 378.999C159.508 375.828 152.547 371.178 146.685 365.315C140.822 359.453 136.172 352.492 133.001 344.831C129.83 337.171 128.2 328.96 128.204 320.669V191.331C128.204 174.589 134.855 158.532 146.693 146.693C158.532 134.855 174.589 128.204 191.331 128.204H320.669C337.412 128.204 353.468 134.855 365.307 146.693C377.145 158.532 383.796 174.589 383.796 191.331V320.669Z'
            fill='white'
          />
          <path
            d='M256 173.236C239.631 173.236 223.629 178.09 210.019 187.185C196.409 196.279 185.801 209.205 179.536 224.328C173.272 239.451 171.633 256.092 174.827 272.146C178.02 288.201 185.903 302.948 197.477 314.523C209.052 326.097 223.799 333.98 239.854 337.173C255.908 340.367 272.549 338.728 287.672 332.464C302.795 326.199 315.721 315.591 324.815 301.981C333.91 288.371 338.764 272.369 338.764 256C338.733 234.059 330.003 213.026 314.489 197.511C298.974 181.997 277.941 173.267 256 173.236ZM256 306.589C245.994 306.589 236.214 303.622 227.894 298.063C219.575 292.505 213.091 284.604 209.262 275.36C205.433 266.116 204.431 255.944 206.383 246.131C208.335 236.317 213.153 227.303 220.228 220.228C227.303 213.153 236.317 208.335 246.131 206.383C255.944 204.431 266.116 205.433 275.36 209.262C284.604 213.091 292.505 219.575 298.063 227.894C303.622 236.214 306.589 245.994 306.589 256C306.581 269.415 301.249 282.278 291.763 291.763C282.278 301.249 269.415 306.581 256 306.589Z'
            fill='white'
          />
          <path
            d='M339.229 193.687C350.524 193.687 359.68 184.531 359.68 173.236C359.68 161.942 350.524 152.785 339.229 152.785C327.934 152.785 318.778 161.942 318.778 173.236C318.778 184.531 327.934 193.687 339.229 193.687Z'
            fill='white'
          />
        </g>
        <defs>
          <linearGradient
            id='paint0_linear_6_98'
            x1='38.109'
            y1='473.891'
            x2='465.454'
            y2='46.5454'
            gradientUnits='userSpaceOnUse'>
            <stop stopColor='#E09B3D' />
            <stop offset='0.3' stopColor='#C74C4D' />
            <stop offset='0.6' stopColor='#C21975' />
            <stop offset='1' stopColor='#7024C4' />
          </linearGradient>
          <clipPath id='clip0_6_98'>
            <rect width='512' height='512' fill='white' />
          </clipPath>
        </defs>
      </svg>
    )

  // TIKTOK
  if (platforms.includes('tiktok'))
    return (
      <svg
        x={cx - 10}
        y={cy - 10}
        viewBox='0 0 1024 1024'
        fill='none'
        width={35}
        height={35}
        xmlns='http://www.w3.org/2000/svg'>
        <g clipPath='url(#clip0_6_108)'>
          <path
            d='M420.422 503.273C311.156 514.93 200.96 514.93 91.6945 503.273C70.4761 501.032 50.6669 491.586 35.5692 476.509C20.4715 461.433 10.9977 441.637 8.72727 420.422C-2.93 311.156 -2.93 200.96 8.72727 91.6945C10.968 70.4761 20.4139 50.6668 35.4904 35.5691C50.5669 20.4715 70.3629 10.9977 91.5782 8.72721C200.844 -2.93007 311.04 -2.93007 420.305 8.72721C441.524 10.9679 461.333 20.4139 476.431 35.4904C491.528 50.5669 501.002 70.3629 503.273 91.5781C514.93 200.844 514.93 311.04 503.273 420.305C501.032 441.524 491.586 461.333 476.51 476.431C461.433 491.528 441.637 501.002 420.422 503.273Z'
            fill='#010101'
          />
          <path
            d='M217.396 416C190.315 416 164.344 405.242 145.194 386.093C126.045 366.944 115.287 340.972 115.287 313.891C115.287 286.81 126.045 260.838 145.194 241.689C164.344 222.54 190.315 211.782 217.396 211.782H242.327V261.615H217.396C207.04 261.615 196.916 264.686 188.305 270.439C179.694 276.193 172.982 284.371 169.019 293.939C165.055 303.508 164.018 314.036 166.039 324.194C168.059 334.351 173.047 343.682 180.37 351.005C187.693 358.328 197.023 363.315 207.181 365.336C217.338 367.356 227.867 366.319 237.435 362.356C247.003 358.393 255.181 351.681 260.935 343.07C266.689 334.459 269.76 324.335 269.76 313.978V96H319.593V120.902C319.593 134.79 325.11 148.108 334.93 157.929C344.75 167.749 358.069 173.265 371.956 173.265H396.887V223.011H371.782C353.326 223.038 335.216 218.007 319.418 208.465V313.891C319.395 340.95 308.642 366.895 289.517 386.037C270.391 405.178 244.455 415.954 217.396 416Z'
            fill='white'
          />
        </g>
        <defs>
          <clipPath id='clip0_6_108'>
            <rect width='512' height='512' fill='white' />
          </clipPath>
        </defs>
      </svg>
    )

  // YOUTUBE
  if (platforms.includes('youtube'))
    return (
      <svg
        x={cx - 10}
        y={cy - 10}
        viewBox='0 0 1024 1024'
        fill='none'
        width={35}
        height={35}
        xmlns='http://www.w3.org/2000/svg'>
        <g clipPath='url(#clip0_6_114)'>
          <path
            d='M420.422 503.273C311.156 514.93 200.96 514.93 91.6945 503.273C70.4761 501.032 50.6668 491.586 35.5691 476.509C20.4715 461.433 10.9977 441.637 8.72721 420.422C-2.93007 311.156 -2.93007 200.96 8.72721 91.6945C10.9679 70.4761 20.4139 50.6668 35.4904 35.5691C50.5669 20.4715 70.3629 10.9977 91.5781 8.72721C200.844 -2.93007 311.04 -2.93007 420.305 8.72721C441.524 10.9679 461.333 20.4139 476.431 35.4904C491.528 50.5669 501.002 70.3629 503.273 91.5781C514.93 200.844 514.93 311.04 503.273 420.305C501.032 441.524 491.586 461.333 476.509 476.431C461.433 491.528 441.637 501.002 420.422 503.273Z'
            fill='#E60000'
          />
          <path
            d='M408.524 179.025C402.124 159.331 380.945 147.404 361.454 144.669C291.349 137.222 220.651 137.222 150.545 144.669C131.054 147.404 109.818 159.214 103.476 179.025C93.4983 229.867 93.4983 282.162 103.476 333.004C109.876 352.669 131.054 364.625 150.545 367.36C220.651 374.807 291.349 374.807 361.454 367.36C380.945 364.625 402.182 352.814 408.524 333.004C418.502 282.162 418.502 229.867 408.524 179.025ZM217.716 317.324V194.676L317.556 256C283.898 276.684 251.229 296.727 217.716 317.324Z'
            fill='white'
          />
        </g>
        <defs>
          <clipPath id='clip0_6_114'>
            <rect width='512' height='512' fill='white' />
          </clipPath>
        </defs>
      </svg>
    )

  return <></>
}
