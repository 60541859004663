import { UpOutlined } from '@ant-design/icons'
import React, { useState } from 'react'
import styled from 'styled-components/macro'

import CampaignListItem from './CampaignListItem.js'

const CampaignList = ({ campaigns: unfilteredCampaigns }) => {
  const [lists, setLists] = useState({})
  const campaigns = { publish: [], draft: [], paused: [], pending: [], archived: [] }
  unfilteredCampaigns.forEach(campaign => {
    campaigns[campaign.status] &&
      campaigns[campaign.status].push(<CampaignListItem key={campaign.id} campaign={campaign} />)
  })

  return (
    <Wrapper>
      {!!campaigns.publish.length && (
        <div className={`list publish ${lists.publish ? 'closed' : null}`}>
          <div
            className='header'
            onClick={() => setLists(prev => ({ ...prev, publish: !prev.publish }))}
            onKeyDown={() => setLists(prev => ({ ...prev, publish: !prev.publish }))}
            role='button'
            tabIndex={0}>
            <span className='type'>
              <UpOutlined className='caret' /> Active
            </span>
            <span>Opt-Ins</span>
            <span>Pending</span>
            <span>Activated</span>
            <span>ShoutOuts</span>
            <span>Published</span>
            <span />
          </div>
          <div className='campaigns'>{campaigns.publish}</div>
        </div>
      )}
      {!!campaigns.pending.length && (
        <div className={`list pending ${lists.pending ? 'closed' : null}`}>
          <div
            className='header'
            onClick={() => setLists(prev => ({ ...prev, pending: !prev.pending }))}
            onKeyDown={() => setLists(prev => ({ ...prev, pending: !prev.pending }))}
            role='button'
            tabIndex={0}>
            <span className='type'>
              <UpOutlined className='caret' /> Pending
            </span>
            <span />
            <span />
            <span />
            <span />
            <span>Last Updated</span>
            <span />
          </div>
          <div className='campaigns'>{campaigns.pending}</div>
        </div>
      )}
      {!!campaigns.paused.length && (
        <div className={`list paused ${lists.paused ? 'closed' : null}`}>
          <div
            className='header'
            onClick={() => setLists(prev => ({ ...prev, paused: !prev.paused }))}
            onKeyDown={() => setLists(prev => ({ ...prev, paused: !prev.paused }))}
            role='button'
            tabIndex={0}>
            <span className='type'>
              <UpOutlined className='caret' /> Paused
            </span>
            <span>Opt-Ins</span>
            <span>Pending</span>
            <span>Activated</span>
            <span>ShoutOuts</span>
            <span />
            <span />
          </div>
          <div className='campaigns'>{campaigns.paused}</div>
        </div>
      )}
      {!!campaigns.draft.length && (
        <div className={`list draft ${lists.draft ? 'closed' : null}`}>
          <div
            className='header'
            onClick={() => setLists(prev => ({ ...prev, draft: !prev.draft }))}
            onKeyDown={() => setLists(prev => ({ ...prev, draft: !prev.draft }))}
            role='button'
            tabIndex={0}>
            <span className='type'>
              <UpOutlined className='caret' /> Draft
            </span>
            <span />
            <span />
            <span />
            <span />
            <span>Last Updated</span>
            <span />
          </div>
          <div className='campaigns'>{campaigns.draft}</div>
        </div>
      )}
      {!!campaigns.archived.length && (
        <div className={`list archived ${lists.archived ? 'closed' : null}`}>
          <div
            className='header'
            onClick={() => setLists(prev => ({ ...prev, archived: !prev.archived }))}
            onKeyDown={() => setLists(prev => ({ ...prev, archived: !prev.archived }))}
            role='button'
            tabIndex={0}>
            <span className='type'>
              <UpOutlined className='caret' /> Archived
            </span>
            <span />
            <span />
            <span />
            <span />
            <span>Last Updated</span>
            <span />
          </div>
          <div className='campaigns'>{campaigns.archived}</div>
        </div>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  height: 100%;
  overflow: auto;
  border: 1px solid #e6e6e6;
  border-radius: 0 0 10px 10px;
  ${props => props.theme.scrollbar}
  .header {
    background: #f2f2f2;
    font-size: 1rem;
    text-align: center;
    padding: 10px;
    border-bottom: 1px solid #e6e6e6;
    position: sticky;
    top: 0;
    z-index: 1;
    transition: 0.2s ease-in-out;
    &:hover {
      background: #eee;
    }
    & > span {
      padding: 0 16px;
      transition: 0.2s;
      font-size: 14px;
      white-space: nowrap;
    }
    .type {
      text-align: left;
      font-size: 16px;
      .caret {
        font-size: 0.8rem;
        color: rgba(0, 0, 0, 0.5);
        margin-right: 8px;
        transition: 0.2s ease-in-out;
      }
    }
  }

  .list {
    .header {
      cursor: pointer;
    }

    .campaigns {
      overflow: hidden;
      transition: 0.3s ease-in-out;
      display: flex;
      flex-direction: column;
    }

    .header,
    .campaign-row {
      display: grid;
      grid-template-columns: 3fr 1fr 1fr 1fr 1fr 1.5fr 1fr;
      align-items: center;
      transition: 0.3s ease-in-out;
    }
    .campaign-row {
      overflow: hidden;
      max-height: 500px;
    }
  }

  .closed {
    .header {
      & > span:not(.type) {
        opacity: 0;
      }
    }
    .campaigns {
      gap: 0;
      opacity: 0;
      .campaign-row {
        opacity: 0;
        max-height: 0;
        border: 0;
      }
    }
    .type .caret {
      transform: rotate(180deg);
    }
  }

  .ant-btn.ant-btn-text,
  .ant-btn.ant-btn-link {
    padding: 0 5px;
    transition: 0.2s ease-in-out;
    &:hover {
      background: #f7f7f7;
    }
  }
`

export default CampaignList
