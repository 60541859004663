import { Alert } from 'antd'
import React from 'react'
import { Link } from 'react-router-dom'

export const EmailSettingsNotice = () => {
  return (
    <Alert
      message='Your Brand, Your Email'
      description={
        <>
          <p>
            You asked, we built it! Connect your domain and add your brand logo today to connect
            with Influencers in a more authentic way. Using your domain is more personalized and
            ensures you have a direct line of communication with the Creators you want to recruit
            and collaborate with.
          </p>
          <p>
            Proceed to <Link to='/account#email-settings'>Email Setup</Link> now or visit our{' '}
            <a
              href='https://brands-creatorco.zendesk.com/hc/en-us/articles/23157357193876-Customized-Recruitment-Sequences'
              target='_blank'
              rel='noopener noreferrer'>
              Help Center
            </a>{' '}
            for more information.
          </p>
        </>
      }
      type='warning'
      style={{ marginBottom: '20px' }}
    />
  )
}
