import React from 'react'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'

// This is a generic component that can be used to make any list draggable supported by the atlassian react-beautiful-dnd library: https://github.com/atlassian/react-beautiful-dnd

// In order to use this component, you need to pass in an array of items (item.content should contain the UI of the list item) and a function to handle the drag and drop events (e.g. to update the order of the items in the array and store it in state or the database)

// Note: the draggableId must be a string

export const DraggableList = ({ items, onDragEnd, getDragItemStyle }) => {
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId='droppable'>
        {provided => (
          <div className='drag-drop-list' {...provided.droppableProps} ref={provided.innerRef}>
            {items.map((item, index) => (
              <Draggable key={item.id} draggableId={item.id.toString()} index={index}>
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={getDragItemStyle(snapshot.isDragging, provided.draggableProps.style)}>
                    {item.content}
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  )
}
