import { DownOutlined } from '@ant-design/icons'
import { Popover, Spin } from 'antd'
import axios from 'axios'
import { Select } from 'formik-antd'
import React, { useState, useRef } from 'react'

import { HelpText } from './CreatorFilters'
import FormItem from '../../../components/forms/FormItem'
import { NODE_URL } from '../../../constants'
const { Option } = Select

const AudienceFilters = ({ platform, values, formChange, socapiFilters }) => {
  const [fetchingLocations, setFetchingLocations] = useState(false)
  const [locations, setLocations] = useState([])
  const lastLocationFetchID = useRef(0)
  const fetchLocations = val => {
    if (val.length > 2) {
      lastLocationFetchID.current += 1
      const fetchID = lastLocationFetchID.current
      setFetchingLocations(true)
      setLocations([])
      axios.get(`${NODE_URL}/socialdata-countries/?platform=${platform}&val=${val}`).then(res => {
        if (fetchID !== lastLocationFetchID.current) {
          return
        }
        setFetchingLocations(false)
        setLocations(
          res.data?.map(location => ({
            text: location.title,
            value: location.id,
          }))
        )
      })
    }
  }

  // #region POPOVER FILTERS
  const audienceGenderInput = handleChange => {
    return (
      <FormItem width={'275px'} size='small'>
        <p>Identify creators by audience gender.</p>
        <Select
          name='audienceGender'
          style={{ width: '100%' }}
          placeholder='Select'
          allowClear
          onChange={e => {
            handleChange(e, 'audienceGender')
          }}>
          <Option value='FEMALE'>Female</Option>
          <Option value='MALE'>Male</Option>
          <Option value='KNOWN'>Known</Option>
          <Option value='UNKNOWN'>Unknown</Option>
        </Select>
        <HelpText>
          <p>
            We determine gender by analyzing profile photos, name and text in their profile bios, as
            well as selfies in recent posts.
          </p>
        </HelpText>
      </FormItem>
    )
  }

  const audienceAgeInput = handleChange => {
    return (
      <FormItem width={'275px'} size='small'>
        <p>Identify creators by audience age.</p>
        <Select
          style={{ width: '100%' }}
          name='audienceAge'
          mode='multiple'
          placeholder='Select'
          showArrow
          onChange={e => {
            handleChange(e, 'audienceAge')
          }}>
          <Option value='18-24'>18-24</Option>
          <Option value='25-34'>25-34</Option>
          <Option value='35-44'>35-44</Option>
          <Option value='45-64'>45-64</Option>
          <Option value='65-'>65+</Option>
        </Select>
        <HelpText>
          <p>We determine age by analyzing profile photos and selfies in recent posts.</p>
        </HelpText>
      </FormItem>
    )
  }

  const audienceLanguageInput = handleChange => {
    return (
      <FormItem width={'275px'} size='small'>
        <p>Identify creators by audience language.</p>
        <Select
          showSearch
          optionFilterProp='label'
          optionLabelProp='label'
          filterOption
          loading={!socapiFilters}
          name='audienceLanguage'
          style={{ width: '100%' }}
          placeholder='Select'
          allowClear
          onChange={e => {
            handleChange(e, 'audienceLanguage')
          }}>
          {socapiFilters ? (
            <>
              {socapiFilters.languages &&
                socapiFilters.languages.map(item => (
                  <Option label={item.name} key={item.code} value={item.code}>
                    {item.name}
                  </Option>
                ))}
            </>
          ) : (
            <span>Loading...</span>
          )}
        </Select>
        <HelpText>
          <p>We determine language by analyzing captions of recent posts.</p>
        </HelpText>
      </FormItem>
    )
  }

  const audienceEthnicityInput = handleChange => {
    return (
      <FormItem width={'275px'} size='small'>
        <p>Identify creators by audience ethnicity.</p>
        <Select
          name='audienceEthnicity'
          style={{ width: '100%' }}
          placeholder='Select'
          allowClear
          onChange={e => {
            handleChange(e, 'audienceEthnicity')
          }}>
          <Option value='Black'>African</Option>
          <Option value='Asian'>Asian</Option>
          <Option value='White'>Caucasian</Option>
          <Option value='Hispanic'>Hispanic</Option>
        </Select>
        <HelpText>
          <p>We determine ethnicity by analyzing profile photos and selfies in recent posts.</p>
        </HelpText>
      </FormItem>
    )
  }

  const audienceLocationInput = handleChange => {
    return (
      <FormItem width={'275px'} size='small'>
        <p>Identify creators by audience location.</p>
        <Select
          name='audienceLocations'
          mode='multiple'
          labelInValue
          autoComplete='none'
          placeholder='Enter at least 3 characters'
          notFoundContent={fetchingLocations ? <Spin size='small' /> : null}
          filterOption={false}
          onSearch={val => fetchLocations(val)}
          onChange={e => {
            setFetchingLocations(false)
            setLocations([])
            handleChange(e, 'audienceLocations')
          }}
          style={{ width: '100%' }}>
          {locations.map(item => (
            <Option key={item.value} value={item.value}>
              {item.text}
            </Option>
          ))}
        </Select>
        <HelpText>
          <p>
            We determine location by analyzing location tags, language and caption of recent posts
            and text in profile bio.
          </p>
          <p>
            You can add several locations and the results will be refined with creators available in
            any of the specified locations.
          </p>
        </HelpText>
      </FormItem>
    )
  }

  const audienceInterestsInput = handleChange => {
    return (
      <FormItem width={'275px'} size='small'>
        <p>Identify creators by audience niche.</p>
        <Select
          showSearch
          optionFilterProp='label'
          filterOption
          mode='multiple'
          loading={!socapiFilters}
          name='audienceInterests'
          style={{ width: '100%' }}
          placeholder='Select'
          allowClear
          showArrow
          onChange={e => {
            handleChange(e, 'audienceInterests')
          }}>
          {socapiFilters ? (
            <>
              {socapiFilters.interests &&
                socapiFilters.interests.interests.map(
                  item =>
                    !item.deprecated && (
                      <Option label={item.name} key={item.id} value={item.id}>
                        {item.name}
                      </Option>
                    )
                )}
            </>
          ) : (
            <span>Loading...</span>
          )}
        </Select>
        <HelpText>
          <p>
            We determine niche by analyzing posts for caption text, mentions, hashtags and location
            tags.
          </p>
          <p>
            You can add several categories and the results will be refined with creators who have an
            audience matching any of the specified categories.
          </p>
        </HelpText>
      </FormItem>
    )
  }

  const audienceBrandAffinityInput = handleChange => {
    return (
      <FormItem width={'275px'} size='small'>
        <p>Identify creators by audience brand affinities.</p>
        <Select
          showSearch
          optionFilterProp='label'
          filterOption
          mode='multiple'
          loading={!socapiFilters}
          name='audienceBrands'
          placeholder='Select'
          allowClear
          showArrow
          style={{ width: '100%' }}
          onChange={e => {
            handleChange(e, 'audienceBrands')
          }}>
          {socapiFilters ? (
            <>
              {socapiFilters.interests &&
                socapiFilters.interests.brands.map(
                  item =>
                    !item.deprecated && (
                      <Option label={item.name} key={item.id} value={item.id}>
                        {item.name}
                      </Option>
                    )
                )}
            </>
          ) : (
            <span>Loading...</span>
          )}
        </Select>
        <HelpText>
          <p>
            We determine brand affinities by analyzing posts for caption text, mentions, hashtags
            and location tags.
          </p>
          <p>
            You can add several brands and the results will be refined with creators who have an
            audience mentioning any of the specified brands.
          </p>
        </HelpText>
      </FormItem>
    )
  }
  // #endregion POPOVER FILTERS

  return (
    <div className='audience-filters'>
      <Popover placement='bottom' content={audienceGenderInput(formChange)} trigger='hover'>
        <span className={`filter ${values.audienceGender && 'filter-selected'}`}>
          Gender <DownOutlined />
        </span>
      </Popover>

      <Popover placement='bottom' content={audienceAgeInput(formChange)} trigger='hover'>
        <span className={`filter ${values.audienceAge && 'filter-selected'}`}>
          Age <DownOutlined />
        </span>
      </Popover>

      <Popover placement='bottom' content={audienceLanguageInput(formChange)} trigger='hover'>
        <span className={`filter ${values.audienceLanguage && 'filter-selected'}`}>
          Language
          <DownOutlined />
        </span>
      </Popover>

      <Popover placement='bottom' content={audienceLocationInput(formChange)} trigger='hover'>
        <span className={`filter ${!!values.audienceLocations?.length && 'filter-selected'}`}>
          {`${platform === 'instagram' ? 'Location' : 'Country'}`} <DownOutlined />
        </span>
      </Popover>

      {values.platform === 'instagram' && (
        <>
          <Popover placement='bottom' content={audienceEthnicityInput(formChange)} trigger='hover'>
            <span className={`filter ${values.audienceEthnicity && 'filter-selected'}`}>
              Ethnicity
              <DownOutlined />
            </span>
          </Popover>

          <Popover placement='bottom' content={audienceInterestsInput(formChange)} trigger='hover'>
            <span className={`filter ${!!values.audienceInterests?.length && 'filter-selected'}`}>
              Niche
              <DownOutlined />
            </span>
          </Popover>

          <Popover
            placement='bottom'
            content={audienceBrandAffinityInput(formChange)}
            trigger='hover'>
            <span className={`filter ${!!values.audienceBrands?.length && 'filter-selected'}`}>
              Brand Affinity
              <DownOutlined />
            </span>
          </Popover>
        </>
      )}
    </div>
  )
}

export default AudienceFilters
