import { Button, Empty, Radio, Spin } from 'antd'
import axios from 'axios'
import { Formik, Form } from 'formik'
import React, { useContext, useState } from 'react'
import { useQuery } from 'react-query'
import styled from 'styled-components'

import { CreateEditStep } from './CreateEditStep'
import { NODE_URL } from '../../constants'
import { BrandContext } from '../../contexts/BrandContext'

const StepList = ({ close, addStep }) => {
  const { brandId } = useContext(BrandContext)
  const [type, setType] = useState('programmed')
  const [copyCustomStepOpen, setCopyCustomStepOpen] = useState(false)

  const { data, status } = useQuery('steps', async () => {
    const { data } = await axios.get(`${NODE_URL}/brand/${brandId}/campaign/steps/`)
    return data
      .filter(step =>
        [
          'creator-submit-social-post-url',
          'deprecated-influencer-submit-content-preview',
          'deprecated-brand-review-content-preview',
          'deprecated-brand-score-shoutout',
          'deprecated-brand-submit-shipment-tracking-link',
        ].includes(step.slug)
      )
      .map(step => ({ ...step, title: step.title.replace('Old: ', '') }))
  })

  const handleCreateSubmit = values => {
    const {
      brandStatus,
      influencerStatus,
      brandCompletedStatus,
      influencerCompletedStatus,
      customForm,
      ...customStep
    } = values

    addStep({
      ...customStep,
      customForm,
      stepSettings: {
        brandStatus,
        influencerStatus,
        brandCompletedStatus,
        influencerCompletedStatus,
      },
    })
    close()
  }

  return (
    <Wrapper style={{ padding: '20px' }}>
      <div className='step-type-options'>
        <Radio checked={type === 'programmed'} onChange={() => setType('programmed')}>
          Pre-Programmed
        </Radio>
        <Radio checked={type === 'custom'} onChange={() => setType('custom')}>
          Custom
        </Radio>
      </div>

      {type === 'programmed' ? (
        <>
          {status === 'success' && (
            <Formik
              onSubmit={values => {
                if (type === 'programmed') {
                  const step = JSON.parse(values.step)
                  addStep({ stepId: step.id })
                  close()
                }
              }}
              initialValues={{}}>
              {({ isSubmitting, values, setValues }) => (
                <Form>
                  <div className='steps-list'>
                    {data.length ? (
                      data.map(step => (
                        <Radio
                          name='step'
                          className='step'
                          key={step.id}
                          value={JSON.stringify(step)}
                          checked={values.step === JSON.stringify(step)}
                          onChange={() => {
                            setValues({ step: JSON.stringify(step) })
                          }}>
                          <p className='title'>{step.title}</p>
                          <p className='description'>{step.description}</p>
                        </Radio>
                      ))
                    ) : (
                      <Empty description='No steps found.' />
                    )}
                  </div>
                  {!!data.length && (
                    <Button
                      disabled={!values.step}
                      loading={isSubmitting}
                      htmlType='submit'
                      type='primary'>
                      Save
                    </Button>
                  )}
                </Form>
              )}
            </Formik>
          )}
          {status === 'loading' && (
            <div className='loading'>
              <Spin />
            </div>
          )}
        </>
      ) : (
        <CreateEditStep
          handleCreateSubmit={handleCreateSubmit}
          setCopyCustomStepOpen={setCopyCustomStepOpen}
          copyCustomStepOpen={copyCustomStepOpen}
        />
      )}
    </Wrapper>
  )
}

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  .steps-list {
    display: flex;
    flex-direction: column;
    gap: 5px;
    border: 1px solid #e6e6e6;
    border-radius: 10px;
    padding: 10px;
    max-height: 400px;
    overflow: auto;
    margin-bottom: 20px;
  }
  .step {
    width: 100%;
    transition: 0.2s ease-in-out;
    padding: 10px;
    border-radius: 5px;
    transition: 0.2s ease-in-out;
    cursor: pointer;
    &:hover,
    &:checked {
      background: ${props => props.theme.crcoPaleBlue};
    }
    p {
      margin: 0;
    }
    .description {
      color: ${props => props.theme.crcoGrey};
    }
  }
  .loading {
    display: grid;
    place-items: center;
    padding: 20px;
  }
  .ant-radio-wrapper {
    margin-right: 0;
  }
`

export default StepList
