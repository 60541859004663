import { StarFilled } from '@ant-design/icons'
import { googleLogout } from '@react-oauth/google'
import { Carousel } from 'antd'
import queryString from 'query-string'
import React, { useContext, useState } from 'react'
import { Redirect, useLocation } from 'react-router-dom'

import { BrandInfoForm } from './BrandInfoForm'
import { InviteForm } from './InviteForm'
import { MiscInfoForm } from './MiscInfoForm'
import { UserInfoForm } from './UserInfoForm'
import { UserContext } from '../../contexts/UserContext'
import { useDocumentTitle } from '../../custom-hooks/useDocumentTitle'
import g2Icon from '../../images/signup/g2-reviews.svg'
import googleIcon from '../../images/signup/google-reviews.svg'
import image1 from '../../images/signup/image1.png'
import image2 from '../../images/signup/image2.png'
import image3 from '../../images/signup/image3.png'
import image4 from '../../images/signup/image4.png'
import image5 from '../../images/signup/image5.png'
import quoteMarks from '../../images/signup/quote-marks.svg'
import trustPilotIcon from '../../images/signup/trustpilot-reviews.svg'
import { ImageLoader } from '../general/ImageLoader'
import { Wrapper } from '../Login'
import 'antd/dist/antd.css'
import 'antd-country-phone-input/dist/index.css'

export const Signup = () => {
  useDocumentTitle('Sign up | Brand')
  const urlParams = queryString.parse(useLocation().search)
  const [step, setStep] = useState(1)
  const { nodeToken } = useContext(UserContext)
  const [emailVerified, setEmailVerified] = useState(false)

  const [formData, setFormData] = useState({
    // user info form
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    short: 'US',
    code: '',
    sendText: false,
    password: '',
    confirmPassword: '',

    // brand info form
    brandName: '',
    brandWebsite: '',
    companyType: '',
    categories: [],
    creatorSize: [],
    reference: '',
    brandGoal: '',
    agreement: false,
    rid: urlParams.rid || '',
  })

  const nextStep = () => setStep(prev => prev + 1)
  const prevStep = () => setStep(prev => prev - 1)

  // GOOGLE OAUTH
  const [googleProfile, setGoogleProfile] = useState(null)

  const handleGoogleSignup = async googleToken => {
    const response = await fetch('https://www.googleapis.com/oauth2/v3/tokeninfo', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: `id_token=${googleToken}`,
    })
    const resJson = await response.json()

    if (resJson.error) {
      return
    }
    setGoogleProfile(resJson)
    setEmailVerified(true)
    setFormData(prev => ({
      ...prev,
      firstName: resJson.given_name,
      lastName: resJson.family_name,
      email: resJson.email,
    }))
  }

  const logOut = () => {
    googleLogout()
    setGoogleProfile(null)
    setFormData({
      // user info form
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      short: 'US',
      code: '',
      sendText: false,
      password: '',
      confirmPassword: '',

      // brand info form
      brandName: '',
      brandWebsite: '',
      companyType: '',
      categories: [],
      creatorSize: [],
      reference: '',
      brandGoal: '',
      agreement: false,
      rid: urlParams.rid || '',
    })
  }

  const carouselContent = [
    {
      image: image1,
      caption:
        'Creator.co makes it super easy to track and manage all of the creators we use to promote our brand, and their account managers are so helpful & on top of it!',
      reviewer: 'Dineka R, Embody',
      reviewSiteLogo: g2Icon,
    },
    {
      image: image2,
      caption:
        'Creator.co support all of our brands, and are doing an amazing job on each one of them. I’m so happy with all content the influencers are producing and the traction we are getting.',
      reviewer: 'Diana Danoff, Green Vallery Creamery',
      reviewSiteLogo: googleIcon,
    },
    {
      image: image3,
      caption:
        'As a Brand Manager, Creator.co full service option with influencer negotiation has made this part of my job seamless and we are receiving high quality content!',
      reviewer: 'Kate Slaney, Meyenberg',
      reviewSiteLogo: googleIcon,
    },
    {
      image: image4,
      caption: `Hands down the best Influencer platform out there! The team is fantastic and they do a really good job. The influencers are wonderful as well as the service.`,
      reviewer: 'Kevin Campbell, Chippin Pet',
      reviewSiteLogo: trustPilotIcon,
    },
    {
      image: image5,
      caption:
        'We explored multiple platforms before choosing Creator.co - after 1 week of our campaign going live we had hundreds of on-brand Influencers to work with at our discretion.',
      reviewer: 'Sandro C, Allure Global Inc',
      reviewSiteLogo: g2Icon,
    },
  ]

  return !nodeToken ? (
    <Wrapper className='signup'>
      <div className='inner'>
        <section className='carousel'>
          <Carousel autoplay>
            {carouselContent.map((item, index) => (
              <div className='testimonial-card' key={index}>
                <div className='image'>
                  <ImageLoader src={item.image} alt='' />
                </div>
                <div className='text'>
                  <img className='quote-marks' src={quoteMarks} alt='' />
                  <p className='caption'>{item.caption}</p>
                  <div className='brand-info'>
                    <p className='name'>{item.name}</p>
                    <p className='reviewer'>{item.reviewer}</p>
                  </div>
                  <div className='review-site'>
                    <img src={item.reviewSiteLogo} alt='' />
                    {Array(5)
                      .fill(0)
                      .map(i => (
                        <StarFilled key={i} />
                      ))}
                  </div>
                </div>
              </div>
            ))}
          </Carousel>
        </section>

        <section className='login-form-container'>
          {!urlParams.invite ? (
            <>
              {step === 1 && (
                <UserInfoForm
                  googleProfile={googleProfile}
                  logOut={logOut}
                  formData={formData}
                  setFormData={setFormData}
                  prevStep={prevStep}
                  nextStep={nextStep}
                  emailVerified={emailVerified}
                  setEmailVerified={setEmailVerified}
                  handleGoogleSignup={handleGoogleSignup}
                />
              )}
              {step === 2 && (
                <BrandInfoForm
                  formData={formData}
                  setFormData={setFormData}
                  prevStep={prevStep}
                  nextStep={nextStep}
                />
              )}
              {step === 3 && (
                <MiscInfoForm
                  formData={formData}
                  setFormData={setFormData}
                  prevStep={prevStep}
                  nextStep={nextStep}
                />
              )}
            </>
          ) : (
            <InviteForm inviteString={urlParams.invite} />
          )}
          <div className='sub-links creator'>
            Are you a Creator? <a href='https://app.creator.co/signup'>Sign Up Here</a>
          </div>
        </section>
      </div>
    </Wrapper>
  ) : (
    <Redirect to='/campaigns' />
  )
}
