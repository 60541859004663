import React, { useRef, useCallback } from 'react'
import ReactQuill from 'react-quill'
import styled from 'styled-components/macro'

import 'quill-mention'
import 'react-quill/dist/quill.snow.css'
import 'quill-mention/dist/quill.mention.css'
import { theme } from '../../GlobalStyles'
import { toLowerCamelCase } from '../../utils'

export const RichTextEditor = ({ variables, initialValue, editorRef }) => {
  const quillRef = useRef()

  // on demand public interface
  if (editorRef)
    editorRef.current = {
      getHTML: () => {
        if (!quillRef) return
        const editor = quillRef.current.getEditor()
        const unprivilegedEditor = quillRef.current.makeUnprivilegedEditor(editor)
        return unprivilegedEditor.getHTML()
      },
      getText: () => {
        if (!quillRef) return
        const editor = quillRef.current.getEditor()
        const unprivilegedEditor = quillRef.current.makeUnprivilegedEditor(editor)
        return unprivilegedEditor.getText()
      },
      focus: () => {
        if (!quillRef) return
        // wait full render
        setTimeout(() => quillRef.current.editor.focus(), 25)
      },
    }

  // variables feat
  const variablesOpts = variables
    ? variables.map((s, i) => ({
        id: i,
        value: `${toLowerCamelCase(s)}}}`,
        label: s,
      }))
    : null
  const variableSelectRenderItem = useCallback(item => {
    return item.label
  }, [])
  const variableSource = useCallback((searchTerm, renderList) => {
    if (searchTerm.length === 0) renderList(variablesOpts, searchTerm)
    else {
      const lowerSearch = searchTerm.toLowerCase()
      const matches = variablesOpts.filter(v => v.value.toLowerCase().includes(lowerSearch))
      renderList(matches, searchTerm)
    }
  }, [])

  const getModules = () => {
    const modules = {
      toolbar: [
        ['bold', 'italic', 'underline', 'strike'], // toggled buttons
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        ['link', 'video'], // 'image' -> keep as ref
        [{ align: [] }],
        [{ list: 'ordered' }, { list: 'bullet' }], // , { list: 'check' } -> keep as ref
        [{ color: [] }, { background: [] }], // dropdown with defaults from theme
        // [{ header: 1 }, { header: 2 }], // custom button values
        // ['blockquote', 'code-block'], -> keep as ref
        // [{ font: [] }],  -> keep as ref
        // [{ script: 'sub' }, { script: 'super' }], // -> keep as ref
        // [{ indent: '-1' }, { indent: '+1' }], // outdent/indent -> keep as ref
        // [{ direction: 'rtl' }], // text direction -> keep as ref
        // [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown -> keep as ref
        // ['clean'], // remove formatting button -> keep as ref
      ],
    }
    if (variables) {
      modules['mention'] = {
        mentionDenotationChars: ['{{'],
        allowInlineMentionChar: true,
        renderItem: variableSelectRenderItem,
        source: variableSource,
      }
    }
    return modules
  }
  const getFormats = () => {
    return [
      'header',
      'bold',
      'size',
      'italic',
      'underline',
      'strike',
      'list',
      'bullet',
      'link',
      'video',
      'color',
      'background',
      'align',
      'mention',
    ]
  }

  return (
    <StyledReactQuill
      defaultValue={initialValue}
      ref={quillRef}
      modules={getModules()}
      formats={getFormats()}
    />
  )
}

const StyledReactQuill = styled(ReactQuill)`
  .ql-mention-list-item {
    line-height: 34px;
    font-size: 12px;
    font-weight: 500;
  }
  .mention {
    background-color: ${theme.crcoPaleBlue};
    font-weight: 500;
  }
  .ql-mention-list-item.selected {
    background-color: ${theme.crcoPaleBlue};
  }
`
