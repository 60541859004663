import { EditOutlined } from '@ant-design/icons'
import { Alert } from 'antd'
import { Formik, Form } from 'formik'
import { Checkbox, Input, Select } from 'formik-antd'
import React, { useContext } from 'react'
import { useQuery } from 'react-query'
import * as yup from 'yup'

import FormItem from '../../components/forms/FormItem'
import { FadeIn } from '../../components/general/FadeIn'
import { BrandContext } from '../../contexts/BrandContext'
import bespoke from '../../images/icons/bespoke.svg'
import giveaway from '../../images/icons/giveaway.svg'
import shoutout from '../../images/icons/shoutout.svg'
import instagram from '../../images/icons/social/instagram-color-square.svg'
import tiktok from '../../images/icons/social/tiktok-color-square.svg'
import youtube from '../../images/icons/social/youtube-color-square.svg'
import ugc from '../../images/icons/ugc.svg'
const { Option } = Select

export const CampaignStrategy = ({
  formData,
  setFormData,
  formRef,
  sectionRef,
  submitCampaign,
}) => {
  const { fetchBrandData, brandId } = useContext(BrandContext)
  const { data: brandData } = useQuery(['brand', brandId], fetchBrandData)

  const schema = yup.object().shape({
    title: yup.string().required('Required').max(100, 'Too long'),
    strategy: yup.string().required('Required'),
    socialChannels:
      formData.strategy !== 'ugc' && formData.strategy !== 'affiliate'
        ? yup.array().required('Please select at least 1')
        : null,
  })

  const initialValues = {
    title: formData.title,
    strategy: formData.strategy || '',
    socialChannels: formData.socialChannels,
    unlisted: formData.unlisted,
  }

  return (
    <section ref={sectionRef}>
      <Formik
        innerRef={formRef}
        initialValues={initialValues}
        validationSchema={schema}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={() => {}}>
        {({ values, setValues }) => (
          <Form>
            {!brandData.activeSubscriptions?.length && (
              <Alert
                type='warning'
                message='You must be on a subscription plan to submit campaigns.'
                showIcon
                style={{ marginBottom: '20px' }}
              />
            )}
            <h2 className='header'>General</h2>
            <FormItem name='title' label='Title'>
              <Input
                name='title'
                maxLength={100}
                placeholder='Untitled campaign'
                prefix={<EditOutlined />}
                onChange={e => {
                  setFormData({ ...formData, title: e.target.value })
                  submitCampaign(false)
                }}
              />
            </FormItem>
            <FormItem name='strategy' label='Strategy'>
              <Select
                getPopupContainer={trigger => trigger.parentNode}
                name='strategy'
                placeholder='Select a strategy'
                style={{ width: '100%' }}
                onChange={e => {
                  if (['affiliate', 'ugc'].includes(e)) {
                    setFormData({
                      ...formData,
                      strategy: e,
                      socialChannels: [],
                      contentFormats: '',
                      contentFormatCounts: {},
                    })
                    setValues({
                      ...values,
                      strategy: e,
                      socialChannels: [],
                      contentFormats: '',
                      contentFormatCounts: {},
                    })
                  } else if (e !== 'bespoke') {
                    setFormData({ ...formData, strategy: e, creatorProfession: null })
                    setValues({ ...values, strategy: e, creatorProfession: null })
                  } else {
                    setFormData({ ...formData, strategy: e })
                    setValues({ ...values, strategy: e })
                  }
                  submitCampaign(false)
                }}>
                <Option value='ugc'>
                  <img src={ugc} alt='' style={{ height: '20px', marginRight: '8px' }} />
                  UGC
                </Option>
                <Option value='shoutout'>
                  <img src={shoutout} alt='' style={{ height: '20px', marginRight: '8px' }} />
                  ShoutOut
                </Option>
                <Option value='giveaway'>
                  <img src={giveaway} alt='' style={{ height: '20px', marginRight: '8px' }} />
                  Giveaway
                </Option>
                <Option value='bespoke'>
                  <img src={bespoke} alt='' style={{ height: '20px', marginRight: '8px' }} />
                  Bespoke
                </Option>
              </Select>
            </FormItem>
            <table className='strategy-table'>
              <tbody>
                <tr>
                  <td className='strategy'>UGC</td>
                  <td className='description'>
                    Drive user-generated content for your brand (no posts on social media)
                  </td>
                </tr>
                <tr>
                  <td className='strategy'>ShoutOut</td>
                  <td className='description'>
                    Provide products or services to creators (payments recommended) for tailored
                    posts on social media
                  </td>
                </tr>
                <tr>
                  <td className='strategy'>Giveaway</td>
                  <td className='description'>
                    Grow your social media following and engagement when creators host giveaways on
                    your behalf
                  </td>
                </tr>
                <tr>
                  <td className='strategy'>Bespoke</td>
                  <td className='description'>
                    For experiences and campaigns that require more than the typical &quot;product
                    for post&quot; ask of creators
                  </td>
                </tr>
              </tbody>
            </table>
            {formData.strategy && !['affiliate', 'ugc'].includes(formData.strategy) && (
              <FadeIn>
                <FormItem name='socialChannels' label='Social Channel(s)'>
                  <Select
                    getPopupContainer={trigger => trigger.parentNode}
                    name='socialChannels'
                    placeholder='Select'
                    mode='multiple'
                    allowClear
                    showArrow
                    style={{ width: '100%' }}
                    onChange={e => {
                      setFormData({
                        ...formData,
                        socialChannels: e,
                      })
                      setValues({ ...values, socialChannels: e })
                      submitCampaign(false)
                    }}>
                    <Option value='instagram'>
                      <img src={instagram} alt='' style={{ height: '20px', marginRight: '8px' }} />
                      Instagram
                    </Option>
                    <Option value='tiktok'>
                      <img src={tiktok} alt='' style={{ height: '20px', marginRight: '8px' }} />
                      TikTok
                    </Option>
                    <Option value='youtube'>
                      <img src={youtube} alt='' style={{ height: '20px', marginRight: '8px' }} />
                      YouTube
                    </Option>
                  </Select>
                </FormItem>
              </FadeIn>
            )}
            {brandData.userHasAgencyPrivileges && (
              <FormItem size='small'>
                <label style={{ cursor: 'pointer' }} htmlFor='unlisted'>
                  <Checkbox
                    name='unlisted'
                    onChange={e => {
                      setFormData({ ...formData, unlisted: e.target.checked })
                      setValues({ ...values, unlisted: e.target.checked })
                      submitCampaign(false)
                    }}
                  />
                  <span> Unlisted</span>
                </label>
              </FormItem>
            )}
          </Form>
        )}
      </Formik>
    </section>
  )
}
