import {
  RedoOutlined,
  EyeOutlined,
  PauseCircleOutlined,
  EditOutlined,
  FolderOutlined,
  CopyOutlined,
  ExclamationCircleOutlined,
  ShareAltOutlined,
} from '@ant-design/icons'
import { Button, Modal, Dropdown, message, Tag, Tooltip } from 'antd'
import { Formik, Form } from 'formik'
import { Input, Select } from 'formik-antd'
import moment from 'moment'
import React, { useContext, useState } from 'react'
import CopyToClipboard from 'react-copy-to-clipboard'
import { queryCache, useQuery } from 'react-query'
import { useHistory } from 'react-router'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { BrandContext } from '../../contexts/BrandContext'
import { CampaignContext } from '../../contexts/CampaignContext'
import { UserContext } from '../../contexts/UserContext'
import { ActionMenuWrapper } from '../../GlobalStyles'
import affiliate from '../../images/icons/affiliate.svg'
import bespoke from '../../images/icons/bespoke.svg'
import giveaway from '../../images/icons/giveaway.svg'
import shoutout from '../../images/icons/shoutout.svg'
import instagram from '../../images/icons/social/instagram-color-square.svg'
import tiktok from '../../images/icons/social/tiktok-color-square.svg'
import youtube from '../../images/icons/social/youtube-color-square.svg'
import { ActionMenuIcon } from '../general/ActionMenuIcon'
const socialIcons = { instagram, youtube, tiktok }
const { Option } = Select

const CampaignListItem = ({ campaign }) => {
  const { role } = useContext(UserContext)
  const { brandId, fetchBrandData } = useContext(BrandContext)
  const { duplicateCampaign, pauseCampaign, archiveCampaign, restoreCampaign, shareCampaign } =
    useContext(CampaignContext)

  const [duplicateVisible, setDuplicateVisible] = useState(false)
  const [pauseVisible, setPauseVisible] = useState(false)
  const [archiveVisible, setArchiveVisible] = useState(false)
  const [reactivateVisible, setReactivateVisible] = useState(false)
  const [restoreVisible, setRestoreVisible] = useState(false)
  const [shareVisible, setShareVisible] = useState(false)
  const [copied, setCopied] = useState({})
  const [isActionLoading, setIsActionLoading] = useState(false)
  const [dropdownOpen, setDropdownOpen] = useState(false)

  const history = useHistory()
  const isActive = campaign.status === 'publish' || campaign.status === 'paused'

  const { data: brandData } = useQuery(['brand', brandId], fetchBrandData)

  const Thumbnail = ({ status = campaign.status }) => {
    const thumbnailUrl = !campaign.shoptypeProduct
      ? campaign.images?.filter(image => image.type === 'primary')[0]?.sizes[1]?.url
      : campaign.shoptypeProduct.primaryImageSrc?.imageSrc
    return (
      <ThumbnailWrapper status={status} className='thumbnail-portrait'>
        <div className='thumbnail' style={{ backgroundImage: `url(${thumbnailUrl})` }} />
        <span style={{ marginTop: '2px' }}>
          {status === 'publish' && 'Active'}
          {status === 'pending' && 'Pending'}
          {status === 'paused' && 'Paused'}
          {status === 'draft' && 'Draft'}
          {status === 'archived' && 'Archived'}
        </span>
      </ThumbnailWrapper>
    )
  }

  const ActionsMenu = () => {
    return (
      <ActionMenuWrapper>
        {role === 'administrator' && (
          <a
            href={`${
              process.env.NODE_ENV === 'production'
                ? 'https://admin.creator.co'
                : 'http://localhost:3003'
            }/campaign/${campaign.id}`}
            target='_blank'
            rel='noopener noreferrer'>
            <Button type='link' icon={<EditOutlined />} onClick={e => e.stopPropagation()}>
              Edit (Admin)
            </Button>
          </a>
        )}

        {(campaign.status === 'publish' || campaign.status === 'paused') && (
          <>
            <a
              href={`https://app.creator.co/campaign/${campaign.slug}`}
              target='_blank'
              rel='noopener noreferrer'>
              <Button
                type='link'
                icon={<EyeOutlined />}
                onClick={e => {
                  e.stopPropagation()
                }}>
                View Brief
              </Button>
            </a>
            {brandData.userHasAgencyPrivileges && (
              <Link to={`/campaign-builder/${campaign.id}`}>
                <Button
                  type='link'
                  icon={<EditOutlined />}
                  onClick={e => {
                    e.stopPropagation()
                  }}>
                  Edit
                </Button>
              </Link>
            )}
          </>
        )}

        {campaign.status !== 'archived' && (
          <Button
            type='link'
            icon={<CopyOutlined />}
            onClick={e => {
              e.stopPropagation()
              setDuplicateVisible(true)
            }}>
            Duplicate
          </Button>
        )}

        {campaign.status === 'publish' && (
          <Button
            type='link'
            danger
            icon={<PauseCircleOutlined />}
            onClick={e => {
              e.stopPropagation()
              setPauseVisible(true)
            }}>
            Pause
          </Button>
        )}

        {campaign.status === 'paused' && (
          <Button
            type='link'
            icon={<RedoOutlined />}
            onClick={e => {
              e.stopPropagation()
              setReactivateVisible(true)
            }}>
            Reactivate
          </Button>
        )}

        {campaign.status === 'archived' && (
          <Button
            type='link'
            icon={<RedoOutlined />}
            onClick={e => {
              e.stopPropagation()
              setRestoreVisible(true)
            }}>
            Restore
          </Button>
        )}

        {(campaign.status === 'pending' || campaign.status === 'draft') && (
          <>
            <Link to={`/campaign-builder/${campaign.id}`}>
              <Button type='link' icon={<EditOutlined />}>
                Edit
              </Button>
            </Link>

            <Button type='link' icon={<ShareAltOutlined />} onClick={() => setShareVisible(true)}>
              Share
            </Button>

            <Button
              type='link'
              danger
              icon={<FolderOutlined />}
              onClick={e => {
                e.stopPropagation()
                setArchiveVisible(true)
              }}>
              Archive
            </Button>
          </>
        )}
      </ActionMenuWrapper>
    )
  }
  const handleDuplicate = async values => {
    try {
      const res = await duplicateCampaign(campaign.id, values)
      message.success('Campaign duplicated')
      setDuplicateVisible(false)
      history.push(`/campaign-builder/${res.data.campaignId}`)
      queryCache.invalidateQueries('campaigns')
    } catch (err) {
      message.error('Sorry, something went wrong')
    }
  }

  const handlePause = async () => {
    setIsActionLoading(true)
    await pauseCampaign(campaign.id)
    setIsActionLoading(false)
    setPauseVisible(false)
    queryCache.invalidateQueries('campaigns')
  }

  const handleArchive = async () => {
    setIsActionLoading(true)
    await archiveCampaign(campaign.id)
    setIsActionLoading(false)
    setArchiveVisible(false)
    queryCache.invalidateQueries('campaigns')
  }

  const handleRestore = async () => {
    setIsActionLoading(true)
    await restoreCampaign(campaign.id)
    setIsActionLoading(false)
    setRestoreVisible(false)
    queryCache.invalidateQueries('campaigns')
  }

  const handleShare = async data => {
    await shareCampaign(campaign.id, data)
    setShareVisible(false)
    queryCache.invalidateQueries('campaigns')
  }

  const handleCopy = line => {
    setCopied(prev => ({
      ...prev,
      [line]: true,
    }))
    setTimeout(() => {
      setCopied(prev => ({
        ...prev,
        [line]: false,
      }))
    }, 1500)
  }

  return (
    <>
      <Wrapper
        style={{ cursor: ['publish', 'paused'].includes(campaign.status) ? 'pointer' : 'default' }}
        className='campaign-row'
        onClick={() => isActive && history.push(`/campaigns/${campaign.id}`)}>
        <div className='general'>
          <Thumbnail />
          <div className='info'>
            <span className='title'>{campaign.title}</span>
            <div className='strategy'>
              {!!campaign.password && (
                <Tag color='volcano'>
                  <ExclamationCircleOutlined /> Unlisted
                </Tag>
              )}
              {campaign.socialChannels?.map((channel, i) => (
                <img src={socialIcons[channel]} alt={channel} key={i} />
              ))}
              <img
                className='icon'
                src={
                  campaign.strategy === 'shoutout'
                    ? shoutout
                    : campaign.strategy === 'giveaway'
                      ? giveaway
                      : campaign.strategy === 'affiliate'
                        ? affiliate
                        : bespoke
                }
                alt=''
              />
              {campaign.contentFormats?.length && campaign.contentFormats[0].length ? (
                campaign.contentFormats.map(
                  format =>
                    !!format.length && (
                      <span key={format} className='format'>
                        {format === 'Video Post' ? 'Video' : format}
                      </span>
                    )
                )
              ) : (
                <span className='format'>Custom</span>
              )}
            </div>
            <div className='payout'>
              {campaign.maxPaidAmount ? (
                <>
                  <span className='label'>Payment: </span>
                  <span className='amount'>
                    $
                    {campaign.minPaidAmount
                      ? `${campaign.minPaidAmount}-${campaign.maxPaidAmount}`
                      : campaign.maxPaidAmount}{' '}
                    USD
                  </span>
                </>
              ) : (
                'No payment.'
              )}
            </div>
          </div>
        </div>
        {isActive ? (
          <>
            <div className={`optins ${campaign.optIns === 0 && 'none'}`}>{campaign.optIns}</div>
            <div className={`pending ${campaign.pending === 0 && 'none'}`}>{campaign.pending}</div>
            <div className={`activated ${campaign.activated === 0 && 'none'}`}>
              {campaign.activated}
            </div>
            <div className={`activated ${campaign.shoutouts === 0 && 'none'}`}>
              {campaign.shoutouts}
            </div>
            <div className='published'>
              {campaign.status === 'publish' && moment(campaign.publishDate).format('ll')}
            </div>
          </>
        ) : (
          <>
            <div />
            <div />
            <div />
            <div />
            <div className='updated'>{moment(campaign.date).format('ll')}</div>
          </>
        )}
        <Dropdown
          onClick={e => e.stopPropagation()}
          overlay={ActionsMenu}
          onOpenChange={() => setDropdownOpen(!dropdownOpen)}
          placement='bottom'>
          <ActionMenuIcon open={dropdownOpen}></ActionMenuIcon>
        </Dropdown>
      </Wrapper>

      {/* DUPLICATE CAMPAIGN MODAL */}
      <Modal
        title='Duplicate Campaign'
        open={duplicateVisible}
        onCancel={() => setDuplicateVisible(false)}
        destroyOnClose
        footer={null}>
        <Formik
          initialValues={{
            title: `${campaign.title} (Copy)`,
            duplicateKPIs: false,
          }}
          onSubmit={handleDuplicate}>
          {({ values, submitForm, isSubmitting }) => (
            <Form>
              <ModalWrapper className='duplicate-modal'>
                <div className='campaign-details'>
                  <Thumbnail status='draft' />
                  <div className='input-wrapper'>
                    <p className='label'>New campaign title</p>
                    <Input name='title' />
                  </div>
                </div>

                <div className='button-wrapper'>
                  <div className='buttons'>
                    <Button type='secondary' onClick={() => setDuplicateVisible(false)}>
                      Cancel
                    </Button>
                    <Button
                      type='primary'
                      onClick={submitForm}
                      loading={isSubmitting}
                      disabled={!values.title}>
                      Continue
                    </Button>
                  </div>
                </div>
              </ModalWrapper>
            </Form>
          )}
        </Formik>
      </Modal>

      {/* PAUSE CAMPAIGN MODAL */}
      <Modal
        title='Pause Campaign'
        open={pauseVisible}
        okText='Pause'
        okButtonProps={{ danger: true }}
        onOk={handlePause}
        onCancel={() => setPauseVisible(false)}
        confirmLoading={isActionLoading}>
        <ModalWrapper>
          <Thumbnail />
          <p>
            Are you sure you want to pause <b>{campaign.title}</b>?
          </p>
          <p className='info-text'>
            Pausing your campaign will prevent new creators from opting in. However, you&apos;ll
            still be able manage it, view metrics, and view & download content.
          </p>
        </ModalWrapper>
      </Modal>

      {/* ARCHIVE CAMPAIGN MODAL */}
      <Modal
        title='Archive Campaign'
        open={archiveVisible}
        okText='Archive'
        okButtonProps={{ danger: true }}
        onOk={handleArchive}
        onCancel={() => setArchiveVisible(false)}
        confirmLoading={isActionLoading}>
        <ModalWrapper>
          <Thumbnail />
          <p>
            Are you sure you want to archive <b>{campaign.title}</b>?
          </p>
          <p className='info-text'>
            Archiving your campaign will prevent new creators from opting in. However, you&apos;ll
            still be able manage it, view metrics, and view & download content.
          </p>
        </ModalWrapper>
      </Modal>

      {/* RESTORE CAMPAIGN MODAL */}
      <Modal
        title='Restore Campaign'
        open={restoreVisible}
        okText='Restore'
        okButtonProps={{ danger: true }}
        onOk={handleRestore}
        onCancel={() => setRestoreVisible(false)}
        confirmLoading={isActionLoading}>
        <ModalWrapper>
          <Thumbnail />
          <p>
            Are you sure you want to restore <b>{campaign.title}</b>?
          </p>
          <p className='info-text'>Please find your campaign under the Draft tab.</p>
        </ModalWrapper>
      </Modal>

      {/* REACTIVATE CAMPAIGN MODAL */}
      <Modal
        title='Reactivate Campaign'
        open={reactivateVisible}
        footer={null}
        onCancel={() => setReactivateVisible(false)}>
        <ModalWrapper>
          <Thumbnail />
          <p>
            Please contact{' '}
            <a onClick={e => e.stopPropagation()} href='mailto:campaignsupport@creator.co'>
              campaignsupport@creator.co
            </a>{' '}
            if you&apos;d like to reactivate your campaign.
          </p>
        </ModalWrapper>
      </Modal>

      {/* SHARE CAMPAIGN MODAL */}
      <Modal
        title='Share Campaign'
        open={shareVisible}
        onCancel={() => setShareVisible(false)}
        destroyOnClose
        okButtonProps={{ danger: true }}
        footer={null}>
        <Formik initialValues={{}} onSubmit={handleShare}>
          {({ submitForm, isSubmitting }) => (
            <Form>
              <ModalWrapper className='share-modal'>
                <div>Choose which brand users you wish to send this campaign to.</div>
                <Select
                  virtual={false}
                  name='shareCampaigns'
                  mode='multiple'
                  labelInValue
                  className='user-dropdown'>
                  {brandData?.brandUsers?.map(user => (
                    <Option key={user.id} value={user.user.email}>
                      {user.user.firstName} {user.user.lastName} ({user.user.email})
                    </Option>
                  ))}
                </Select>

                <div className='share-footer'>
                  <Button key='link' type='link' icon={<ShareAltOutlined />}>
                    <CopyToClipboard
                      onCopy={() => handleCopy('name')}
                      text={`https://brands.creator.co/campaign-builder/${campaign.id}`}>
                      <Tooltip open={!!copied.name} title='Copied to clipboard'>
                        <span className='copy name'>Copy Link</span>
                      </Tooltip>
                    </CopyToClipboard>
                  </Button>
                  <div className='share-buttons'>
                    <Button key='back' onClick={() => setShareVisible(false)}>
                      Cancel
                    </Button>
                    <Button key='submit' type='primary' onClick={submitForm} loading={isSubmitting}>
                      Send Link
                    </Button>
                  </div>
                </div>
              </ModalWrapper>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  )
}

const Wrapper = styled.div`
  text-align: center;
  align-items: center;
  &:not(:last-child) {
    border-bottom: 1px solid #e6e6e6;
  }
  &:hover {
    background: #f6f6f6;
  }
  .general {
    text-align: left;
    display: flex;
    align-items: center;
    min-width: 300px;
    .info {
      display: flex;
      flex-direction: column;
      gap: 5px;
      flex: 1;
      .title {
        font-size: 1rem;
      }
      .strategy {
        display: flex;
        flex-wrap: wrap;
        grid-gap: 5px;
        img {
          height: 20px;
        }
        .format {
          background: #ecf6ff;
          color: ${props => props.theme.crcoTechBlue};
          display: grid;
          place-content: center;
          padding: 0 5px;
          border-radius: 3px;
          font-size: 0.7rem;
        }
      }
      .payout {
        font-size: 0.8rem;
        margin-top: 5px;
        color: rgba(0, 0, 0, 0.3);
        .amount {
          color: #00c457;
          font-weight: bold;
        }
      }
    }
  }
  .pending,
  .activated {
    color: ${props => props.theme.crcoTechBlue};
  }
  .optins,
  .pending,
  .activated {
    &.none {
      color: #999;
    }
  }

  & > div {
    padding: 16px;
  }
`

const ThumbnailWrapper = styled.div`
  width: 80px;
  height: 100px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  text-align: center;
  color: #fff;
  margin-right: 12px;
  font-size: 0.8rem;

  .thumbnail {
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    background-color: #fff;
    border-radius: 4px;
    flex: 1;
  }

  border: 2px solid
    ${props => {
      switch (props.status) {
        case 'pending':
          return '#80CCFF'
        case 'paused':
          return '#bbb'
        case 'draft':
          return '#FC9C44'
        case 'archived':
          return '#4C606E'
        default:
          // publish
          return '#18D96E'
      }
    }};
  background-color: ${props => {
    switch (props.status) {
      case 'pending':
        return '#80CCFF'
      case 'paused':
        return '#bbb'
      case 'draft':
        return '#FC9C44'
      case 'archived':
        return '#4C606E'
      default:
        // publish
        return '#18D96E'
    }
  }};
`

const ModalWrapper = styled.div`
  text-align: center;
  .thumbnail-portrait {
    margin: 0 auto 16px;
  }
  p {
    font-size: 1rem;
  }
  .info-text {
    font-size: 0.8rem;
    color: gray;
  }
  &.duplicate-modal {
    display: flex;
    flex-direction: column;
    text-align: left;
    .campaign-details {
      display: flex;
      align-items: center;
      gap: 20px;
      margin-bottom: 30px;
      .thumbnail-portrait {
        margin: 0;
      }
      .input-wrapper {
        flex: 1;
      }
      .label {
        font-size: 1rem;
        margin: 0 0 5px 0;
      }
      .ant-checkbox-wrapper {
        margin-top: 15px;
      }
    }
    .button-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .buttons {
      display: flex;
      gap: 12px;
      margin-left: auto;
    }
  }

  &.share-modal {
    text-align: left;
    padding: 0 30px;
    display: flex;
    flex-direction: column;
    gap: 10px;

    .user-dropdown {
      width: 90%;
    }

    .share-footer {
      margin-top: 10px;
      display: flex;
      justify-content: space-between;

      .share-buttons {
        display: flex;
        gap: 10px;
      }
    }
  }
`

export default CampaignListItem
