import React from 'react'
import styled from 'styled-components'

import { getProfilePicUrl } from '../../../../utils'
import CreatorAvatar from '../../../general/CreatorAvatar'

const NewOptInItem = ({ optIn, selectedOptIn, setSelectedOptIn, setShowProfile }) => {
  const profilePicUrl = getProfilePicUrl(optIn)

  return (
    <Wrapper
      className={`
      ${selectedOptIn?.id === optIn.id ? 'selected' : ''}
      ${selectedOptIn ? 'active' : ''}
      ${!optIn.seen && 'not-seen'}
      `}>
      <div
        onClick={() => {
          setSelectedOptIn(optIn.id)
          setShowProfile(true)
        }}
        onKeyDown={() => {
          setSelectedOptIn(optIn.id)
          setShowProfile(true)
        }}
        role='button'
        tabIndex={0}>
        <CreatorAvatar
          id={optIn.userId}
          size={76}
          className='avatar'
          url={profilePicUrl}
          initials={`
          ${optIn.user?.firstName ? optIn.user?.firstName?.charAt(0).toUpperCase() : ''} ${
            optIn.user?.lastName ? optIn.user?.lastName?.charAt(0).toUpperCase() : ''
          }
        `}
        />
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  transition: 0.2s;

  &.active {
    &:not(.selected) {
      opacity: 0.3;
    }
  }
  .avatar {
    cursor: pointer;
    border: 5px solid #ffffff;
  }
`

export default NewOptInItem
