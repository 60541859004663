import { Alert } from 'antd'
import React from 'react'

const AffiliateSettings = () => {
  return (
    <div className='account-section' id='affiliate-program'>
      <div className='section-header'>
        <h2 className='section-title'>Affiliate Program</h2>
      </div>
      <div className='section-body'>
        <Alert
          type='info'
          showIcon
          message='Integrate your affiliate programs with Creator.co'
          description={
            <div>
              <p>
                If you are running an existing affiliate program on Awin, Impact Radius, or Rakuten,
                we offer integrations to incorporate them into our platform and your campaigns.
              </p>
              <p>
                To apply, please contact us at{' '}
                <a href='mailto:brands@creator.co'>brands@creator.co</a>.
              </p>
            </div>
          }
        />
      </div>
    </div>
  )
}

export default AffiliateSettings
