import { Modal, message, Button } from 'antd'
import axios from 'axios'
import { Form, Formik } from 'formik'
import { Input, InputNumber } from 'formik-antd'
import React from 'react'
import { queryCache } from 'react-query'
import styled from 'styled-components/macro'
import * as yup from 'yup'

import FormItem from '../../components/forms/FormItem'
import { NODE_URL } from '../../constants'

const CreateLinkModal = ({ createLinkModalOpen, setCreateLinkModalOpen, brandId }) => {
  const validationSchema = yup.object().shape({
    url: yup.string().url().required('URL field is required'),
    description: yup.string().nullable(),
    commission: yup.number().positive('Number has to be a positive number'),
  })

  const handleCreateLinkForm = values => {
    axios
      .post(`${NODE_URL}/brand/${brandId}/affiliates/link`, values)
      .then(() => {
        setCreateLinkModalOpen(false)
      })
      .catch(() => {
        message.error('Sorry, something went wrong.')
      })
      .finally(() => {
        message.success('Successfully created a new link')
        queryCache.invalidateQueries('affiliate-link-data')
        queryCache.invalidateQueries('affiliate-analytics')
      })
  }

  return (
    <Modal
      width={470}
      footer={null}
      open={createLinkModalOpen}
      onCancel={e => {
        e.stopPropagation()
        setCreateLinkModalOpen(false)
      }}
      title='New Link'
      destroyOnClose>
      <ModalWrapper>
        <Formik
          initialValues={{
            commission: 20,
          }}
          validationSchema={validationSchema}
          onSubmit={values => handleCreateLinkForm(values)}>
          {({ isSubmitting, submitForm }) => (
            <Form>
              <FormItem label='URL' name='url' size='small'>
                <Input
                  type='text'
                  name='url'
                  placeholder='e.g. https://creator.co/shopify-payment'
                />
              </FormItem>
              <FormItem label='Description' name='description' size='small'>
                <Input.TextArea
                  type='text'
                  name='description'
                  placeholder='Explain to the creators where this link should be posted and by whom'
                />
              </FormItem>
              <FormItem label='Commission Amount' name='commission' size='small'>
                <InputNumber name='commission' formatter={value => `${value} %`} />
              </FormItem>
              <div className='buttons'>
                <Button loading={isSubmitting} type='primary' onClick={submitForm}>
                  Submit
                </Button>
                <Button type='secondary' onClick={() => setCreateLinkModalOpen(false)}>
                  Cancel
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </ModalWrapper>
    </Modal>
  )
}

const ModalWrapper = styled.div`
  form {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  .buttons {
    display: flex;
    gap: 12px;
  }
`

export default CreateLinkModal
