import { Empty, Spin } from 'antd'
import React, { useContext, useCallback, useRef } from 'react'
import { useInfiniteQuery } from 'react-query'

import { StoriesListItem } from './StoriesListItem'
import { CampaignContext } from '../../../../contexts/CampaignContext'

const StoriesList = () => {
  const { selectedCampaignID, fetchStories } = useContext(CampaignContext)

  const storiesScrollRef = useRef(false)

  const {
    status: storiesStatus,
    data: storiesData,
    isFetchingMore: isFetchingMoreStories,
    fetchMore: fetchMoreStories,
    canFetchMore: canFetchMoreStories,
  } = useInfiniteQuery(
    [
      'stories',
      {
        campaignID: selectedCampaignID,
      },
    ],
    fetchStories,
    {
      getFetchMore: lastGroup => {
        return lastGroup?.nextCursor
      },
    }
  )

  const handleScrollStories = useCallback(
    currentScroll => {
      const distance = currentScroll
        ? currentScroll.scrollHeight - (currentScroll.scrollTop + currentScroll.offsetHeight)
        : 0
      if (distance < 400 && canFetchMoreStories && !isFetchingMoreStories) {
        fetchMoreStories()
      }
    },
    [canFetchMoreStories, fetchMoreStories, isFetchingMoreStories]
  )

  return (
    <>
      {storiesStatus === 'success' && (
        <>
          {storiesData[0]?.totalResults ? (
            <div
              className={`content-list grid`}
              ref={storiesScrollRef}
              onScroll={() => handleScrollStories(storiesScrollRef.current)}>
              {storiesData.map(group =>
                group.stories.map(story => <StoriesListItem key={story.id} story={story} />)
              )}
            </div>
          ) : (
            <div className='no-results'>
              <Empty description='No stories yet.' />
            </div>
          )}
        </>
      )}
      {storiesStatus === 'loading' && (
        <div className='loading'>
          <Spin />
        </div>
      )}
    </>
  )
}

export default StoriesList
