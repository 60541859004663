import { BarChartOutlined, LoadingOutlined, PlusOutlined } from '@ant-design/icons'
import { Button, message } from 'antd'
import axios from 'axios'
import React, { useContext, useState } from 'react'
import { queryCache, useQuery } from 'react-query'

import { Wrapper } from './AddContacts'
import { NODE_URL } from '../../constants'
import { BrandContext } from '../../contexts/BrandContext'
import { UserContext } from '../../contexts/UserContext'

const AddReports = () => {
  const { nodeToken } = useContext(UserContext)
  const { brandId, fetchBrandData } = useContext(BrandContext)
  const { data: brandData, status } = useQuery(['brand', brandId], fetchBrandData)
  const [loading, setLoading] = useState(false)

  const addReportsChargebee = () => {
    setLoading(true)
    var chargebeeInstance = window.Chargebee.getInstance()
    chargebeeInstance.closeAll()
    axios
      .get(`${NODE_URL}/checkout-reports/${brandId}`, {
        headers: { Authorization: `Bearer ${nodeToken}` },
      })
      .then(res => {
        setLoading(false)
        chargebeeInstance.openCheckout({
          hostedPage: () => {
            return new Promise(function (resolve) {
              resolve(res.data)
            })
          },
          error: () => {
            message.error('Error opening checkout.')
          },
          success: hostedPageId => {
            // add tokens to brand and email admin
            axios
              .post(
                `${NODE_URL}/checkout-reports/${brandId}`,
                { hostedPageId },
                {
                  headers: { Authorization: `Bearer ${nodeToken}` },
                }
              )
              .then(() => {
                chargebeeInstance.closeAll()
                queryCache.refetchQueries(['brand'])
                message.success('Reports Added')
              })
              .catch(() => {
                message.error('Error adding credits.')
              })
          },
          close: () => {}, // TODO: cleanup empty callback?
        })
      })
      .catch(() => {
        setLoading(false)
        message.error('Sorry, something went wrong.')
      })
  }

  return (
    <Wrapper>
      <div className='icon'>
        <BarChartOutlined />
      </div>

      <div className='details'>
        <p className='label'>Deep Insights Credits</p>
        <div className='value-wrapper'>
          {status === 'success' ? (
            <>
              <p className='value'>{brandData.reportCredits?.toLocaleString() || 0}</p>
              <Button
                className='add-btn'
                loading={loading}
                type='link'
                icon={<PlusOutlined />}
                onClick={addReportsChargebee}>
                Top Up
              </Button>
            </>
          ) : (
            <LoadingOutlined spin />
          )}
        </div>
      </div>
    </Wrapper>
  )
}

export default AddReports
