import { CopyOutlined } from '@ant-design/icons'
import { Button, Tooltip } from 'antd'
import React, { useState } from 'react'
import CopyToClipboard from 'react-copy-to-clipboard'
import styled from 'styled-components'

const CopyAddress = ({ address }) => {
  const {
    firstName,
    lastName,
    shippingAddress1,
    shippingAddress2,
    shippingCity,
    shippingRegion,
    shippingCountry,
    shippingPostcode,
  } = address

  const addressAvailable =
    shippingAddress1 ||
    shippingAddress2 ||
    shippingCity ||
    shippingRegion ||
    shippingCountry ||
    shippingPostcode

  const [copied, setCopied] = useState({})

  const handleCopy = line => {
    setCopied(prev => ({
      ...prev,
      [line]: true,
    }))
    setTimeout(() => {
      setCopied(prev => ({
        ...prev,
        [line]: false,
      }))
    }, 700)
  }

  return (
    <Wrapper>
      {addressAvailable ? (
        <>
          <div className='address'>
            <div>
              <CopyToClipboard onCopy={() => handleCopy('name')} text={`${firstName} ${lastName}`}>
                <Tooltip open={!!copied.name} title='Copied to clipboard'>
                  <span className='copy name'>{`${firstName} ${lastName}`}</span>
                </Tooltip>
              </CopyToClipboard>
            </div>
            <CopyToClipboard onCopy={() => handleCopy('shippingAddress1')} text={shippingAddress1}>
              <Tooltip open={!!copied.shippingAddress1} title='Copied to clipboard'>
                <div className='copy shippingAddress1'>{shippingAddress1}</div>
              </Tooltip>
            </CopyToClipboard>
            <CopyToClipboard onCopy={() => handleCopy('shippingAddress2')} text={shippingAddress2}>
              <Tooltip open={!!copied.shippingAddress2} title='Copied to clipboard'>
                <div className='copy shippingAddress2'>{shippingAddress2}</div>
              </Tooltip>
            </CopyToClipboard>
            <div className='address-row'>
              {shippingCity && (
                <CopyToClipboard onCopy={() => handleCopy('shippingCity')} text={shippingCity}>
                  <Tooltip open={!!copied.shippingCity} title='Copied to clipboard'>
                    <div className='copy city'>
                      {shippingCity}
                      {shippingRegion && <>,</>}
                    </div>
                  </Tooltip>
                </CopyToClipboard>
              )}
              {shippingRegion && (
                <CopyToClipboard onCopy={() => handleCopy('shippingRegion')} text={shippingRegion}>
                  <Tooltip open={!!copied.shippingRegion} title='Copied to clipboard'>
                    <div className='copy region'>{shippingRegion}</div>
                  </Tooltip>
                </CopyToClipboard>
              )}
            </div>
            <div className='address-row'>
              {shippingCountry && (
                <CopyToClipboard
                  onCopy={() => handleCopy('shippingCountry')}
                  text={shippingCountry}>
                  <Tooltip open={!!copied.shippingCountry} title='Copied to clipboard'>
                    <div className='copy country'>{shippingCountry}</div>
                  </Tooltip>
                </CopyToClipboard>
              )}
              {shippingPostcode && (
                <CopyToClipboard
                  onCopy={() => handleCopy('shippingPostcode')}
                  text={shippingPostcode}>
                  <Tooltip open={!!copied.shippingPostcode} title='Copied to clipboard'>
                    <div className='copy postcode'>{shippingPostcode}</div>
                  </Tooltip>
                </CopyToClipboard>
              )}
            </div>
            <CopyToClipboard
              onCopy={() => handleCopy('all')}
              text={`${firstName} ${lastName}\n${shippingAddress1}${
                shippingAddress2 ? '\n' + shippingAddress2 : ''
              }\n${shippingCity}, ${shippingRegion}\n${shippingCountry} ${shippingPostcode}`}>
              <Tooltip open={!!copied.all} title='Copied to clipboard'>
                <Button type='secondary' icon={<CopyOutlined />}>
                  Copy Address
                </Button>
              </Tooltip>
            </CopyToClipboard>
          </div>
        </>
      ) : (
        <p className='no-address'>No address provided.</p>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  color: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  .address-row {
    display: flex;
    gap: 8px;
  }
  .copy {
    cursor: pointer;
    transition: 0.2s ease-in-out;
    &:hover {
      color: ${props => props.theme.crcoMidnight};
    }
  }
  button {
    margin-top: 20px;
  }
  .no-address {
    color: #999;
  }
`

export default CopyAddress
