import { EditOutlined, CloseCircleOutlined, SaveOutlined } from '@ant-design/icons'
import { Button, message } from 'antd'
import axios from 'axios'
import { Formik, Form } from 'formik'
import { Input } from 'formik-antd'
import React, { useContext, useState } from 'react'
import { useQuery, queryCache } from 'react-query'

import FormItem from '../../components/forms/FormItem'
import { NODE_URL } from '../../constants'
import { BrandContext } from '../../contexts/BrandContext'
import { UserContext } from '../../contexts/UserContext'

export const BrandInformation = () => {
  const { nodeToken } = useContext(UserContext)
  const { brandId, fetchBrandData } = useContext(BrandContext)
  const { data: brandData } = useQuery(['brand', brandId], fetchBrandData)

  const [editMode, setEditMode] = useState(false)

  return (
    <Formik
      initialValues={{
        instagramUsername: brandData.extraData?.instagramUsername,
        name: brandData.name,
        website: brandData.website,
        description: brandData.description,
      }}
      enableReinitialize
      onSubmit={(data, { setSubmitting }) => {
        axios
          .post(`${NODE_URL}/brand/${brandId}`, data, {
            headers: {
              Authorization: `Bearer ${nodeToken}`,
            },
          })
          .then(() => {
            message.success('Brand info updated')
            queryCache.invalidateQueries('current-user')
            queryCache.invalidateQueries(['brand', brandId])
            setEditMode(false)
          })
          .catch(err => {
            message.error(err.response.data.error)
          })
          .finally(() => {
            setSubmitting(false)
          })
      }}>
      {({ isSubmitting }) => (
        <Form className='account-section' id='brand-info'>
          <div className='section-header'>
            <h2 className='section-title'>Brand Info</h2>
            {!editMode ? (
              <Button type='link' onClick={() => setEditMode(true)}>
                <EditOutlined /> Edit
              </Button>
            ) : (
              <div className='buttons'>
                <Button danger type='link' onClick={() => setEditMode(false)}>
                  <CloseCircleOutlined /> Cancel
                </Button>
                <Button type='link' htmlType='submit' loading={isSubmitting}>
                  <SaveOutlined /> Save
                </Button>
              </div>
            )}
          </div>

          <div className='section-body'>
            <div className='form-row-wrapper'>
              <FormItem name='name' label='Company Name' width={'100%'}>
                <Input name='name' disabled={!editMode} />
              </FormItem>

              <FormItem name='website' label='Company Website' width={'100%'}>
                <Input name='website' disabled={!editMode} />
              </FormItem>
            </div>

            <FormItem name='instagramUsername' label='Instagram Handle' width={'100%'}>
              <Input prefix='@' name='instagramUsername' disabled={!editMode} />
            </FormItem>

            <FormItem name='description' label='Brand Story' rows='4' width={'100%'}>
              <Input.TextArea name='description' disabled={!editMode} rows='6' />
            </FormItem>
          </div>
        </Form>
      )}
    </Formik>
  )
}
