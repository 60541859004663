import { LoadingOutlined } from '@ant-design/icons'
import { Button, Carousel, Steps } from 'antd'
import React, { useContext, useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { Link } from 'react-router-dom'
import styled from 'styled-components/macro'

import { Footer } from '../components/Footer'
import { BOOK_DEMO_URL } from '../constants'
import { BrandContext } from '../contexts/BrandContext'
import { UserContext } from '../contexts/UserContext'
import { useDocumentTitle } from '../custom-hooks/useDocumentTitle'
import amy from '../images/team/amy.png'
import charlotte from '../images/team/charlotte.png'
import julia from '../images/team/julia.png'
import kiyana from '../images/team/kiyana.png'
import nylah from '../images/team/nylah.png'
import sinead from '../images/team/sinead.png'
import tanner from '../images/team/tanner.png'
const { Step } = Steps

export const Welcome = () => {
  useDocumentTitle('Welcome | Brand Dashboard')
  const { fetchCurrentUserData } = useContext(UserContext)
  const { brandId, fetchBrandData } = useContext(BrandContext)
  const { data: userData } = useQuery('current-user', fetchCurrentUserData)
  const { data: brandData } = useQuery(['brand', brandId], fetchBrandData)
  const [videosWatched, setVideosWatched] = useState(
    JSON.parse(localStorage.getItem('crcoDemoVideos'))
  )
  // for greeting the user based on the time of day
  const [timeOfDay, setTimeOfDay] = useState('')

  useEffect(() => {
    const getCurrentTime = () => {
      const currentHour = new Date().getHours()

      if (currentHour < 12) {
        setTimeOfDay('morning')
      } else if (currentHour < 18) {
        setTimeOfDay('afternoon')
      } else {
        setTimeOfDay('evening')
      }
    }

    getCurrentTime()
  }, [])

  // intersection observer for scrolling fade-in animations
  useEffect(() => {
    const faders = document.querySelectorAll('.fade-in')

    const appearOptions = {
      // threshold controls how much of the target should be visible before the effect runs
      threshold: 0.3,
    }

    const appearOnScroll = new IntersectionObserver(function (entries, appearOnScroll) {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('appear')
          appearOnScroll.unobserve(entry.target)
        }
      })
    }, appearOptions)

    faders.forEach(fader => {
      appearOnScroll.observe(fader)
    })
  })

  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
  }

  // TUTORIAL STEPS
  const items = [
    { title: 'Build a campaign' },
    { title: 'Identify the right Creators' },
    { title: 'Start collaborating' },
    { title: 'Drive long-term success' },
  ]

  // YOUTUBE IFRAME API
  const onPlayerStateChange = event => {
    if (event.data === 0) {
      if (event.target.h.id === 'video1') {
        setVideosWatched(prev => ({ ...prev, video1: true }))
      } else if (event.target.h.id === 'video2') {
        setVideosWatched(prev => ({ ...prev, video2: true }))
      } else if (event.target.h.id === 'video3') {
        setVideosWatched(prev => ({ ...prev, video3: true }))
      } else if (event.target.h.id === 'video4') {
        setVideosWatched(prev => ({ ...prev, video4: true }))
      }
    }
  }

  useEffect(() => {
    if (videosWatched) {
      localStorage.setItem('crcoDemoVideos', JSON.stringify(videosWatched))
    }
  }, [videosWatched])

  new window.YT.Player('video1', {
    videoId: 'Mhy_kpQgO-U',
    events: {
      onStateChange: onPlayerStateChange,
    },
  })
  new window.YT.Player('video2', {
    videoId: '2gvJ7Q_VzsA',
    events: {
      onStateChange: onPlayerStateChange,
    },
  })
  new window.YT.Player('video3', {
    videoId: 'vyd4jH_2dsM',
    events: {
      onStateChange: onPlayerStateChange,
    },
  })
  new window.YT.Player('video4', {
    videoId: '0jILSU7Wjyk',
    events: {
      onStateChange: onPlayerStateChange,
    },
  })

  return (
    <Wrapper>
      <section className='page-header'>
        <div className='banner'>
          <div className='text'>
            <h1>
              Good {timeOfDay}, {userData?.firstName}!
            </h1>
            <p>
              Welcome to your brand dashboard. Here you can find everything you need to help you get
              started with influencer marketing and track your campaigns.
            </p>
          </div>
        </div>
        <div className='brand-info'>
          <p className='subheader'>Your Brand Highlights</p>
          {brandData ? (
            <>
              <p className='name'>{brandData?.name}</p>
              {brandData?.website && (
                <a className='website' href={brandData.website}>
                  {brandData?.website}
                </a>
              )}
              <div className='divider' />
              <p>
                <b>Campaign Specialist:</b>{' '}
                {brandData?.dedicatedSpecialist
                  ? `${brandData.dedicatedSpecialist.firstName} ${brandData.dedicatedSpecialist.lastName}`
                  : 'None'}
              </p>
              <p>
                <b>{brandData?.campaigns?.length}</b> campaigns
              </p>
            </>
          ) : (
            <LoadingOutlined spin />
          )}
        </div>
      </section>

      <section className='tutorial-videos'>
        <div className='tutorial-nav'>
          <Steps
            current={
              !videosWatched?.video1
                ? 0
                : !videosWatched?.video2
                  ? 1
                  : !videosWatched?.video3
                    ? 2
                    : !videosWatched?.video4
                      ? 3
                      : null
            }
            size='small'
            labelPlacement='vertical'
            onChange={() => {
              // need to include this onChange prop for hover effect, don't remove
            }}>
            {items.map((item, index) => (
              <Step
                key={index}
                title={item.title}
                status={videosWatched?.[`video${index + 1}`] && 'finish'}
                onClick={() => {
                  // scroll to video
                  document.getElementById(`video${index + 1}`).scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                  })
                }}
              />
            ))}
          </Steps>
        </div>

        <div className='tutorial fade-in'>
          <div className='video'>
            <div id='video1' />
          </div>
          <div className='text'>
            <h2 className=''>Build a campaign</h2>
            <p>
              Build a channel-specific campaign and once it&apos;s live, you&apos;ll be able to
              invite any creators to participate. Additionally, over 175,000 creators in our
              community will have the option to opt-in if they&apos;re interested!
            </p>
            <Button type='primary'>
              <Link to='/campaigns'>
                {brandData?.activeSubscriptions?.length ? 'View Dashboard' : 'Try Now'}
              </Link>
            </Button>
          </div>
        </div>
        <div className='tutorial fade-in'>
          <div className='video'>
            <div id='video2' />
          </div>
          <div className='text'>
            <h2 className=''>Identify the right Creators</h2>
            <p>
              Discover over 300 million creators on Instagram, TikTok, or YouTube whom you can
              invite to participate in your campaign. Before doing so, review our deep insights
              report to ensure that their content aligns with the values of{' '}
              {brandData?.name || 'your brand'}.
            </p>
            <Link to='/creator-search'>
              <Button type='primary'>
                {brandData?.activeSubscriptions?.length ? 'Search Creators' : 'Try Now'}
              </Button>
            </Link>
          </div>
        </div>
        <div className='tutorial fade-in'>
          <div className='video'>
            <div id='video3' />
          </div>
          <div className='text'>
            <h2 className=''>Start collaborating</h2>
            <p>
              We&apos;ve made campaign management easy with our platform. You can easily review,
              chat, negotiate with, and activate your favorite influencers all in one place.
              Creator.co automatically pulls in the posts, assets, and associated KPIs to populate
              your dashboard, streamlining the process even further.
            </p>
            {!brandData?.activeSubscriptions?.length && (
              <Link to='/pricing'>
                <Button type='primary'>Subscribe</Button>
              </Link>
            )}
          </div>
        </div>
        <div className='tutorial fade-in'>
          <div className='video'>
            <div id='video4' />
          </div>
          <div className='text'>
            <h2 className=''>Drive long-term success</h2>
            <p>
              Collaborate with our influencer marketing experts to build a customized strategy,
              recruit top-performing creators, and achieve a high return on ad spend (ROAS). With
              their extensive knowledge and experience in the industry, our experts will ensure you
              meet your goals.
            </p>
            <a href={BOOK_DEMO_URL} target='_blank' rel='noopener noreferrer'>
              <Button type='primary'>Book Demo</Button>
            </a>
          </div>
        </div>
      </section>

      <section className='page-footer'>
        <div className='team-intro'>
          <h3>Meet Your Success Team</h3>
          <p className='text'>
            We help some of the world&apos;s top brands build and execute successful creator
            strategies.
          </p>
          <Carousel {...sliderSettings}>
            <div className='team-member'>
              <div className='inner'>
                <img src={nylah} alt='' />
              </div>
            </div>
            <div className='team-member'>
              <div className='inner'>
                <img src={kiyana} alt='' />
              </div>
            </div>
            <div className='team-member'>
              <div className='inner'>
                <img src={julia} alt='' />
              </div>
            </div>
            <div className='team-member'>
              <div className='inner'>
                <img src={tanner} alt='' />
              </div>
            </div>
            <div className='team-member'>
              <div className='inner'>
                <img src={sinead} alt='' />
              </div>
            </div>
            <div className='team-member'>
              <div className='inner'>
                <img src={charlotte} alt='' />
              </div>
            </div>
            <div className='team-member'>
              <div className='inner'>
                <img src={amy} alt='' />
              </div>
            </div>
          </Carousel>
        </div>
      </section>
      <Footer />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  // MOBILE
  background: #f4f4f8;
  color: #23262f;
  max-width: 100vw;
  font-family: 'Poppins-Regular', sans-serif;
  section {
    margin: 0 auto;
    max-width: 1200px;
  }
  .page-header,
  .page-footer {
    display: flex;
    flex-direction: column;
  }
  .banner {
    background: #dcecfb;
    padding: 40px 20px;
    margin: 20px;
    border-radius: 20px;
    .text {
      max-width: 500px;
    }
    h1 {
      font-family: 'Gilroy-ExtraBold', sans-serif;
      font-size: 2rem;
      margin: 10px 0;
      .heart-font {
        font-family: 'Campton-SemiBold-Heart', Verdana, sans-serif;
      }
    }
    p {
      font-size: 1rem;
      margin: 20px 0;
      color: #777e90;
    }
  }

  .brand-info {
    background: #ece5ff;
    border-radius: 20px;
    padding: 40px 20px;
    margin: 0 20px;
    .subheader {
      font-family: 'Gilroy-ExtraBold', sans-serif;
      font-size: 1rem;
      color: #777e90;
      margin-bottom: 20px;
      text-transform: uppercase;
    }
    .divider {
      width: 100%;
      height: 1px;
      background: #000;
      margin: 20px 0;
      opacity: 0.1;
    }
    p {
      margin: 0;
    }
    .name {
      font-size: 1.2rem;
    }
    .website {
      font-size: 0.8rem;
    }
  }

  .fade-in {
    opacity: 0;
    transform: translateY(25%);
    transition: 0.6s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  }
  .fade-in.appear {
    opacity: 1;
    transform: translateY(0);
  }

  .tutorial-videos {
    display: flex;
    flex-direction: column;
    gap: 40px;
    margin: 20px auto;
    padding: 20px;
    .tutorial-nav {
      background: #fff;
      padding: 20px 10px;
      border-radius: 10px;
      border: 1px solid #e6e6e6;
    }
    .ant-steps-item-title {
      line-height: 1.2;
      font-size: 0.9rem;
    }
    .ant-steps-item {
      &:last-child {
        .ant-steps-item-content {
          min-height: 0;
        }
      }
    }

    .tutorial {
      background: #fff;
      border: 2px solid #f3f3f3;
      box-shadow: 4px 8px 20px 4px rgba(154, 175, 193, 0.12);
      border-radius: 20px;
      display: flex;
      flex-direction: column;
      gap: 20px;
      padding: 20px;
      .video {
        aspect-ratio: 5/3;
        iframe {
          height: 100%;
          width: 100%;
          border-radius: 10px;
          border: none;
        }
      }
      .text {
        flex: 1;
      }
      h2 {
        font-size: 1.2rem;
        font-weight: bold;
      }
      p {
        color: #777e90;
        font-family: 'Avenir', Verdana, sans-serif;
        font-weight: 600;
      }
      button {
        background: #23262f;
        color: #fff;
        border: none;
        padding: 8px 30px;
        font-size: 1rem;
        transition: 0.2s ease-in-out;
        height: 42px;
        &:hover {
          background: ${props => props.theme.crcoTechBlue};
        }
      }
    }
  }
  .team-intro {
    background: #dcecfb;
    padding: 40px 20px;
    border-radius: 20px;
    margin: 20px;
    h3 {
      font-family: 'Gilroy-ExtraBold', sans-serif;
    }
    .text {
      color: #777e90;
      font-size: 1rem;
      margin: 30px 0;
    }
    .team-member {
      .inner {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        gap: 8px;
        img {
          border: 3px solid #fff;
          height: 75px;
          width: 75px;
          border-radius: 50%;
        }
      }
    }
  }
  .misc-info {
    background: #e6e6e6;
    color: #666;
    border-radius: 20px 20px 0 0;
    display: flex;
    flex-direction: column;
    padding: 30px;
    h4 {
      font-weight: bold;
      font-size: 1rem;
      margin-bottom: 20px;
    }
    .social-links {
      margin: 20px 0;
      display: flex;
      gap: 20px;
      .platform {
        .icon {
          opacity: 0.4;
          height: 20px;
          transition: 0.2s ease-in-out;
          &:hover {
            opacity: 0.6;
          }
        }
      }
    }
  }
  // TABLET
  @media only screen and (min-width: ${props => props.theme.breakpointTablet}) {
    padding-top: 20px;
    .page-header {
      flex-direction: row;
      gap: 20px;
      padding: 20px;
      .banner {
        flex: 2;
        margin: 0;
      }
      .brand-info {
        flex: 1;
        margin: 0;
      }
    }
    .tutorial-videos {
      .tutorial-nav {
        position: sticky;
        top: 0;
        z-index: 1;
      }
      .tutorial {
        flex-direction: row;
        padding: 30px;
        gap: 30px;
        font-size: 1rem;
        .video {
          flex: 1;
        }
        &:nth-child(odd) {
          flex-direction: row-reverse;
        }
      }
    }
    .page-footer {
      margin-bottom: 20px;
    }
    .team-intro {
      text-align: center;
      .text {
        max-width: 400px;
        margin: 30px auto;
        font-size: 1rem;
      }
      .team-member .inner img {
        height: 150px;
        width: 150px;
      }
    }
  }
  // DESKTOP
  @media only screen and (min-width: ${props => props.theme.breakpointDesktop}) {
    .page-header {
      gap: 30px;
      .banner {
        padding: 40px;
      }
      .brand-info {
        padding: 40px;
      }
    }
    .tutorial-videos {
      .tutorial {
        align-items: center;
        justify-content: space-evenly;
        padding: 40px;
        .text {
          max-width: 400px;
          p {
            margin: 30px 0;
          }
        }
        .video {
          max-width: 500px;
        }
      }
    }
    .page-footer {
      flex-direction: row;
      gap: 30px;
      margin: 0 auto 20px auto;
      padding: 20px;
      .team-intro {
        padding: 40px;
        flex: 2;
        margin: 0;
        .ant-carousel {
          max-width: 800px;
          margin: auto;
        }
        .team-member .inner img {
          height: 160px;
          width: 160px;
        }
      }
      .misc-info {
        padding: 40px;
        flex: 1;
        border-radius: 10px;
        h4 {
          margin-bottom: 30px;
        }
        a {
          width: fit-content;
        }
        .social-links {
          margin-top: auto;
        }
      }
    }
  }
`
