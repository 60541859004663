import { LoadingOutlined, SearchOutlined } from '@ant-design/icons'
import { Input as AntInput, List, Checkbox, message } from 'antd'
import axios from 'axios'
import React, { useContext, useEffect, useState } from 'react'
import { queryCache, useInfiniteQuery, useQuery } from 'react-query'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
import styled from 'styled-components'

import { NODE_URL } from '../../constants'
import { BrandContext } from '../../contexts/BrandContext'
import shopifyIcon from '../../images/icons/shopify-icon.svg'

const ShopifyProducts = () => {
  const { campaignId } = useParams()
  const { brandId, fetchBrandData } = useContext(BrandContext)
  const { data: brandData } = useQuery(['brand', brandId], fetchBrandData)

  const [search, setSearch] = useState('')
  const [selectLoading, setSelectLoading] = useState(false)
  const [products, setProducts] = useState([])
  const [totalSelected, setTotalSelected] = useState(0)

  const {
    data: productsData,
    status: productsStatus,
    isFetchingMore,
    fetchMore,
    canFetchMore,
  } = useInfiniteQuery(
    ['shopify-products', brandId, search],
    async (key, brandId, search, page = 1) => {
      const data = await axios
        .get(`${NODE_URL}/brand/${brandId}/campaign/${campaignId}/shopify/products/${page}`, {
          params: {
            search,
          },
        })
        .then(res => res.data)
      return data
    },
    { getFetchMore: lastGroup => lastGroup.nextPage }
  )

  useEffect(() => {
    const flatProducts = productsData
      ?.flatMap(group => group.products)
      .map(product => ({
        ...product,
        selected: !!product.campaigns.find(id => id == campaignId),
      }))
    setProducts(flatProducts)
    setTotalSelected(productsData?.[0]?.totalSelected)
  }, [productsData])

  const handleScroll = e => {
    if (e.target.scrollTop + e.target.clientHeight === e.target.scrollHeight && canFetchMore) {
      fetchMore()
    }
  }

  const searchRef = React.useRef(0)
  const handleSearch = e => {
    const search = e.target.value

    searchRef.current += 1
    const currentSearch = searchRef.current
    setTimeout(() => {
      if (currentSearch === searchRef.current) {
        setSearch(search)
      }
    }, 500)
  }

  const handleSelectProduct = (selected, productId = undefined) => {
    if (!productId) {
      setTotalSelected()
    }
    setProducts(prev =>
      prev.map(product => {
        const isSelected = productId
          ? productId === product.id
            ? selected
            : product.selected
          : selected
        return {
          ...product,
          selected: isSelected,
        }
      })
    )

    setSelectLoading(true)
    axios
      .post(`${NODE_URL}/brand/${brandId}/campaign/${campaignId}/shopify/products`, {
        selected,
        productId,
      })
      .then(() => {
        queryCache.invalidateQueries('shopify-products')
        setSelectLoading(false)
      })
      .catch(() => {
        message.error('Something went wrong')
      })
  }

  return (
    <Wrapper>
      <div className='products'>
        <div className='products-header'>
          <span className='title'>Your Shopify Products</span>
          <AntInput
            placeholder='Search products'
            prefix={<SearchOutlined />}
            allowClear
            onChange={handleSearch}
          />
        </div>
        <div className='products-body'>
          {productsStatus === 'success' &&
            (products?.length ? (
              <>
                <div className='select-all'>
                  <label>
                    <span>Select all</span>
                    {!selectLoading ? (
                      <Checkbox
                        indeterminate={
                          totalSelected && totalSelected < brandData.totalShopifyProducts
                        }
                        checked={totalSelected === brandData.totalShopifyProducts}
                        onChange={e => handleSelectProduct(e.target.checked)}
                        loading={selectLoading}
                      />
                    ) : (
                      <div className='select-all-loading'>
                        <LoadingOutlined spin />
                      </div>
                    )}
                  </label>
                </div>
                <List
                  className='products-list'
                  onScroll={handleScroll}
                  loading={isFetchingMore}
                  dataSource={products}
                  rowKey={product => product.id}
                  renderItem={product => (
                    <List.Item key={product.id} className='product'>
                      <img
                        className='product-image'
                        src={product.imageUrl || shopifyIcon}
                        alt={product.title}
                      />
                      <span className='product-title'>{product.title}</span>
                      <Checkbox
                        checked={product.selected}
                        onChange={e => handleSelectProduct(e.target.checked, product.id)}
                      />
                    </List.Item>
                  )}
                />
              </>
            ) : (
              <div>
                No products found on your store. Please add products to your store or connect your
                store and try again.
              </div>
            ))}
          {productsStatus === 'loading' && (
            <div className='loading'>
              <LoadingOutlined spin />
            </div>
          )}
        </div>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  margin-bottom: 24px;

  .products-header {
    .title {
      display: block;
      font-size: 16px;
      font-weight: bold;
      margin-bottom: 12px;
    }
    margin-bottom: 12px;
  }

  .select-all {
    display: flex;
    justify-content: flex-end;
    margin-right: 32px;

    & > label {
      cursor: pointer;
      margin-bottom: 0;
      display: flex;
      align-items: center;

      & > span {
        margin-right: 8px;
      }
    }

    .select-all-loading {
      width: 16px;
      height: 16px;
    }
  }

  .products-list {
    max-height: 600px;
    overflow-y: auto;

    .product {
      padding: 12px;
      display: flex;
      justify-content: flex-start;
      gap: 16px;
      .product-image {
        width: 50px;
        height: 50px;
        border-radius: 50%;
      }
      .product-title {
        flex: 1;
        font-size: 16px;
        font-weight: bold;
      }
      .variants {
        display: flex;
        flex-direction: column;
        gap: 12px;
        .variant {
          cursor: pointer;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          gap: 8px;
        }
      }
    }
  }
`

export default ShopifyProducts
