import axios from 'axios'
import React, { createContext, useState } from 'react'

import { NODE_URL } from '../constants'

export const GlobalContext = createContext(null)

export const GlobalContextProvider = props => {
  const [menuExpanded, setMenuExpanded] = useState(false)
  const toggleMenu = () => setMenuExpanded(!menuExpanded)

  const getCategories = async () => {
    const { data } = await axios.get(`${NODE_URL}/categories`)
    return data
  }

  const contextValues = {
    menuExpanded,
    toggleMenu,
    getCategories,
  }

  return <GlobalContext.Provider value={contextValues}>{props.children}</GlobalContext.Provider>
}
