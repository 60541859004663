import { UnorderedListOutlined, PlusOutlined, LoadingOutlined } from '@ant-design/icons'
import { Button, message } from 'antd'
import axios from 'axios'
import React, { useContext, useState } from 'react'
import { queryCache, useQuery } from 'react-query'
import styled from 'styled-components'

import { NODE_URL } from '../../constants'
import { BrandContext } from '../../contexts/BrandContext'
import { UserContext } from '../../contexts/UserContext'

const AddContacts = () => {
  const { nodeToken } = useContext(UserContext)
  const { brandId, fetchBrandData } = useContext(BrandContext)
  const { data: brandData, status } = useQuery(['brand', brandId], fetchBrandData)
  const [loading, setLoading] = useState(false)

  const addContactsChargebee = () => {
    setLoading(true)
    var chargebeeInstance = window.Chargebee.getInstance()
    chargebeeInstance.closeAll()
    axios
      .get(`${NODE_URL}/checkout-contacts/${brandId}`, {
        headers: { Authorization: `Bearer ${nodeToken}` },
      })
      .then(res => {
        setLoading(false)
        chargebeeInstance.openCheckout({
          hostedPage: () => {
            return new Promise(function (resolve) {
              resolve(res.data)
            })
          },
          error: () => {
            message.error('Error opening checkout.')
          },
          success: hostedPageId => {
            axios
              .post(
                `${NODE_URL}/checkout-contacts/${brandId}`,
                { hostedPageId },
                {
                  headers: { Authorization: `Bearer ${nodeToken}` },
                }
              )
              .then(() => {
                chargebeeInstance.closeAll()
                queryCache.refetchQueries(['brand'])
                message.success('Contacts Added')
              })
              .catch(() => {
                message.error('Error adding credits.')
              })
          },
          close: () => {}, // TODO: cleanup empty callback?
        })
      })
      .catch(() => {
        setLoading(false)
        message.error('Sorry, something went wrong.')
      })
  }

  return (
    <Wrapper>
      <div className='icon'>
        <UnorderedListOutlined />
      </div>

      <div className='details'>
        <p className='label'>Contact Credits</p>
        <div className='value-wrapper'>
          {status === 'success' ? (
            <>
              <p className='value'>{brandData.searchContacts?.toLocaleString() || 0}</p>
              <Button
                className='add-btn'
                loading={loading}
                type='link'
                icon={<PlusOutlined />}
                onClick={addContactsChargebee}>
                Top Up
              </Button>
            </>
          ) : (
            <LoadingOutlined spin />
          )}
        </div>
      </div>
    </Wrapper>
  )
}

export const Wrapper = styled.div`
  background: #fff;
  border-radius: 10px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  flex: 1;
  .icon {
    font-size: 2rem;
    color: ${props => props.theme.crcoLavender};
  }
  .details {
    color: ${props => props.theme.crcoGrey};
    margin-top: 5px;
  }
  p {
    margin: 0;
  }
  .value-wrapper {
    color: ${props => props.theme.crcoMidnight};
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .value {
    margin-right: 10px;
    font-weight: bold;
    font-size: 1.5rem;
  }
  button.ant-btn.ant-btn-link {
    padding: 0;
  }

  @media only screen and (min-width: ${props => props.theme.breakpointTablet}) {
    .value-wrapper {
      flex-direction: row;
    }
  }

  @media only screen and (min-width: ${props => props.theme.breakpointDesktop}) {
    flex-direction: row;
    gap: 40px;
  }
`

export default AddContacts
