import { Empty } from 'antd'
import React from 'react'
import * as yup from 'yup'

import { BrandIdFilter } from '../filters/BrandIdFilter'
import { CampaignStatusFilter } from '../filters/CampaignStatusFilter'
import { SocialChannelFilter } from '../filters/SocialChannelFilter'
import { ManageWidget } from '../ManageWidget'
import { MetricsWrapper, WidgetCard } from '../WidgetCard'

export const TotalCampaigns = ({ widget, activeView, setActiveView, brandData, data }) => {
  // default values for this widget (used when resetting widget)
  const defaultValues = {
    title: widget.defaultTitle,
    ...widget.defaultFilters,
  }

  // current settings for this widget (used for formik)
  const initialValues = {
    title: widget.title || defaultValues.title,
    brandIds: widget.filters?.brandIds || defaultValues.brandIds,
    campaignStatuses: widget.filters?.campaignStatuses || defaultValues.campaignStatuses,
    socialChannels: widget.filters?.socialChannels || defaultValues.socialChannels,
    visible: widget.visible,
  }

  const validationSchema = yup.object().shape({
    title: yup.string().required('Required').max(50, 'Must be 50 characters or less'),
    brandIds: yup.array(),
    campaignStatuses: yup.array(),
    socialChannels: yup.array(),
  })

  // data to be sent to backend when updating widget
  const constructData = values => {
    return {
      title: values.title,
      filters: {
        brandIds: values.brandIds,
        campaignStatuses: values.campaignStatuses,
        socialChannels: values.socialChannels,
      },
      visible: values.visible,
    }
  }

  // unique widget filters
  const Filters = () => {
    return (
      <>
        {!!brandData.childBrands?.length && <BrandIdFilter brandData={brandData} />}
        <CampaignStatusFilter />
        <SocialChannelFilter />
      </>
    )
  }

  const Metrics = () => {
    return (
      <MetricsWrapper>
        {data && !!Object.keys(data)?.length ? (
          <p className='value primary'>{data?.totalCampaigns || 0}</p>
        ) : (
          <div className='no-data'>
            <Empty description='No data.' />
          </div>
        )}
      </MetricsWrapper>
    )
  }

  const settingsProps = {
    widget,
    Filters,
    defaultValues,
    initialValues,
    validationSchema,
    constructData,
    activeView,
    setActiveView,
    brandId: brandData.id,
  }

  const cardProps = {
    widget,
    settings: <ManageWidget {...settingsProps} />,
    metrics: <Metrics />,
    brandData,
  }

  return <WidgetCard {...cardProps} />
}
