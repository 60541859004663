import { MailOutlined } from '@ant-design/icons'
import React from 'react'
import styled from 'styled-components/macro'

export const CreatorSearchGuide = () => {
  return (
    <Wrapper>
      <section>
        <h2>How it Works</h2>
        <p>
          Are you searching for the perfect creators to collaborate with on your campaigns? Our
          Creator Search feature makes it easy to find, invite, and save profiles of potential
          influencers. Additionally, our deep insights provide comprehensive data about their social
          media channels and metrics, simplifying your decision-making process.
        </p>
        <p>
          Currently, our platform supports <b>Instagram, TikTok, and YouTube</b>.
        </p>
      </section>
      <section>
        <h2>Using Credits</h2>
        <p>
          Your subscription plan includes a set number of credits, which you can use for accessing
          deep insights and contact information. If you find that you&apos;re running low on
          credits, you can top them up as needed.
        </p>
        <h3>Deep Insights</h3>
        <p>
          Our unique algorithm provides valuable insights on creators. It helps you identify
          potential issues such as unusual social growth or suspicious engagement patterns.
          Additionally, it provides a clear understanding of the creator&apos;s audience
          demographics, ensuring that your product reaches your target audience effectively.
        </p>
        <h3>Contacts / Creator Lists</h3>
        <p>
          Unlock contact information for creators when you add them to your lists and streamline
          your outreach process. Each saved creator requires a contact credit. Please note, you can
          only unlock 100 contacts at a time but there is no limit to the number of creators in a
          list.
        </p>
        <p>
          You may also use our built-in email to send out your collaboration invites by opening a
          profile and clicking the{' '}
          <span className='blue'>
            <MailOutlined /> Invite
          </span>{' '}
          button.
        </p>
      </section>
    </Wrapper>
  )
}

export const Wrapper = styled.div`
  padding: 20px;
  h2,
  h3 {
    font-family: 'Gilroy-ExtraBold', sans-serif;
  }
  h2 {
    font-size: 20px;
  }
  h3 {
    font-size: 17px;
  }
  section {
    margin-bottom: 30px;
  }
  p {
    font-size: 14px;
    line-height: 1.5;
    color: ${props => props.theme.crcoGrey};
  }
  .blue {
    color: ${props => props.theme.crcoTechBlue};
  }
`
