import { AffiliateGenericEvents } from './widgets/AffiliateGenericEvents'
import { AffiliateSalesVsPosts } from './widgets/AffiliateSalesVsPosts'
import { AudienceAges } from './widgets/AudienceAges'
import { AudienceBrandAffinity } from './widgets/AudienceBrandAffinity'
import { AudienceEthnicity } from './widgets/AudienceEthnicity'
import { AudienceGender } from './widgets/AudienceGender'
import { AudienceInterests } from './widgets/AudienceInterests'
import { AudienceLanguages } from './widgets/AudienceLanguages'
import { AudienceLocation } from './widgets/AudienceLocation'
import { AudienceReachability } from './widgets/AudienceReachability'
import { CampaignPerformance } from './widgets/CampaignPerformance'
import { ContentEngagement } from './widgets/ContentEngagement'
import { CreatorStats } from './widgets/CreatorStats'
import { GoogleAnalyticsSalesVsPosts } from './widgets/GoogleAnalyticsSalesVsPosts'
import { InfluencerBrandAffinity } from './widgets/InfluencerBrandAffinity'
import { ShopifyDiscountCodeSales } from './widgets/ShopifyDiscountCodeSales'
import { TotalCampaigns } from './widgets/TotalCampaigns'
import { TotalContent } from './widgets/TotalContent'
import { TotalPayouts } from './widgets/TotalPayouts'
import { TotalROI } from './widgets/TotalROI'

export const widgetComponents = [
  // NOTE: componentIds are stored in backend so do not change
  {
    componentId: 'TotalCampaigns',
    component: TotalCampaigns,
    defaultTitle: 'Total Campaigns',
    defaultFilters: {
      brandIds: [],
      campaignStatuses: [],
      socialChannels: [],
    },
  },
  {
    componentId: 'TotalContent',
    component: TotalContent,
    defaultTitle: 'Total Content',
    defaultFilters: {
      brandIds: [],
      campaignIds: [],
      socialChannels: [],
      fromDate: null,
      toDate: null,
      dateRange: 'allTime',
    },
  },
  {
    componentId: 'TotalPayouts',
    component: TotalPayouts,
    defaultTitle: 'Total Payouts',
    defaultFilters: {
      brandIds: [],
      campaignIds: [],
      socialChannels: [],
      fromDate: null,
      toDate: null,
      dateRange: 'allTime',
    },
  },
  {
    componentId: 'TotalROI',
    component: TotalROI,
    defaultTitle: 'Total ROI',
    defaultFilters: {
      brandIds: [],
      campaignIds: [],
      socialChannels: [],
    },
  },
  {
    componentId: 'CampaignPerformance',
    component: CampaignPerformance,
    defaultTitle: 'Campaign Performance Snapshot',
    defaultFilters: {
      brandIds: [],
      campaignStatuses: [],
      socialChannels: [],
      fromDate: null,
      toDate: null,
      dateRange: 'allTime',
    },
  },
  {
    componentId: 'ContentEngagement',
    component: ContentEngagement,
    defaultTitle: 'Content Engagement Snapshot',
    defaultFilters: {
      brandIds: [],
      campaignIds: [],
      fromDate: null,
      toDate: null,
      dateRange: 'allTime',
    },
  },
  {
    componentId: 'CreatorStats',
    component: CreatorStats,
    defaultTitle: 'Creator Stats',
    defaultFilters: {
      brandIds: [],
      campaignIds: [],
      socialChannels: [],
    },
  },
  // {
  //   componentId: 'TotalAudience',
  //   component: TotalAudience,
  //   defaultTitle: 'Total Audience',
  //   defaultFilters: {
  //     brandIds: [],
  //     campaignIds: [],
  //     socialChannels: [],
  //   },
  // },
  // {
  //   componentId: 'InfluencerAges',
  //   component: InfluencerAges,
  //   defaultTitle: 'Influencer Ages',
  //   defaultFilters: {
  //     brandIds: [],
  //     campaignIds: [],
  //     socialChannels: [],
  //   },
  // },
  {
    componentId: 'AudienceAges',
    component: AudienceAges,
    defaultTitle: 'Audience Ages',
    defaultFilters: {
      brandIds: [],
      campaignIds: [],
      socialChannels: [],
    },
  },
  // {
  //   componentId: 'InfluencerLanguages',
  //   component: InfluencerLanguages,
  //   defaultTitle: 'Influencer Languages',
  //   defaultFilters: {
  //     brandIds: [],
  //     campaignIds: [],
  //     socialChannels: [],
  //   },
  // },
  {
    componentId: 'AudienceLanguages',
    component: AudienceLanguages,
    defaultTitle: 'Audience Languages',
    defaultFilters: {
      brandIds: [],
      campaignIds: [],
      socialChannels: [],
    },
  },
  {
    componentId: 'InfluencerBrandAffinity',
    component: InfluencerBrandAffinity,
    defaultTitle: 'Influencer Brand Affinity',
    defaultFilters: {
      brandIds: [],
      campaignIds: [],
      socialChannels: [],
    },
  },
  {
    componentId: 'AudienceBrandAffinity',
    component: AudienceBrandAffinity,
    defaultTitle: 'Audience Brand Affinity',
    defaultFilters: {
      brandIds: [],
      campaignIds: [],
      socialChannels: [],
    },
  },
  // {
  //   componentId: 'InfluencerInterests',
  //   component: InfluencerInterests,
  //   defaultTitle: 'Influencer Interests',
  //   defaultFilters: {
  //     brandIds: [],
  //     campaignIds: [],
  //     socialChannels: [],
  //   },
  // },
  {
    componentId: 'AudienceInterests',
    component: AudienceInterests,
    defaultTitle: 'Audience Interests',
    defaultFilters: {
      brandIds: [],
      campaignIds: [],
      socialChannels: [],
    },
  },
  // {
  //   componentId: 'InfluencerLocation',
  //   component: InfluencerLocation,
  //   defaultTitle: 'Influencer Location',
  //   defaultFilters: {
  //     brandIds: [],
  //     campaignIds: [],
  //     socialChannels: [],
  //   },
  // },
  {
    componentId: 'AudienceLocation',
    component: AudienceLocation,
    defaultTitle: 'Audience Location',
    defaultFilters: {
      brandIds: [],
      campaignIds: [],
      socialChannels: [],
    },
  },
  // {
  //   componentId: 'InfluencerGender',
  //   component: InfluencerGender,
  //   defaultTitle: 'Influencer Gender',
  //   defaultFilters: {
  //     brandIds: [],
  //     campaignIds: [],
  //     socialChannels: [],
  //   },
  // },
  {
    componentId: 'AudienceGender',
    component: AudienceGender,
    defaultTitle: 'Audience Gender',
    defaultFilters: {
      brandIds: [],
      campaignIds: [],
      socialChannels: [],
    },
  },
  {
    componentId: 'AudienceReachability',
    component: AudienceReachability,
    defaultTitle: 'Audience Reachability',
    defaultFilters: {
      brandIds: [],
      campaignIds: [],
      socialChannels: [],
    },
  },
  {
    componentId: 'AudienceEthnicity',
    component: AudienceEthnicity,
    defaultTitle: 'Audience Ethnicity',
    defaultFilters: {
      brandIds: [],
      campaignIds: [],
      socialChannels: [],
    },
  },
  // {
  //   componentId: 'AudienceAgeGender',
  //   component: AudienceAgeGender,
  //   defaultTitle: 'Audience Age & Gender Split',
  //   defaultFilters: {
  //     brandIds: [],
  //     campaignIds: [],
  //     socialChannels: [],
  //   },
  // },
  {
    componentId: 'AffiliateSalesVsPosts',
    component: AffiliateSalesVsPosts,
    defaultTitle: 'Affiliate Sales vs Posts',
    defaultFilters: {
      brandIds: [],
      campaignIds: [],
      fromDate: null,
      toDate: null,
      dateRange: 'allTime',
    },
  },
  {
    componentId: 'GoogleAnalyticsSalesVsPosts',
    component: GoogleAnalyticsSalesVsPosts,
    defaultTitle: 'Google Analytics Sales vs Posts',
    defaultFilters: {
      brandIds: [],
      campaignIds: [],
      fromDate: null,
      toDate: null,
      dateRange: 'allTime',
    },
  },
  {
    componentId: 'AffiliateAwinEvents',
    component: AffiliateGenericEvents('awin'),
    defaultTitle: 'Affiliate Awin Events',
    defaultFilters: {
      brandIds: [],
      campaignIds: [],
      fromDate: null,
      toDate: null,
      dateRange: 'allTime',
    },
  },
  {
    componentId: 'AffiliateImpactEvents',
    component: AffiliateGenericEvents('impact'),
    defaultTitle: 'Affiliate Impact Events',
    defaultFilters: {
      brandIds: [],
      campaignIds: [],
      fromDate: null,
      toDate: null,
      dateRange: 'allTime',
    },
  },
  {
    componentId: 'AffiliateInternalEvents',
    component: AffiliateGenericEvents('internal'),
    defaultTitle: 'Affiliate Internal Events',
    defaultFilters: {
      brandIds: [],
      campaignIds: [],
      fromDate: null,
      toDate: null,
      dateRange: 'allTime',
    },
  },
  {
    componentId: 'AffiliateRakutenEvents',
    component: AffiliateGenericEvents('rakuten', { disableCampaignFilter: true }),
    defaultTitle: 'Affiliate Rakuten Events',
    defaultFilters: {
      brandIds: [],
      fromDate: null,
      toDate: null,
      dateRange: 'allTime',
    },
  },
  {
    componentId: 'AffiliateShareasaleEvents',
    component: AffiliateGenericEvents('shareasale'),
    defaultTitle: 'Affiliate ShareASale Events',
    defaultFilters: {
      brandIds: [],
      fromDate: null,
      toDate: null,
      dateRange: 'allTime',
    },
  },
  {
    componentId: 'ShopifyDiscountCodeSales',
    component: ShopifyDiscountCodeSales,
    defaultTitle: 'Shopify Coupon Sales',
    defaultFilters: {
      brandIds: [],
      campaignIds: [],
      fromDate: null,
      toDate: null,
      dateRange: 'allTime',
    },
  },
]
