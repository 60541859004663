import { Carousel } from 'antd'
import React from 'react'
import styled from 'styled-components/macro'

import breinfuel from '../../images/logos/breinfuel-black.svg'
import laidbacksnacks from '../../images/logos/laidbacksnacks-black.svg'
import zerowater from '../../images/logos/zerowater-black.svg'
import background from '../../images/pricing/testimonials-bg.svg'
const logos = { breinfuel, laidbacksnacks, zerowater }

export const Testimonials = () => {
  const testimonials = [
    {
      reviewer: 'Brittany Alfinito',
      brand: 'ZeroWater',
      jobTitle: 'Marketing Coordinator',
      review:
        "I love that new customers are coming to our brand organically because of Creator.co's campaigns. We partnered with creators who were familiar with our products and genuinely wanted to share our products with their friends, family, and community.",
    },
    {
      reviewer: 'Ernie B. Manansala Jr.',
      brand: 'Brein Fuel',
      jobTitle: 'CMO',
      review:
        'Creator.co has been the most efficient and effective turnkey solution for all of my investments, and endeavors. They take out the guessing game, logistics, and provide everything into one dashboard for you to architect the success of your business with direct real relationships with creators.',
    },
    {
      reviewer: 'Ryley Humphry',
      brand: 'Laid Back Snacks',
      jobTitle: 'Co-Owner, VP Sales',
      review: `Laid Back Snacks has had the pleasure of working with Creator.co for seven months and we've been very happy with this partnership! They've helped us build our following on Instagram, and increase our engagement. Vinod and his team have been fantastic to work with - quick to respond and always ready to help out in any way they can. I highly recommend Creator.co to anyone who's interested!`,
    },
  ]

  const Testimonial = ({ testimonial }) => {
    const { reviewer, brand, jobTitle, review } = testimonial
    const brandClass = brand.toLowerCase().replace(/\s/g, '-')
    const brandName = brand.toLowerCase().replace(/\s/g, '')
    const brandLogo = logos[brandName]

    return (
      <div className='testimonial'>
        <div className={`testimonial-inner ${brandClass}`}>
          <div className='message-container'>
            <p className='message'>&quot;{review}&quot;</p>
            <div className='arrow'></div>
          </div>
          <div className='brand-info'>
            <div className='brand-logo'>
              <img className='logo' src={brandLogo} alt='' />
            </div>
            <p className='reviewer-name'>{reviewer}</p>
            <p className='job-title'>{jobTitle}</p>
            <p className='brand-name'>{brand}</p>
          </div>
        </div>
      </div>
    )
  }

  const DesktopTestimonials = ({ testimonials }) => (
    <div className='desktop-testimonials'>
      {testimonials.map((testimonial, i) => (
        <Testimonial key={i} testimonial={testimonial} />
      ))}
    </div>
  )

  const TestimonialsSlider = ({ testimonials }) => (
    <div className='testimonials-slider'>
      <Carousel autoplay effect='fade'>
        {testimonials.map((testimonial, i) => (
          <Testimonial key={i} testimonial={testimonial} />
        ))}
      </Carousel>
    </div>
  )

  return (
    <Wrapper>
      <div className='background'>
        <img src={background} alt='' />
      </div>
      <div className='inner'>
        <h2>
          What brands are
          <br />
          saying about us
        </h2>
        <DesktopTestimonials testimonials={testimonials} />
        <TestimonialsSlider testimonials={testimonials} />
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.section`
  width: 100%;
  position: relative;
  padding: 0;
  margin: auto;
  .background {
    position: absolute;
    height: 100%;
    width: 100%;
    display: grid;
    img {
      width: 80%;
      max-width: 1400px;
      margin: auto;
    }
  }
  .inner {
    padding: 40px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  h2 {
    font-family: 'Gilroy-ExtraBold', sans-serif;
    font-size: 26px;
    margin-bottom: 40px;
    align-self: flex-start;
  }
  .desktop-testimonials {
    display: none;
  }
  .testimonials-slider {
    width: 90vw;
    .slick-list {
      .slick-track {
        display: flex;
      }
    }
  }
  .slick-dots {
    position: absolute;
    padding: 0;
    margin: 0;
    display: flex;
    gap: 12px;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    li {
      list-style: none;
      background: rgba(35, 38, 47, 0.15);
      height: 10px;
      width: 10px;
      border-radius: 10px;
      margin: 0;
      cursor: pointer;
      transition: 0.2s ease-in-out;
      &:hover,
      &.slick-active {
        background: #23262f;
      }
      &.slick-active {
        width: 35px;
      }
      button {
        display: none;
      }
    }
  }
  .testimonial {
    margin-bottom: 40px;
    .testimonial-inner {
      .message-container {
        height: 290px;
        padding: 20px;
        margin-bottom: 40px;
        border-radius: 16px;
        position: relative;
        display: grid;
        .message {
          font-family: 'Avenir', sans-serif;
          font-size: 14px;
          color: #fff;
          margin: auto;
        }
        .arrow {
          position: absolute;
          bottom: -20px;
          left: 50%;
          transform: translateX(-50%) rotate(135deg);
          height: 40px;
          width: 40px;
        }
      }
      .brand-info {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      .brand-logo {
        height: 80px;
        width: 80px;
        border-radius: 50%;
        display: grid;
        place-content: center;
        padding: 5px;
      }
      .reviewer-name {
        font-family: 'Gilroy-ExtraBold', sans-serif;
        font-size: 22px;
        margin: 10px 0 0 0;
      }
      .job-title {
        color: #455880;
        font-size: 16px;
        margin: 0;
      }
      .brand-name {
        color: #455880;
        font-size: 12px;
        opacity: 0.7;
        margin: 0;
      }
      &.zerowater {
        .message-container {
          background: #ff6b58;
          .arrow {
            background: #ff6b58;
          }
        }
        .brand-logo {
          background: #fec6be;
        }
      }
      &.brein-fuel {
        .message-container {
          background: #afa1d8;
          .arrow {
            background: #afa1d8;
          }
        }
        .brand-logo {
          background: #e6e0f5;
        }
      }
      &.laid-back-snacks {
        .message-container {
          background: #027df0;
          .arrow {
            background: #027df0;
          }
        }
        .brand-logo {
          background: #cce4ff;
        }
      }
    }
  }

  @media only screen and (min-width: ${props => props.theme.breakpointTablet}) {
    .inner {
      max-width: 1200px;
      margin: auto;
      padding: 80px 40px;
    }
    h2 {
      font-size: 40px;
      text-align: center;
      align-self: center;
    }
    .testimonials-slider {
      width: 500px;
    }
    .testimonial {
      .testimonial-inner {
        .message-container {
          padding: 30px;
          .message {
            font-size: 18px;
          }
        }
      }
    }
  }

  @media only screen and (min-width: ${props => props.theme.breakpointDesktopWide}) {
    h2 {
      font-size: 54px;
      text-align: left;
      align-self: flex-start;
    }
    .testimonials-slider {
      display: none;
    }
    .desktop-testimonials {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 40px;
    }
    .testimonial {
      .testimonial-inner {
        .message-container {
          height: 420px;
        }
      }
    }
  }
`
