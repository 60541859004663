import {
  CheckCircleOutlined,
  DownloadOutlined,
  ExclamationCircleOutlined,
  LoadingOutlined,
  WarningOutlined,
} from '@ant-design/icons'
import { Button, Empty } from 'antd'
import axios from 'axios'
import moment from 'moment'
import React, { useContext, useState, useEffect } from 'react'
import CopyToClipboard from 'react-copy-to-clipboard'
import { useQuery } from 'react-query'

import { Wrapper } from './ExpandedShoutout'
import { NODE_URL } from '../../../../constants'
import { UserContext } from '../../../../contexts/UserContext'
import { findProfile } from '../../../../utils'
import CreatorAvatar from '../../../general/CreatorAvatar'

export const ExpandedStory = ({ story }) => {
  const { role } = useContext(UserContext)

  const [connectionStatus, setConnectionStatus] = useState(undefined)

  const storyData =
    story.metaData && Object.keys(story.metaData).length !== 0 ? story.metaData : story.data

  const socialProfile = findProfile(story.user.socialProfiles, 'instagram') // stories are instagram only
  const phylloAccountId = socialProfile?.phylloData?.account?.id
  const { data, status } = useQuery(['phyllo-data', phylloAccountId], async () => {
    if (!phylloAccountId) return { status: 'NOT_CONNECTED' }
    const { data: phylloData } = await axios.get(`${NODE_URL}/phyllo-account/${phylloAccountId}`)
    return phylloData
  })

  useEffect(() => {
    if (!data) return
    setConnectionStatus(data.status)
  }, [data])

  return (
    <Wrapper>
      {role === 'administrator' && (
        <div className='admin-bar'>
          <span style={{ marginRight: '10px' }}>For admins:</span>
          <div className='ids'>
            <CopyToClipboard text={story.id}>
              <div className='id'>Story #{story.id}</div>
            </CopyToClipboard>
          </div>
        </div>
      )}

      <div className='inner'>
        <section className='post'>
          <div className='header'>
            <a
              className='user-info'
              href={socialProfile?.profileUrl}
              target='_blank'
              rel='noopener noreferrer'>
              <CreatorAvatar
                size={50}
                id={story.user.id}
                url={socialProfile?.profilePicUrl}
                initials={`${story.user.firstName?.charAt(0).toUpperCase()} ${story.user.lastName
                  ?.charAt(0)
                  .toUpperCase()}`}
              />
              <div className='text'>
                <p className='full-name'>
                  {story.user.firstName} {story.user.lastName}
                </p>
                <div className='social-info'>
                  <span className='username'>@{socialProfile?.username}</span>
                  <span
                    className={`connection-status ${
                      connectionStatus === 'NOT_CONNECTED' || connectionStatus === 'SESSION_EXPIRED'
                        ? 'not-connected'
                        : ''
                    }`}>
                    {status === 'loading' || !data ? (
                      <LoadingOutlined spin />
                    ) : (
                      <>
                        {connectionStatus === 'CONNECTED' ? (
                          <CheckCircleOutlined />
                        ) : (
                          <ExclamationCircleOutlined />
                        )}
                        {connectionStatus === 'CONNECTED' && 'Connected'}
                        {connectionStatus === 'NOT_CONNECTED' && 'Not Connected'}
                        {connectionStatus === 'SESSION_EXPIRED' && 'Connection Expired'}
                      </>
                    )}
                  </span>
                </div>
              </div>
            </a>
          </div>
          <div className='content'>
            <div className='media'>
              {storyData.thumbnailUrl || storyData.thumbnail ? (
                <img src={storyData.thumbnailUrl || storyData.thumbnail} alt='' />
              ) : (
                <div className='placeholder'>
                  <Empty description='No media found.' />
                </div>
              )}
            </div>
            <div className='caption'>{story.title}</div>
            <div className='content-row'>
              <span className='date'>
                {story.posted ? moment(story.posted).format('lll') : 'Date Unknown'}
              </span>
              <div className='actions'>
                {!storyData.copyrightedMedia ? (
                  <a href={storyData.mediaUrl} target='_blank' rel='noopener noreferrer'>
                    <Button type='primary'>
                      <DownloadOutlined /> Download Assets
                    </Button>
                  </a>
                ) : (
                  <span>
                    <WarningOutlined /> This post contains copyrighted material and we are unable to
                    retrieve its content.
                  </span>
                )}
              </div>
            </div>
          </div>
        </section>

        <section className='metrics-section'>
          <div className='title'>Metrics</div>
          <div className='stats'>
            <div className='stat'>
              <span className='label'>Reach</span>
              <span className='value'>{story.reach ? story.reach.toLocaleString() : 'N/A'}</span>
            </div>
            <div className='stat'>
              <span className='label'>Impressions</span>
              <span className='value'>
                {story.impressions ? story.impressions.toLocaleString() : 'N/A'}
              </span>
            </div>
          </div>
        </section>
      </div>
    </Wrapper>
  )
}
