import { BarChartOutlined, DownloadOutlined } from '@ant-design/icons'
import { Alert, Button, Spin, Tooltip } from 'antd'
import moment from 'moment'
import React, { useContext, useState } from 'react'
import { useQuery } from 'react-query'
import styled from 'styled-components'

import AudienceDataInner from './AudienceDataInner'
import { BrandContext } from '../../../contexts/BrandContext'
import { UpdateReport } from '../../../layout/creator-search/lists/CreatorListItem'

const AudienceData = ({ socialProfileId }) => {
  const { brandId, getAudienceData, purchaseAudienceData } = useContext(BrandContext)

  const newAudienceData = async force => {
    setDeepInsightsLoading(true)
    await purchaseAudienceData(brandId, socialProfileId, !!force)
    setDeepInsightsLoading(false)
  }

  const { data: audienceData, status: audienceDataStatus } = useQuery(
    ['audience-data', { brandId, socialProfileId }],
    getAudienceData,
    {
      retry: (failureCount, error) => error?.response?.status === 503,
    }
  )

  const [deepInsightsLoading, setDeepInsightsLoading] = useState(false)
  const daysOld = parseInt(
    moment().diff(moment(audienceData?.report?.report_info?.created), 'days', true)
  )

  return (
    <Wrapper>
      {audienceDataStatus === 'success' &&
        (audienceData?.report?.report_info ? (
          <>
            <AudienceDataInner report={audienceData.report} />
            <div className='report'>
              <p>Last Updated: {moment(audienceData.report.report_info.created).format('ll')}</p>
              <a href={audienceData.reportUrl} download>
                <Button icon={<DownloadOutlined />} type='secondary'>
                  Download
                </Button>
              </a>
            </div>
            <UpdateReport
              isLoading={deepInsightsLoading}
              onUpdate={newAudienceData.bind(this, true)}
              daysOld={daysOld}
            />
          </>
        ) : (
          <Tooltip title='Costs 1 credit'>
            <Button
              icon={<BarChartOutlined />}
              type='secondary'
              loading={deepInsightsLoading}
              onClick={newAudienceData}>
              Purchase
            </Button>
          </Tooltip>
        ))}
      {audienceDataStatus === 'loading' && <Spin />}
      {audienceDataStatus === 'error' && (
        <Alert type='error' description='Oops, something went wrong.' />
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: grid;
  place-items: center;
  min-height: 100px;

  .report {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: baseline;
  }

  p {
    font-size: 12px;
    color: ${props => props.theme.crcoGrey};
  }
`

export default AudienceData
