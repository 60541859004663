import {
  CheckCircleOutlined,
  DisconnectOutlined,
  EditOutlined,
  LinkOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons'
import {
  Button,
  Badge,
  Row,
  Table,
  Descriptions,
  Divider,
  Typography,
  notification,
  message,
  Empty,
  Tooltip,
} from 'antd'
import moment from 'moment'
import React, { Fragment, useState, useContext } from 'react'
import { useQuery, queryCache } from 'react-query'

import { GA4SetupModal } from '../../components/modals/GA4SetupModal'
import { BrandContext } from '../../contexts/BrandContext'
import { useAnalyticsApi } from '../../custom-hooks/analyticsApi'
import gaIcon from '../../images/icons/ga-logo.svg'
import { uppercaseFirstChar } from '../../utils'

const BrandGoogleAnalytics = () => {
  // state
  const [setupModal, setSetupModal] = useState(false)
  const [deleting, setDeleting] = useState(false)
  // API
  const { brandId } = useContext(BrandContext)
  const analyticsApi = useAnalyticsApi()
  const { data: integrations } = useQuery(['google-analytics-integrations', brandId], () =>
    analyticsApi.integration.integrationGa4SDetail(brandId).then(d => d.integrations)
  )
  // Handlers
  const handleConnect = () => {
    setSetupModal(true)
  }

  const handleDisconnect = async () => {
    setDeleting(true)
    try {
      await analyticsApi.integration.integrationGa4Delete(integrations[0]?.id, brandId)
      message.success('Google Analytics disconnected!')
    } catch (e) {
      notification.error({
        message: 'Error while deleting integration!',
        description: e?.response?.data?.err || '',
      })
    } finally {
      queryCache.invalidateQueries(['google-analytics-integrations', brandId])
      setDeleting(false)
    }
  }
  const handleSetupClose = () => {
    setSetupModal(false)
    queryCache.invalidateQueries(['google-analytics-integrations', brandId])
  }

  return (
    <div className='account-section' id='google-analytics'>
      <div className='section-header'>
        <h2 className='section-title'>
          <img className='icon' src={gaIcon} alt='' /> Google Analytics
        </h2>
        <div className='buttons'>
          {integrations?.length > 0 && (
            <Button
              type='link'
              danger
              onClick={handleDisconnect}
              disabled={deleting}
              loading={deleting}
              icon={<DisconnectOutlined />}>
              Disconnect
            </Button>
          )}
          <Button
            type='primary'
            onClick={handleConnect}
            disabled={deleting}
            icon={integrations?.length ? <EditOutlined /> : <LinkOutlined />}>
            {integrations?.length ? 'Update' : 'Connect'}
          </Button>
        </div>
      </div>
      <div className='section-body'>
        {!integrations?.length && (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description={
              <>
                No integration yet.{' '}
                <Tooltip
                  title={`
                Unlock powerful insights by integrating your brand's Google Analytics with our platform. Our simple
                process will guide you through each step. No technical skills required!
            `}>
                  <InfoCircleOutlined />
                </Tooltip>
              </>
            }
          />
        )}
        {integrations?.length > 0 && (
          <>
            <Row type='flex' justify='space-between' align='middle'>
              <Typography.Paragraph style={{ marginTop: 10 }}>
                <Badge color='green' style={{ marginRight: 10 }} />
                Connected <CheckCircleOutlined />
              </Typography.Paragraph>
            </Row>
            <Divider />
            {'Integration Details'}
            <Table
              rowKey={'id'}
              pagination={false}
              columns={[
                {
                  title: 'Type',
                  dataIndex: 'type',
                  key: 'type',
                  render: p => uppercaseFirstChar(p),
                },
                {
                  title: 'Status',
                  dataIndex: 'status',
                  key: 'status',
                  render: p => uppercaseFirstChar(p),
                },
                {
                  title: 'Last sync date',
                  dataIndex: 'syncDate',
                  key: 'syncDate',
                  render: p => (p ? moment(p).format('ll') : '-'),
                },
              ]}
              expandable={{
                expandedRowRender: record => (
                  <Descriptions bordered size='small'>
                    {Object.keys(record.syncData || {}).map(k => {
                      if (record.syncData[k]) {
                        const m = moment(record.syncData[k])
                        const v = m.isValid() ? m.format('ll') : record.syncData[k]
                        return (
                          <Descriptions.Item key={k} label={uppercaseFirstChar(k)}>
                            {v}
                          </Descriptions.Item>
                        )
                      }
                      return <Fragment key={k}></Fragment>
                    })}
                  </Descriptions>
                ),
              }}
              dataSource={integrations?.[0]?.scheduledTasks || []}
            />
          </>
        )}
        {setupModal && (
          <GA4SetupModal
            brandId={brandId}
            integration={integrations?.[0]}
            onClose={handleSetupClose}
          />
        )}
      </div>
    </div>
  )
}
export default BrandGoogleAnalytics
