import React, { useContext, useEffect, useState } from 'react'
import { CircleFlag } from 'react-circle-flags'
import { useQuery } from 'react-query'
import Truncate from 'react-truncate'
import styled from 'styled-components'

import { BrandContext } from '../../contexts/BrandContext'
import { GlobalContext } from '../../contexts/GlobalContext'
import affiliate from '../../images/icons/affiliate.svg'
import bespoke from '../../images/icons/bespoke.svg'
import giveaway from '../../images/icons/giveaway.svg'
import globe from '../../images/icons/globe.svg'
import shoutout from '../../images/icons/shoutout.svg'
import instagram from '../../images/icons/social/instagram-color-square.svg'
import tiktok from '../../images/icons/social/tiktok-color-square.svg'
import youtube from '../../images/icons/social/youtube-color-square.svg'
import ugc from '../../images/icons/ugc.svg'
const socialIcons = { instagram, youtube, tiktok }

export const CampaignPreview = ({ formData }) => {
  const { brandId, fetchBrandData } = useContext(BrandContext)
  const { getCategories } = useContext(GlobalContext)
  const { data: brandData } = useQuery(['brand', brandId], fetchBrandData)
  const [filteredInterests, setFilteredInterests] = useState([])
  const [reachRange, setReachRange] = useState(null)
  const [engagementRange, setEngagementRange] = useState(null)

  const { data: categories } = useQuery('categories', getCategories)

  useEffect(() => {
    // filtering to match selected interests
    if (formData && formData.interests && categories) {
      setFilteredInterests(
        categories.filter(category => formData.interests.includes(category.slug))
      )
    }
  }, [categories, formData, formData.interests])

  useEffect(() => {
    // setting reach and engagement ranges based on selected social channels & requirements

    if (formData?.socialChannels?.length) {
      // using a copy to avoid mutating state
      const socialChannels = [...formData.socialChannels]

      const sortedReach = socialChannels.sort((a, b) => {
        const aReach = formData[`${a}Reach`]
        const bReach = formData[`${b}Reach`]
        return aReach > bReach ? 1 : bReach > aReach ? -1 : 0
      })

      const lowestReach = formData[`${sortedReach[0]}Reach`]
      const highestReach =
        socialChannels.length > 1 ? formData[`${sortedReach[sortedReach.length - 1]}Reach`] : null

      setReachRange(
        lowestReach || highestReach
          ? `${lowestReach?.toLocaleString() || 0}${
              highestReach && highestReach !== lowestReach
                ? `-${highestReach.toLocaleString()}+`
                : '+'
            }`
          : '-'
      )

      // do the same for engagement
      const sortedEngagement = socialChannels.sort((a, b) => {
        const aEngagement = formData[`${a}Engagement`]
        const bEngagement = formData[`${b}Engagement`]
        return aEngagement > bEngagement ? 1 : bEngagement > aEngagement ? -1 : 0
      })

      const lowestEngagement = formData[`${sortedEngagement[0]}Engagement`]
      const highestEngagement =
        socialChannels.length > 1
          ? formData[`${sortedEngagement[sortedEngagement.length - 1]}Engagement`]
          : null

      setEngagementRange(
        lowestEngagement || highestEngagement
          ? `${lowestEngagement?.toLocaleString() || 0}${
              highestEngagement && highestEngagement !== lowestEngagement
                ? `-${highestEngagement.toLocaleString()}%+`
                : '%+'
            }`
          : '-'
      )
    }
  }, [formData])

  return (
    <Wrapper>
      <div className='campaign-card-inner'>
        <span className={`status ${formData.status}`}>{formData.status || 'New'} Campaign</span>
        <div className='basic-info'>
          <p className='brand'>
            <Truncate lines={1} ellipsis={'...'} trimWhitespace>
              {brandData?.name}
            </Truncate>
          </p>
          <p className='title'>
            <Truncate lines={2} ellipsis={'...'} trimWhitespace>
              {formData.title}
            </Truncate>
          </p>
          {formData.maxPaidAmount && (
            <p className='paid-campaign'>
              Payment:{' '}
              <span className='value'>
                $
                {formData.minPaidAmount
                  ? `${formData.minPaidAmount.toLocaleString()}-${formData.maxPaidAmount.toLocaleString()}`
                  : formData.maxPaidAmount.toLocaleString()}{' '}
                USD
              </span>
            </p>
          )}
        </div>
        <div className='details'>
          <div className='product'>
            <div className={`thumbnail ${!formData.thumbnailSrc && 'placeholder'}`}>
              {formData.thumbnailSrc && <img src={formData.thumbnailSrc} alt='' />}
            </div>
          </div>
          <div className='requirements'>
            <div className='qualifications'>
              <div className='requirement'>
                <span className='label'>
                  {formData.socialChannels?.[0] === 'youtube' ? 'Subscribers' : 'Followers'}
                </span>
                <span className='value'>{formData.socialChannels?.length ? reachRange : '-'}</span>
              </div>
              <div className='requirement'>
                <span className='label'>Engagement</span>
                <span className='value'>
                  {formData.socialChannels?.length ? engagementRange : '-'}
                </span>
              </div>
              {/* age range default 13+ */}
              <div className='requirement'>
                <span className='label'>Age</span>
                <span className='value'>
                  {formData.minAge && formData.maxAge
                    ? `${formData.minAge} to ${formData.maxAge}`
                    : formData.minAge && !formData.maxAge
                      ? `${formData.minAge}+`
                      : !formData.minAge && formData.maxAge
                        ? `13 to ${formData.maxAge}`
                        : '13+'}
                </span>
              </div>
              <div className='requirement'>
                <span className='label'>Residence</span>
                <span className='value'>
                  {formData.countries?.length ? (
                    formData.countries.length > 2 ? (
                      <>
                        {formData.countries.slice(0, 2).map((country, key) => (
                          <CircleFlag
                            key={key}
                            className='flag'
                            countryCode={`${country?.value?.toLowerCase()}`}
                          />
                        ))}
                        <img className='flag plus' src={globe} alt='More countries' />
                      </>
                    ) : (
                      formData.countries.map((country, key) => (
                        <CircleFlag
                          key={key}
                          className='flag'
                          countryCode={`${country?.value?.toLowerCase()}`}
                        />
                      ))
                    )
                  ) : (
                    <img className='flag' src={globe} alt='Global' />
                  )}
                </span>
              </div>
              <div className='requirement'>
                <span className={`niche`}>
                  {filteredInterests.length > 0
                    ? filteredInterests.map(item => item.title).join(' • ')
                    : 'Any niche'}
                </span>
              </div>
            </div>
            <div className='objectives'>
              <div className='strategy'>
                {formData.strategy && (
                  <img
                    className='icon'
                    src={
                      formData.strategy === 'ugc'
                        ? ugc
                        : formData.strategy === 'shoutout'
                          ? shoutout
                          : formData.strategy === 'giveaway'
                            ? giveaway
                            : formData.strategy === 'bespoke'
                              ? bespoke
                              : formData.strategy === 'affiliate' && affiliate
                    }
                    alt=''
                  />
                )}
                {formData.socialChannels?.map((channel, i) => (
                  <img src={socialIcons[channel]} alt={channel} key={i} />
                ))}
                {!!formData.contentFormats?.length &&
                  !!formData.contentFormats[0].length &&
                  formData.contentFormats.map(
                    format =>
                      !!format.length && (
                        <span key={format} className='format'>
                          {format === 'Video Post' ? 'Video' : format}
                          {formData.contentFormatCounts?.[format?.toLowerCase()] &&
                            ` (${formData.contentFormatCounts?.[format?.toLowerCase()]})`}
                        </span>
                      )
                  )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  )
}
const Wrapper = styled.div`
  background: #fff;
  border: 1px solid rgb(224, 224, 247);
  min-height: 100%;
  width: 100%;
  max-width: 400px;
  border-radius: 20px;
  transition: 0.2s ease-in-out;
  .ant-popover-arrow {
    right: 8px;
  }
  .ant-popover-buttons button {
    padding: 3px 8px 0 8px;
  }
  p {
    margin: 0;
  }
  .fade-in {
    height: 100%;
    position: relative;
  }
  .campaign-card-inner {
    color: ${props => props.theme.crcoMidnight};
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 10px;
    position: relative;
  }
  .status {
    background: #18d96e;
    padding: 5px 10px 2px 10px;
    font-size: 12px;
    text-transform: uppercase;
    color: #fff;
    border-radius: 10px;
    line-height: 1;
    position: absolute;
    top: -10px;
    left: 50%;
    transform: translateX(-50%);
    &.pending {
      background: #80ccff;
    }
    &.draft {
      background: #fc9c44;
    }
  }
  .basic-info {
    width: 100%;
    text-align: center;
    margin: auto;
    padding-bottom: 10px;
    .brand {
      color: #080830;
      font-size: 0.8rem;
      opacity: 0.5;
    }
    .title {
      font-size: 0.9rem;
      padding: 5px 0;
    }
    .paid-campaign,
    .retail-value {
      color: rgba(0, 0, 0, 0.3);
      font-size: 0.8rem;
    }
    .paid-campaign {
      .value {
        color: #00c457;
      }
    }
    .retail-value {
      .value {
        color: ${props => props.theme.crcoTechBlue};
      }
    }
  }
  .details {
    display: flex;
    border-top: 1px solid #e6e6e6;
    .product {
      width: 50%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-evenly;
      padding: 10px;
      border-right: 1px solid #e6e6e6;
      .thumbnail {
        height: 125px;
        width: 125px;
        display: grid;
        place-content: center;
        overflow: hidden;
        border-radius: 5px;
        &.placeholder {
          background: #f2f2f2;
        }
        img {
          height: 100%;
          width: 100%;
          object-fit: cover;
          border-radius: 5px;
        }
      }
    }
    .requirements {
      width: 50%;
      padding: 10px;
      .header-text {
        display: none;
      }
      .qualifications {
        padding-bottom: 5px;
        border-bottom: 1px solid #e6e6e6;
        margin-bottom: 10px;
        .requirement {
          display: flex;
          justify-content: space-between;
          font-size: 0.7rem;
          color: #808080;
          .eligibility-indicator {
            margin-right: 5px;
          }
          .label {
            white-space: nowrap;
          }
          .value {
            color: ${props => props.theme.crcoTechBlue};
            display: flex;
          }
          .flag {
            height: 15px;
            width: 15px;
            margin-left: 5px;
            &.plus {
              border: 1px dotted ${props => props.theme.crcoTechBlue};
              border-radius: 50%;
              opacity: 0.5;
            }
          }
        }
        .niche {
          border: 1px solid;
          border-radius: 20px;
          text-align: center;
          font-size: 0.7rem;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          padding: 0 5px;
          width: 100%;
          &.eligible {
            border-color: ${props => props.theme.crcoTechBlue};
            color: ${props => props.theme.crcoTechBlue};
          }
          &.not-eligible {
            border-color: #ccc;
            color: #999;
          }
        }
      }
      .objectives {
        .strategy {
          display: flex;
          flex-wrap: wrap;
          gap: 5px;
          img {
            height: 20px;
          }
          .format {
            background: #ecf6ff;
            color: #027df0;
            display: grid;
            place-content: center;
            padding: 0 5px;
            border-radius: 3px;
            font-size: 0.7rem;
          }
        }
      }
    }
  }
  @media only screen and (min-width: 900px) {
    width: 310px;
    .campaign-card-inner {
      padding: 20px;
    }
    .basic-info {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .brand {
        font-size: 0.9rem;
      }
      .title {
        font-size: 1rem;
        min-height: 60px;
        display: grid;
        place-content: center;
      }
    }
    .details {
      flex-direction: column;
      border: none;
      .product {
        width: 100%;
        padding: 0 0 10px 0;
        border-right: none;
        border-bottom: 1px solid #e6e6e6;
        .thumbnail {
          height: 200px;
          width: 200px;
        }
      }
      .requirements {
        width: 100%;
        padding: 10px 0 0 0;
        .header-text {
          display: block;
          text-align: center;
          margin-bottom: 5px;
        }
        .qualifications {
          .requirement {
            font-size: 0.8rem;
          }
          .niche {
            font-size: 0.8rem;
          }
        }
        .objectives {
          display: flex;
          flex-direction: column;
          align-items: center;
          .objectives-content .format {
            font-size: 0.8rem;
          }
        }
      }
    }
  }
`
