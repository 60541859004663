import { Empty, Table } from 'antd'
import React from 'react'
import * as yup from 'yup'

import { BrandIdFilter } from '../filters/BrandIdFilter'
import { CampaignIdFilter } from '../filters/CampaignIdFilter'
import { DateRangeFilter } from '../filters/DateRangeFilter'
import { ManualDateRangeFilter } from '../filters/ManualDateRangeFilter'
import { ManageWidget } from '../ManageWidget'
import { useCampaignOptions } from '../utils'
import { MetricsWrapper, WidgetCard } from '../WidgetCard'

export const ShopifyDiscountCodeSales = ({
  widget,
  activeView,
  setActiveView,
  brandData,
  data,
}) => {
  const campaignOptions = useCampaignOptions(brandData)

  const defaultValues = {
    title: widget.defaultTitle,
    ...widget.defaultFilters,
  }

  const initialValues = {
    title: widget.title || defaultValues.title,
    brandIds: widget.filters?.brandIds || defaultValues.brandIds,
    campaignIds: widget.filters?.campaignIds || defaultValues.campaignIds,
    fromDate: widget.filters?.fromDate || defaultValues.fromDate,
    toDate: widget.filters?.toDate || defaultValues.toDate,
    dateRange:
      widget.filters?.fromDate ||
      defaultValues.fromDate ||
      widget.filters?.toDate ||
      defaultValues.toDate
        ? 'custom'
        : widget.filters?.dateRange || defaultValues.dateRange,
  }

  const validationSchema = yup.object().shape({
    title: yup.string().required('Required').max(50, 'Must be 50 characters or less'),
    brandIds: yup.array(),
    campaignIds: yup.array(),
    dateRange: yup.string(),
  })

  const constructData = values => {
    return {
      title: values.title,
      filters: {
        brandIds: values.brandIds,
        campaignIds: values.campaignIds,
        fromDate: values.fromDate,
        toDate: values.toDate,
        dateRange: values.dateRange,
      },
    }
  }

  const Filters = ({ setFieldValue }) => {
    return (
      <>
        {!!brandData.childBrands?.length && <BrandIdFilter brandData={brandData} />}
        <CampaignIdFilter campaignOptions={campaignOptions} />

        <div className='date-range-wrapper'>
          <ManualDateRangeFilter setFieldValue={setFieldValue} />
          <DateRangeFilter setFieldValue={setFieldValue} />
        </div>
      </>
    )
  }

  const Metrics = () => {
    const columns = [
      {
        title: 'Creator',
        key: 'creator',
        sorter: (a, b) => a.creator.localeCompare(b.creator), // sort by string value
      },
      {
        title: 'Campaign',
        key: 'campaign',
        sorter: (a, b) => a.campaign.localeCompare(b.campaign),
      },
      ...(brandData.childBrands?.length
        ? [
            // agencies only
            {
              title: 'Brand',
              key: 'brand',
              sorter: (a, b) => a.brand.localeCompare(b.brand),
            },
          ]
        : []),
      {
        title: 'Sales Count',
        key: 'salesCount',
        sorter: (a, b) => a.salesCount - b.salesCount, // sort by numerical value
      },
      {
        title: 'Sales Total',
        key: 'salesTotal',
        sorter: (a, b) => a.salesTotal - b.salesTotal,
        render: (text, record) => `$${record.salesTotal.toLocaleString()}`, // format with $ sign
      },
      {
        title: 'Coupon Code',
        key: 'couponCode',
      },
    ].map(col => ({
      dataIndex: col.key,
      ...col,
    }))

    return (
      <MetricsWrapper className='overflow-auto'>
        {data?.length ? (
          <Table
            columns={columns}
            dataSource={data}
            pagination={{
              defaultPageSize: 5,
              hideOnSinglePage: true,
              showSizeChanger: true,
              pageSizeOptions: ['5', '10', '20'],
            }}
          />
        ) : (
          <div className='no-data'>
            <Empty description='No data.' />
          </div>
        )}
      </MetricsWrapper>
    )
  }

  const settingsProps = {
    widget,
    Filters,
    defaultValues,
    initialValues,
    validationSchema,
    constructData,
    activeView,
    setActiveView,
    brandId: brandData.id,
  }

  const cardProps = {
    widget,
    settings: <ManageWidget {...settingsProps} />,
    metrics: <Metrics />,
    size: 'large',
    brandData,
  }

  return <WidgetCard {...cardProps} />
}
