import { Button } from 'antd'
import React, { useContext, useEffect } from 'react'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'

import CampaignHeader from './CampaignHeader'
import CampaignTabs from './tabs/CampaignTabs'
import { BrandContext } from '../../contexts/BrandContext'
import { CampaignContext } from '../../contexts/CampaignContext'
import { UserContext } from '../../contexts/UserContext'
import { useDocumentTitle } from '../../custom-hooks/useDocumentTitle.js'
import LoadingLogo from '../general/LoadingLogo'

const SingleCampaign = ({ brand }) => {
  const { campaignID: campaignId } = useParams()
  const { role } = useContext(UserContext)
  const { setBrandId } = useContext(BrandContext)
  const { setSelectedCampaignID, fetchCampaign } = useContext(CampaignContext)
  const {
    data: campaign,
    status,
    error,
    refetch,
  } = useQuery(['campaign', campaignId], fetchCampaign, {
    retry: (failureCount, error) => !error.response?.status === 404,
  })

  useEffect(() => {
    setSelectedCampaignID(campaignId)
  }, [campaignId, setSelectedCampaignID])

  useEffect(() => {
    campaign && setBrandId(campaign.brand?.id)
  }, [campaign, setBrandId])

  useDocumentTitle(
    role === 'administrator'
      ? brand
        ? `${brand} - ${campaign?.title || 'Campaign'} | Brand Dashboard`
        : 'Brand Dashboard'
      : campaign?.title
        ? `${campaign.title} | My Campaigns | Brand Dashboard`
        : 'My Campaigns | Brand Dashboard'
  )

  return (
    <Wrapper>
      {status === 'success' && (
        <>
          <CampaignHeader campaign={campaign} />
          <CampaignTabs campaign={campaign} />
        </>
      )}
      {status === 'loading' && (
        <div style={{ height: '100%', display: 'grid', placeItems: 'center' }}>
          <LoadingLogo />
        </div>
      )}
      {/* TODO: proper not found state and updated error UI */}
      {status === 'error' && (
        <div className='error'>
          <div>
            Oops, something went wrong while fetching the campaign info.
            {error.response?.data.code === 'campaign_not_found' &&
              " We couldn't find the requested campaign."}
            <Button type='primary' onClick={refetch}>
              Try Again
            </Button>
          </div>
        </div>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  background: #f4f4f8;
  height: 100%;
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 20px;
  display: flex;
  flex-direction: column;

  .error {
    display: grid;
    place-items: center;
    height: 100%;

    & > div {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 16px;
    }
  }
`

export default SingleCampaign
